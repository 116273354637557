import React, { useEffect } from 'react'; 
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Text } from 'pctComponents/atoms';
import './style.scss';

type Props = {
    obj: any;
};

const ModalEquivalencyPages = NiceModal.create(({ obj }: Props) => {
    const modal = useModal();
    const { pages } = obj;

    useEffect(() => {
        if (modal.visible) {
            document.body.classList.add('bodyOverflowHidden');
        } else {
            document.body.classList.remove('bodyOverflowHidden');
        }

        return () => {
            document.body.classList.remove('bodyOverflowHidden');
        };
    }, [modal]);

    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        modal.remove();
    };

    return (
        <div className="modal-equivalency-pages">
            <div >
                <div className="modal-equivalency-body-pct">
                    <div className="modal-equivalency-title-wrapper-top">
                        <Text component="h3" text="Concluído por equivalência" />
                    </div>
                    <div className="modal-equivalency-page-description">
                        <Text component="h4" text="Páginas com equivalência:" />
                    </div>
                    <div className="modal-equivalency-page-list">
                        {pages.map((page, index) => (
                            <p key={index}>{page.title}</p>
                        ))}
                    </div>
                </div>
                <div className="modal-equivalency-page-footer">
                    <div className="modal-equivalency-Btn">
                        <Button text="Ok, entendi" className="button03" callEvent={(e) => handleClose(e)} />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ModalEquivalencyPages;
