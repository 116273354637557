import "./style.scss";

import React from "react";

const EvaluationSVG = () => {
  const svgStr = `<svg
            id="Grupo_1090"
            data-name="Grupo 1090"
            xmlns="http://www.w3.org/2000/svg"
            width="523.742"
            height="307.932"
            viewBox="0 0 523.742 307.932"
          >
            <g id="Grupo_1085" data-name="Grupo 1085" opacity="1">
              <rect
                id="GettyImages-95574693"
                width="523.742"
                height="307.932"
                rx="5"
                className="svgBg"
              />
            </g>
            <g
              id="Grupo_1102"
              data-name="Grupo 1102"
              transform="translate(7660 -18180)"
            >
              <path
                id="Caminho_546"
                data-name="Caminho 546"
                d="M-21.526,24.5l-8.244,8.244,26.5,26.5L41.807,14.166,55.621.354,47.376-7.891-3.27,42.754Z"
                transform="translate(-7399.355 18299.322)"
                fill="#fff"
                fill-rule="evenodd"
              />
              <path
                id="Caminho_547"
                data-name="Caminho 547"
                d="M52.173,81.172h-83.18V-2.008H28.407V-13.889H-31.007A11.918,11.918,0,0,0-42.89-2.008v83.18A11.92,11.92,0,0,0-31.007,93.055h83.18A11.918,11.918,0,0,0,64.054,81.172V33.639H52.173Z"
                transform="translate(-7410.11 18293.889)"
                className="svgBorder"
                fill-rule="evenodd"
              />
            </g>
          </svg>`;

  return svgStr;
}

export default EvaluationSVG
