import { useEffect, useState } from 'react'

const useModalOverflowHidden = (modal) => {
    useEffect(() => {
        if (modal.visible) {
            document.body.classList.add('bodyOverflowHidden');
        } else {
            document.body.classList.remove('bodyOverflowHidden');
        }

        return () => {
            document.body.classList.remove('bodyOverflowHidden');
        };
    }, [modal.visible]);
}

export default useModalOverflowHidden