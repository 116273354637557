import { createNodeEnrollments } from '../services/api';
import { useHistory } from "react-router-dom";
import { getProfileInfo } from "../services/utils";

const useNodeEnrollment =  () => {

  const history = useHistory();
  
  const callNodeEnrollment = ({ collection, modalLoadingNodeEnrollment, _parent, _onFinished, doestGoToLevelPage = false }) =>{
        
        modalLoadingNodeEnrollment.show({
          obj: {
            message: "Aguarde, iniciando página.",
            closeBtn: false,
            loadding: true,
          },
        });

        let nodeEnrollmentObj;

        if(_parent){
          nodeEnrollmentObj = {
            "idUser": collection.data.nodeEnrollment.enrollment.idUser,
            "idEnrollment": collection.data.nodeEnrollment.enrollment.idEnrollment,
            "nodeCode": collection.data.nodeCode,
            "idTeam": collection.data.nodeEnrollment.enrollment.idTeam,
            "idParentNodeEnrollment": _parent.node.nodeEnrollment.idNodeEnrollment
          };
        }else{
          nodeEnrollmentObj = {
            "idUser": collection.data.hasOwnProperty('node') ? collection.data.node.nodeEnrollment.enrollment.idUser : collection.data.nodeEnrollment.enrollment.idUser,
            "idEnrollment": collection.data.hasOwnProperty('node') ? collection.data.node.nodeEnrollment.enrollment.idEnrollment : collection.data.nodeEnrollment.enrollment.idEnrollment,
            "nodeCode": collection.data.hasOwnProperty('node') ? collection.data.node.nodeEnrollment.node.nodeCode : collection.data.nodeCode,
            "idTeam": collection.data.hasOwnProperty('node') ? collection.data.node.nodeEnrollment.enrollment.idTeam : collection.data.nodeEnrollment.enrollment.idTeam,
          };

        }

        createNodeEnrollments(nodeEnrollmentObj)
          .then((result) => {
            if(_onFinished){
              _onFinished(result);
            }

            if(_parent && !doestGoToLevelPage){
                history.push(`/level/${nodeEnrollmentObj.idEnrollment}/${nodeEnrollmentObj.nodeCode}`);
            }

            modalLoadingNodeEnrollment.remove();
            

          }).catch((error) => {
            console.log(error);
          });
            

    
  }

  return { callNodeEnrollment }
}

export default useNodeEnrollment;