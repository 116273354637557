import { DialogProps } from "../@model/ManagerTrial.model";

export const dialogContainerModel = ( model: DialogProps ) => {
    const dialogData = {
        'ConfirmTrial': {
            title: `Parabéns, você acaba de Iniciar a degustação do <span>Plano ${model.plan}</span>!`,
            subTitle: 'Verifique o seu e-mail para mais orientações de acesso.',  
            buttonConfirm: {
                text: 'Acesse sua degustação',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
        'ConfirmSucess': {
            title: `Obrigado sua mensagem foi enviada com sucesso!`,
            subTitle: 'Em breve, o nosso time comercial entrará em contato.',  
            buttonConfirm: {
                text: 'Ok, entendi',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
        'ConfirmPlan': {
            title: `Parabéns, você acaba de solicitar a contratação do <span>Plano ${model.plan}</span>!`,
            subTitle: 'Nosso time comercial entrará em contato em breve para finalizar os detalhes.',  
            // buttonCancel: {
            //     text: 'Continuar na área do aluno',
            //     callEvent : model.buttonCancel?.callEvent
            // },
            buttonConfirm: {
                text: 'Ok, entendi',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
        'FinishTrial': {
            title: `Atenção!`,
            subTitle: `Seu período de degustação do <span>Plano ${model.plan}</span> se <span>encerrou</span>.<br> A qualquer momento você pode continuar trabalhando na aprendizagem da sua empresa, basta contratar alguns de nossos planos.`,  
            buttonConfirm: {
                text: 'Ok, entendi',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
        'ExtendTrial': {
            title: `Parabéns, você acaba de estender a degustação do <span>Plano ${model.plan}</span> por mais <span>${model.agreement} dia${model.agreement && model.agreement > 0 ? 's': ''}</span>.`,
            subTitle: `Aproveite ao máximo todas as funcionalidades que a ferramenta tem para lhe oferecer.`,  
            buttonConfirm: {
                text: 'Ok, entendi',
                callEvent : model.buttonConfirm?.callEvent
            }
        },
    }[model.type as string]

    return dialogData as DialogProps
}