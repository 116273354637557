import "./style.scss";

import React from "react";

import { Text } from '../'
import { AiFillStar } from "react-icons/ai"

type Props = {
  text: string | number | null | undefined
  css?: object
  className?: string
}

function Star({ text , css = {}, className = "" }: Props) {
  
  return (
    ( text !== null && text !== undefined ) ? 
    <div className={`pctAtom pctStar ${className}`} style={css}>
        <AiFillStar/>
        <div className="point">
          <Text component="small" text={`${ text?.toString() }`} />
        </div>
    </div> : null
  )
}

export default Star
