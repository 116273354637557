import { API_REPLICATION } from '../actions/actionTypes';


export const apiReplicationReducer = (state = {}, action : {type: string, value: any}) => {
    switch (action.type) {
        case API_REPLICATION:
           return {
                ...state,
                ...action.value
            };
        default:
            return state;
    }
};
