import React from "react";
import { getDesignById } from '../services/utils'

class Arya extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };


    }

    _getDesign(item, only = false, valueOnly = false){
        let design = getDesignById(this.props.idSection)
      
        if(!design[item]){return null} // return null if theres no item specified
      
        if(only){ // parameter only means it will only return the property specified
          if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
      
          design = { [only]:design[item][only] }
          return design
        }
      
        return design[item]
      }

    render(){
        let vh = window.innerHeight * 0.01;

        return(
           <div  style={{...this._getDesign('general'), ...{ height:`calc(${vh * 100}px - 54px - 000px)`}}}>
                 <iframe
                src="./webchat/index.html"
                style={{ height:'100%' }}
                width="100%"
                frameBorder="0"
                />
           </div>
        )
    }
}

export default Arya;