import React from "react";
import {
  FaRegEye,
  FaEyeSlash,
} from "react-icons/fa";

import './style.scss'
export const HiddenMessage = ({title, subtitle}: {title: string, subtitle: string}) => {
  return (
    <div className="hidden-message-container">
      <p className="hidden-message-text">
          <FaEyeSlash /> 
          <span> {title}</span>   
      </p>  
      <span className="hidden-message-text">{subtitle}</span>
    </div>
  );
};