import { useEffect, useState } from 'react'

import { getProfileInfo } from "services/utils";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import ModalSyllabus  from "pctComponents/modals/ModalSyllabus";

enum STATUS_POINT  {
    NULL = 'NULL',
    PROGRESS  = 'PROGRESS',
    COMPLETED = 'COMPLETED',
}
type Props = {
   collection: BlockElement | any;
};


const useSyllabus = ({ collection }:Props) => {

    const userModal = useModal(ModalSyllabus);
    
    const callShowSyllabus = (e) => {

        e.stopPropagation(); 
        userModal.show({collection});
    } 
 
    return {
        callShowSyllabus
    }
}

export default useSyllabus

