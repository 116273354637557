import './style.scss';
import React, { useState, useEffect } from 'react';

import { Text, Button } from 'pctComponents/atoms';

import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';

import { getProfileInfo } from 'services/utils';
import { getUserInfo, logout, postContactTrial, postRequestTrial, renewlogin } from 'services/api';

import { useManagerTrial } from '../../context/contextManagerTrial';
import { PlaneProps, ViewScreen, FormProps, DialogModel, requestTrialResponse } from '../../@model/ManagerTrial.model';

import { textPlansStatus } from '../../utils/TextPlansStatus';
import { dialogContainerModel } from '../../utils/DialogContainerModel';
import { useModal } from '@ebay/nice-modal-react';
import { AxiosResponse } from 'axios';
import { connect } from 'react-redux';
import { updatePlan } from 'actions';
import { Redirect } from 'react-router-dom';

const schema = yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    email: yup.string().email('E-mail inválido.').required('E-mail obrigatório'),
    company: yup.string(),
    role: yup.string().required('Cargo obrigatório'),
    department: yup.string().required('Departamento obrigatório'),
    description: yup.string(),
    numberOfEmployees: yup.string(),
});

const FormUpgrade = (props) => {
    const { setViewScreenPlan, currentPlan, trialStatusPlan, setDialogPlan } = useManagerTrial();
    const [_logout, setLogout] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { name, idPlan, deadline = 15 } = currentPlan as PlaneProps;
    const isTrial = name !== 'Enterprise';
    const modal = useModal();
    const [formItem, setFormItem] = useState<FormProps | null>(null);
    const planText = {
        screen: 'FormUpgade',
        trialStatusPlan: trialStatusPlan,
        deadline: deadline,
        plan: `Plano ${name}`,
        trialPlan: isTrial,
    };

    const _getUserInfo = (id) => {
        getUserInfo(id).then((res) => {
            const { user, company } = res.data;

            const userData = {
                idUser: user.idUser,
                idCompany: user.idCompany,
                company: company.name,
                email: user.email,
                name: user.name,
                role: user.role,
                department: user.department,
                description: '',
                numberOfEmployees: '1 - 100',
            };

            setFormItem(userData);
        });
    };

    useEffect(() => {
        const _user = getProfileInfo();
        _getUserInfo(_user.idUser);
    }, []);

    const parseSelectedOption = (numberOfEmployees: string) => {
        if (numberOfEmployees.includes('-')) {
            const [min, max] = numberOfEmployees.split(' - ').map(Number);
            return [min, max];
        } else if (numberOfEmployees.startsWith('>')) {
            const num = parseInt(numberOfEmployees.slice(2));
            return [num];
        } else {
            return [parseInt(numberOfEmployees)];
        }
    };

    const requestTrial = async ({
        role,
        department,
        numberOfEmployees,
    }): Promise<AxiosResponse<requestTrialResponse>> => {
        const formattedNumberOfEmployees = parseSelectedOption(numberOfEmployees);
        return postRequestTrial({
            idPlan,
            department,
            role,
            numberOfEmployees: formattedNumberOfEmployees,
        });
    };

    const sendContactTrial = async ({
        role,
        department,
        numberOfEmployees,
        description,
    }): Promise<AxiosResponse<requestTrialResponse>> => {
        const formattedNumberOfEmployees = parseSelectedOption(numberOfEmployees);
        return postContactTrial({
            idPlan,
            department,
            role,
            message: description,
            numberOfEmployees: formattedNumberOfEmployees,
        });
    };

    const contactTrial = async (values) => {
        try {
            const {
                data: { domain, token },
            } = await sendContactTrial(values);
            setDialogPlan(
                dialogContainerModel({
                    type: DialogModel.ConfirmSucess,
                    plan: name,
                    buttonConfirm: {
                        callEvent: async () => {
                            logout();
                            setLogout(true);
                            window.location.reload();
                        },
                    },
                }),
            );
        } catch (e) {
            modal.remove();
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (values) => {
        if (isTrial) {
            setLoading(true);
            if (Number(deadline) > 0) {
                try {
                    const {
                        data: { domain, token },
                    } = await requestTrial(values);

                    setDialogPlan(
                        dialogContainerModel({
                            type: DialogModel.ConfirmTrial,
                            plan: name,
                            buttonConfirm: {
                                callEvent: async () => {
                                    modal.remove();
                                    window.open(`https://${domain}/#/login?token=${token}`);
                                    renewlogin();
                                    props.updatePlan({
                                        ...props.planUpgrade,
                                        isUpgradeButtonActive: false,
                                    });
                                },
                            },
                        }),
                    );
                } catch (error) {
                    modal.remove();
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            } else {
                await contactTrial(values);
            }
        }
        setViewScreenPlan(ViewScreen.dialog);
    };
    if (_logout) {
        return <Redirect to="/login" />;
    }

    return (
        <div className={`pctUpgradeForm`}>
            {!formItem || loading ? (
                <>
                    <div className="loader">
                        <div className="preloaderIt"></div>
                    </div>
                </>
            ) : (
                <Formik
                    validationSchema={schema}
                    onSubmit={(values) => onSubmit(values)}
                    initialValues={{
                        name: formItem.name,
                        email: formItem.email,
                        company: formItem.company,
                        role: formItem.role || '',
                        department: formItem.department || '',
                        description: formItem.description || '',
                        numberOfEmployees: formItem.numberOfEmployees || '1 - 100',
                    }}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <div className="pctUpgradeFormBase">
                                    <Text
                                        component={'h3'}
                                        text={textPlansStatus({
                                            ...planText,
                                            element: 'h3',
                                        })}
                                    />

                                    <Form.Group className="profile-edit-item section-form-edit">
                                        <Form.Label className="profile-edit-label">Nome completo *</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Nome"
                                            value={values.name}
                                            className="form-control edit-login-input "
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.name}
                                            disabled={!!values.name}
                                        />
                                    </Form.Group>

                                    {values.email && (
                                        <Form.Group className="profile-edit-item section-form-edit">
                                            <Form.Label className="profile-edit-label">E-mail comercial *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                value={values.email as string}
                                                className="form-control edit-login-input "
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={!!errors.email}
                                                disabled={!!values.email}
                                            />
                                        </Form.Group>
                                    )}

                                    <div className="profile-list">
                                        {
                                            <Form.Group className="profile-edit-item section-form-edit">
                                                <Form.Label className="profile-edit-label">Cargo *</Form.Label>
                                                <Form.Control
                                                    name="role"
                                                    placeholder="Ex: coordenador, gerente"
                                                    value={values.role as string}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control edit-login-input"
                                                    isInvalid={!!errors.role && touched.role}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.role}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        }
                                        {
                                            <Form.Group className="profile-edit-item section-form-edit">
                                                <Form.Label className="profile-edit-label">Departamento *</Form.Label>
                                                <Form.Control
                                                    name="department"
                                                    placeholder="Ex: comercial, tecnologia"
                                                    value={values.department as string}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="form-control edit-login-input"
                                                    isInvalid={!!errors.department && touched.department}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.department}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        }
                                    </div>

                                    <div className="profile-list">
                                        {
                                            <Form.Group className="profile-edit-item section-form-edit">
                                                <Form.Label className="profile-edit-label">Empresa *</Form.Label>
                                                <Form.Control
                                                    name="company"
                                                    placeholder="Ex: Empresa SA"
                                                    className="form-control edit-login-input"
                                                    value={values.company as string}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={!!errors.company}
                                                    disabled={!!values.company}
                                                />
                                            </Form.Group>
                                        }

                                        <Form.Group className="profile-edit-item section-form-edit">
                                            <Form.Label className="profile-edit-label">
                                                Quantidade de funcionários *
                                            </Form.Label>
                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    name="numberOfEmployees"
                                                    value={values.numberOfEmployees as string}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={!!errors.numberOfEmployees}
                                                >
                                                    <option value={'1 - 100'}>1 - 100</option>
                                                    <option value={'101 - 250'}>101 - 250</option>
                                                    <option value={'251 - 500'}>251 - 500</option>
                                                    <option value={'501 - 2500'}>501 - 2500</option>
                                                    <option value={'> 2500'}> {'> 2500'}</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Form.Group>
                                    </div>

                                    {!isTrial && (
                                        <Form.Group className="profile-edit-item section-form-edit profile-edit-textarea">
                                            <Form.Label>Como nosso time comercial pode ajudar? *</Form.Label>
                                            <Form.Control
                                                placeholder="Ex: Possuem alguma integração com... "
                                                as="textarea"
                                                name="description"
                                                rows={4}
                                                value={values.description as string}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={!!errors.description}
                                            />
                                        </Form.Group>
                                    )}

                                    {Number(deadline) <= 0 && (
                                        <Form.Group className="profile-edit-item section-form-edit profile-edit-textarea">
                                            <Form.Label>
                                                Digite a motivação para estender seu período de degustação *
                                            </Form.Label>
                                            <Form.Control
                                                placeholder="Ex: Possuem alguma integração com... "
                                                as="textarea"
                                                name="description"
                                                rows={4}
                                                value={values.description as string}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={!!errors.description}
                                            />
                                        </Form.Group>
                                    )}

                                    {isTrial && Number(deadline) > 0 && (
                                        <Form.Group className="profile-edit-item section-form-edit profile-edit-textarea">
                                            <Form.Label>
                                                OBS: Ao Iniciar a degustação, os usuários cadastrados receberão um
                                                e-mail de boas vindas para acesso a nova plataforma.
                                            </Form.Label>
                                        </Form.Group>
                                    )}
                                </div>

                                <div className="buttonsWrapper">
                                    {
                                        <Button
                                            callEvent={() => {}}
                                            className={`button06`}
                                            attr={{ type: 'submit' }}
                                            text={textPlansStatus({ ...planText, element: 'button' }) || ''}
                                        />
                                    }
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            )}
        </div>
    );
};

const mapStateToProps = (store) => ({
    planUpgrade: store.planUpgrade,
});

const mapDispatchToProps = (dispatch) => ({
    updatePlan: (item) => dispatch(updatePlan(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormUpgrade);
