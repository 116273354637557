import './style.scss';

import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AiOutlineDownload } from 'react-icons/ai';
import { updateHeaderStatus, updateFooterStatus, updateConfigHeaderFooter, updateConfigCampaign } from '../../../actions';

import { learningContentsTracking, recordTracking } from '../../../services/api';

import useCards from 'pctHooks/useCards';
import { Duration, Image, Label, Text } from 'pctComponents/atoms';

const FileDetailPct = (props) => {
    const { data, dataParentObj, params } = props;
    const { image } = data;
    const setProgressStateRef = useRef(null as any);
    const progressInfoRef = useRef(null as any);
    const concluidedText = useRef('');

    let tempData = data;
    tempData.contentEnrollment = dataParentObj.contentEnrollment;

    tempData = {
        data: tempData,
        contentEnrollment: dataParentObj.contentEnrollment,
    };

    const { callDownload } = useCards({
        collection: tempData,
        dataParent: tempData,
        setProgressStateRef,
        progressInfoRef,
    });

    const _recordTracking = async (_type: string | null = null) => {
        if (props.data.idContentEnrollment) return false;

        // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
        const timeStart = 0;
        const navItem = props.data.idContentItem;
        const navType = 1;
        const idSection = props.data.idSection;
        const timeExecuted = 0;

        // console.log(timeStart, navItem, navType, idSection, timeExecuted);
        const res = await recordTracking(timeStart, navItem, navType, idSection, timeExecuted);

        const earnPointsStatus = res.data.earnPoints && props.data.completed === 0;
        //const earnPointsStatus = true;

        props.updateConfigCampaign({
            earnPointsStatus: _type === 'WillUnmount' ? false : earnPointsStatus,
        });
    };

    const contentTracking = async () => {
        if (props.data.idContentEnrollment && props.dataParentObj.contentEnrollment.status !== 'COMPLETED') {
            if (props.params.idEnrollment) {
                const objTrackingContent = {
                    idEnrollment: props.params.idEnrollment,
                    type: 'ACCESS_CONTENT',
                    progress: 100,
                };

                await learningContentsTracking(objTrackingContent, props.data.idContentItem);
            }
        }

        const { updateConfigHeaderFooter } = props;

        updateConfigHeaderFooter({
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: dataParentObj,
            backCloseText: 'FECHAR CONTEÚDO',
            unlockNext: true,
        });
    };

    const callContendDownload = async () => {
        callDownload(() => {
            contentTracking();
        });

        concluidedText.current = `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}`;

        _recordTracking();
    };

    useEffect(() => {
        props.updateConfigHeaderFooter({
            prevNavStyle: 'closeShow',
            footerStyle: 'hide',
            contentNavigation: dataParentObj,
            backCloseText: 'FECHAR CONTEÚDO',
            single: params.scoType === 'single',
        });

        return () => {
            props.updateConfigCampaign({ earnPointsStatus: false });
        };
    }, []);

    if (dataParentObj.contentEnrollment && !concluidedText.current) {
        concluidedText.current =
            dataParentObj.contentEnrollment.status === 'COMPLETED' ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}` : '';
    } else if (props.data.hasOwnProperty('completed')) {
        concluidedText.current = props.data.completed === 1 ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}` : '';
    }

    return (
        <div className={`pctPage fileDetailPct`}>
            <div className="contentHeader">
                <Image path={image} />
            </div>

            <div className="infos">
                <div className="infosBase">
                    {(data.text || data.description) && (
                        <div className="mediaInfos">
                            {data.text && <Text component="h2" text={data.text} />}
                            {data.description && <Text component="p" text={data.description} />}
                        </div>
                    )}

                    <div className="footerInfos">
                        <div className="leftInfo">
                            <div className="dataInfos">
                                <div className="dis dis1">
                                    {<Label text={data.label ? data.label : 'Arquivo'} className="label" />}

                                    {!!data.duration && <Duration text={data.duration} timeFormat={'seconds'} />}

                                    <button onClick={() => callContendDownload()} className="btnDownload">
                                        <AiOutlineDownload />
                                        <Text component="p" text={'Baixar arquivo'} />
                                    </button>

                                    <Text component="small" text={concluidedText.current} className="concluidedText" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updateConfigCampaign: (item) => dispatch(updateConfigCampaign(item)),
});

export default connect(null, mapDispatchToProps)(FileDetailPct);
