import './style.scss';

import React, {useEffect} from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { withRouter, useParams } from "react-router-dom"

import { updateConfigHeaderFooter } from "../../../actions";
import { useFetch } from "../../../pctHooks/useFetch";

import { Preloader } from "../../atoms";
import TreeList from 'pctComponents/organisms/TreeList';


type idType = {
  id?: string
};

const NodeTree = (props) => {
    
    useEffect(() => {
      const { updateConfigHeaderFooter } = props;
      updateConfigHeaderFooter({});
    });

    
    const { id } = useParams<idType>()
    const { data, error } =  useFetch({url:`/learning/nodes/${id}`})

    if (error) return <p>There is an error.</p>
    if (!data) return < Preloader/>

    
    const fetchData: any = data;

    return(
      <div className='pctPage pctNodeTree'>
        <TreeList collection={fetchData}/>
      </div>
    )
    
    
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(NodeTree);
