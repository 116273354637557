import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { BsX, BsBoxArrowLeft } from "react-icons/bs";
import { RiWindow2Fill } from "react-icons/ri";
import { getDesign } from "../services/utils";
import DoneIcon from '@material-ui/icons/Done';

const ImpactFullMenu = forwardRef((props, ref) => {

  const [impactList, setImpactList] = useState([
    {
      id: 1,
      chapters: [],
      classificationAbbreviation: "",
      classificationTitle: "",
    },
    {
      id: 2,
      chapters: [],
      classificationAbbreviation: "",
      classificationTitle: "",
    },
    {
      id: 3,
      chapters: [],
      classificationAbbreviation: "",
      classificationTitle: "",
    },
    {
      id: 4,
      chapters: [],
      classificationAbbreviation: "",
      classificationTitle: "",
    },
    {
      id: 5,
      chapters: [],
      classificationAbbreviation: "",
      classificationTitle: "",
    },
    {
      id: 6,
      chapters: [],
      classificationAbbreviation: "",
      classificationTitle: "",
    },
  ]);

  useImperativeHandle(ref, () => ({
    setAnchors() {
      const journeyIframe = document.getElementById('journey-iframe')
      const anchors = journeyIframe.contentWindow.document.getElementsByClassName('podcast-container')

      let newImpactList = [...impactList]

      impactList.map((listItem, listIndex) => {
        listItem.chapters.forEach((chapter, chapterIndex) => {
          chapter.children.forEach((page, pageIndex) => {
            for(let i = 0; i < anchors.length; i++) {
              newImpactList[listIndex].chapters[chapterIndex].children[props.pageIndex].anchor
                ? newImpactList[listIndex].chapters[chapterIndex].children[props.pageIndex].anchor = [i]
                : newImpactList[listIndex].chapters[chapterIndex].children[props.pageIndex].anchor.push(i)
            }
          })
        })
      })
    }
  }))

  useEffect(() => {
    if (props.pages.length > 0) {
      sortListaImpact();
      popularListaImpact();
    }
  }, [props.pages]);

  // sort pra trazer os capitulos pela ordem da sigla impact
  const sortListaImpact = () => {
    props.pages.sort((a, b) => {
      return a.idClassification - b.idClassification;
    });
  };

  // atribuição da estrutura para evitar duas letras impact iguais na coluna esquerda
  const popularListaImpact = () => {
    let newImpactList = []
    impactList.map((impactItem) => {
      props.pages.map((page) => {
        if (page.idClassification == impactItem.id) {
          impactItem.classificationAbbreviation = page.classificationAbbreviation;
          impactItem.classificationTitle = page.classificationTitle;
          impactItem.chapters.push(page);
        }
      });
      newImpactList.push(impactItem)
    });
    setImpactList(filtragemDaListaImpact(newImpactList))
  };

  // filtrando a lista caso alguma letra impact nao tenha capitulos
  const filtragemDaListaImpact = (list) => {
    return list.filter((item) => item.chapters.length > 0)
  };

  return (
    <div
      style={{
        background: getDesign(props.idSection, "general")?.backgroundColor,
      }}
      className={
        "impact-full-menu-expand " +
        (props.display ? "impact-full-menu-expand-open" : "")
      }
    >
      <div className="impact-step-side-menu-header">
        <div>
          <button
            className="step-side-menu-header-button"
            onClick={props.closeButtonHandler}
          >
            <BsX size={30} />
          </button>
        </div>
        <div className="impact-step-side-menu-header-text">
          MAPA DE LOCALIZAÇÃO
          <span className="impact-secondary-header-text">
            EXPLORAÇÃO INTERATIVA
          </span>
        </div>
      </div>

      <div className="impact-full-menu-body">
        {
          impactList.map((impactLetter, index) =>
              <div className="impact-full-menu-body-item" key={index}>
                <div
                  style={{
                    borderBottomColor: getDesign(props.idSection, "firstItemText")
                      ?.color,
                  }}
                  className="impact-full-menu-row"
                >
                  <div
                    style={{
                      background: getDesign(props.idSection, "general")?.columnColor,
                    }}
                    className="impact-left-content impact-selected-content"
                  >
                    <div
                      style={{
                        color: getDesign(props.idSection, "firstItemText")?.color,
                      }}
                      className="impact-left-content-icon"
                    >
                      {impactLetter["classificationAbbreviation"]}
                    </div>
                    <div
                      style={{
                        color: getDesign(props.idSection, "itemText")?.color,
                      }}
                      className="impact-left-content-text"
                    >
                      {impactLetter["classificationTitle"]}
                    </div>
                  </div>
                  <div className="impact-right-content">
                    <div className="impact-chapter-list">
                      {impactLetter.chapters?.map((chapter, i) => (
                        <div key={i} style={{ width: 'max-content' }}>
                          <span
                            style={{
                              color: getDesign(props.idSection, "firstItemText")
                                ?.color,
                            }}
                            className="impact-chapter-text"
                          >
                            {chapter.text}
                          </span>
                          <div className="impact-page-list">
                            {chapter.children?.map((page, i) => (
                              <div
                                key={i}
                                style={{
                                  color: getDesign(props.idSection, "itemText")?.color,
                                }}
                                className={`impact-page ${props.pageList[props.pageIndex]?.idContentItem === page.idContentItem ? 'impact-page-selected' : ''}`}
                                onClick={() => props.pageClickHandler(page.idContentItem)}
                              >
                                <div className="impact-page-icon">
                                  {
                                    page.image ? 
                                    <img alt="impact-page-icon" className="impact-page-image" src={page.image} /> :
                                    <RiWindow2Fill size={30} />
                                  }
                                  {
                                    page.completed == 1 && 
                                    <div className="impact-page-completed-icon">
                                      <DoneIcon style={{ fontSize: 15 }} />
                                    </div>
                                  }
                                </div>
                                <div className="impact-page-text">{page["text"]}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>)
        }
      </div>

      <div className="impact-full-menu-footer">
        <button
          className="step-side-menu-header-button"
          onClick={props.closeButtonHandler}
        >
          <BsBoxArrowLeft size={30} />
        </button>
        <div className="impact-step-side-menu-footer-text">
          SAIR DESTE MÓDULO
        </div>
      </div>
    </div>
  );
});

export default ImpactFullMenu;
