import "./style.scss";
import React, { Fragment } from "react";

import ReactPaginate from "react-paginate";

import { Text, DropDown } from "../../atoms";
import { PlaceholderTable } from "pctComponents/placehoader";

import AlertComponent from "../AlertComponent";

import useGamification from "./hooks/useListGamification";

type Props = {
  userId: number;
};

const ListGamification = ({ userId }: Props) => {
  const {
    columns,
    listCampaign,
    selectCampaign,
    selectDepartment,
    itemsLenght,
    handlePageClick,
    pageCount,
    pageNum,
    listPointCampaign,
    loading,
    dataUser,
    listDepartments,
    currentCampaign,
    callRules,
  } = useGamification({ userId });

  const renderRows = (dataRow: any) => {
    const drawnRows = columns.map((colum: any, k) => {
      const row = dataRow[colum.accessor];
      const position1 = colum.accessor === "position" && row === 1;
      const position2 = colum.accessor === "position" && row === 2;
      const position3 = colum.accessor === "position" && row === 3;

      return (
        <td key={k}>
          <div>
            {(position1 || position2 || position3) && (
              <div
                className={`classification  
                ${position1 ? "classification1" : ""} 
                ${position2 ? "classification2" : ""} 
                ${position3 ? "classification3" : ""}`}
              />
            )}
            <span
              className={position1 || position2 || position3 ? "classText" : ""}
            >
              {row}
            </span>
          </div>
        </td>
      );
    });

    return drawnRows;
  };

  return (
    <div className="ptcGamification">
      <div className="ptcGamificationControl">
        <div className="inputGamificationlists">
          {listCampaign && (
            <DropDown
              placeholder={"Selecione Campanha"}
              options={listCampaign}
              onSelected={(item) => {
                selectCampaign(item);
              }}
            />
          )}

          {listDepartments && (
            <DropDown
              placeholder={"Departamentos"}
              options={listDepartments}
              onSelected={(item) => {
                selectDepartment(item);
              }}
            />
          )}
        </div>
        {dataUser && dataUser === "userNotDepartment" ? (
          <div>
            Você nao faz parte desse departamento. Altere ou remova o filtro.
          </div>
        ) : dataUser && dataUser !== "userNotDepartment" ? (
          <div className="infoDataLists">
            <Text
              component="h3"
              text={`Posição no Ranking: <span>${dataUser.position}º</span> `}
              className="infoDataGamificationlists"
            />
            <Text
              component="h3"
              text={`Pontuação: <span>${dataUser.points} pts</span>`}
              className="infoDataGamificationlists"
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {currentCampaign && (
        <div className="rulesData">
          <Text
            component={"h4"}
            text={`Você está participando da ${currentCampaign.name}!`}
          />
          <p>
            Confira o escopo da campanha em{" "}
            <span onClick={callRules}>Regras da campanha</span>.
          </p>
        </div>
      )}

      {!listPointCampaign && !loading && (
        <div className="placeRankingInit">
          <div className="placeRankingInitBase">
            <Text
              component={"h1"}
              text={
                "Selecione entre campanhas ou departamentos para que seja renderizado o ranking de resultados."
              }
            />
          </div>
        </div>
      )}

      {loading && (
        <PlaceholderTable
          rowsNumber={11}
          columnsNumber={3}
          className="shortGamification"
        />
      )}

      {listPointCampaign &&
        !loading &&
        (listPointCampaign.length > 0 ? (
          <>
            <div className="mobTable">
              {listPointCampaign.map((item, j) => {
                const position1 = item.position === 1;
                const position2 = item.position === 2;
                const position3 = item.position === 3;

                return (
                  <Fragment key={j}>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className="thBase">
                              <strong>Posição:</strong>
                              <div className="thClass">
                                {(position1 || position2 || position3) && (
                                  <div
                                    className={`classification  
                                              ${
                                                position1
                                                  ? "classification1"
                                                  : ""
                                              } 
                                              ${
                                                position2
                                                  ? "classification2"
                                                  : ""
                                              } 
                                              ${
                                                position3
                                                  ? "classification3"
                                                  : ""
                                              }`}
                                  />
                                )}
                              </div>
                              <p
                                className={
                                  position1 || position2 || position3
                                    ? "classText"
                                    : ""
                                }
                              >
                                {item.position}
                              </p>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <strong>Usuário:</strong>
                              {item.name}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <div>
                              <strong>Pontuação:</strong>
                              {item.points}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Fragment>
                );
              })}
            </div>

            <div className="deskTable">
              <table>
                <thead>
                  <tr>
                    {columns.map((colum: any, i) => {
                      let { header, accessor } = colum;
                      return (
                        <th key={i}>
                          <p>{header}</p>
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {listPointCampaign.map((item, j) => {
                    return <tr key={j}>{renderRows(item)}</tr>;
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <AlertComponent
            message="Ainda não há dados para serem exibidos!"
            fullpage={false}
          />
        ))}

      {itemsLenght.current > 0 ? (
        <div className="paginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel="próximo >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            forcePage={pageNum.current}
            previousLabel="< anterior"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ListGamification;
