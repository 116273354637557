import { useEffect, useState } from 'react'

import { getCampaignListByUser, postCampaignNotified, getCampaignListByContent } from "services/api";
import { getProfileInfo } from "services/utils";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { Model, ModalCampaign } from "pctComponents/modals/ModalCampaign";

enum STATUS_POINT  {
    NULL = 'NULL',
    PROGRESS  = 'PROGRESS',
    COMPLETED = 'COMPLETED',
}
type Props = {
    idElement?: number | string | null
};


const useCampaingns= ({ idElement = null }:Props) => {

    const userModal = useModal(ModalCampaign);
    
    const statusPointCampaign = STATUS_POINT.PROGRESS;
    

    const showListCampaingns = () =>{
 
        const verifyShowCampaign = ( data ) => {
            const showCampaign = data.filter(it => !it.notified)
            return showCampaign.length > 0 ? showCampaign : null;
        } 

        getCampaignListByUser().then((res) => {
            const showCampaign = verifyShowCampaign( res.data );
            if( showCampaign ){
                NiceModal.show(ModalCampaign, {
                    model: showCampaign.length === 1 ? Model.SINGLE  : Model.MULTIPLE,
                    campaign: showCampaign
                });

                showCampaign.map((it) => {
                    postCampaignNotified({idCampaign: it.idgamification_campaign, idUser: getProfileInfo().idUser});
                    return it;
                });  
            }
        })
    }
   

    const listPointCampaign = (e) => {

        e.stopPropagation(); 

        userModal.show({model: Model.LIST});
        getCampaignListByContent({idElement}).then((res) => {
            userModal.show({
                model: Model.LIST,
                listPoint:res.data,
            });
        });
    } 
 
    return {
        showListCampaingns,
        listPointCampaign,
        statusPointCampaign,
        STATUS_POINT,
    }
}

export default useCampaingns

