
import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { getFooterSize, getDesign, getColors } from '../../services/utils';
import Rate from "../Rate";
import ProgressBar from '../ProgressBar';
import './style.scss'


const ListMultiformat = (props) => {

    const checkFileIsImage = (file)=>{
      return file ? file.includes('image') : false
    }
    

    const rateStyle = {
      color: "#000",
    }
    

    const componentsMultiformat = (props,element,key) => {
      if( typeof element.rating != 'undefined' ){

        //apagar depois que aprovar ou fizerem layout
        // element.progress = Math.floor(Math.random() * 100 + 1);
        // element.rating = ( element.rating != null && element.rating != '' ) ? element.rating : '0';
        // element.numberVotes = ( element.numberVotes != null && element.numberVotes != '' ) ? element.numberVotes : '0';

        return (
          // <Rating id={props.data.currentIdSection} userStar={element.vote} />
          <>
            {
              ( (element.idContentType !== 1 && element.idContentType !== 2) || (element.idContentType === 1 && element.duration > 0) || (element.idContentType === 2 && element.duration > 0) ) ?
              (
                <ProgressBar classNameContainer={ key > 0 && "progressBarContainerFirst" } legend={false} percent={element.progress} completed={element.completed} />
              ) : null
            }
            
            <Rate id={props.data.idContentItem} classNameContainer={ key > 0 && "ratingFirst" } stars={element.rating} rateContainer= {{backgroundColor:getColors("menu").fourthColor}} styleFeedBack={{color:'#fff'}} numberVotes={element.numberVotes} click={false} avalie={false} />
          </>
        )
      }else{
        return ("");
      }
    }

    return (
        <ul className="upload-list__content">
            {
              props.data?.length > 0
              ? props.data.map((elem, index) => (
                <li key={index} className="upload-list__item">
                  <Link
                    key={elem.key}
                    className="upload-list__item--link"
                    to={elem.link}
                  >
                    <div className="upload-list__item--image">
                      {
                        (elem.isImage || checkFileIsImage(elem.mimetype)) && elem.url != '' && elem.url != null  ?
                          <img style={{ height: '260px' }} src={elem.url} alt="" /> 
                          :
                          <i className={elem.extension || 'generic'} />
                      }
                    </div>

                    <div className="upload-list__item--info">
                      <h3 className="title" style={getDesign(props.currentIdSection, 'itemText')}>{elem.title}</h3>
                      {elem.name && <p className="author" style={getDesign(props.currentIdSection, 'itemDescription')}>por {elem.name}</p>}
                      <p style={getDesign(props.currentIdSection, 'itemDescription')}>{elem.description}</p>
                    
                      {componentsMultiformat(props,elem,elem.key)}

                    </div>
                  </Link>
                </li>
              ))
              : <div className="no-content">
                  Nenhum conteúdo disponível
                </div>
            }
          </ul>
    )
}


export default ListMultiformat;