import { CONFIG_HAS_ENROLLMENT_CAMPAIGN } from "../actions/actionTypes";

const initialState = {
  configHasEnrollmentCampaign: {
    userIsInCampaign: false,
    hasEnrollmentRequests: false,
  },
};

export const hasEnrollmentCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_HAS_ENROLLMENT_CAMPAIGN:
      return {
        // ...state,
        configHasEnrollmentCampaign: {
          ...state.configHasEnrollmentCampaign,
          ...action.configHasEnrollmentCampaign,
        },
      };
    default:
      return state;
  }
};
