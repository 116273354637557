import React, { useState, useEffect, useContext }  from "react";

import moment from "moment";

type Props = {
    temp : any | null;
    dur?:  ("year" | "years" | "y" |
    "month" | "months" | "M" |
    "week" | "weeks" | "w" |
    "day" | "days" | "d" |
    "hour" | "hours" | "h" |
    "minute" | "minutes" | "m" |
    "second" | "seconds" | "s" |
    "millisecond" | "milliseconds" | "ms"),
    format?: string
}

const useTimeMoment = ( { temp = 0, dur = 's', format= "hh __ mm __ ss __"  }: Props) => {
    
    const [timeMoment, setTimeMoment] = useState(null);

    useEffect(()=>{

      const filterPT = (timeCurrent) => {
        return timeCurrent
          .replace("hour", "hora")
          .replace("minute", "minuto")
          .replace("second", "segundo");
      };

      if(temp > 0 ){
        const timeEN = moment
          .duration(temp, dur)
          .format(format);

        setTimeMoment(filterPT(timeEN));
      }

    })

  
    
  return timeMoment;
};

export default useTimeMoment;