import React,{ useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  
  //TODO: REMOVER ESTA LÓGICA QUANDO FINALIZAR A LÓGICA DE SAVE DA ÚLTIMA POSÇÃO DO USUÁRIO.
  // const location = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  // return <>{props.children}</>
  return <></>
};

export default ScrollToTop;