import "./style.scss";

import React from "react";

import { Button, Text } from "pctComponents/atoms";

type Props = {
  title?: string;
  className?: string;
  htmlBody?: any;
  btn1Info?: {
    event: () => void;
    text: string;
    className?: string;
  };
  btn2Info?: {
    event: () => void;
    text: string;
    className?: string;
  };
  show: boolean;
  setShow?: any;
};

const ModalDialog = ({
  title,
  htmlBody,
  btn1Info,
  btn2Info,
  show,
  setShow = null,
  className = "",
}: Props) => {
  return show ? (
    <div className={`modalDialog ${className}`}>
      {setShow && (
        <div className="modalDialogHit" onClick={() => setShow(false)} />
      )}
      <div className="modalWrapper">
        {title && (
          <div className="topbarHeader">
            <Text component="h3" text={title} />
          </div>
        )}
        {htmlBody && <div className="htmlBody">{htmlBody}</div>}
        <div className="buttonsWrapper">
          {btn1Info && (
            <Button
              callEvent={btn1Info.event}
              className={`button04 ${
                btn1Info.className ? btn1Info.className : ""
              }`}
              text={btn1Info.text}
            />
          )}

          {btn2Info && (
            <Button
              callEvent={btn2Info.event}
              className={`button04 ${
                btn2Info.className ? btn2Info.className : ""
              }`}
              text={btn2Info.text}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ModalDialog;
