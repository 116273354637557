import "./style.scss";

import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, useParams, useHistory } from "react-router-dom";
import { getProfileInfo } from "services/utils";
import { FiCheckSquare } from "react-icons/fi";

import { updateConfigHeaderFooter } from "../../../actions";
import { useFetch } from "../../../pctHooks/useFetch";
import {
  postQuestionnairesSave,
  postQuestionnairesFinish,
} from "../../../services/api";
import { Preloader, Text } from "../../atoms";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalLoadingInfo from "../../modals/ModalLoadingInfo";

import MultiLikertScale from "./Questions/MultiLikertScale";
import LikertScale from "./Questions/LikertScale";
import SingleOptionComment from "./Questions/SingleOptionComment";
import Comment from "./Questions/Comment";

import useBackControll from "../../../pctHooks/useBackControll";

type Props = {
  fetchData: any;
  idEnrollment: any;
  idEvaluation: any;
  idQuestionnaire: any;
  finishEvent: any;
};

const EvaluationsReaction = ({
  fetchData,
  idEnrollment,
  idEvaluation,
  idQuestionnaire,
  finishEvent
}: Props) => {
  const modalLoading = useModal("ModalLoadingInfo");
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);

  const userProfile = getProfileInfo();
  const history = useHistory();
  const [finishStatus, setFinishStatus] = useState(false);
  const [saveStatus, setSaveStatus] = useState<boolean | null>(null);
  const [forceCompleted, setForceCompleted] = useState(false);
  const { backControllFn } = useBackControll({});

  const onCloseProcessModal = () => {
    backControllFn(history);
  };

  const { answers, evaluation, finishDate } = fetchData;

  const title = evaluation.title;
  const description = evaluation.description;
  const closingText =
    evaluation.parameters.closingText ||
    "Agradecemos imensamente por dedicar um momento para fornecer seu feedback. Juntos, podemos elevar essa experiência de aprendizagem a um novo patamar!";

  const onHandleChange = () => {
    const answerStatusFilter = answers.filter((it) => !it.answerStatus);
    const answerStatus = answerStatusFilter.length === 0 ? true : false;

    setFinishStatus(answerStatus);
  };

  const finishTxt = () => {
    return (
      <div className="finishTxt">
        <FiCheckSquare className="ico" />
        <h4>{closingText}</h4>
      </div>
    );
  };

  const saveEvent = () => {
    setSaveStatus(true);

    (async () => {
      await postQuestionnairesSave(
        fetchData,
        userProfile.idUser,
        idEnrollment,
        idEvaluation,
        idQuestionnaire
      );
      setSaveStatus(null);
      // onCloseProcessModal();
    })();
  };

  const finishEventEvaluation = () => {
    if (!finishDate) {
      setSaveStatus(true);

      (async () => {
        await postQuestionnairesFinish(
          fetchData,
          userProfile.idUser,
          idEnrollment,
          idEvaluation,
          idQuestionnaire
        );

        setSaveStatus(null);
        finishEvent();
        
        modalLoading.show({
          obj: {
            content: finishTxt,
            closeBtn: true,
            className: "evaluationsModal",
            // closeBtnFn: onCloseProcessModal
            closeBtnFn: setForceCompleted(true),
          },
        });
      })();
    } else {
      onCloseProcessModal();
    }
  };

  return (
    <div
      className={`pctPage pctEvaluations ${
        finishDate || forceCompleted ? "pctFinish" : ""
      }`}
    >
      {saveStatus !== null && <Preloader />}
      <div className="pctEvaluationsBase">
        <Text component={"h3"} text={title} />
        <Text component={"p"} text={description} className="description" />

        <div className="pctEvaluationsBaseForm">
          {answers.map((item, i) => {
            const obj = { answer: item, key: i, indice: i + 1, onHandleChange };

            return {
              MULTI_LIKERT_SCALE: <MultiLikertScale {...obj} />,
              LIKERT_SCALE: <LikertScale {...obj} />,
              SINGLE_OPTION_COMMENT: <SingleOptionComment {...obj} />,
              COMMENT: <Comment {...obj} />,
            }[item.question.questionType];
          })}
        </div>
        <div className={`containerBtns`}>
          <button
            className={`btnSalvar`}
            onClick={() => {
              saveEvent();
            }}
          >
            Salvar
          </button>
          <button
            className={!finishStatus ? "inativeFinish" : ""}
            onClick={() => {
              finishEventEvaluation();
            }}
          >
            Enviar avaliação
          </button>

          <Text
            className="textFinish"
            component={"p"}
            text={"Sua avaliação já foi recebida."}
          />
        </div>
      </div>
    </div>
  );
};

export default EvaluationsReaction;
