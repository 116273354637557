import "./style.scss";

import React, { useState, useEffect, useContext, useRef } from "react";
import { CreateEvaluationKnowledgeContext } from "../../../pctContexts/contextEvaluationKnowledge";
import { Button, Text, Timer } from "pctComponents/atoms";

import { BsCloudCheck } from "react-icons/bs";
import { LuTimer } from "react-icons/lu";

const EvaluationKnowPlayerHeader = () => {
  const { 
    contextCurrentEvaluation,
    contextQuestionnaireTimeLeft,
    updateEvaluationLeftTime,
    finishEvaluation
  } = useContext(
    CreateEvaluationKnowledgeContext
  );

  const _leftTimeRef = useRef(contextQuestionnaireTimeLeft);
  const time = new Date();
  time.setSeconds(time.getSeconds() + contextQuestionnaireTimeLeft);
  // const beforeunloadListener = (e) => {
  //     updateEvaluationLeftTime(_leftTimeRef.current);
  //     window.removeEventListener("beforeunload", beforeunloadListener);
  //     e.preventDefault();
  //     window.removeEventListener("beforeunload", beforeunloadListener);
  // };

  const cleanup = () => {
    // window.removeEventListener("beforeunload", beforeunloadListener);
    updateEvaluationLeftTime(_leftTimeRef.current);
  }

  useEffect(() => {
    if(contextQuestionnaireTimeLeft != null){
      const intervalProgress = setInterval(e => {
        //ATUALIZA O TEMPO DECORRIDO DA AVALIAÇÃO A CADA 10 SEGUNDOS.
        updateEvaluationLeftTime(_leftTimeRef.current);
  
        if( _leftTimeRef.current == 0 ){
          clearInterval(intervalProgress);
        }
  
      }, 10000);
  
      return () => {
        updateEvaluationLeftTime(_leftTimeRef.current);
        clearInterval(intervalProgress);
      };
    }

  }, []);


  return (
    <div className="evaluationKnowPlayerHeader">
      <div className="areaLateral" />
      <div className="textData areaCenter">
        <Text component={"h3"} text={contextCurrentEvaluation.title} />
      </div>
      <div className="infoData">
        <div className="infoSave">
          <Text component={"p"} text="Salvamento automático" />
          <BsCloudCheck />
        </div>
        
        {(contextQuestionnaireTimeLeft != null) &&
          <div className="infoTime">
            <Text
              className={"infoTimeTitle"}
              component={"p"}
              text="Tempo restante"
            />

            
              <Timer 
                expiryTimestamp={time}
                leftTimeRef={_leftTimeRef}
                onExpireTime={() =>{
                  //FINALIZA AVALIAÇÃO POIS O TEMPO FOI EXPIRADO.
                  // updateEvaluationLeftTime(0);
                  _leftTimeRef.current = 0;
                  finishEvaluation();
                
                }}
              />
          </div>
        }

      </div>
    </div>
  );
};

export default EvaluationKnowPlayerHeader;
