import React, { Component } from "react";
import ReactDOM from 'react-dom';
import parse from 'html-react-parser'
import './style.scss'
import Text from "../Text";

type Props = {
  text?: string,
  className?: string
}


function BrurEffect({ text="", className = "" }: Props) {
  
  return (
    <div className={`brurEffect ${className}`}>
        <Text component={"h4"} text={ text } />
        <div className="preloaderIt"></div>
    </div>
   
  )
}

export default BrurEffect

