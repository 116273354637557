import React, { useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

import parse from "html-react-parser";
import WhatsappSVG from "pctComponents/svg/WhatsappSVG";

import "./style.scss";
import { Button } from "pctComponents/atoms";
import { MdCheckCircle } from "react-icons/md";
import { postLogsShare } from "services/api";
export type Props = {
  obj: {
    closedModal: (e?: string | null) => void;
    link: string;
    idContent: number;
    idUser: number;
  };
};

const ModalShareLink = NiceModal.create(({ obj }: Props) => {
  const [clickedCopy, setClickedCopy] = useState<boolean>(false);
  const modal = useModal();
  const closeModal = () => {
    obj?.closedModal();
    modal.remove();
  };

  const updateLog = async () => {
    const { idContent, idUser } = obj;
    if (idContent && idUser) {
      try {
        const data = {
          origin: "content/share/copy",
          message: { idUser: idUser, idContent: idContent },
        };
        postLogsShare(data);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const callCopy = async () => {
    if (clickedCopy) {
      return;
    }
    if (await copy(obj.link)) {
      setClickedCopy(true);
      setTimeout(() => setClickedCopy(false), 5000);
    }
    updateLog();
  };

  const copy = async (text) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      return false;
    }
  };
  return (
    <div className="modal-share-link">
      <div className={`modal-body-pct`}>
        <>
          <button className="closeBtn" onClick={closeModal}>
            <IoMdClose />
          </button>
          <div className="container-share">
            <h2>Compartilhar conteúdo</h2>
            <div className="button-share">
              <p>{obj.link}</p>
              <Button
                className={`button06 ${clickedCopy && "success-button"}`}
                text={clickedCopy ? "Link copiado" : "Copiar link"}
                callEvent={() => callCopy()}
                ico={clickedCopy && MdCheckCircle}
              />
            </div>

            <a
              href={`https://api.whatsapp.com/send/?text=${obj.link}`}
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp"
              onClick={updateLog}
            >
              <span className="icone">{parse(`${WhatsappSVG()}`)}</span>
              <span>Whatsapp</span>
            </a>
          </div>
        </>
      </div>
    </div>
  );
});

export default ModalShareLink;
