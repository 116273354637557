import { ReactNode } from "react";

export enum EntityTypeEnum {
    TEAM = 'TEAM',
    NODE = 'NODE',
}

export type DrawerTabs = {
    id: number;
    title?: string;
    icon?: ReactNode;
    label?: ReactNode;
};

export type DrawerContent = {
    title?: string;
    entityType: EntityTypeEnum;
    idEntity: string;
};

export type DrawerContainer = {
    tabs: DrawerTabs[];
    contents: DrawerContent[];
    enabledTabs: boolean;
};
