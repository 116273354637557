import React, { Component, useState, useRef } from "react";
import parse from "html-react-parser";
// import Modal, { useModalState } from "react-simple-modal-provider";
import { IoMdClose } from "react-icons/io";
import { MdUnfoldMore } from "react-icons/md";
import "./style.scss";
import { Label, Preloader, Text, Duration } from "pctComponents/atoms";
import { useFetch } from "pctHooks/useFetch";
import {
  convertToBrDate,
  convertToBrDateWithHour,
  convertToBrDateWithHourStartAndHourFinish,
  getProfileInfo,
  sanitizeText,
  removeTagsHtml,
} from "services/utils";
import { userEnrollments, userEnrollmentRequest } from "../../../services/api";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ModalLoadingInfo from "../../modals/ModalLoadingInfo";
import ModalDialog from "../../modals/ModalDialog";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { LuMapPin } from "react-icons/lu";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { FaArrowRightLong, FaArrowDownLong } from "react-icons/fa6";
import { useCopyToClipboard } from "pctHooks/useCopyToClipboard";
import Popup from "reactjs-popup";
import { updateHasEnrollmentCampaign } from "actions";
import { connect } from "react-redux";

type Props = {
  obj: any;
  updateHasEnrollmentCampaign: any
};

const ModalRegistration = NiceModal.create(({ obj, updateHasEnrollmentCampaign }: Props) => {
  const idSearch: any = obj.nodeCode;
  const history = useHistory();
  const fetchData = useRef();
  const [copyTextToClipboard] = useCopyToClipboard();
  const [showConfirmAwaitingApproval, setShowConfirmAwaitingApproval] =
    useState(false);
  const currentAwaitingApprovalTitle = useRef("Nome da turma");

  const [syllabusStatus, setSyllabusStatus] = useState(false);
  const collection = obj;
  let collectionToGo;

  if (collection.hasOwnProperty("collectionToGo")) {
    collectionToGo = collection.collectionToGo.data;
  }

  const columns = [
    {
      header: "Turma",
      accessor: "name",
    },
    {
      header: "Período para realização",
      accessor: "duration",
    },
    {
      header: "Data de início",
      accessor: "initialDate",
    },
    {
      header: "Data de termino",
      accessor: "finalDate",
    },
    {
      header: "Capacidade",
      accessor: "capacity",
    },
    {
      header: "Vagas restantes",
      accessor: "totalVacanciesAvailable",
    },
  ];

  const columnsMeet = [
    {
      header: "Turma",
      accessor: "name",
    },
    {
      header: "Período para realização",
      accessor: "period",
    },
    {
      header: "Tipo de encontro",
      accessor: "meetType",
    },
    {
      header: "Encontros",
      accessor: "meets",
    },
    {
      header: "Endereço",
      accessor: "adress",
    },
    {
      header: "Capacidade",
      accessor: "capacity",
    },
    {
      header: "Vagas restantes",
      accessor: "totalVacanciesAvailable",
    },
  ];

  const renderAccessor = (accessor, _data) => {
    const _meetingsInfo: any = meetingsInfo();

    switch (accessor) {
      case "period":
        return `${convertToBrDate(_data["initialDate"])} à ${convertToBrDate(
          _data["finalDate"]
        )}`;
        break;

      case "meetType":
        return _meetingsInfo.meetType;
        break;

      case "meets":
        return _meetingsInfo.meets;
        break;

      case "adress":
        return _meetingsInfo.adress;
        break;

      default:
        return _data[accessor];
        break;
    }
  };

  const renderRows = (dataRow: any, _key) => {
    let _row =
      dataRow.meetings.length == 0
        ? columns.map((colum: any, k) => {
            return (
              <td key={k}>
                <div>
                  {colum.accessor == "initialDate" ||
                  colum.accessor == "finalDate"
                    ? convertToBrDate(dataRow[colum.accessor])
                    : dataRow[colum.accessor]
                    ? colum.accessor == "duration"
                      ? dataRow[colum.accessor] + " dias"
                      : dataRow[colum.accessor]
                    : colum.accessor === "totalVacanciesAvailable" &&
                      dataRow[colum.accessor] === null
                    ? "ilimitadas"
                    : colum.accessor === "totalVacanciesAvailable" &&
                      dataRow[colum.accessor] <= 0
                    ? "0"
                    : "-"}
                </div>
              </td>
            );
          })
        : columnsMeet.map((colum: any, k) => {
            return (
              <td key={k}>
                <div>{renderAccessor(colum.accessor, dataRow)}</div>
              </td>
            );
          });

    return _row;
  };

  const [sortType, setSortType] = useState("");
  const modal = useModal();
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  const modalLoading = useModal("ModalLoadingInfo");

  let currentUrl = "";
  if (!fetchData.current) {
    currentUrl = `/learning/teams/`;
  }

  const { data, error } = useFetch({
    url: currentUrl,
    params: { limit: 30, nodeCode: idSearch, onlyAvailable: true },
  });
  if (error) return <p>There is an error.</p>;
  if (!data) return <Preloader />;

  if (!fetchData.current) {
    fetchData.current = data;
  }

  let currentData: any = fetchData.current;
  const hasMeetings = () => {
    for (var i = 0; i < currentData.length; i++) {
      if (currentData[i].hasOwnProperty("meetings")) {
        if (currentData[i].meetings.length > 0) {
          return true;
        }
      }
    }
  };

  const updateEnrollmentRequest = () => {
    updateHasEnrollmentCampaign({
      hasEnrollmentRequests: false
    })
  }

  const onClickErrolmentRequest = (item, evt) =>  {
   
    const params: any = {
      idTeam: item.idTeam,
      // nodeCode: item.nodeCode,
      // idUser: getProfileInfo().idUser,
    };

    //SOLICITANDO MATRÍCULA
    modalLoading.show({
      obj: {
        message:
          "Aguarde, realizando <span class='primaryColor'>solicitação de matrícula.</span>.",
        closeBtn: false,
        loadding: true,
      },
    });

    userEnrollmentRequest(params)
      .then((response) => {
        updateHasEnrollmentCampaign({ hasEnrollmentRequests: true})
        setTimeout(() => {
          modalLoading.remove();
          currentAwaitingApprovalTitle.current =
            item.name;
          setShowConfirmAwaitingApproval(true);
         
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });

    evt.stopPropagation();
  }

  const classNotStartedYet = () => {
    if(!currentData.length || currentData.length > 1){
      return false
    }
    const initialDate = new Date(currentData[0].initialDate).getTime();
    const currentDate = new Date().getTime();
    return initialDate ? initialDate > currentDate : false;
  }

  const onClickEnroll = (item, evt) => {
    const params: any = {
      idTeam: item.idTeam,
      nodeCode: item.nodeCode,
      idUser: getProfileInfo().idUser,
    };

    modalLoading.show({
      obj: {
        message:
          "Aguarde, realizando <span class='primaryColor'>a sua matrícula</span>.",
        closeBtn: false,
        loadding: true,
      },
    });
    userEnrollments(params)
      .then((response) => {
        setTimeout(() => {
          if(classNotStartedYet()){
              window.location.reload()
              return;
          }
          history.push(
            collection.hasOwnProperty(
              "collectionToGo"
            )
              ? `/level/${response.data.idEnrollment}/${collection.collectionToGo.data.nodeCode}`
              : `/level/${response.data.idEnrollment}`
          );
          modalLoading.remove();
          modal.remove();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });

    evt.stopPropagation();
  }

  const meetingsInfo = () => {
    let meetingsInfoObj: any = {
      meetType: null,
      meets: null,
      adress: null,
    };

    let meetType: Array<any> = [];
    let meets: Array<any> = [];
    let adress: Array<any> = [];

    for (var i = 0; i < currentData.length; i++) {
      if (currentData[i].hasOwnProperty("meetings")) {
        currentData[i].meetings.map((meet: any, i) => {
          meetType.push(
            React.createElement(
              "div",
              { className: "meetItem" },
              meet.meetingType.toUpperCase().charAt(0) +
                meet.meetingType.slice(1).toLowerCase()
            )
          );
          meetingsInfoObj.meetType = React.createElement(
            "div",
            { className: "meetRow meetType" },
            meetType
          );
          meets.push(
            React.createElement(
              "div",
              { className: "meetItem" },

              <Popup
                trigger={(open) => (
                  <div>
                    {sanitizeText(
                      convertToBrDateWithHourStartAndHourFinish(
                        meet.startDate,
                        meet.finishDate
                      ),
                      8
                    )}
                  </div>
                )}
                position="top center"
                on={["hover", "focus"]}
                arrow={true}
                closeOnDocumentClick
              >
                <div className="tooltipAdressBox">
                  {/* <Text component="p" text="Hora do encontro:" className="tooltipText"/> */}
                  <Text
                    component="p"
                    text={convertToBrDateWithHourStartAndHourFinish(
                      meet.startDate,
                      meet.finishDate
                    )}
                    className="tooltipText"
                  />
                </div>
              </Popup>
            )
          );

          meetingsInfoObj.meets = React.createElement(
            "div",
            { className: "meetRow meets" },
            meets
          );

          adress.push(
            React.createElement(
              "div",
              { className: "meetItem" },
              meet.meetingType === "PRESENCIAL" ? (
                <Popup
                  trigger={(open) => (
                    // <button className="button">Trigger - {open ? 'Opened' : 'Closed'}</button>
                    <div>
                      <LuMapPin className="adressIco clickedIco" />
                      {sanitizeText(meet.address, 10)}
                    </div>
                  )}
                  position="top center"
                  on={["hover", "focus"]}
                  arrow={true}
                  closeOnDocumentClick
                >
                  <div className="tooltipAdressBox">
                    <Text
                      component="p"
                      text="Endereço:"
                      className="tooltipText"
                    />
                    <Text
                      component="p"
                      text={meet.address}
                      className="tooltipText"
                    />

                    <p
                      className="btnAdress"
                      onClick={() => {
                        copyTextToClipboard(meet.address);
                      }}
                    >
                      Copiar
                    </p>
                  </div>
                </Popup>
              ) : (
                <>
                  <HiOutlineStatusOnline className="adressIco" />{" "}
                  {"Sala virtual"}
                </>
              )
            )
          );
          meetingsInfoObj.adress = React.createElement(
            "div",
            { className: "meetRow adress" },
            adress
          );
        });
      }
    }

    return meetingsInfoObj;
  };

  if (sortType != "") {
    currentData = currentData.sort((a, b) =>
      a[sortType] > b[sortType] ? 1 : -1
    );
  }

  return (
    <div className="modalRegistration">
      <ModalDialog
        className="darkDilog"
        btn1Info={{
          event: () => {
            setShowConfirmAwaitingApproval(false);
            modal.remove();
          },
          text: "Ok, entendi",
        }}
        htmlBody={
          <>
            <p>Obrigado por solicitar a matrícula do treinamento,</p>
            <h3>{`${collection.title}`}</h3>
            <p>Agora é só aguardar a aprovação da matrícula!</p>
            <p>
              Para acompanhar o status da solicitação acesse a área{" "}
              <a
                onClick={() => {
                  history.push("/myjourney/requestEnrollmentStatus");
                  modal.remove();
                }}
              >
                Minha Jornada
              </a>
            </p>
          </>
        }
        show={showConfirmAwaitingApproval}
      />

      <div className="modal-body-pct">
        {collectionToGo && (
          <>
            <Text
              component="p"
              text="Para acessar este treinamento:"
              className="title"
            />
            <div className="info bottonLine">
              <div className="left">
                {collectionToGo.label && (
                  <Label text={collectionToGo.label} className="label" />
                )}
                {collectionToGo.title && (
                  <Text
                    component="p"
                    text={collectionToGo.title}
                    className="title"
                  />
                )}
              </div>
              {/* <div className="right">
                {collectionToGo.description && <Text component="small" text={collectionToGo.description} />}
              </div> */}
            </div>
            <Text
              component="p"
              text="É necessário realizar matrícula em:"
              className="title"
            />
          </>
        )}

        {!collectionToGo && (
          <>
            <Text
              component="p"
              text="Para acessar este treinamento é necessário realizar a sua matrícula em uma das turmas disponíveis:"
              className="title"
            />
          </>
        )}

        <div className="info currentEnrollment">
          <div className="left currentEnrollment">
            {collection.title && (
              <Text component="p" text={collection.title} className="title" />
            )}

            <div className="leftinfo">
              {collection.label && (
                <Label text={collection.label} className="label" />
              )}
              {collection.workload && (
                <Duration
                  text={collection.workload}
                  className="RightInfo"
                  timeFormat={"seconds"}
                />
              )}
            </div>
          </div>
          {collection.description && (
            <div className="right currentEnrollment">
              <Text component="p" text={collection.description} />
            </div>
          )}
          {collection.syllabus &&
            removeTagsHtml(collection.syllabus).length > 0 &&
            collection.nodeType === "LEARNING" && (
              <div className={"syllabusInfo"}>
                <button
                  onClick={(e) => setSyllabusStatus(!syllabusStatus)}
                  className={`syllabusBtn ${
                    syllabusStatus ? "syllabusBtnActive" : "syllabusBtnInactive"
                  }`}
                >
                  <span>Ver ementa</span>
                  {syllabusStatus ? <FaArrowDownLong /> : <FaArrowRightLong />}
                </button>

                <div
                  className={`syllabusContainer ${
                    syllabusStatus
                      ? "syllabusContainerOpen"
                      : "syllabusContainerClosed"
                  }`}
                >
                  {parse(`${collection.syllabus}`)}
                </div>
                {/* {syllabusStatus ? (
               
              ) : (
                <></>
              )} */}
              </div>

              // <div className="left currentEnrollment">

              //   {parse(collection.syllabus)}
              // </div>
            )}
        </div>

        <>
          <div className="deskTable">
            <table>
              <thead>
                <tr>
                  {(!hasMeetings() ? columns : columnsMeet).map(
                    (colum: any, i) => {
                      let { header, accessor } = colum;

                      return (
                        <th key={i}>
                          <button
                            type="button"
                            onClick={() => {
                              setSortType(accessor);
                            }}
                          >
                            <p>{header}</p> <MdUnfoldMore />
                          </button>
                        </th>
                      );
                    }
                  )}

                  <th>
                    <p>Matrícula</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentData.length == 0 && (
                  <Text component="p" text="Sem turma disponível." />
                )}
                {currentData.map((item, j) => {
                  return (
                    <tr key={j}>
                      {renderRows(item, j)}
                      <td>
                        {item.needApproval === 0 && (
                          <button
                            type="button"
                            onClick={(evt) => {onClickEnroll(item, evt)}}
                          >
                            <Label text="Matricular-se" />
                          </button>
                        )}

                        {item.needApproval === 1 &&
                          item.awaitingApproval === false && (
                            <button
                              type="button"
                              onClick={(evt) => onClickErrolmentRequest(item, evt)}

                            >
                              <Label text="Solicitar matrícula" />
                            </button>
                          )}

                        {item.awaitingApproval === true && (
                          <Label
                            className="small"
                            text="Aguardando aprovação da matrícula."
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="mobTable">
            {currentData.length == 0 && (
              <Text component="p" text="Sem turma disponível." />
            )}

            {currentData.map((item, j) => {
              return !hasMeetings() ? (
                <table key={j}>
                  <thead>
                    <tr>
                      <th>
                        <p>
                          {item.name} - {item.description}
                        </p>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">
                            Período para realização:
                          </strong>
                          {item.duration} dias
                        </div>
                      </td>
                    </tr>

                    {/* <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">Capacidade: </strong>
                          {item.capacity ? item.capacity : "-"}
                        </div>
                      </td>
                    </tr> */}

                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">
                            Data de início:
                          </strong>
                          {item.initialDate
                            ? convertToBrDate(item.initialDate)
                            : "-"}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">
                            Data de termino:
                          </strong>
                          {item.finalDate
                            ? convertToBrDate(item.finalDate)
                            : "-"}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">
                            Vagas (Disponíveis/Total):
                          </strong>

                          {renderAccessor("totalVacanciesAvailable", item) ===
                          null ? (
                            <Text text={"ilimitadas"} />
                          ) : (
                            <Text
                              text={`${
                                renderAccessor(
                                  "totalVacanciesAvailable",
                                  item
                                ) > 0
                                  ? renderAccessor(
                                      "totalVacanciesAvailable",
                                      item
                                    )
                                  : "0"
                              } / ${
                                renderAccessor("capacity", item) > 0
                                  ? renderAccessor("capacity", item)
                                  : "0"
                              }`}
                            />
                          )}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">Matrícula: </strong>
                        </div>
                        <div className="actions">
                          {item.needApproval === 0 && (
                            <button
                              type="button"
                              onClick={(evt) => {onClickEnroll(item, evt)}}
                            >
                              <Label text="Matricular-se" />
                            </button>
                          )}

                          {item.needApproval === 1 &&
                            item.awaitingApproval === false && (
                              <button
                                type="button"
                                onClick={(evt) => onClickErrolmentRequest(item, evt)}
                              >
                                <Label text="Solicitar matrícula" />
                              </button>
                            )}

                          {item.awaitingApproval === true && (
                            <Label
                              className="small"
                              text="Aguardando aprovação da matrícula."
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table key={j}>
                  <thead>
                    <tr>
                      <th>
                        <p>
                          {item.name} - {item.description}
                        </p>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">
                            Período para realização:
                          </strong>
                          {renderAccessor("period", item)}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">Encontros:</strong>

                          <table>
                            <tr className="meetsMob">
                              <td>
                                <div>{renderAccessor("meetType", item)}</div>
                              </td>
                              <td>
                                <div>{renderAccessor("meets", item)}</div>
                              </td>
                              <td>
                                <div>{renderAccessor("adress", item)}</div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">
                            Vagas (Disponíveis/Total):
                          </strong>
                          <Text
                            text={`${
                              renderAccessor(
                                "totalVacanciesAvailable",
                                item
                              ) !== null
                                ? renderAccessor(
                                    "totalVacanciesAvailable",
                                    item
                                  )
                                : "-"
                            } / ${
                              renderAccessor("capacity", item) !== null
                                ? renderAccessor("capacity", item)
                                : "-"
                            }`}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <strong className="valuesTitle">Matrícula: </strong>
                        </div>
                        <div className="actions">
                          {item.needApproval === 0 && (
                            <button
                              type="button"
                              onClick={(evt) => {onClickEnroll(item, evt)}}
                            >
                              <Label text="Matricular-se" />
                            </button>
                          )}

                          {item.needApproval === 1 &&
                            item.awaitingApproval === false && (
                              <button
                                type="button"
                                onClick={(evt) => onClickErrolmentRequest(item, evt)}

                              >
                                <Label text="Solicitar matrícula" />
                              </button>
                            )}

                          {item.awaitingApproval === 1 && (
                            <Label
                              className="small"
                              text="Aguardando aprovação da matrícula."
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          </div>
        </>

        <button className="closeBtn" onClick={() => modal.remove()}>
          <IoMdClose />
        </button>
      </div>
    </div>
  );
});
const mapDispatchToProps = (dispatch) => ({
  updateHasEnrollmentCampaign: (item) =>
    dispatch(updateHasEnrollmentCampaign(item)),
});

export default connect(null, mapDispatchToProps)(ModalRegistration);

