import './style.scss'

import React, { Component } from "react";
import ReactDOM from 'react-dom';

import { Text } from '../'
import { AiFillStar } from "react-icons/ai"
// import {useNavigate, To} from 'react-router-dom'

type Props = {
    className?: string
    css?: {}
    text?: string
    link?: string
}


const Rate = ({ className="", text="", link, css={}  }: Props) => {
    // const navigate = useNavigate();
    // const handleOnClick = () => {
    //     (link) ? navigate(link as To) : null
    // }

    return (
        
        // <button className={`rateData ${className}`} onClick={handleOnClick} style={css} >
        <button className={`pctAtom pctRate ${className}`} style={css} >
            <AiFillStar/>
            <div className="point">
                <Text component="small" text={`${text}`} />
            </div>
        </button>
    );
};


export default Rate