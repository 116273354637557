import { useState, useEffect, useRef } from "react";
import axios from "axios";
// import {useStyles}  from './useThemes';
import { getToken } from "../services/api";

export const useFetch = ({
  url,
  method = "get",
  body = "",
  headers = "",
  property = "",
  params = {},
  page = "",
}) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_ENVIRONMENT,
  });

  const header = {
    params: params,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  function useIsComponentMounted() {
    const isComponentMounted = useRef(false);

    useEffect(() => {
      isComponentMounted.current = true;
      return () => {
        isComponentMounted.current = false;
      };
    }, []);

    return isComponentMounted;
  }

  const isComponentMounted = useIsComponentMounted();

  // const callStyles = useStyles;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    // const headersObj = (headers !== "") ? JSON.parse(headers) : {};
    const headersObj = header;
    const bodyObj = body !== "" ? JSON.parse(body) : {};
    // const _body = document.documentElement.classList;

    const fetchData = async () => {
      try {
        if (url == "" ) {
          //CASO A URL SEJA VAZIA NÃO CHAMA A API
          const nullObject: any = {} as object | null;
          setData(nullObject);
          return { data };
        }

        await api[method](url, headersObj, bodyObj)
          .then((res) => {
            if (isComponentMounted.current) {
              // if(res.data.styles) {
              //     callStyles(res.data.styles)
              // }; //TODO: tentar transformar isso num Redux

              // callStyles([]);

              if(property == ""){
                setData(res.data.result ? res.data.result : res.data);
              }else{
                setData(res.data[property]);
              }
              
            }
          })
          .catch((err) => {
            if (isComponentMounted.current) {
              setError(err);
            }
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  // [method, url, headers, body]
  return { data, error };
};