import React from "react";
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { api } from '../services/api'
import { getFooterSize, getDesign } from '../services/utils';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import ListMultiformat from '../components/listMultiformat';

import Loader from '../components/Loader';
import '../assets/css/UploadList.scss'

class MultiformatList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      errorOnLoading: false,
      isLoading: false,
    }
  }

  firstElementIsOfTypeImage(list) {
    const firstFileItemIsImage = this.checkFileIsImage(list[0]?.mimetype) 
    if (firstFileItemIsImage) return list

    const index = list.findIndex(({ mimetype }) => this.checkFileIsImage(mimetype))
    list.splice(0, 0, list.splice(index, 1)[0])

    if (list[0] === undefined) { return [] }

    return list
  }

  async getUploadList() {
    this.setState({ isLoading: true })
    
    const pathParams =  new URLSearchParams(this.props.location.search)
    const parentSection = pathParams.get("idSection")
    const currentIdSection = this.props?.idSection || parentSection

    await api.get(`/content/list/${currentIdSection}`)
      .then(({ data: { content } }) => {

        // let list = this.firstElementIsOfTypeImage(content)
        
        //idUserUpload, title, name, description,image,  mimetype, extension, url 
        content = content.map(elem => {
          return {
            ...elem,
            key: elem.idContentType,
            title:elem.text,
            isImage: true,
            url: elem.image,
            name: elem.author,
            link: `/content/${elem.idContentItem}?idSection=${elem.idContentType}`
          }
        })
        this.setState({ data: content, isLoading: false })
      })
      .catch((err) => {
        console.log('Error:', err)
        this.setState({ errorOnLoading: true, isLoading: false })
      })
  }

  checkFileIsImage(file) {
    return file ? file.includes('image') : false
  }

  componentDidMount() {
    this.getUploadList()
    const { updateHeaderStatus, updateFooterStatus, idSection } = this.props
    const pathParams =  new URLSearchParams(this.props.location.search)
    const parentSection = pathParams.get("idSection")
    const currentIdSection = idSection || parentSection   
    updateFooterStatus(getDesign(currentIdSection, 'general')?.showBottomMenu || 'true')
    updateHeaderStatus(getDesign(currentIdSection, 'general')?.showTopBar || 'true')
  }
  render() {
    const { data, errorOnLoading, isLoading } = this.state
    const { idSection } = this.props
    const pathParams =  new URLSearchParams(this.props.location.search)
    const parentSection = pathParams.get("idSection")
    const currentIdSection = idSection || parentSection
    return (

      <div className="upload-list" style={{ ...getDesign(currentIdSection, 'general'), ...getFooterSize() }}>
        {
          isLoading ?
            <Loader />
            :
            errorOnLoading ?
              <div style={{ marginTop: 280, textAlign: 'center' }}>Houve algum erro durante o carregamento das informações</div>
              :
              <ListMultiformat data={data} currentIdSection={currentIdSection} />
              
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(MultiformatList);
