import { UPDATE_SCORE_POINTS, UPDATE_POINTS } from '../actions/actionTypes';

const initialState = {
    points: 0,
    scorePoints: 0,
    change:false
  };

export const pointsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SCORE_POINTS:
        return {
            ...state,
            scorePoints: action.scorePoints,
            change: !state.change
        };
        case UPDATE_POINTS:
        return {
            ...state,
            points: action.points
        };
        default:
        return state;
    }
};