import React, { ReactNode, useMemo, useState } from 'react';
import './style.scss';
import PartyPopper from 'pctComponents/svg/PartyPopperSVG';
import { SmileUnhappy } from 'pctComponents/svg';
import parse from 'html-react-parser';
import Button from 'pctComponents/atoms/Button';
import { updatePlan } from 'actions';
import { connect } from 'react-redux';
import NiceModal, { NiceModalHandler } from '@ebay/nice-modal-react';
import ModalLoadingInfo from 'pctComponents/modals/ModalLoadingInfo';
import SmileSvg from 'pctComponents/svg/SmileSVG';
import { planUpgradeProps } from '../../@model/ManagerTrial.model';
import usePlan from 'pctHooks/usePlan';
import { logout } from 'services/api';
import { Redirect } from 'react-router-dom';
import PctPlanContract from 'pctComponents/organisms/PctPlanContract';
import { IoMdClose } from 'react-icons/io';
import { License } from 'reducers/license';

type Props = {
    modal?: NiceModalHandler;
    planUpgrade: planUpgradeProps;
    updatePlan?: (item: License) => void;
};
const PctClientUpgrade = ({ modal, planUpgrade, updatePlan }: Props) => {
    const { hasRequestedUpgrade, numberRequest } = planUpgrade;
    NiceModal.register('ModalLoadingInfo', ModalLoadingInfo);
    const [isUpdateRequest, setIsUpdateRequest] = useState<boolean>(hasRequestedUpgrade); //hasRequestedUpgrade
    const [isInit, setIsInit] = useState<boolean>(true);
    const { getActivePlanData } = usePlan();
    const [_logout, setLogout] = useState<boolean>(false);

    const closeModal = (forceLogout = false) => {
        if ((dataPlan.active && Number(dataPlan?.deadline) <= 0 && isUpdateRequest) || forceLogout) {
            logout();
            setLogout(true);
            window.location.href = '/login';
            return;
        }
        setIsUpdateRequest(true);
        setIsInit(false);
        modal?.remove();
    };

    const dataPlan: any = useMemo(() => {
        const data = getActivePlanData() || null;
        if (data) {
            return { ...data, active: !!data };
        }

        return { active: false, deadline: null };
    }, [getActivePlanData]);

    const UpgradeCongratulations = ({ svg, children }: { svg: string; children: ReactNode }) => (
        <div className={`columnItem padding ${!numberRequest && 'padding-numberzero'}`}>
            <div className="circle">
                <span className="icone">{parse(`${svg}`)}</span>
            </div>
            <h3>{children}</h3>
        </div>
    );

    const SubtitleCongratulation = ({ text }: { text: string }) => (
        <div className="subtitle">
            <p>{text}</p>
        </div>
    );

    const UpgradeUserCount = () => (
        <div className="upgradeUserCount">
            <p>
                <span>{numberRequest} usuários </span>
                da sua plataforma gostariam de ter a plataforma
            </p>
        </div>
    );

    // const QuestionSatisfaction = () => (
    //     <div className="padding-top">
    //         <div className="title">
    //             <h3>Está gostando da Plataforma?</h3>
    //             <p>Já imaginou poder criar seus próprios treinamentos?</p>
    //         </div>
    //     </div>
    // );
    const Footer = ({ children, className }: { children: ReactNode; className?: string }) => (
        <div className={`footer ${className}`}>{children}</div>
    );

    // const UpgradeRequestsMessage = () => (
    //     <div className={`rowItem padding`}>
    //         {!!numberRequest && (
    //             <>
    //                 <div className="circle">
    //                     <span className="icone">{parse(`${PartyPopper()}`)}</span>
    //                 </div>
    //                 <h4>
    //                     <span>{numberRequest} usuários </span> da sua plataforma gostaria de fazer o upgrade.
    //                 </h4>
    //             </>
    //         )}
    //     </div>
    // );

    const Congratulation = () => (
        <>
            <UpgradeCongratulations svg={PartyPopper()}>
                <p> Obrigado por compartilhar!</p>
            </UpgradeCongratulations>
            <SubtitleCongratulation
                text={'O Administrador da sua plataforma acaba de ser notificado sobre o seu interesse.'}
            />
            {!!numberRequest && <UpgradeUserCount />}
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const ThanksForSharing = () => (
        <>
            <UpgradeCongratulations svg={PartyPopper()}>
                <p> Obrigado por compartilhar!</p>
                <p className="subline">
                    {' '}
                    Ainda <span>restam {dataPlan.deadline} dias</span> para continuar aproveitado
                </p>
            </UpgradeCongratulations>
            <SubtitleCongratulation
                text={'O Administrador da sua plataforma acaba de ser notificado sobre a sua opinião da plataforma.'}
            />
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const FeedBackThanksForSharing = () => (
        <>
            <UpgradeCongratulations svg={PartyPopper()}>
                <p className="subline">
                    {' '}
                    Sua opinião <span>já foi compartilhada</span>
                </p>
                <p className="subline">Agora é com seu administrador!</p>
                <p className="subline spancing">
                    Ainda <span>restam {dataPlan.deadline} dias</span> para continuar aproveitando.
                </p>
            </UpgradeCongratulations>
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const RequestAlreadyMade = () => (
        <>
            <UpgradeCongratulations svg={SmileSvg()}>
                <p>Solicitação já realizada</p>
            </UpgradeCongratulations>
            <SubtitleCongratulation text={'Agora é com o seu administrador!'} />
            {!!numberRequest && <UpgradeUserCount />}
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const ThanksForSharingExpiredTrial = () => (
        <>
            <button className="closeBtn" onClick={() => closeModal(true)}>
                <IoMdClose />
            </button>
            <UpgradeCongratulations svg={PartyPopper()}>
                <p className="subline">
                    {' '}
                    Sua opinião que está gostando <span>já foi compartilhada.</span>
                </p>
                <p className="subline spancing">Agora é com seu administrador!</p>
            </UpgradeCongratulations>
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const FeedBackThanksForSharingExpiredTrial = () => (
        <>
            <button className="closeBtn" onClick={() => closeModal(true)}>
                <IoMdClose />
            </button>
            <UpgradeCongratulations svg={PartyPopper()}>
                <p className="subline">
                    {' '}
                    Obrigado por <span>compartilhar!</span>
                </p>
                <p className="content-text spancing">
                    O Administrador da sua plataforma acaba de ser notificado sobre a sua opinião da plataforma.
                </p>
            </UpgradeCongratulations>
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const SharingExpiredTrial = () => (
        <>
            <button className="closeBtn" onClick={() => closeModal(true)}>
                <IoMdClose />
            </button>
            <UpgradeCongratulations svg={SmileUnhappy()}>
                <p className="subline">
                    {' '}
                    Seu período de <span>degustação se encerrou.</span>
                </p>
                <p className="content-text">
                    Está gostando da experiência? Compartilhe com seus administradores para que eles contratem a
                    plataforma.
                </p>
            </UpgradeCongratulations>
            <Footer>
                <PctPlanContract
                    callEvent={() => closeModal()}
                    text={'Diga que está gostando da degustação'}
                    hideNotification={true}
                    {...dataPlan}
                    planUpgrade={planUpgrade}
                    updatePlan={updatePlan}
                    forceVisible={true}
                />
            </Footer>
        </>
    );

    const ModalRender = () => {
        if (dataPlan.active) {
            if (dataPlan?.deadline <= 0) {
                return !isUpdateRequest ? (
                    <SharingExpiredTrial />
                ) : isInit ? (
                    <ThanksForSharingExpiredTrial />
                ) : (
                    <FeedBackThanksForSharingExpiredTrial />
                );
            } else {
                return !isUpdateRequest ? <ThanksForSharing /> : <FeedBackThanksForSharing />;
            }
        } else {
            return !isUpdateRequest ? <Congratulation /> : <RequestAlreadyMade />;
        }
    };

    if (_logout) {
        return <Redirect to="/login" />;
    }

    return <div className="PctClientUpgrade">{ModalRender()}</div>;
};

const mapDispatchToProps = (dispatch) => ({
    updatePlan: (item) => dispatch(updatePlan(item)),
});

const mapStateToProps = (store) => ({
    planUpgrade: store.planUpgrade,
});
export default connect(mapStateToProps, mapDispatchToProps)(PctClientUpgrade);
