import React from "react";

const PartyPopper = () => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="37.448" height="35.623" viewBox="0 0 37.448 35.623">
      <path id="Caminho_782" data-name="Caminho 782" d="M2,37.123,10.689,12.8l15.64,15.64Zm5.734-5.734,12.251-4.344-7.907-7.907ZM23.808,20.7l-1.825-1.825,9.731-9.731a4.72,4.72,0,0,1,6.69,0l1.043,1.043-1.825,1.825-1.043-1.043a2.2,2.2,0,0,0-3.041,0Zm-6.951-6.951-1.825-1.825,1.043-1.043a2.1,2.1,0,0,0,0-2.954L14.946,6.8l1.825-1.825L17.9,6.1a4.616,4.616,0,0,1,0,6.6Zm3.475,3.475L18.508,15.4l6.256-6.256a2.064,2.064,0,0,0,.608-1.521A2.064,2.064,0,0,0,24.764,6.1l-2.78-2.78L23.808,1.5l2.78,2.78a4.562,4.562,0,0,1,1.39,3.345,4.562,4.562,0,0,1-1.39,3.345Zm6.951,6.951-1.825-1.825,2.78-2.78a4.72,4.72,0,0,1,6.69,0l2.78,2.78-1.825,1.825-2.78-2.78a2.2,2.2,0,0,0-3.041,0Z" transform="translate(-2 -1.5)" fill="#feac0d"/>
    </svg>

  `
}

export default PartyPopper