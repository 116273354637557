import React from "react";
import "./style.scss";
import { Text } from '..'
import { TbBell } from "react-icons/tb"

type Props = {
  text?:string
  hasBell?: boolean
  css?: object
  className?: string
}

function Notification({ text , hasBell = true, css = {}, className = "" }: Props) {
  
  return (

    <div className={`pctAtom pctNotification ${className}`} style={css}>
        {text &&
          <Text component="small" text={`${text}`} className="notification" />
        }
       {hasBell && <TbBell/>}
    </div>
  )
}

export default Notification
