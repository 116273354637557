import "./style.scss";
import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateConfigHeaderFooter } from "../../../actions";
import NotFoundLogo from '../../../assets/images/not-found.svg';
import { Text } from "../../atoms";


const NotFound = (props) => {
  
  
  const { updateConfigHeaderFooter } = props;


  // useEffect(() => {   
  //   updateConfigHeaderFooter({ footerStyle: "hide" });
  // });

  updateConfigHeaderFooter({ footerStyle: "hide"});

  return (
    <>
      <div className="pctNotFound">
          <div className="pctNotFoundContainer"> 
              <img src={NotFoundLogo} alt="img" />
              <Text text={"PÁGINA NÃO ENCONTRADA."} />
          </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(NotFound);
