import React, { useState } from 'react';
import { Rating, RateCard } from './Rating';

export default function Rate({
  stars,
  numberVotes,
  id,
  avalie = null,
  styleStarsAvalie = null,
  click = false,
  rateContainer = {},
  styleFeedBack = {},
}) {
  const [_stars, setStars] = useState(stars);
  const [isRating, setIsRating] = useState(false);
  const [_numberVotes, setNumberVotes] = useState(numberVotes);
  const style = {
    divButton: {
      paddingTop: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      justifyContent: 'left',
    },
  };
  return (
    <div
      className="rating"
      style={{ marginLeft: '0px', justifyContent: 'left' }}
      onClick={(e) => {
        e.stopPropagation();
        if (click) {
          setIsRating(true);
        }
      }}
    >
      {_stars && !isRating ? (
        <div style={{ display: 'flex' }}>
          <Rating
            stars={_stars}
            numberVotes={_numberVotes}
            styleStarsAvalie={styleStarsAvalie}
          />
          {avalie && (
            <div
              style={{
                marginTop: 2,
                marginRight: 5,
                fontFamily: 'PT Sans Narrow',
                color: 'white',
                display: 'flex',
                alignItems: 'left',
                ...styleStarsAvalie,
              }}
            >
              {avalie}{' '}
            </div>
          )}
        </div>
      ) : (
        <RateCard
          id={id}
          numberVotes={_numberVotes}
          stars={_stars}
          setStars={setStars}
          setNumberVotes={setNumberVotes}
          buttonPosition={style.divButton}
          styleStarsAvalie={styleStarsAvalie}
          styleFeedBack={styleFeedBack}
          avalie={avalie}
          rateContainer={rateContainer}
          isRating={isRating}
          setIsRating={setIsRating}
        />
      )}
    </div>
  );
}
