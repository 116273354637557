import React from 'react';

import { Tooltip, withStyles } from '@material-ui/core';

import { FaEllipsisVertical } from 'react-icons/fa6';
import { TiDocument } from 'react-icons/ti';
import { RiCalendarEventFill } from 'react-icons/ri';
import { BiComment } from 'react-icons/bi';
import { FiPaperclip } from 'react-icons/fi';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: 8,
        maxWidth: 300,
        background: '#211F26',
    },
}))(Tooltip);

const MenuHeaderContentDesk = ({
    syllabusStatus,
    callSyllabus,
    meetingStatus,
    attachmentStatus,
    setIsOpenAttachmentSideBar,
    showInteractionDrawerButton,
    setIsOpen,
}) => {
    return (
        <div className="menuHeaderContentDesk">
            {syllabusStatus && (
                <CustomTooltip title={'Ver ementa'} placement="bottom">
                    <button onClick={callSyllabus}>
                        <TiDocument />
                    </button>
                </CustomTooltip>
            )}
            {meetingStatus && (
                <CustomTooltip title={'Encontros'} placement="bottom">
                    <button>
                        <RiCalendarEventFill />
                    </button>
                </CustomTooltip>
            )}
            {attachmentStatus && (
                <CustomTooltip title={'Anexos de turma'} placement="bottom" onClick={() => setIsOpenAttachmentSideBar(true)}>
                    <button>
                        <FiPaperclip />
                    </button>
                </CustomTooltip>
            )}
            {Boolean(showInteractionDrawerButton) && (
                <>
                    <CustomTooltip title={'Interações e comentários'} placement="bottom">
                        <button className="commentBtn" onClick={() => setIsOpen(true)}>
                            <div className="commentB">
                                <BiComment />
                            </div>
                        </button>
                    </CustomTooltip>
                </>
            )}
        </div>
    );
};

const MenuHeaderContentMobile = ({
    syllabusStatus,
    callSyllabus,
    meetingStatus,
    attachmentStatus,
    setIsOpenAttachmentSideBar,
    showInteractionDrawerButton,
    setIsOpen,
}) => {
    return (
        <div className="menuHeaderContentMobile">
            <DropdownMenu.Root
                onOpenChange={(e) => {
                    // setDrownOpen(e);
                }}
            >
                <DropdownMenu.Trigger className="DropdownMenuTriggerHeaderContent" asChild>
                    <button className={`perfilUsuarioHeader`}>
                        <FaEllipsisVertical />
                    </button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal className="DropdownMenuTriggerHeaderPortal">
                    <DropdownMenu.Content className="dropdownMenuContentHeaderPortal" sideOffset={3}>
                        {syllabusStatus && (
                            <DropdownMenu.Item className="dropdownMenuHeaderItem" onClick={callSyllabus}>
                                <TiDocument />
                                <span>Ver ementa</span>
                            </DropdownMenu.Item>
                        )}

                        {attachmentStatus && (
                            <DropdownMenu.Item className="dropdownMenuHeaderItem" onClick={() => setIsOpenAttachmentSideBar(true)}>
                                <div className="commentB">
                                    <FiPaperclip />
                                </div>
                                <span>Anexos de turma</span>
                            </DropdownMenu.Item>
                        )}

                        {meetingStatus && (
                            <DropdownMenu.Item className="dropdownMenuHeaderItem" onClick={() => {}}>
                                <RiCalendarEventFill />
                                <span>Encontros</span>
                            </DropdownMenu.Item>
                        )}

                        {Boolean(showInteractionDrawerButton) && (
                            <DropdownMenu.Item className="dropdownMenuHeaderItem" onClick={() => setIsOpen(true)}>
                                <div className="commentB">
                                    <BiComment />
                                    {/* <div className="comment">05</div> */}
                                </div>
                                <span>Interações e comentários</span>
                            </DropdownMenu.Item>
                        )}
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
    );
};

export const MenuHeaderContentView = (props) => {
    return (
        <>
            <MenuHeaderContentDesk {...props} />
            <MenuHeaderContentMobile {...props} />
        </>
    );
};
