import { useEffect, useState } from 'react';
import { getLevelNodeEnrollment } from 'services/api';
import { getProfileInfo } from 'services/utils';

const useNotificationCampaigns = ({ props, setCampaign }) => {
  const [contentItem, setContentItem] = useState(null);
  const [currentCampaignNode, setCurrentCampaignNode] = useState<any>(null);
  const [contentNavigationData, setContentNavigationData] = useState<any>({
    idEnrollment: null,
    idContentItem: null,
    response: null,
  });

  const idUser = getProfileInfo()?.idUser;

  // Verifica se a campanha foi completada
  const verifyCompleteCampaign = (nodeData) => {
    const enrollmentStatus = nodeData.nodeEnrollment.status;

    if (currentCampaignNode?.nodeEnrollment?.status !== 'COMPLETED' &&
        currentCampaignNode?.nodeEnrollment?.status !== 'PASSED') {

      if (enrollmentStatus === 'COMPLETED' || enrollmentStatus === 'PASSED') {
        setCampaign({ earnPointsStatus: true });
      }
    }

    // Reset state
    setCurrentCampaignNode(null);
    setContentItem(null);
  };

  // Verifica a campanha atual
  const verifyCampaign = () => {
    const { idContentItem, response } = contentNavigationData;

    if (contentItem === null || idContentItem === contentItem) {
      // Primeira vez
      if (!currentCampaignNode) {
        setCurrentCampaignNode(response.data.node);
        setContentItem(idContentItem);
      } else {
        // Campanha finalizada
        verifyCompleteCampaign(response.data.node);
      }
    } else {
      // Mudança de conteúdo
      setCurrentCampaignNode(response.data.node);
      setContentItem(idContentItem);
    }
  };

  // Controladores de navegação de conteúdo
  const handleContentNavigation = (contentNavigation, contentKey, dataKey) => {
    if (contentNavigation && contentNavigation[contentKey]) {

      // console.log(contentNavigation, contentKey, dataKey);

      setTimeout(() => {
        const { idEnrollment } = contentNavigation[dataKey];
        const idContentItem = contentNavigation[contentKey].idContentItem || contentNavigation[contentKey].idEvaluation;
        setContentNavigationData((prev) => ({ ...prev, idEnrollment, idContentItem }));
      }, 1000);
    }
  };

  const controlContent = (contentNavigation) => {
    handleContentNavigation(contentNavigation, 'content', 'contentEnrollment');
  };

  const controlScorm = (contentNavigation) => {
    handleContentNavigation(contentNavigation, 'objScorm', 'rootContent');
  };

  const controlEvaluation = (contentNavigation) => {
    handleContentNavigation(contentNavigation, 'evaluation', 'currentItem');
  };

  // useEffect para controlar a navegação do conteúdo
  useEffect(() => {
    const contentNavigation = props.configHeaderFooter?.contentNavigation;
    controlContent(contentNavigation);
    controlScorm(contentNavigation);
    controlEvaluation(contentNavigation);
  }, [props]);

  // useEffect para verificar campanha quando a resposta da query mudar
  useEffect(() => {
    if (contentNavigationData.response) {
      verifyCampaign();
    }
  }, [contentNavigationData]);

  // Atualiza o estado de contentNavigationData quando a query retorna dados
  useEffect(() => {
     if (!contentNavigationData.response && contentNavigationData.idEnrollment ) {
        //Se vc estiver vindo do level - os dados vão vir do redux
        if( props?.apiReplication?.getLevelNodeEnrollment){
          const response = props?.apiReplication?.getLevelNodeEnrollment;
          setContentNavigationData((prev) => ({ ...prev, response }));
        }else{
          //Se for direto - os dados vão vir da API
          getLevelNodeEnrollment(idUser, String(contentNavigationData.idEnrollment), null).then((response) => {
            setContentNavigationData((prev) => ({ ...prev, response }));
          })
        }
        
     }
  }, [contentNavigationData]);

  return null; // Ou qualquer retorno necessário para o seu hook
};

export default useNotificationCampaigns;
