import React, { useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { AccessSupport } from 'pctComponents/organisms';
import './style.scss';

export const cloneModalAccessSupport = () => {
    // Retorna uma nova instância do componente
    return function ClonedModalAccessSupport(props) {
        return <ModalAccessSupport {...props} />;
    };
}


const ModalAccessSupport = NiceModal.create((props: any) => {
    const modal = useModal(); 
    useEffect(() => {
        if (modal.visible) {
            document.body.classList.add('bodyOverflowHidden'); 
        } else {
            document.body.classList.remove('bodyOverflowHidden');
        }

        return () => {
            document.body.classList.remove('bodyOverflowHidden');
        };
    }, [modal]);

    return (
        <div className={`ModalAccessSupport`}>
            <div className={`modal-body-pct`}>
                <AccessSupport modal={modal} />
            </div>
        </div>
    );
});

export default ModalAccessSupport;
