import React from "react";

import { useHistory } from "react-router-dom";

import { Notification } from "pctComponents/atoms";

function HeaderNotification({ perfilStyle }) {
  const history = useHistory();

  return (
    <button
      className={`perfilHeader ${perfilStyle}`}
      onClick={() => {
        history.push(`/editPerfil`);
      }}
    >
      <Notification />
    </button>
  );
}

export default HeaderNotification;
