import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { IconContext, IconType } from "react-icons";

type Props = {
  // using `interface` is also ok
  icone: IconType;
  size?: string;
  color?: string;
  className?:string;
};

const Icon = ( props:Props ) => {
    
  return (
    <>
      <IconContext.Provider value={{  size:props.size,
                                      color:props.color,
                                      className:props.className}}>
          <props.icone/>
      </IconContext.Provider>
    </>
  );
};


export default Icon