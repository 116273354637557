import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaRegComment, FaExclamationTriangle } from 'react-icons/fa';
//  import ReactMarkdown from "react-markdown";
//  import remarkGfm from "remark-gfm";
import Markdown from 'markdown-to-jsx';
import InteractionMessageMenu from '../InteractionMessageMenu';
import './style.scss';
import { HiddenMessage } from 'pctComponents/atoms/HiddenMessage';

const InteractionReply = ({
    handleReplyOpen,
    menuOpitions,
    idComment,
    avatar,
    author,
    time,
    text,
    warningsCount,
    commentsCount,
    onResize,
    handleReply,
    removeButton,
    editButton,
    deleted,
    reportButton,
    reportedByMe,
    lastModifiedDate,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const messageTextRef = useRef<HTMLDivElement>(null);
    const messageRef = useRef<HTMLDivElement>(null);
    const [_reportedByMe, setReportedByMe] = useState(reportedByMe);
    const [_deleted, setDeleted] = useState(deleted);

    useEffect(() => {
        setReportedByMe(reportedByMe);
    }, [reportedByMe]);

    useEffect(()=>{
        setDeleted(deleted);
    },[deleted])

    const measureHeight = useCallback(() => {
        if (messageTextRef.current) {
            const { clientHeight, scrollHeight } = messageTextRef.current;
            const lineHeight = parseInt(window.getComputedStyle(messageTextRef.current).lineHeight, 10);
            const maxLines = 3;
            const maxHeight = lineHeight * maxLines;

            if (scrollHeight > maxHeight) {
                setShowMoreButton(true);
            } else {
                setShowMoreButton(false);
            }
        }

        if (messageRef.current) {
            const height = messageRef.current.getBoundingClientRect().height;
            onResize(height);
        }
    }, [onResize]);

    useEffect(() => {
        measureHeight();
    }, [text, expanded, measureHeight]);

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };
    const firstLetter = author ? author.substr(0, 1) : '';


    return (
        <div className="reply-wrapper" ref={messageRef}>
            {!_deleted ? (
                <div className="reply">
                    <div className="pctAvatar avatarImg avatarHrader">
                        <div className={`avtContainer message-avatar}`}>
                            {avatar ? <img src={avatar} className="avatarImg" alt={author} /> : <div className="avatarTxt">{firstLetter}</div>}
                        </div>
                    </div>
                    <div className="reply-content">
                        <div className="reply-author-time">
                            <p className="reply-author">{author}</p>
                            <p className="reply-time">{`${time} ${Boolean(lastModifiedDate) ? ' - editado' : ''}`}</p> 
                             
                            <InteractionMessageMenu
                                removeButton={removeButton}
                                editButton={editButton}
                                handleReplyOpen={handleReplyOpen}
                                idComment={idComment}
                                menuOpitions={menuOpitions}
                                reportButton={reportButton}
                                reportedByMe={_reportedByMe}
                                message={text}
                                isReply={true}
                                type={'reply'}
                            />
                        </div>
                        <div className="reply-text-container">
                            <div className={`reply-text ${expanded ? 'expanded' : 'collapsed'}`} ref={messageTextRef}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: text,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="reply-icons">
                            {commentsCount > 0 && (
                                <span className="icon">
                                    <FaRegComment /> {commentsCount}
                                </span>
                            )}
                            {/* {warningsCount > 0 && (
                                <span className="icon">
                                    <FaExclamationTriangle style={{ color: 'red' }} /> {warningsCount}
                                </span>
                            )} */}
                            {/* <span className="reply-reply" onClick={handleReply}>
               Responder
             </span> */}
                        </div>
                    </div>
                </div>
            ) : (
                <HiddenMessage
                    title={'A visualização desta resposta foi ocultada'}
                    subtitle="Esta resposta pode conter linguagem inadequada ou outro conteúdo que ai contra as nossas Diretrizes da Comunidade"
                />
            )}

            {showMoreButton && (
                <button onClick={handleToggleExpand} className="reply-toggle">
                    {expanded ? 'Ver menos' : 'Ver mais'}
                </button>
            )}
        </div>
    );
};

export default InteractionReply;
