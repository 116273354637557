import "./style.scss";
import React from "react";


function Preloader({className = 'pctPreloader'}) {
  
  return (
    <div className={"pctAtom " + className}>
        <div className="preloaderIt"></div>
    </div>
  )
}

export default Preloader