import React, { useEffect, useRef, useState } from 'react';
import { TbSearch } from 'react-icons/tb';
import { IoMdClose } from 'react-icons/io';
import { FaSquareArrowUpRight } from 'react-icons/fa6';
import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table, FormControlLabel, Checkbox, IconButton } from '@material-ui/core';
import './style.scss';
import PctPagination from '../PctPagination';
import useMediaQuery from 'pctHooks/useMediaQuery';
import { loginSupportAccess, renewlogin, usersListWithParams } from 'services/api';
import { Redirect } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

const ITEMS_PER_PAGE = 10;

interface AccessSupportUser {
    name: string;
    email: string;
    username: string;
    idUser: number;
}

const MyTooltip = ({ text, containerClasseName, textClassName, children }) => {
    return (
        <div className={containerClasseName}>
            {children}
            <span className={textClassName}>{text}</span>
        </div>
    );
};

const AccessSupport: React.FC<any> = ({ modal }) => {
    const isMobile = useMediaQuery('md');
    const [currentPage, setCurrentPage] = useState(1);
    const [immediateManager, setImmediateManager] = useState(false);
    const [listOnlyMyTeam, setListOnlyMyTeam] = useState(false);
    const [loadingAccessSupport, setLoadingAccessSupport] = useState(false);
    const [search, setSearch] = useState('');
    const [accessSupportUsers, setAccessSupportUserList] = useState<AccessSupportUser[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const observer = useRef<IntersectionObserver | null>(null);
    const lastElementRef = useRef<HTMLDivElement | null>(null);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    const retrieveUserList = async ({ offset = 0, limit = ITEMS_PER_PAGE, searchText = '', onlyActive = 1, isScroll = false }) => {
        setFirstTime(false);
        setIsLoading(true);
        try {
            const {
                data: { userList, totalRecords },
            } = await usersListWithParams({ offset, limit, searchText, onlyActive });

            const formattedData = userList.map((user) => {
                return { name: user.name, email: user.email, username: user.username, idUser: user.idUser };
            });

            if (isMobile) {
                setAccessSupportUserList((prevUsers) => (!isScroll ? formattedData : [...prevUsers, ...formattedData]));
            } else {
                setAccessSupportUserList(formattedData);
            }

            setTotalPages(totalRecords);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setAccessSupportUserList([]);
        setTotalPages(0);
        setCurrentPage(1);
        setFirstTime(true);
        setSearch('');
    }, [isMobile]);

    useEffect(() => {
        if (isMobile) {
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                const first = entries[0];
                const morePagesAvailable = currentPage < Math.ceil(totalPages / ITEMS_PER_PAGE);
                if (first.isIntersecting && !isLoading && morePagesAvailable) {
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            });

            const currentObserver = observer.current;
            if (lastElementRef.current) {
                currentObserver.observe(lastElementRef.current);
            }

            return () => {
                if (currentObserver && lastElementRef?.current) {
                    currentObserver.unobserve(lastElementRef?.current);
                }
            };
        }
    }, [isLoading, currentPage, totalPages, accessSupportUsers.length, isMobile]);

    useEffect(() => {
     if (currentPage > 1 && isMobile && !firstTime) {
            retrieveUserList({
                offset: (currentPage - 1) * ITEMS_PER_PAGE,
                limit: ITEMS_PER_PAGE,
                searchText: search,
                onlyActive: 1,
                isScroll: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, isMobile, firstTime]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= Math.ceil(totalPages / ITEMS_PER_PAGE)) {
            setCurrentPage(page);
            const newOffset = (page - 1) * ITEMS_PER_PAGE;
            retrieveUserList({ offset: newOffset, limit: ITEMS_PER_PAGE, searchText: search, onlyActive: 1 });
        }
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        if (isMobile && e.key === 'Enter') {
            doSearch();
        }
    };

    const onChangeCheckbox = (e, checked) => {
        e.preventDefault();
        setListOnlyMyTeam(checked);
    };

    const doSearch = () => {
        if (search.trim().length > 0) {
            setCurrentPage(1);
            retrieveUserList({ offset: 0, limit: ITEMS_PER_PAGE, searchText: search, onlyActive: 1 });
        }
    };

    const UserListingTable = () => {
        const accessUserSupport = async (name, idUser) => {
            const menuDataRaw = localStorage.getItem('menu-data');
            const menuData = menuDataRaw ? JSON.parse(menuDataRaw) : {};
            if (menuData && 'token' in menuData) {
                const { token: currentUserToken } = menuData;

                const {
                    data: { result: token },
                } = await loginSupportAccess(idUser);

                await renewlogin(token, {
                    accessSupport: {
                        currentUserToken,
                        supportAccessUser: { name },
                        isActive: true,
                    },
                });
                setLoadingAccessSupport(true);
                document.body.classList.remove('bodyOverflowHidden');
                modal.remove();
            }
        };

        return (
            <>
                <TableContainer component={Paper} className="access-support-table-container">
                    <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                        <TableHead>
                            {!isMobile && (
                                <TableRow className="access-support-table-head">
                                    <TableCell align="center">
                                        <span className="access-support-table-title">Nome</span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span className="access-support-table-title">Email</span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span className="access-support-table-title">Login</span>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span className="access-support-table-title last-span">Ação</span>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableHead>
                        <TableBody>
                            {accessSupportUsers.map((row) => (
                                <TableRow key={row.idUser} className="access-support-table-row">
                                    {!isMobile && (
                                        <TableCell align="center" className="access-support-table-cell">
                                            <div className="cell-content">{row.name}</div>
                                        </TableCell>
                                    )}
                                    {isMobile && (
                                        <div className="access-support-table-head">
                                            <TableCell align="left">{row.name}</TableCell>
                                        </div>
                                    )}
                                    <TableCell align="center" className="access-support-table-cell">
                                        {isMobile && <div className="cell-title">Email:</div>}
                                        <div className="cell-content">{row.email}</div>
                                    </TableCell>
                                    <TableCell align="center" className="access-support-table-cell">
                                        {isMobile && <div className="cell-title">Login:</div>}
                                        <div className="cell-content">{row.username}</div>
                                    </TableCell>
                                    <TableCell align="center" className="access-support-table-cell action">
                                        {isMobile && <div className="cell-title">Ação:</div>}
                                        <MyTooltip
                                            text="Acessar conta do usuário"
                                            containerClasseName="access-support-tooltip-container"
                                            textClassName="access-support-tooltip-text"
                                        >
                                            <div className="cell-content access-support-redirect-cell">
                                                <IconButton onClick={() => accessUserSupport(row.name, row.idUser)}>
                                                    <FaSquareArrowUpRight className="access-support-arrow-icon" />
                                                </IconButton>
                                            </div>
                                        </MyTooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div ref={lastElementRef} style={{ height: '10px' }}></div>
            </>
        );
    };

    const TableSkeleton = ({ rows = 5 }) => {
        return (
            <TableContainer component={Paper} className="access-support-table-container">
                <Table aria-label="simple table">
                    <TableHead>
                        {!isMobile && (
                            <TableRow className="access-support-table-head">
                                <TableCell align="center">
                                    <Skeleton variant="text" width={55} />
                                </TableCell>
                                <TableCell align="center">
                                    <Skeleton variant="text" width={55} />
                                </TableCell>
                                <TableCell align="center">
                                    <Skeleton variant="text" width={55} />
                                </TableCell>
                                <TableCell align="center" className="skeleton-redirect-cell-head">
                                    <Skeleton variant="text" width={48} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>
                        {[...Array(rows)].map((_, index) => (
                            <TableRow key={index} className="access-support-table-row">
                                {!isMobile && (
                                    <TableCell align="center" className="access-support-table-cell">
                                        <Skeleton variant="text" width={200} />
                                    </TableCell>
                                )}
                                {isMobile && (
                                    <div className="access-support-table-head">
                                        <TableCell align="left" width="100%">
                                            <Skeleton variant="text" width="100%" />
                                        </TableCell>
                                    </div>
                                )}
                                <TableCell align="center" className="access-support-table-cell">
                                    {isMobile && <Skeleton variant="text" width={21} />}
                                    <Skeleton variant="text" width={200} />
                                </TableCell>
                                <TableCell align="center" className="access-support-table-cell">
                                    {isMobile && <Skeleton variant="text" width={21} />}
                                    <Skeleton variant="text" width={200} />
                                </TableCell>
                                <TableCell align="center" className="access-support-table-cell">
                                    {isMobile && <Skeleton variant="text" width={21} />}
                                    <div className="cell-content access-support-redirect-cell">
                                        <Skeleton variant="rect" width={21} height={21} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    if (loadingAccessSupport) {
        return <Redirect to="/" />;
    }

    return (
        <div ref={scrollContainerRef} className={`access-support ${isMobile ? 'mobile' : ''}`}>
            <button className="access-support-close-btn" onClick={modal.remove}>
                <IoMdClose />
            </button>
            <div className="access-support-content">
                <div className="access-support-description">
                    <h1 className="access-support-title">Acesso suporte</h1>
                    <p className="access-support-subtitle">
                        Navegue com a mesma visão do usuário-alvo. Você terá as mesmas permissões e todas as atividades realizadas durante a
                        utilização desta funcionalidade serão registradas no login acessado.
                    </p>
                </div>
                <div className="access-support-search-container">
                    <div className="access-support-search">
                        <input
                            className="access-support-input"
                            placeholder="Pesquise por nome, e-mail ou login"
                            maxLength={300}
                            type="search"
                            value={search}
                            onChange={handleSearchChange}
                            onKeyDown={handleSearchSubmit}
                        />
                        <TbSearch className="access-support-search-icon" onClick={doSearch} />
                    </div>
                    {!isMobile && (
                        <button className="access-support-btnSearch" onClick={doSearch} disabled={search.length === 0}>
                            Pesquisar
                        </button>
                    )}
                </div>
                <span className="access-support-span">Apenas usuários ativos serão listados.</span>
                {immediateManager && (
                    <div className="access-support-list-team">
                        <FormControlLabel
                            className="access-support-checkbox-list-team"
                            onChange={onChangeCheckbox}
                            control={<Checkbox name="list-team" />}
                            label="Listar minha equipe"
                        />
                    </div>
                )}
                <div className="access-support-table">
                    <>
                        {isLoading && <TableSkeleton rows={5} />}
                        {!isLoading && accessSupportUsers.length > 0 && <UserListingTable />}
                        {!firstTime && !isLoading && accessSupportUsers.length === 0 && <p>Nenhum resultado encontrado.</p>}
                    </>
                </div>
            </div>
            {!isMobile && !isLoading && accessSupportUsers.length > 0 && (
                <div className="access-support-footer">
                    <div className="access-support-paginate">
                        <PctPagination total={Math.ceil(totalPages / ITEMS_PER_PAGE)} current={currentPage} onPageChange={handlePageChange} />
                    </div>
                </div>
            )}

            {!isMobile && !firstTime && !isLoading && accessSupportUsers.length === 0 && (
                <div className="access-support-footer">
                    <div className="access-support-paginate"></div>
                </div>
            )}

            {!isMobile && isLoading && (
                <div className="access-support-footer">
                    <div className="access-support-paginate">
                        <Skeleton variant="rect" width="100%" height={50} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccessSupport;
