import React from "react";
import ModalWarning from "./ModalWarning";
import { useHistory } from "react-router-dom"

function AdminAccess({ denyAction, acceptAction, fromSection = false }) {
  const appDetails = JSON.parse(localStorage.getItem("appDetails"));
  const { token } = JSON.parse(localStorage.getItem("menu-data"));
  const history = useHistory();

  return (
    <ModalWarning
      description={
        <div style={{ textAlign: "center" }}>
          Você será redirecionado para area de administração!
        </div>
      }
      acceptAction={() => {
        window.open(`https://${appDetails.adminDomain}?token=${token}#/login`);
        acceptAction();
      }}
      denyAction={fromSection ? history.goBack : denyAction}
    />
  );
}

export default AdminAccess;
