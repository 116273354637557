import React from "react"; // TODO item não utilizado
import ShowRating from './Components/ShowRating'
import Rate from './Components/Rate'


function Rating( {isDesktop, userStar, id, stars, numberVotes} ) {

        return (typeof stars != 'undefined') ?
            <ShowRating stars={stars} numberVotes={numberVotes} />
        :
            <Rate isDesktop={isDesktop} userStar={userStar} id={id}/>
        ;
}

export default Rating;
