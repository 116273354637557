import "./style.scss";

import React from "react";
import { Button } from "../../atoms";
import { CardStandard, CardAvatar, CardNavigation } from "../../molecules";
import AlertComponent from "../AlertComponent";

import useCardsFormat from "../../../pctHooks/useCardsFormat";

const CardListing = (props) => {
  const { data, _parentData } = props;
  const { parameters } = data as any;
  const typeContainer = parameters.type
    ? parameters.type.toUpperCase()
    : "CARDSTANDARD";

  // console.log(parameters.cardFormat);

  const { displayColumnsElementsCalc, formatCard, effectCard } = useCardsFormat(parameters);

  const widthCard = `${100 / displayColumnsElementsCalc}%`;
  const widthCardStyle = { width: widthCard };

  const tableRows = data.elements.map((collection: any, i) => {
    if (typeContainer === "BUTTON") {
      return (
        <div key={i} className="containerBtn" style={widthCardStyle}>
          <Button text={collection.text} className="button03" />
        </div>
      );
    } else if (typeContainer === "CARDSTANDARD") {
      return (
        <CardStandard
          key={i}
          css={widthCardStyle}
          format={formatCard}
          effects={effectCard}
          collection={collection}
          dataParent={_parentData}
        />
      );
    } else if (typeContainer === "CARDNAVIGATION") {
      return (
        <CardNavigation
          key={i}
          css={widthCardStyle}
          format={formatCard}
          effects={effectCard}
          collection={collection}
          dataParent={_parentData}
        />
      );
    } else if (typeContainer === "CARDAVATAR") {
      return (
        <CardAvatar key={i} css={widthCardStyle} collection={collection} />
      );
    } else {
      return (
        <AlertComponent
          fullpage={false}
          message={`O tipo "${typeContainer}" não existe na lista de componentes.`}
        />
      );
    }
  });

  return tableRows;
};

export default CardListing;
