import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './cropImage'
import { uploadImageApi } from '../services/api'

const ImageCropper = ({ getBlob, inputImg, callbackImage }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)

    /* onCropComplete() will occur each time the user modifies the cropped area, 
    which isn't ideal. A better implementation would be getting the blob 
    only when the user hits the submit button, but this works for now  */
    const onCropComplete = async (_, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
            inputImg,
            croppedAreaPixels
        )
        getBlob(croppedImage)
    }

    const handleSubmitImage = (uploadFileItem) => {
        let addFormat = "";
        let tipo = 'image';
        if (uploadFileItem.type.includes("image")) {
            addFormat = uploadFileItem.type.replace("image/", ".");
        } else {
            tipo = 'video';
            addFormat = uploadFileItem.type.replace("video/", ".");
          //props.loading(true);
        }
    
        const filename = "fe_thumb" + Date.now();
        
        let data = new FormData();
        data.append("fileData", uploadFileItem);
        data.append("fileName", filename);
        
        uploadImageApi(data)
          .then((response) => {
            //props.success(true);
            if (200 === response.status) {
                let fileData = response.data;
                callbackImage(fileData.url)
                //aqui vc tem o retorno
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
    }

    return (
        /* need to have a parent with `position: relative` 
    to prevent cropper taking up whole page */
        <div className='cropper'> 
            <Cropper
                image={inputImg}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
            />
        </div>
    )
}

export default ImageCropper