import { useEffect, useState } from 'react'


type Props = {
  cardFormat?: string;
  effects?: string;
};

const useCardsFormat = (parameters:Props) => {
   
    let displayColumnsElementsCalc:number = 5;
    let formatCard:string = parameters.cardFormat ? parameters.cardFormat : 'portrait';
    let effectCard:string = parameters.effects ? parameters.effects : '';

    if (formatCard === "portrait") {
        displayColumnsElementsCalc = 5;
    } else if (formatCard === "square") {
        displayColumnsElementsCalc = 4;
    } else if (formatCard === "landscapeXsmall") {
        formatCard = "landscape-xsmall";
        displayColumnsElementsCalc = 3;
    }
    
    return {
        displayColumnsElementsCalc,
        formatCard,
        effectCard
    }
}

export default useCardsFormat

