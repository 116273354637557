import "./style.scss";

import React from "react";

import { TiDocument } from "react-icons/ti";

import { Text } from "pctComponents/atoms";
import useSyllabus from "pctHooks/useSyllabus";

import { removeTagsHtml } from "services/utils";

type Props = {
  collection: BlockElement | any;
  css?: object;
  className?: string;
};

function ViewSyllabus({ collection, css = {}, className = "" }: Props) {
  const { callShowSyllabus } = useSyllabus({ collection });

  return collection.data.syllabus &&
    removeTagsHtml(collection.data.syllabus).length > 0 &&
    collection.data.nodeType === "LEARNING" ? (
    <button
      onClick={callShowSyllabus}
      className={`pctAtom pctViewSyllabus ${className}`}
      style={css}
    >
      <Text component="small" text={`Ver ementa`} />
      <TiDocument />
    </button>
  ) : null;
}

export default ViewSyllabus;
