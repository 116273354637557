import "./style.scss";

import React from "react";
import Flickity from "react-flickity-component";
import { Banner } from "../../molecules";

type Props = {
  data: any;
  type?: string;
  _parentData?: any;
  dots?: boolean;
  auto?: boolean;
};

function MasterBanner({
  data,
  _parentData,
  type,
  dots = true,
  auto = true,
}: Props) {
  const { effects, infos, marginBottom, marginTop, size } = data.parameters;

  const shadowClass =
    String(effects).indexOf("shadow") !== -1 ? "b_shadow" : "b_shadow_none";
  const infoClass = !!Number(infos) ? "b_info" : "b_info_none";
  const sizeClass = size !== null ? "b_size b_size_" + size : "b_size b_size_l";
  const MBClass =
    marginBottom !== null ? "b_mb b_mb_" + marginBottom : "b_mb b_mb_none";
  const MTClass =
    marginTop !== null ? "b_mt b_mt_" + marginTop : "b_mt b_mt_none";

  const masterBannerClass =
    MBClass +
    " " +
    MTClass +
    " " +
    shadowClass +
    "_master " +
    infoClass +
    "_master " +
    sizeClass +
    "_master ";

  const bannerClass = shadowClass + " " + infoClass + " " + sizeClass + " ";

  const typeContainer =
    data.elements && data.elements.length > 0 && type !== "list"
      ? "SLIDER"
      : type === "list"
      ? "LIST_IMAGE"
      : "NO_IMAGE";

  const rotationInterval = Number(data?.parameters?.rotationInterval) || null;

  const flickityOptions = {
    autoPlay: rotationInterval ? 1000 * rotationInterval : false,
    pageDots: dots,
    accessibility: true,
    adaptiveHeight: true,
    wrapAround: true,
  };

  return (
    <>
      {typeContainer === "SLIDER" && (
        <section className={`pctMasterBanner ${masterBannerClass}`}>
          <Flickity options={flickityOptions}>
            {data.elements.map((dataItem: any, i) => {
              return (
                <Banner
                  key={i}
                  data={dataItem.data}
                  _parentData={_parentData}
                  className={bannerClass}
                  size={size}
                  elemType={dataItem.elementType}
                  elemId={dataItem.idElement}
                />
              );
            })}
          </Flickity>
        </section>
      )}
      {typeContainer === "LIST_IMAGE" && (
        <section
          className={`pctOrganism pctMasterBanner ${
            type != "list" ? masterBannerClass : ""
          } `}
        >
          <Banner
            data={
              _parentData
                ? _parentData.node
                  ? _parentData.node
                  : _parentData
                : data
            }
            className={"bannerImage"}
            type="IMAGE"
            size={size}
            _parentData={_parentData}
            elemId={
              _parentData
                ? _parentData.node && _parentData.node.idElement
                  ? _parentData.node.idElement
                  : _parentData.idElement
                  ? _parentData.idElement
                  : _parentData.idNode
                : data.idElement
            }
          />
        </section>
      )}
      {typeContainer === "NO_IMAGE" && <></>}
    </>
  );
}

export default MasterBanner;
