import './style.scss';

import React, { useState, useEffect, useContext, useRef } from 'react';

import { CreateEvaluationKnowledgeContext } from '../../../pctContexts/contextEvaluationKnowledge';
import { Button, Text } from 'pctComponents/atoms';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';

type Props = {
    finishEvent?: any;
};

const EvaluationKnowPlayerFooter = ({ finishEvent }: Props) => {
    const {
        modalLoading,
        contextCurrentEvaluation,
        evaluationCurrentIndex,
        evaluationGoToNextQuestion,
        evaluationGoToPrevQuestion,
        getQuestionsNotAnswer,
        currentQuestionIsAnswer,
        setContextUpdateFooterRef,
        finishEvaluation,
    } = useContext(CreateEvaluationKnowledgeContext);

    const [updateFooter, setUpdateFooter] = useState('');
    const questionsNotAnswer = useRef(getQuestionsNotAnswer());
    const nextQuestionIsBlocked = useRef(
        (Number(contextCurrentEvaluation.parameters.lockNavigation) === 1 && currentQuestionIsAnswer() === false) ||
            Number(evaluationCurrentIndex + 1) === Number(contextCurrentEvaluation.parameters.questionAmount)
            ? true
            : false,
    );

    useEffect(() => {
        setContextUpdateFooterRef(setUpdateFooter);
    }, [setContextUpdateFooterRef]);

    useEffect(() => {
        questionsNotAnswer.current = getQuestionsNotAnswer();
        nextQuestionIsBlocked.current =
            (Number(contextCurrentEvaluation.parameters.lockNavigation) === 1 && currentQuestionIsAnswer() === false) ||
            Number(evaluationCurrentIndex + 1) === Number(contextCurrentEvaluation.parameters.questionAmount)
                ? true
                : false;

        setUpdateFooter('');
    }, [updateFooter]);

    return (
        <div className="evaluationKnowPlayerFooter">
            <div className="controlFooterBase">
                <div className="controlFooter">
                    <button
                        className={`btnNav ${evaluationCurrentIndex === 0 ? 'btnNavInative' : ''}`}
                        onClick={(e) => {
                            //AVANÇA PARA A QUESTÃO ANTERIOR
                            evaluationGoToPrevQuestion();
                            setUpdateFooter(String(e.timeStamp));
                        }}
                    >
                        <GrFormPrevious className="btnIco" />
                        <Text text={'Retornar à questão'} />
                    </button>

                    <div className="navSizePage">
                        <Text
                            text={`${evaluationCurrentIndex + 1} / ${
                                contextCurrentEvaluation.parameters.questionAmount
                            }`}
                        />
                    </div>

                    <button
                        className={`btnNav ${nextQuestionIsBlocked.current ? 'btnNavInative' : ''}`}
                        onClick={(e) => {
                            //AVANÇA PARA A PRÓXIMA QUESTÃO
                            evaluationGoToNextQuestion();
                            setUpdateFooter(String(e.timeStamp));
                        }}
                    >
                        <Text text={'Avançar questão'} />
                        <GrFormNext className="btnIco" />
                    </button>
                </div>

                {(Number(evaluationCurrentIndex + 1) === Number(contextCurrentEvaluation.parameters.questionAmount) ||
                    questionsNotAnswer.current.length === 0) && (
                    <Button
                        className="button03"
                        text="Enviar avaliação"
                        callEvent={() => {
                            const questionsNotAnswer = getQuestionsNotAnswer();

                            //CASO NÃO EXISTA QUESTÕES AINDA NÃO RESPONDIDAS.
                            if (questionsNotAnswer.length === 0) {
                                finishEvaluation(() => {
                                    if (finishEvent) finishEvent();
                                });
                            } else {
                                modalLoading.show({
                                    obj: {
                                        content: () => {
                                            return (
                                                <div className="alertText">
                                                    <h3 className="primaryColor">
                                                        {questionsNotAnswer.length > 1
                                                            ? `É necessário responder todas as questões para enviar a avaliação.`
                                                            : `É necessário responder a questão para enviar a avaliação.`}
                                                    </h3>
                                                    <h4>
                                                        {questionsNotAnswer.length > 1
                                                            ? `Faltam responder as seguintes questões:`
                                                            : `Falta responder a seguinte questão:`}
                                                    </h4>
                                                    <p> {questionsNotAnswer.join(', ')} </p>
                                                </div>
                                            );
                                        },
                                        closeBtn: true,
                                        className: 'evaluationsModal evaluationsModalFeed',
                                    },
                                });
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default EvaluationKnowPlayerFooter;
