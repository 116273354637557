import React, { useState, useEffect, useRef } from "react";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {  ModalRulesGamification } from "pctComponents/modals/ModalRulesGamification";


import { getListCampaignsActive, getListCampaignpointsSummary, getDataCampaignUser, getCampaignDepartments } from "services/api";

type Props = {
  userId: number;
};

const useListGamification = ({ userId }: Props) => {

  const columns = [
    {
      header: "Posições",
      accessor: "position",
    },
    {
      header: "Usuários",
      accessor: "name",
    },
    {
      header: "Pontuação",
      accessor: "points",
    },
  ];


  const [loading, setLoading] = useState(false);
 
  const [currentCampaign, setCurrentCampaign] = useState<any>(null);
  const [idCampaign, setidCampaign] = useState(null);
  const [departmentCampaign, setDepartmentCampaign] = useState('');
  const [dataUser, setDataUser] = useState<any>(null);
  const [listCampaign, setListCampaign] = useState<any>(null); 
  const [listDepartments, setListDepartments] = useState<any>(null); 
  const [listPointCampaign, setlistPointCampaign] = useState<any>(null); 

  const [itemOffset, setItemOffset] = useState(0);
  const itemsLenght = useRef(0);
  const itemsPerPage = 10;
  const pageNum = useRef(0);
  const pageCount = Math.ceil(itemsLenght.current / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % itemsLenght.current;

    pageNum.current = event.selected;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    let isMounted = true;

     getListCampaignsActive()
      .then((res) => {
        if(isMounted){
          
          const refCampaingns = res.data.campaigns.map((it)=>{
            it.title = it.name;
            return it; 
          })
          setListCampaign(refCampaingns);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, []);

   useEffect(() => {
    
    if(idCampaign){
      
      const params = {
        "idCampaign" : idCampaign,
        'offset' : itemOffset,
        'limit' : itemsPerPage,
        'department' : departmentCampaign
      };

      setLoading(true);
      setDataUser(null);
      getListCampaignpointsSummary(params)
        .then((res) => {
          itemsLenght.current = res.data.totalRecords;

          const indicePostion = ( pageNum.current * itemsPerPage );
          const campPoint = res.data.campaignpoints.map((it, indice)=>{
            it.position = ( indice + 1 ) + indicePostion;
            return it;
          })

          infoDataCampaignUser();
          infoCampaignDepartments();
          setlistPointCampaign(campPoint);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
   
  }, [idCampaign, itemOffset, departmentCampaign]);


  const infoDataCampaignUser = () =>{
    getDataCampaignUser({ idCampaign, idUser: userId , department: departmentCampaign })
        .then((res) => {
          setDataUser(res.data.campaignUserDetail)
        })
        .catch((err) => {
          setDataUser('userNotDepartment');
        });
  }

  const infoCampaignDepartments = () =>{
    getCampaignDepartments({ idCampaign })
        .then((res) => {
          
          const refDepartments = res.data.result.map((it)=>{
            it.title = it.name;
            return it; 
          })
          setListDepartments([ {title:'-', reset:true} , ...refDepartments]);
        })
        .catch((err) => {
          console.log(err);
        });
  }


  const selectCampaign = (it) =>{
    setListDepartments(null);
    setDepartmentCampaign('');
    setidCampaign(it.idgamification_campaign);
    setCurrentCampaign(it);
  } 
  
  const selectDepartment = (it) =>{
    setItemOffset(0);
    setDepartmentCampaign(it.reset ? '' : it.title);
  } 

  const callRules = () =>{
    
    const {idgamification_campaign} = currentCampaign;
    
    NiceModal.show(ModalRulesGamification, {
      idgamification_campaign
    })
  }

  return { columns, listCampaign, selectCampaign, selectDepartment, itemsLenght, handlePageClick, pageCount, pageNum, listPointCampaign, loading, dataUser, listDepartments, currentCampaign, callRules };
};

export default useListGamification;
