import "./style.scss";

import React from "react";

import { Button, Text } from "pctComponents/atoms";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import icoDenuncia from '../../../assets/images/ico-denuncia.svg'
import icoConfirm from '../../../assets/images/ico-confirm.svg'
import { ReactSVG } from "react-svg";

type Props = {
  title?: string;
  className?: string;
  htmlBody?: any;
  btn1Info?: {
    event: () => void;
    text: string;
    className?: string;
  };
  btn2Info?: {
    event: () => void;
    text: string;
    className?: string;
  };
  show: boolean;
  setShow?: any;
};

const ModalInteractionDrawer = ({
  title,
  htmlBody,
  btn1Info,
  btn2Info,
  show,
  setShow = null,
  className = "",
}: Props) => {
  return show ? (
    <div className={`modalInteractionDrawer ${className}`}>
      {setShow && (
        <div className="modalDialogHit" onClick={() => setShow(false)} />
      )}
      <div className="modalWrapper">
        <div className="topbar" >
          <div className="headerIcon">
            <ReactSVG src={icoDenuncia} alt="img" className="icoDenuncia" />
            <ReactSVG src={icoConfirm} alt="img" className="icoConfirm" />
          </div>
          <button className="closeButton" onClick={() => setShow(false)}>
              <CloseIcon />
          </button>
        </div>
        {title && (
          <div className="topbarHeader">
            <Text component="h3" text={title} />
          </div>
        )}
        {htmlBody && <div className="htmlBody">{htmlBody}</div>}
        <div className="buttonsWrapper">
          {btn1Info && (
            <Button
              callEvent={btn1Info.event}
              className={`button04 ${
                btn1Info.className ? btn1Info.className : ""
              }`}
              text={btn1Info.text}
            />
          )}

          {btn2Info && (
            <Button
              callEvent={btn2Info.event}
              className={`button04 ${
                btn2Info.className ? btn2Info.className : ""
              }`}
              text={btn2Info.text}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ModalInteractionDrawer;
