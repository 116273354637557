import './style.scss';

import React, { Component } from "react";
import ReactDOM from 'react-dom';

import { Text } from '../../atoms';

type Props = {
    css?: object
    className?: string
    html?: string
    model?: string
  }

const TextSection = (prop: Props) => {

    const { html , className="" } = prop;

    return (
        <section className={`pctOrganism pctTextSection ${className}`}>
            <div className="textSectionBase">
                <Text component="p" text={html} className='textBlockStyle'/>
            </div>
        </section>
    )
};

export default TextSection