import './style.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CarrousselView } from 'pctComponents/molecules';
import { Text, Button } from 'pctComponents/atoms';

import CardUpgrade from '../CardUpgrade';
import parse from 'html-react-parser';
import { DialogModel, PlaneProps, ViewScreen } from '../../@model/ManagerTrial.model';

import { useManagerTrial } from '../../context/contextManagerTrial';

import { textPlansStatus } from '../../utils/TextPlansStatus';
import { dialogContainerModel } from '../../utils/DialogContainerModel';
import { useModal } from '@ebay/nice-modal-react';
import PartyPopper from 'pctComponents/svg/PartyPopperSVG';
import { postExtendTrial,  renewlogin } from 'services/api';
import moment from 'moment';

const SelectedPlanUpgrade = (props) => {
    const [otherPlans, setOtherPlans] = useState<PlaneProps[]>([]);
    const [loading, setLoading] = useState<Boolean>(false);
    const modal = useModal();

    useEffect(() => {
        getOtherPlans();
    }, []);

    const { trialStatusPlan, currentPlan,setCurrentPlan, setViewScreenPlan, dialogPlan, setDialogPlan, listPlans } = useManagerTrial();
    const { name, deadline, planUpgrade, idPlan, trialsCount } = currentPlan as PlaneProps;
    const planText = {
        screen: 'SelectedPlanUpgrade',
        trialStatusPlan: trialStatusPlan,
        deadline,
        plan: name,
        requestsInfo: planUpgrade?.numberRequest,
    };

    const getOtherPlans = () => {
        const areOtherPlans = (listPlans || []).filter((plan) => plan.idPlan !== currentPlan?.idPlan);
        setOtherPlans(areOtherPlans as PlaneProps[]);
    };

    const buyPlan = async () => {
        setViewScreenPlan(ViewScreen.calculatorPlan);

        // setViewScreenPlan(ViewScreen.dialog);
        // setDialogPlan(
        //   dialogContainerModel({
        //     type: DialogModel.ConfirmPlan,
        //     plan: name,
        //     buttonConfirm: {
        //       callEvent: async () => {
        //         const _menuData = localStorage.getItem('menu-data');
        //         const {token} = _menuData ? JSON.parse(_menuData) : null;

        //         const _appDetails = localStorage.getItem('appDetails');
        //         const {adminBucket} = _appDetails ? JSON.parse(_appDetails) : null;

        //         window.open(`https://${adminBucket}?token=${token}#/login`);
        //         modal.remove();
        //       },
        //     },
        //     buttonCancel: {
        //       callEvent: async () => {
        //         modal.remove();
        //       },
        //     }
        //   })
        // );
    };

    const extendTrial = async () => {
        return postExtendTrial({
            idPlan,
        });
    };

    const updateTimeTrial = useCallback(async () => {
        setLoading(true)
        try {
            const {
                data,
            } = await extendTrial();
            const startTime = moment().startOf('day');
            const finishTime = moment(data?.finishDate).startOf('day');
    
            const _deadline = finishTime.diff(startTime, 'days');
            
            await renewlogin();
        
            setCurrentPlan((prev ): PlaneProps =>( { ...prev,...data,deadline: _deadline }))
            props.updatePlan({...props.planUpgrade})
            setDialogPlan(
                dialogContainerModel({
                    type: DialogModel.ExtendTrial,
                    agreement: 7,
                    plan: name,
                    buttonConfirm: {
                        callEvent: async () => {
                            window.location.reload();
                            modal.remove()
                        },
                    },
                }),
            );
            setViewScreenPlan(ViewScreen.expiring);
            props.updatePlan({...props.planUpgrade})
           
        } catch(e){
         console.log(e)
        }finally {
        setLoading(false)

        }
       
    }, []);

    const needMoreTimeTrial = useMemo(() => {
        if (Number(deadline) > 0) {
            return <></>;
        }
        return (
            <div className="pctDivNeedMoreTime">
                <Text component={'span'} text={'Precisa de mais tempo? '}></Text>
                <div onClick={() => trialsCount === 1 ? updateTimeTrial() :   setViewScreenPlan(ViewScreen.form)}>
                    <Text className={'link'} component={'span'} text={'Entre em contato com nosso time'}></Text>
                </div>
            </div>
        );
    }, [deadline]);

    return (
        <div className="pctSelectedPlanUpgrade">
            {loading ? (
                <div className="loader">
                    <div className="preloaderIt"></div>
                </div>
            ) : (
                <>
                    <div className={`pctSelectedPlaItemCurrent`}>
                        {currentPlan && <CardUpgrade key={`card-upgrade-${currentPlan.idRef}`} showDetail={true} elem={currentPlan} />}
                    </div>
                    <div className="pctSelectedPlaItemInfo">
                        {(currentPlan?.deadline ?? 0) > 0 && planUpgrade && planUpgrade.numberRequest > 0 && (
                            <div className={`rowItem padding`}>
                                <div className="circle">
                                    <span className="icone">{parse(`${PartyPopper()}`)}</span>
                                </div>
                                <Text
                                    component={'p'}
                                    text={textPlansStatus({
                                        ...planText,
                                        element: 'requests-info',
                                    })}
                                />
                            </div>
                        )}

                        <Text
                            component={'h1'}
                            text={textPlansStatus({
                                ...planText,
                                element: 'h1',
                            })}
                        />
                        <Text
                            component={'p'}
                            text={textPlansStatus({
                                ...planText,
                                element: 'p',
                            })}
                        />
                        <div className="buttonsWrapperSelected">
                            {otherPlans.length > 0 && (
                                <Button
                                    callEvent={() => {
                                        setViewScreenPlan(ViewScreen.upgrade);
                                    }}
                                    className={`button01`}
                                    text={`Ver planos`}
                                />
                            )}
                            <Button callEvent={buyPlan} className={`button06`} text={`Contratar agora`} />
                        </div>
                        {needMoreTimeTrial}
                    </div>
                </>
            )}
        </div>
    );
};

export default SelectedPlanUpgrade;
