import React from "react";

import { useManagerTrial } from "../../context/contextManagerTrial";
import { PlaneProps } from "../../@model/ManagerTrial.model";

const TermUpgrade = () => {
  const { currentPlan } = useManagerTrial();
  const { agreementContent } = currentPlan as PlaneProps;

  return (
    <>
      <div className="terms-container">
        <div className="terms-wrapper">
          <div className="term-container">
            <div
              dangerouslySetInnerHTML={{
                __html: agreementContent || "agreement",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TermUpgrade;
