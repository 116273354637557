import React from "react";

import { Link } from "react-router-dom";

import { Toast } from "pctComponents/atoms";
import TrophySVG from "pctComponents/svg/TrophySVG";

const HeaderContentToast = () => {
  return (
    <h4>
      <span>Parabéns!</span> Você acaba de ganhar pontos em uma ou mais
      campanhas. Acesse sua área de{" "}
      <Link className="linkToast" to={"/myjourney/gamification"}>
        Campanhas e Gamificação
      </Link>{" "}
      para mais detalhes.
    </h4>
  );
};

function HeaderToast({ campaign }) {
  return (
    <Toast
      status={campaign ? campaign.earnPointsStatus : false}
      duration={9}
      ico={{ class: "icoTrophy", element: TrophySVG() }}
      ContentToast={<HeaderContentToast />}
    />
  );
}

export default HeaderToast;
