import React, { useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import useModalOverflowHidden from '../../../../../pctHooks/useModalOverflowHidden';
import { cancelExternalCourses } from '../../../../../services/api';
import { cancelExternalCoursesServices } from '../../ExternalCourse.services';
import { ExternalCourseType } from '../../ExternalCourse.types';

interface ModalCancelProps {
    item: Partial<ExternalCourseType>;
    resetItemOffset: () => void | null;
}

const ModalCancelController = ({ item, resetItemOffset }: ModalCancelProps) => {
    const modal = useModal();

    const [loading, setLoading] = useState(false);

    useModalOverflowHidden(modal);

    const closeModal = () => {
        modal.remove();
    };

    const onHandledCancel = () => {
        setLoading(true);
        if (item) {
            cancelExternalCoursesServices({
                idUserExternalCourse: item.idUserExternalCourse,
                resetItemOffset,
                setLoading,
                closeModal,
            });
        }
    };

    return {
        loading,
        closeModal,
        onHandledCancel,
    };
};

export default ModalCancelController;
