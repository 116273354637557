import "./style.scss";
import React, { useEffect } from "react";
import NotFoundLogo from '../../../assets/images/not-found.svg';
import { Text } from "../../atoms";
import { MdOutlineSimCardAlert } from "react-icons/md";

type Props = {
  message ?: string,
  fullpage ?: boolean
};

const AlertComponent = ({ message, fullpage = true }: Props) => {
  return (
    <>
      <div className={`pctAlert ${fullpage ? 'fullpage' : ''}`}>
          <div className="pctErrorContainer"> 
              <MdOutlineSimCardAlert/>
              <Text text={message} />
          </div>
      </div>
    </>
  );
};


export default AlertComponent;
