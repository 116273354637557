import "./style.scss";
import React from "react";
import {
  Award,
  Duration,
  Label,
  Text,
  ProgressBar,
  Star,
  Visualization,
  Image,
} from "../../atoms";
import { useHistory } from "react-router-dom";


type Props = {
  id: any;
  key: any;
  collection: any;
};

const CardBlockJourney = ({ collection }: Props) => {
  const cardType =
    collection.enrollment === true || collection.enrollment === false
      ? "enrollments"
      : "nodes";
  const history = useHistory();

  switch (cardType) {
    case "nodes": {
      const {
        label,
        views,
        endDate,
        enabled,
        text,
        title,
        duration,
        idContentType,
        nodeCode,
      } = collection.data;
      // const lockStatus = ( !enabled ) ? true : false;

      // const viewsText = ( views && +views > 0 ) ?  `${views}` : "0"
      // const modelText = null;

      const { progress, status } = collection.data.enrollment;

      const progressPorc = progress && +progress > 0 ? `${Math.round(progress)}%` : "";
      let concluidedText = "";

      if (status === "COMPLETED") {
        concluidedText =
          "<i class='fa fa-check' aria-hidden='true'></i> Concluído";
      } else if (status === "NOT_ATTEMPTED" || status === undefined) {
        concluidedText = "Não iniciado";
      } else if (status === "IN_PROGRESS") {
        concluidedText = "Em andamento";
      }

      const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        let pathLink = "";

        // console.log(collection.data.userEnrollment);
        // history.push( collection.elementType != "NODE" ? pathLink : `/nodeTree/${nodeCode}` );

        if (collection.data.enrollment) {
          history.push(`/level/${collection.data.enrollment.idEnrollment}`);
        }
      };

      return (
        <div
          className="cardBlockJourney"
          onClick={(e) => {
            handleMouseEvent(e);
          }}
        >
          <Label text={label} />
          {/* <Label text={getContentTypeLabel(idContentType)!="N/A"?getContentTypeLabel(idContentType):label} /> */}

          <div className={`infosContainer`}>
            <div className="headerInfos">
              {title && <Text component="p" text={title} />}
              {text && <Text component="p" text={text} />}
            </div>

            <div className="footerInfos">
              <Text component="small" text={concluidedText} />

              {(progress > 0 && progress < 100) &&
                <Text component="small" text={progressPorc} />
              }
              
              {duration && <Duration text={duration} timeFormat={"seconds"} />}
            </div>
          </div>
        </div>
      );

      break;
    }

    case "enrollments": {
      const { label, workload, views, description, title, nodeCode } =
        collection;
      const { progress } = collection.enrollment;
      const progressPorc = progress && +progress > 0 ? `${progress}%` : "";
      // const concluidedText = progress == 100 ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}` : status == "NOT_ATTEMPTED" ?"Não iniciado":`${"Em andamento " + progressPorc}`;
      const concluidedText =
        progress === 100
          ? `${"<i class='fa fa-check' aria-hidden='true'></i> Concluído"}`
          : `${"Em andamento " + progressPorc}`;

      const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        console.log(collection);
        e.preventDefault();
        history.push(`/nodeTree/${nodeCode}`);
      };

      return (
        <div
          className="cardBlockJourney"
          onClick={(e) => {
            handleMouseEvent(e);
          }}
        >
          <Label text={label} />
          {
            <div className={`infosContainer`}>
              <div className="headerInfos">
                {title && <Text component="p" text={title} />}
              </div>

              <div className="footerInfos">
                <Text component="small" text={concluidedText} />
                {workload && (
                  <Duration text={workload} timeFormat={"seconds"} />
                )}
              </div>
            </div>
          }
        </div>
      );

      break;
    }
  }
};

export default CardBlockJourney;
