import "./style.scss";

import React, { useState } from "react";
import { getProfileInfo } from "services/utils";
import { Text, Preloader } from "../../atoms";
import { useFetch } from "../../../pctHooks/useFetch";
import { CardStandard, CarrousselView } from "../../molecules";
import { CardGrid } from "pctComponents/organisms";

import useCardsFormat from "../../../pctHooks/useCardsFormat";

import { FaAngleRight } from "react-icons/fa6";

// type Props = {
//   data: Block;
// };

const EnrollmentsInProgress = (props) => {
  const [openGrid, setOpenGrid] = useState(false);

  const { parameters } = props.data;
  const { title, description } = parameters;
  const { data: dataElements, error } = useFetch({
    url: `/learning/students/${
      getProfileInfo().idUser
    }/enrollments-in-progress`,
  });

  const { displayColumnsElementsCalc, formatCard, effectCard } =
    useCardsFormat(parameters);

  if (error) return <p>There is an error.</p>;
  if (!dataElements) return <Preloader />;

  const fetchData: any = dataElements as Array<LearningNode>;
  // console.log( fetchData.data );

  // const displayColumnsElementsCalc = parameters.displayColumnsElements;
  const widthCard = `${100 / displayColumnsElementsCalc}%`;
  const widthCardStyle = { width: widthCard };
  const activeVerTodos = fetchData.data.length > displayColumnsElementsCalc;

  const tableRows = fetchData.data.map((collection: any, i) => {
    let convertToBlockElement = {} as BlockElement;
    convertToBlockElement.data = collection;
    convertToBlockElement.data.parentType = "CATALOG";
    convertToBlockElement.elementType = "NODE";

    return (
      <CardStandard
        format={formatCard}
        key={i}
        css={widthCardStyle}
        collection={convertToBlockElement}
        effects={effectCard}
      />
    );
  });

  return (
    <>
      <div
        className={`pctOrganism pctCarroussel pctEnrollmentsInProgress ${
          description ? "pctCarrousselInText" : ""
        }
      `}
      >
        {/* {title && <Text component="h2" text={title} className="titleSection" />} */}
        <div
          className={`pctControlBase ${
            title ? "pctControlBaseWithTitle" : ""
          }  ${title || activeVerTodos ? "pctControlLine" : ""}`}
        >
          {title && (
            <Text component="h2" text={title} className="titleSection" />
          )}
          {activeVerTodos && (
            <button onClick={(e) => setOpenGrid(true)}>
              <Text component="p" text={"Ver todos"} className="" />
              <FaAngleRight />
            </button>
          )}
        </div>
        {description && (
          <Text component="p" text={description} className="textSection" />
        )}

        <CarrousselView>{tableRows}</CarrousselView>
        {openGrid && (
          <CardGrid
            tableRows={tableRows}
            setOpenGrid={setOpenGrid}
            openGrid={openGrid}
          />
        )}
      </div>
    </>
  );
};

export default EnrollmentsInProgress;
