import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getColors } from "../services/utils";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "../assets/images/close-icon-menu.svg";

function ModalWarning({
  title,
  icon,
  description,
  acceptText,
  acceptAction = false,
  denyAction = false,
  denyText,
  hideDeny = false,
  parentShowModal = null,
  onCloseParent,
  disableAccept = false
}) {
  const [showModal, setShowModal] = useState(true);
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || {};

  function handleDeny() {
    if (onCloseParent) {
      onCloseParent()
    }
    if (denyAction) {
      denyAction();
    }
    setShowModal(false);
  }

  function handleAccept() {
    if (acceptAction) {
      acceptAction();
    }
    setShowModal(false);
  }

  useEffect(() => {
    if (parentShowModal != null) {
      setShowModal(parentShowModal)
    }
  }, [parentShowModal])

  return (
    <Modal
      show={!!showModal}
      onHide={() => handleDeny()}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      className="modal-main-container">
      <div className="modal-support-blur modal-support-blur-warning" />
      <Modal.Header
        style={{ backgroundColor: appDetails.primaryColor }}
        className="modal-header-warning">
        <div
          onClick={() => handleDeny()}
          className="modal-warning-close-container">
          <img src={CloseIcon} className="modal-warning-close" alt="img" />
        </div>
        {icon || <InfoIcon style={{ color: "white", fontSize: 50 }} />}
      </Modal.Header>
      <div className="modal-principal-warning">
        <Modal.Title style={{ textAlign: "center", fontSize: 18 }}>
          {title}
        </Modal.Title>
        <div className="modal-body-warning">{description}</div>
      </div>
      <Modal.Footer 
        className="modal-footer-warning"
        style={{
          alignSelf: hideDeny ? 'flex-end' : 'auto',
          width: hideDeny ? 200 : 'inherit' 
        }}
      >
        {
          !hideDeny &&
          <Button
            style={{ flex: 1 }}
            variant="secondary"
            onClick={() => handleDeny()}>
            {denyText || "Sair"}
          </Button>
        }
        <Button
          style={{ flex: 1, backgroundColor: appDetails.primaryColor, borderColor: appDetails.primaryColor }}
          className="btn botao-login"
          onClick={() => handleAccept()}
          disabled={disableAccept}
        >
          {acceptText || "Continuar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalWarning;
