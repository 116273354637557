import React, { useState, useEffect } from 'react';
import getStyle from './style.js'
import PageDefault from '../../Components/PageDefault';
import ProductList from '../../Components/ProductList';
import { getProductsLojinha, getPurchasedProductsLojinha } from '../../../../services/api';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import useDesignByIdSectionType from '../../hooks/useDesignByIdSectionType';

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [assessments, setAssessments] = useState([]);
    const [onlyPurchased, setOnlyPurchased] = useState(false);
    const [searchText, setSearchText] = useState('');
    const hasAssessments = Boolean(assessments.length);
    const history = useHistory();

    const { hasDesign, getDesign } = useDesignByIdSectionType('34');
    const Style = getStyle(getDesign);
    
    async function getAssessments(){
      setLoading(true);
      const result = (onlyPurchased) ? await getPurchasedProductsLojinha(searchText) : await getProductsLojinha(searchText) ;
      if(result.status === 200 && result.data.success && result.data.offers){
        setAssessments([
          ...result.data.offers,
        ]);
        setLoading(false);
        return assessments;
      }
    }

    function searchProduct(text){
      setSearchText(text);
    }

    function escolherProduto( produto ){
      const stringDados = JSON.stringify(produto);
      localStorage.setItem('produtoSelecionado', stringDados);
      history.push('/detalheslojinha');
    }

    function todosProdutos(){ if(onlyPurchased){ setOnlyPurchased(false) }}
    function meusProdutos(){ if(!onlyPurchased){ setOnlyPurchased(true) }}

    useEffect(() => {
      localStorage.removeItem('produtoSelecionado');
    }, []);

    useEffect(() => {
      getAssessments();
    }, [searchText, onlyPurchased]);

    return (
      <PageDefault search={true} searchHandler={searchProduct}>
        {loading && (<Loader />) }
        { !loading && (
          <>
            <div style={Style.lojinhaHomeProdutosButton}>
              <button onClick={todosProdutos} style={onlyPurchased ? 
                Style.lojinhaHomeProdutosButtonButtonOff : Style.lojinhaHomeProdutosButtonButtonOn }>
                TODOS OS PRODUTOS
              </button>
              <button onClick={meusProdutos} style={onlyPurchased ? 
                Style.lojinhaHomeProdutosButtonButtonOn : Style.lojinhaHomeProdutosButtonButtonOff}>
                MEUS PRODUTOS
              </button>
            </div>
            <div style={Style.lojinhaContentWrapper}>
              { hasAssessments && (
                  <ProductList 
                    list={assessments} 
                    clickHandler={escolherProduto}
                  />
                )}
              { (!hasAssessments && !onlyPurchased) && (
                  <div style={Style.mensagemVazio}>
                    Não foi encontrado nenhum pedido
                  </div>
                )}
              { (!hasAssessments && onlyPurchased) && (
                  <div style={Style.mensagemVazio}>
                    Você ainda não tem nenhum produto adquirido
                  </div>
                )}          
            </div>
          </>
        )}
      </PageDefault>
    )
}

export default Home