import React from "react";
import { listFilter, contentList } from '../services/api';
import { Link } from 'react-router-dom';
import { getDesignById, getColors, getFooterSize } from '../services/utils';
import { bindActionCreators } from 'redux';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

import InfiniteScroll from 'react-infinite-scroll-component';
import { FaCheck } from 'react-icons/fa';
import Conclusion from '../assets/images/Icone conclusion.svg'

class SinglePageList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: [],
            page: 1,
            totalPages: 0,
            searchTags: "",
            totalRecords: 0,
            hasMore: false,
        };
    }

    componentDidMount() {
        this._getContent(this.props.idSection)
    }

    _getDesign(item, only = false, valueOnly = false) {

        let design = getDesignById(this.props.idSection)

        if (!design[item]) { return null } // return null if theres no item specified
        if (only) { // parameter only means it will only return the property specified
            if (valueOnly) { return design[item][only] } // parameter valueOnly means it will only return the property value if specified
            design = { [only]: design[item][only] }
            return design
        }
        return design[item]
    }

    _getContent(id) {
        this.setState({ isLoading: true })

        contentList(id).then(res => {

        //listFilter(id, { "page": 1, "searchTags": "" }).then(res => {
            if (res.data.success === false) {
                this.setState({ isLoading: false })
                return
            }
            
            this.setState({
                isLoading: false,
                data: res.data.content,
                totalPages: Math.ceil((res.data.totalRecord / res.data.content.length) - 0.1), // divide by number of total items by page
                totalRecords: res.data.totalRecord,
                hasMore: res.data.content.length < res.data.totalRecord ? true : false
            })
         
            this.getData()
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }

    searchByPage(page) {
      const data = {
          page: page,
          searchTags: this.state.searchTags
      }

      this.setState({ isLoading: true, page: page })

      
      listFilter(this.props.idSection, data).then(res => {
          if (!res || res.data.success == false) {
              this.setState({ isLoading: false, data: [], totalPages: 0 })
              return
          }
          this.setState({
              isLoading: false,
              data: res.data.content,
              totalPages: Math.ceil((res.data.totalRecord / res.data.content.length))
          })
      }).catch(err => {
          console.log('err ', err)
          this.setState({ isLoading: false })
      })
    }

    searchFilter(word) {
        const data = {
            page: 1,
            searchTags: word,
        }

        this.setState({ isLoading: true, searchTags: word, page: 1 })

        listFilter(this.props.idSection, data).then(res => {
            if (!res || res.data.success == false) {
                this.setState({ isLoading: false, data: [], totalPages: 0 })
                return
            }
            this.setState({
                isLoading: false,
                data: res.data.content,
                totalPages: Math.ceil((res.data.totalRecord / res.data.content.length))
            })
        }).catch(err => {
            console.log('err ', err)
            this.setState({ isLoading: false })
        })
    }

    getData() {
      /*const bodyRequest = {
        page: this.state.page + 1,
        searchTags: this.state.searchTags
      }

      if(bodyRequest.page <= this.state.totalPages) {
        this.setState({ page: bodyRequest.page })
        
        listFilter(this.props.idSection, bodyRequest).then(res => {
          if (!res || res.data.success == false) {
            return
          }
          this.setState({
            isLoading: false,
            data: [...this.state.data, ...res.data.content],
          })
          if(window.screen.width > 768 && bodyRequest.page <= 3) {
            this.getData()
          }
        }).catch(err => {
            console.log('err ', err)
            this.setState({ isLoading: false })
        })
      } else {
        this.setState({ hasMore: false })
      }
      */
    }

    render() {
        const { updateFooterStatus, updateHeaderStatus } = this.props
        updateFooterStatus(this._getDesign('general')?.showBottomMenu || 'true')
        updateHeaderStatus(this._getDesign('general')?.showTopBar || 'true')

        return (
          <div className="single-page-container container-fluid" style={{ padding: "0 15%", paddingTop: 15, ...this._getDesign('general'), ...getFooterSize() }}>
              {this.state.isLoading ?
                <div className="loader loader-center loader-big"></div>
              :
                this.state.data.length > 0 &&
                <InfiniteScroll
                  dataLength={this.state.data.length}
                  next={() => this.getData()}
                  hasMore={this.state.hasMore}
                  loader={<div className="loader loader-center loader-big loader-infinite"></div>}
                  className='row'
                >
                  {this.state.data.map((item, index) => (
                    item.imageStyle === 'icon' ?
                    <Link replace={item?.idContentType === 4} key={index} to={`/content/${item.idContentItem}?idSection=${this.props.idSection}`} className="col-6 col-sm-4 col-md-4 col-lg-2 col-xl-2">
                        <img
                            className="card-list-card"
                            src={item.image}
                            alt="img"
                            width={170}
                            height={170}
                        />
                        { item.completed ?
                           <span className={'concluided-pageList-icon'} >
                            <img  src={Conclusion} alt="cart" style={{width:40}} width={40} height={40}/>

                          </span> : null
                        }
                    </Link>
                    :
                    <Link replace={item?.idContentType === 4} key={index} to={`/content/${item.idContentItem}?idSection=${this.props.idSection}`} className="post-list-item">
                        { item.completed ?
                          <span className={'concluided-pageList-tile'} >
                            <img  src={Conclusion} alt="cart" style={{width:50}}/>

                          </span> : null
                        }
                        <div className="post-list-header-image-container" >
                          
                            <img className="post-list-header-image" src={ item.image } alt="img" />
                            
                        </div>
                        <div className="post-list-footer">
                            <span style={this._getDesign('itemText')}>{item.text}</span>
                            <span style={this._getDesign('itemDescription')} className="post-list-desc">{item.description}</span>
                            
                            
                        </div>
                    </Link>
                  ))}
                </InfiniteScroll>
              }
          </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  });

export default connect(null, mapDispatchToProps)(SinglePageList);
