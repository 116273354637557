
export type AttachmentTypes = {
    idAttachmentType: number
    name: string
    acceptFormats: string
};

export const acceptFormats: AttachmentTypes[] = [
    { idAttachmentType: 1, name: 'PDF', acceptFormats: '.pdf' },
    { idAttachmentType: 2, name: 'Apresentação', acceptFormats: '.ppt,.pptx' },
    { idAttachmentType: 3, name: 'Planilha', acceptFormats: '.xls,.xlsx,.csv' },
    { idAttachmentType: 4, name: 'Documento', acceptFormats: '.doc,.docx' },
    { idAttachmentType: 5, name: 'Imagem', acceptFormats: '.jpg,.png,.svg,.webp,.gif' },
    { idAttachmentType: 6, name: 'Áudio', acceptFormats: '.mp3,.wav,.ogg' },
    { idAttachmentType: 7, name: 'Vídeo', acceptFormats: '.mp4,.webm' },
];
