import "./style.scss";

import React from "react";

const TrophySVG = () => {
  const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.201" height="15.272" viewBox="0 0 15.201 15.272">
  <defs>
    <clipPath id="clip-path">
      <rect id="Retângulo_16" data-name="Retângulo 16" width="15.201" height="15.272" fill="#9f9fa2"/>
    </clipPath>
  </defs>
  <g id="Grupo_279" data-name="Grupo 279" transform="translate(-406.254 -38.668)">
    <g id="Grupo_268" data-name="Grupo 268" transform="translate(406.254 38.668)">
      <g id="Grupo_267" data-name="Grupo 267" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path id="Caminho_57" data-name="Caminho 57" d="M3.088,6.9h.4A4.52,4.52,0,0,0,6.649,9.446v1.485H5.543a.619.619,0,0,0-.619.619v.812H4.371a.394.394,0,0,0-.4.4v2.11a.394.394,0,0,0,.394.394h6.463a.394.394,0,0,0,.4-.394v-2.11a.394.394,0,0,0-.394-.394h-.558v-.812a.619.619,0,0,0-.619-.619H8.553V9.446A4.52,4.52,0,0,0,11.714,6.9h.4A3.086,3.086,0,0,0,15.2,3.81V1.934a.495.495,0,0,0-.495-.495H12.115V.491A.495.495,0,0,0,11.62,0H3.581a.495.495,0,0,0-.495.495v.95H.495A.495.495,0,0,0,0,1.936V3.81A3.086,3.086,0,0,0,3.088,6.9Zm9.027-1.859V3h1.524V3.81a1.526,1.526,0,0,1-1.524,1.524H12.1c0-.1.01-.2.01-.3ZM5.671,3.66l1.113-.165a.193.193,0,0,0,.145-.106l.495-1.008a.193.193,0,0,1,.346,0l.495,1.008a.193.193,0,0,0,.145.106l1.113.165a.193.193,0,0,1,.107.33l-.8.785a.193.193,0,0,0-.056.165l.19,1.109a.193.193,0,0,1-.28.2l-.99-.523a.193.193,0,0,0-.18,0l-.99.523a.193.193,0,0,1-.28-.2L6.43,4.94a.193.193,0,0,0-.056-.165l-.8-.785a.193.193,0,0,1,.1-.33ZM1.564,3H3.088V5.039a2.931,2.931,0,0,0,.01.3h-.01A1.526,1.526,0,0,1,1.564,3.81Z" transform="translate(0 0.004)" fill="#9f9fa2"/>
      </g>
    </g>
  </g>
</svg>`;

  return svgStr;
};

export default TrophySVG;
