

import { UPDATE_LICENSE } from '../actions/actionTypes';

export type License  = {
  isUpgradeButtonActive: boolean,
  userIsAdmin: boolean,
  hasRequestedUpgrade: boolean,
  numberRequest: number,
  isPlanContractButtonActive: boolean | null,
}

function getInitialLicenseType(): License {
  return  {
    isUpgradeButtonActive : false,
    userIsAdmin: false,
    hasRequestedUpgrade: false,
    numberRequest: 0,
    isPlanContractButtonActive: null,
  }
}

const InitialState: License = {
  ...getInitialLicenseType()
}

export const planUpgrade = (state =  InitialState, action: {type: string, values: License}) : License => {
  switch (action.type) {
    case UPDATE_LICENSE:
    return {
        ...state,
        ...action.values
    };
   
    default:
    return state;
}
}

