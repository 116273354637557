import './style.scss';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect, useHistory } from 'react-router-dom';

import Form from 'react-bootstrap/Form';

import NiceModal from '@ebay/nice-modal-react';
import ModalOnboard from '../../modals/ModalOnboard';
import ModalTopicsOfInterest from '../../modals/ModalTopicsOfInterest';

import { getMenuData, setLoginData, setUserAgreement, logout, getFeatures, acceptResponsabilityTerms } from '../../../services/api';
import { getProfileInfo, getAppDetails, getColors } from '../../../services/utils';

import { GrFormNextLink } from 'react-icons/gr';
import { PiWarning } from 'react-icons/pi';
import { updateConfigHeaderFooter } from '../../../actions';
import { Button, Preloader } from '../../atoms';
interface TermsAccepted {
    communication?: boolean;
    agreementTerms?: boolean;
    responsabilityTerms?: boolean;
}



const Terms = (props) => {
    const {
        loginInfo: { agreementTerms, responsabilityTerms, needSignResponsabilityTerms, agreementVersion, userLicenceTermsAccepted },
    } = getMenuData();
    const [_responsabilityTerms, setResponsabilityTerm] = useState({
        responsabilityTermsName: responsabilityTerms?.name,
        responsabilityTermsContent: responsabilityTerms?.content,
        responsabilityTermsVersionNumber: responsabilityTerms?.versionNumber,
        responsabilityTermsId: responsabilityTerms?.idTermOfResponsibility,
        responsabilityTermsHasOldSignature: responsabilityTerms?.hasOldSignature,
    });
    const [showError, setShowError] = useState(false);
    const [redirectLogin, setRedirectLogin] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);
    const history = useHistory();
    const { updateConfigHeaderFooter } = props;
    const [loadingSignTerm, setLoadingSignTerm] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState<TermsAccepted>({});
    const [currentTermsPageIndex, setCurrentTermsPageIndex] = useState(0);
    const termsPageList = useMemo(
        () => [
            {
                name: 'Termos de Uso',
                content: agreementTerms,
                type: 'agreement',
                checkBoxLabel: 'Li e aceito os termos de uso',
                show: agreementVersion !== userLicenceTermsAccepted,
            },
            {
                name: _responsabilityTerms.responsabilityTermsName,
                content: _responsabilityTerms.responsabilityTermsContent,
                type: 'responsibility',
                checkBoxLabel: `Li e aceito o ${_responsabilityTerms.responsabilityTermsName}`,
                show: needSignResponsabilityTerms,
                updated: _responsabilityTerms.responsabilityTermsHasOldSignature,
                updatedText: 'Os termos de responsabilidade da sua organização foram atualizados.',
            },
        ],
        [
            agreementTerms,
            agreementVersion,
            needSignResponsabilityTerms,
            _responsabilityTerms.responsabilityTermsContent,
            _responsabilityTerms.responsabilityTermsHasOldSignature,
            _responsabilityTerms.responsabilityTermsName,
            userLicenceTermsAccepted,
        ],
    );
    const filterredTermsPageList = termsPageList.filter(({ show }) => show);


    useEffect(() => {
        setRedirectHome(agreementVersion === userLicenceTermsAccepted && !needSignResponsabilityTerms);
    }, [agreementVersion, userLicenceTermsAccepted, needSignResponsabilityTerms]);

    useEffect(() => {
        updateConfigHeaderFooter({ headerStyle: 'hide', footerStyle: 'hide' });
    }, []);

    const canContinue = useCallback(() => {
        return Object.entries(termsAccepted).filter((item) => item[0] !== 'communication' && item[1]).length === filterredTermsPageList.length;
    }, [termsAccepted, filterredTermsPageList.length]);

    const getAppIcon = () => {
        const appDetails = getAppDetails();
        const menuHeaderIcon = getColors('menu').headerIcon;
        return menuHeaderIcon || appDetails?.headerIcon;
    };

    function _logout() {
        logout();
        history.push('/login');
        setRedirectLogin(true);
    }

    const getTermByType = (type) => {
        return filterredTermsPageList.find((term) => term.type === type);
    };

    const updateTerms = async () => {
        setLoadingSignTerm(true);
        try {
            const menuData = getMenuData() || {};
            let loginInfoData = { ...menuData.loginInfo };
            if (getTermByType('responsibility')) {
                await acceptResponsabilityTerms({
                    idTermOfResponsibility: _responsabilityTerms.responsabilityTermsId,
                    versionNumber: _responsabilityTerms.responsabilityTermsVersionNumber,
                })

                loginInfoData = {
                    ...loginInfoData,
                    needSignResponsabilityTerms: false,
                };
            }
            
            if (getTermByType('agreement')) {
                const data = {
                    communicationAccepted: termsAccepted.communication ? 1 : 0,
                    userLicenceTermsAccepted: loginInfoData.agreementVersion,
                };

                const { idUser } = getProfileInfo();
                await setUserAgreement(idUser, data);

                loginInfoData = {
                    ...loginInfoData,
                    userLicenceTermsAccepted: loginInfoData.agreementVersion,
                };
            }

            setLoginData({ ...menuData, loginInfo: loginInfoData });
            setRedirectHome(loginInfoData.agreementVersion === loginInfoData.userLicenceTermsAccepted && !loginInfoData.needSignResponsabilityTerms);
        } catch (error) {
            setShowError(true);
        } finally {
            setLoadingSignTerm(false);
        }
    };

    const openModalOnboard = () => {
        const { userLicenceTermsAccepted, onBoardScreen } = getMenuData().loginInfo;
        if (userLicenceTermsAccepted === 0 && onBoardScreen) {
            NiceModal.show(ModalOnboard, {
                obj: {
                    closedModal: (e) => {
                        setRedirectHome(true);
                    },
                },
            });
        } else {
            setRedirectHome(true);
        }
    };

    async function continuar() {
       await  updateTerms();
        getFeatures('RECOMMENDATION_BLOCK').then((res) => {
            const active = res.data?.active;
            if (active) {
                NiceModal.show(ModalTopicsOfInterest, {
                    obj: {
                        closedModal: (e) => {
                            openModalOnboard();
                        },
                    },
                });
            } else {
                openModalOnboard();
            }
        });
    }

    function getTerms(index) {
        if (filterredTermsPageList[index]?.show) {
            return filterredTermsPageList[index].content;
        }

        return null;
    }

    if (redirectLogin) {
        return <Redirect to="/login" />;
    }

    if (redirectHome) {
        return <Redirect to="/" />;
    }

    const handleCheckboxChange = (type, checked) => {
        setTermsAccepted((prev) => ({ ...prev, [type]: checked }));
    };

    return (
        <>
            {loadingSignTerm && <Preloader />}
            <div className="terms-container">
                <div className="terms-logo">
                    <img src={getAppIcon()} alt="logo" />
                    <hr />
                    {filterredTermsPageList[currentTermsPageIndex]?.show && filterredTermsPageList[currentTermsPageIndex]?.updated && (
                        <div className="terms-header">
                            <div className="warning-icon-container">
                                <PiWarning className="warning-icon" />
                            </div>
                            <div className="warning-message">
                                <div className="message-text">
                                    <span className="bold">{`${filterredTermsPageList[currentTermsPageIndex].updatedText}`}</span>
                                    <span>Para continuar utilizando a plataforma, é necessário que você os aceite novamente.</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="terms-wrapper">
                    <div className="term-container">
                        <div dangerouslySetInnerHTML={{ __html: getTerms(currentTermsPageIndex) }} />
                    </div>
                    <div className="terms-page">
                        {filterredTermsPageList.length > 1 &&
                            filterredTermsPageList.map((item, index) => (
                                <Button
                                    className={`terms-page-item ${
                                        currentTermsPageIndex === index ? 'selected-terms-highlight' : 'not-selected-terms'
                                    }`}
                                    text={`${index + 1}. ${item.name}`}
                                    callEvent={() => setCurrentTermsPageIndex(index)}
                                />
                            ))}
                    </div>
                    <div className="terms-checkbox-container">
                        {filterredTermsPageList.map((item, index) => (
                            <label className="terms-checkbox-label">
                                <input
                                    type="checkbox"
                                    className="chb chb-3"
                                    checked={termsAccepted[item.type] || false}
                                    onChange={(e) => handleCheckboxChange(item.type, e.target.checked)}
                                />
                                {`${item.checkBoxLabel} ${filterredTermsPageList.length > 1 ? `[${index + 1}]` : ''}`}
                            </label>
                        ))}
                        {getTermByType('agreement') && (
                            <Form.Check
                                onChange={(e) => handleCheckboxChange('communication', e.target.checked)}
                                type="checkbox"
                                label="Aceito receber e-mail ou outras comunicações."
                                checked={termsAccepted?.communication || false}
                            />
                        )}
                    </div>

                    {showError && <div className="term-error"> Você precisa aceitar os termos para continuar. </div>}

                    <div className={'btnContainer'}>
                        <Button className="decBtn button01" text="Declinar" callEvent={() => _logout()} />
                        <Button
                            className={`button01 contBtn ${!canContinue() ? 'disabledBtn' : ''}`}
                            disabled={!canContinue()}
                            text="Continuar"
                            ico={GrFormNextLink}
                            callEvent={() => continuar()}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Terms);
