import React from "react";
import "./style.scss";
import { IconType } from "react-icons";

type Props = {
  icon: IconType,
  iconProps: any,
  label: string,
  labelProps: any,
  onClick: () => void
}

const MenuOption = ({ icon: Icon, iconProps, label, labelProps, onClick = () => {}}: Props) => {
  return (
    <div className="menu-option" onClick={onClick}>
      {Icon && <Icon {...iconProps}/>}
      <span {...labelProps}>{label}</span>
    </div>
  );
};

export default MenuOption;