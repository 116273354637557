import React, { createRef } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer/dist/modern";
import _ from "lodash";

import {
  findContentStudents,
  findContent,
  recordTracking,
} from "../../services/api";
import { getGamigication } from "../../services/utils";

import {
  updateContentType,
  updatePoints,
  updateHeaderStatus,
  updateFooterStatus,
} from "../../actions";

import YTPlaylist from "../../container/YTPlaylist";
import MosaicJourneyStepList from "../../container/MosaicJourneyStepList";
import JourneyStepNavigation from "../../container/JourneyStepNavigation";
import ImpactJourneyStepNavigation from "../../container/ImpactJourneyStepNavigation";

// import Library from "../components/Library";
import ZoomPage from "../../components/ZoomPage";
// import ContentSinglePage from '../../components/ContentSinglePage';
import ContentPost from "../../components/ContentPost/ContentPost";
import FileDetail from "../../components/FileDetail";
// import ContentAudio from "../../components/ContentAudio/ContentAudio";
import LoaderWhite from "../../components/LoaderWhite";
import ContentPdfViewer from "../../components/ContentPdfViewer";

import Preloader from "../../pctComponents/atoms/Preloader";

// import LivePlayer from '../../components/LivePlayer';
//import ContentSite from '../../components/ContentSite';
import LivePlayer from "../../pctComponents/Pages/LivePlayer"; // versão ptc
import ContentSite from "../../pctComponents/Pages/ContentSite"; // versão ptc
import ContentSinglePage from "../../pctComponents/Pages/ContentSinglePage"; // versão ptc
import ContentAudio from "pctComponents/Pages/ContentAudio/ContentAudio";
import FileDetailPct from "pctComponents/Pages/FileDetailPct";
import ErrorComponent from "pctComponents/organisms/ErrorComponent";
import Link from "pctComponents/Pages/Link";

moment.locale("pt-br");

class ContentSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this._isMounted = true;
    this.setState = this.setState.bind(this);
    this.idleTimerRef = null;
    this.intervalID = 0;
    this.resultObj = null;
    this.isPlayed = createRef();
    this.state = {
      isLoading: true,
      contentData: [],
      idContentItem: 0,
      idContentType: 0,
      timeRefresh: 300000, // 300000ms = 5min
      ignoreRecordTracking: false,
      isFrame: false,
      timeStart: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      isYoutubePlayer: false,
      shouldTrackAfterLoad: false,
      shouldRecordTracking: true,
    };

    props.updateFooterStatus("false");
    props.updateHeaderStatus("false");
  }

  handleOnActive() {
    this.setState({ timeStart: moment().utc().format("YYYY-MM-DD HH:mm:ss") });
    this.setState({ ignoreRecordTracking: false });
  }

  handleOnIdle() {
    const ignoreRecordByIdContentType = [1, 2, 6];
    //existe tracking interno em audio e video
    if (!ignoreRecordByIdContentType.includes(this.state.idContentType)) {
      this._recordTracking();
    }
  }

  checkElementMedia(type) {
    let isPlaying = false;
    try {
      const media = this.state.isFrame
        ? [
            ...window.document
              .getElementsByTagName("iframe")[0]
              ?.contentDocument.getElementsByTagName(type),
          ]
        : [...window.document.getElementsByTagName(type)];
      media.forEach((element) => {
        if (!element.paused) {
          this.resetTime();
          isPlaying = true;
        }
      });
    } catch (e) {
      isPlaying = false;
    }
    return isPlaying;
  }

  createScreen(resultObj, result) {
    if (this._isMounted) {
      try {
        if (resultObj.success === false) {
          this.setState({ isLoading: false });
          return;
        }

        this.resultObj = resultObj;

        if (resultObj.data.hasOwnProperty("contentEnrollment")) {
          //TRATAMENTO PARA CONTEÚDOS COM MATRÍCULA.
          result.data.content[0].idContentEnrollment =
            resultObj.data.contentEnrollment.idContentEnrollment;

          this.setState(
            {
              isLoading: false,
              contentData: result.data.content,
              idContentType: result.data.content?.[0]?.idContentType,
              idContentEnrollment:
                resultObj.data.contentEnrollment.idContentEnrollment,
            },
            () => {
              window.addEventListener("beforeunload", (ev) => {
                this._recordTracking();
              });
              setTimeout(() => {
                this.setState({ shouldTrackAfterLoad: true });
              }, 200);
            }
          );
        } else {
          //TRATAMENTO PARA CONTEÚDOS SEM MATRÍCULA.
          this.setState(
            {
              isLoading: false,
              contentData: result.data.content,
              idContentType: result.data.content?.[0]?.idContentType,
              idContentEnrollment: null,
            },
            () => {
              window.addEventListener("beforeunload", (ev) => {
                this._recordTracking();
              });
              setTimeout(() => {
                this.setState({ shouldTrackAfterLoad: true });
              }, 200);
            }
          );
        }
      } catch (err) {
        this.setState({ isLoading: false });
      }
    }
  }

  resetTime() {
    if (this.idleTimerRef) {
      this.idleTimerRef.reset();
    }
  }

  removeEventListenerFrame() {
    if (
      window.document?.getElementsByTagName("iframe").length &&
      window.document?.getElementsByTagName("iframe")[0].contentDocument
    ) {
      const frame =
        window.document.getElementsByTagName("iframe")[0].contentDocument;

      frame.removeEventListener(
        "scroll",
        _.debounce(() => {
          this.idleTimerRef.reset();
        }, 500)
      );
      frame.removeEventListener(
        "mousemove",
        _.debounce(() => {
          this.idleTimerRef.reset();
        }, 500)
      );
      frame.removeEventListener(
        "mouseup",
        _.debounce(() => {
          this.idleTimerRef.reset();
        }, 500)
      );
    }
  }
  checkActiveFrame() {
    let frame =
      window.document.getElementsByTagName("iframe")[0].contentDocument
        ?.activeElement;
    if (!frame.getAttribute("listener")) {
      this.addEventListenerFrame();
    }
  }
  addEventListenerFrame() {
    if (
      window.document.getElementsByTagName("iframe").length &&
      window.document.getElementsByTagName("iframe")[0].contentDocument
    ) {
      const frame =
        window.document.getElementsByTagName("iframe")[0].contentDocument;
      this.setState({ isFrame: true });
      //utilizado para identificar se ocorreu navegação dentro do frame
      if (frame.activeElement) {
        frame.activeElement.setAttribute("listener", true);
      }

      frame.addEventListener(
        "scroll",
        _.debounce((event) => {
          console.log("SCROLL RESET");
          this.resetTime();
        }, 500)
      );
      frame.addEventListener(
        "mousemove",
        _.debounce((event) => {
          console.log("mousemove RESET");
          this.resetTime();
        }, 500)
      );

      frame.addEventListener(
        "mouseup",
        _.debounce((event) => {
          console.log("mouseup RESET");
          this.resetTime();
        }, 500)
      );
    } else {
      window.document.getElementsByTagName("iframe");
    }
  }

  selectContent(item) {
    // console.log(item);
    let pathParams = new URLSearchParams(this.props.location.search);
    let parentSection = pathParams.get("idSection") || this.props?.idSection;

    switch (item?.idContentType) {
      case 1:
        return (
          <ContentAudio
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 2:
        return (
          <LivePlayer
            isVideo
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 3:
        return (
          <ContentPost
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 4:
        return (
          <ContentSite
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 5:
        return (
          <ContentSinglePage
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 6:
        return (
          <LivePlayer
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 7:
        return (
          <YTPlaylist
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            setState={this.setState}
            idContentItem={item.idContentItem}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 8:
        return (
          <ZoomPage
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 15:
        return (
          <MosaicJourneyStepList
            data={item}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 16:
        return (
          <JourneyStepNavigation
            data={item}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 18:
        return (
          <FileDetailPct
            data={item}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 22:
        return (
          <MosaicJourneyStepList
            data={item}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 23:
        return (
          <ImpactJourneyStepNavigation
            data={item}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      case 25:
        return (
          <ContentPdfViewer
            data={item}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      // case 13:
      //     return <Library data={item} key={index} idSection={parentSection}/>
      case 27:
        return (
          <Link
            data={item}
            key={item.idContentType}
            idSection={parentSection}
            params={this.props.match.params}
            history={this.props.history}
            dataParentObj={this.resultObj.data}
          />
        );
      default:
        return (
          <ErrorComponent
            error={{ message: "Não foi possível abrir o conteúdo." }}
          />
        );
    }
  }

  _recordTracking() {
    const idNodeEnrollment = this.props.match.params.idNodeEnrollment;
    const idUser = this.props.match.params.idUser;
    const idEnrollment = this.props.match.params.idEnrollment;

    if (!idNodeEnrollment || !idEnrollment) {
      let pathParams = new URLSearchParams(this.props.location.search);
      let parentSection =
        pathParams.get("idSection") ||
        this.props?.idSection ||
        this.state.contentData[0].idSection;
      const ignoreRecordByIdContentType = [1, 2, 6];

      //existe tracking interno em audio e video

      if (
        !ignoreRecordByIdContentType.includes(this.state.idContentType) &&
        this.state.shouldRecordTracking
      ) {
        let timeExecuted = 0;
        //caso seja youtube list, verifica no localstorage o tempo de execução do video e adiciona no timeExecuted
        //e limpa o localstorage currentTimeYouTubeList

        if (this.state.idContentType === 7 && !this.state.isYoutubePlayer) {
          let storage = localStorage.getItem("currentTimeYouTubeList");
          const objCurrentTime = storage ? JSON.parse(storage) : false;
          if (
            objCurrentTime &&
            parseInt(objCurrentTime.contentItem) === this.state.idContentItem
          ) {
            timeExecuted = objCurrentTime.currentTime;
            localStorage.removeItem("currentTimeYouTubeList");
          }
        }
        const idContentItem =
          this.props.match.params.id || this.props.idContentItem;

        // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
        recordTracking(
          this.state.timeStart,
          idContentItem,
          1,
          parentSection,
          timeExecuted
        )
          .then((res) => {
            this.setState({ shouldRecordTracking: false });
            // if (getGamigication()) {
            //   const totalUserPoints = res.data.totalUserPoints;
            //   this.props.updatePoints(totalUserPoints);
            // }
          })
          .catch((err) => {
            console.log("error tracking", err);
            this.setState({ isLoading: false });
          });
      }
    }
  }

  componentDidMount() {
    this.setState({ shouldRecordTracking: true });
    this.setState({ isYoutubePlayer: false });
    this.loadContent(this.props.match.params.id || this.props.idContentItem);
    this.resetTime();
    this.props.updateFooterStatus("false");
    this.props.updateHeaderStatus("false");

    //delay para capturar o frame
    // setTimeout(() => {
    //   this.addEventListenerFrame();
    //   if (this.state.isFrame) {
    //     this.intervalID = window.setInterval(
    //       this.checkActiveFrame.bind(this),
    //       5000
    //     );
    //   }
    // }, 2000);
    this.props.updateContent(this.state.idContentType);
  }

  async loadContent(id) {
    const timeout = setTimeout(() => {
      if (!this.isPlayed.current && this._isMounted) {
        this.isPlayed.current = true;

        this.setState({ isLoading: true });

        const idNodeEnrollment = this.props.match.params.idNodeEnrollment;
        const idUser = this.props.match.params.idUser;
        const idEnrollment = this.props.match.params.idEnrollment;

        if (idNodeEnrollment && idEnrollment) {
          findContentStudents(idUser, idEnrollment, id, idNodeEnrollment)
            .then((resultObj) => {
              const result = { data: { content: [resultObj.data.content] } };
              this.createScreen(resultObj, result);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          findContent(id)
            .then((resultObj) => {
              const result = { data: { content: [resultObj.data.content][0] } };
              this.createScreen(resultObj, result);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }

      clearTimeout(timeout);
    }, 1000);
  }

  componentWillUnmount() {
    this.props.updateContent(null);
    this.props.updateFooterStatus("false");
    this.props.updateHeaderStatus("false");

    if (
      !this.state.ignoreRecordTracking &&
      !this.state.isLoading &&
      this.state.shouldTrackAfterLoad
    ) {
      this._recordTracking();
    }
    this.removeEventListenerFrame();
    this.setState({});
    clearInterval(this.intervalID);
    this._isMounted = false;
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Preloader />
        ) : (
          <>
            <IdleTimer
              ref={(ref) => {
                this.idleTimerRef = ref;
              }}
              timeout={this.state.timeRefresh}
              onActive={this.handleOnActive}
              onIdle={this.handleOnIdle}
              debounce={250}
            />

            {this.selectContent(this.state.contentData?.[0])}
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  updateContent: (item) => dispatch(updateContentType(item)),
  updatePoints: (item) => dispatch(updatePoints(item)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(ContentSelector);
