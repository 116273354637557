import "./style.scss";

import React, { useEffect, useRef } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { getProfileInfo } from "services/utils";
import { updateConfigHeaderFooter } from "../../../actions";
import { useFetch } from "../../../pctHooks/useFetch";
import { usePrintBlocks } from "../../../pctHooks/usePrintBlocks";
import { Preloader } from "../../atoms";
import ModalLoadingInfo from "../../modals/ModalLoadingInfo";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ErrorComponent from "pctComponents/organisms/ErrorComponent";
import AlertComponent from "pctComponents/organisms/AlertComponent";
import AlertComponentRestrict from "pctComponents/organisms/AlertComponentRestrict";

type idType = {
  id?: string;
  idEnrollment?: string;
};

const Catalog = (props) => {
  const getBlockTypeComponent = usePrintBlocks;
  NiceModal.register("modalAlertLevel", ModalLoadingInfo);

  const componentIsMounted = useRef(false);

  useEffect(() => {
    componentIsMounted.current = true;
  }, []);

  const { id } = useParams<idType>();
  const { idEnrollment } = useParams<idType>();

  const { data, error } = useFetch({
    url: idEnrollment
      ? `/learning/students/${
          getProfileInfo().idUser
        }/enrollments/${idEnrollment}/nodes/${id}`
      : `/learning/nodes/${id}`,
    page: "catalog",
  });

  if (error) return <ErrorComponent error={error} />;
  if (!data) return <Preloader />;

  const { updateConfigHeaderFooter } = props;
  const fetchData: LearningNode = data as LearningNode;
  const candisplay =
    fetchData.nodeType === "ORGANIZATION" || idEnrollment !== undefined;

  const blocksHasContent = () => {
    let blBlocksHasContent: boolean = false;

    if (fetchData.hasOwnProperty("blocks")) {
      fetchData.blocks.map((block: Block, i: number) => {
        if (block.elements.length > 0) {
          blBlocksHasContent = true;
        }
      });
    }

    return blBlocksHasContent;
  };

  if (componentIsMounted.current) {
    setTimeout(() => {
      updateConfigHeaderFooter({
        nodeCollection: { node: fetchData },
        backCloseText: "",
      });
      componentIsMounted.current = false;
    }, 500);
  }

  const { nodeCode, originalNodeCode, visibility, hasAccess, image, blocks } =
    fetchData;

  if (hasAccess === false) {
    return (
      <AlertComponentRestrict
        imageUrl={image}
        message={`Este <spam class='destaque'>conteúdo é restrito</spam> para um grupo específico e você não tem permissão para visualizar.`}
      />
    );
  }

  //SÓ VAI APRESENTAR O NÓ SE FOR UM NO "nodeType": "ORGANIZATION", POIS ESTE TIPO DE NÓ NÃO PRECISA DE MATRICULA.
  return (
    <div className="pctPage pctPageBlock ptcCatalog">
      {candisplay === true &&
        blocks.map((block: Block, i: number) => {
          return getBlockTypeComponent(block, i, fetchData);
        })}

      {candisplay === true && blocksHasContent() === false && (
        <AlertComponent
          message={`A página ( ${originalNodeCode} -> ${nodeCode} ) não possuí blocos de conteúdos.`}
        />
      )}

      {!candisplay && (
        <AlertComponent
          fullpage={true}
          message={`<b>Nó do tipo ${fetchData.nodeType}</b>. Apenas nós de organização podem ser listados sem uma matricula. É necessário uma matricula ativa no objeto <b>"${fetchData.title}"</b> para conseguir visualizar este conteúdo.`}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Catalog);
