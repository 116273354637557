export type TypeExternalCourseType = 'IN_PERSON' | 'ONLINE' | 'BLENDED' | '';
export type StatusExternalCourseType = 'ALL' | 'APPROVED' | 'REJECTED' | 'PENDING' | 'CANCELED';

export interface ExternalCourseType {
    idUserExternalCourse?: number | null;
    idApp: number;
    idCompany: number;
    idUser: number;
    idParentUserExternalCourse: number | null;
    title: string;
    type: TypeExternalCourseType;
    status: StatusExternalCourseType;
    workload: number | null | undefined;
    expirationDate: string | null;
    entityName: string | null | undefined;
    evidencePath: string | null | undefined;
    creationDate: string | null;
    lastModifiedDate: string | null;
    modifiedBy: string | null;
    startDate: string | null;
    finishDate: string | null;
    statusReason: string | null;
    hasResubmission: boolean | null;
}

export type StatusObjExternalCourseType = {
    ALL: Boolean;
    APPROVED: Boolean;
    REJECTED: Boolean;
    PENDING: Boolean;
    CANCELED: Boolean;
};

export enum StatusExternalCourse {
    ALL = 'ALL',
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    CANCELED = 'CANCELED',
}

export const StatusExternalCourseDescriptions: Record<StatusExternalCourse, string> = {
    [StatusExternalCourse.ALL]: 'Todos',
    [StatusExternalCourse.APPROVED]: 'Validado',
    [StatusExternalCourse.PENDING]: 'Em análise',
    [StatusExternalCourse.REJECTED]: 'Rejeitado',
    [StatusExternalCourse.CANCELED]: 'Cancelado',
};

export enum TypeExternalCourse {
    IN_PERSON = 'IN_PERSON',
    ONLINE = 'ONLINE',
    BLENDED = 'BLENDED',
}

export const TypeExternalCourseDescriptions: Record<TypeExternalCourse, string> = {
    [TypeExternalCourse.IN_PERSON]: 'Presencial',
    [TypeExternalCourse.ONLINE]: 'Online',
    [TypeExternalCourse.BLENDED]: 'Semi-presencial',
};

export const externalCourseInit: Partial<ExternalCourseType> = {
    title: '',
    type: '',
    workload: undefined,
    expirationDate: null,
    startDate: null,
    finishDate: null,
    entityName: undefined,
    evidencePath: undefined,
};

export const StatusObjExternalCourseInit: StatusObjExternalCourseType = {
    ALL: true,
    APPROVED: true,
    REJECTED: true,
    PENDING: true,
    CANCELED: true,
};
