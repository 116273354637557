import { UPDATE_VALUE, UPDATE_HEADER } from '../actions/actionTypes';

const initialState = {
    footerStatus: 'true',
    headerStatus: 'true'
  };

export const footerReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_VALUE:
        return {
            ...state,
            footerStatus: action.footerStatus
        };
        case UPDATE_HEADER:
        return {
            ...state,
            headerStatus: action.headerStatus
        };
        default:
        return state;
    }
};