import "./style.scss";

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";

import NiceModal from "@ebay/nice-modal-react";
import ModalEvaluationKnowledgeFeedback from "../../modals/ModalEvaluationKnowledgeFeedback";

import { Preloader, Text } from "../../atoms";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEye } from "react-icons/ai";
import { CreateEvaluationKnowledgeContext } from "../../../pctContexts/contextEvaluationKnowledge";

type Props = {};

const ItemAnswers = ({ item, indice, itemAll, callModal }) => {
  return (
    <button
      className="itemAnswers"
      onClick={() => {
        callModal(item, indice, itemAll);
      }}
    >
      <Text
        component="div"
        className={"qIndice"}
        text={`Questão ${indice + 1}`}
      />
      <Text
        component="div"
        className={"qText"}
        text={item.statement.replace(/<img .*?>/g, "")}
        limit={60}
      />

      <div className="iControl">
        <div className="iStatus">
          {item.isCorrect ? (
            <AiOutlineCheck className="iPos" />
          ) : (
            <AiOutlineClose className="iNeg" />
          )}
        </div>
        <AiOutlineEye className="iView" />
      </div>
    </button>
  );
};

const EvaluationKnowledgeListAnswers = ({}: Props) => {
  const {
    contextCurrentAvaliationResult,
    contextCurrentAnswers,
    contextCurrentIdUser,
    contextCurrentIdEnrollment,
    contextCurrentIdEvaluation,
    contextCurrentIdQuestionnaire,
  } = useContext(CreateEvaluationKnowledgeContext);

  const callModal = (item, indice, itemAll) => {
    const currentQuestion = contextCurrentAnswers[indice];

    NiceModal.show(ModalEvaluationKnowledgeFeedback, {
      obj: {
        item: item,
        indice: indice,
        itemAll: itemAll as number,
        currentQuestion: currentQuestion,
        contextCurrentIdUser: contextCurrentIdUser,
        contextCurrentIdEnrollment: contextCurrentIdEnrollment,
        contextCurrentIdEvaluation: contextCurrentIdEvaluation,
        contextCurrentIdQuestionnaire: contextCurrentIdQuestionnaire,
      },
    });

    console.log("entrou Feed");
  };

  return (
    <div className={`listAnswersEvaluationKnowledge`}>
      <Text component="h3" className={"infoTitle"} text={`Gabarito`} />
      {contextCurrentAvaliationResult.description ? (
        <Text
          component="p"
          className={"infoDatas"}
          text={`${contextCurrentAvaliationResult.description}`}
        />
      ) : (
        <></>
      )}
      <div className="containerList">
        {contextCurrentAvaliationResult &&
          contextCurrentAvaliationResult.summary.map((item, indice) => {
            return (
              <Fragment key={indice}>
                <ItemAnswers
                  item={item}
                  indice={indice}
                  callModal={callModal}
                  itemAll={contextCurrentAvaliationResult.summary.length}
                />
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

export default EvaluationKnowledgeListAnswers;
