import * as yup from 'yup';

export const schemaExternalCourse = yup.object().shape({
    title: yup.string().required('*Preenchimento obrigatório').default(''),
    type: yup.string().nullable().required('*Preenchimento obrigatório').default(''),
    workload: yup
        .number()
        .nullable()
        .moreThan(0, 'O valor deve ser maior que 0')
        .required('*Preenchimento obrigatório')
        .default(1), // Valida apenas números maiores que 0.required('*Preenchimento obrigatório'),
    startDate: yup.date().nullable().required('*Preenchimento obrigatório'),
    finishDate: yup.date().nullable().required('*Preenchimento obrigatório'),
    entityName: yup.string().required('*Preenchimento obrigatório').default(''),
    expirationDate: yup.date().nullable(),
    evidencePath: yup.string().nullable().default(''),
});

export const validateFileName = (name: string): string => {
    let newFileName = name
        .replace(/\s/g, '')
        .replace(/-/g, '_')
        .replace(/[^a-zA-Z0-9_.]/g, ''); // Remova o espaço em branco e  substitui '-' por '_'
    // Garante que o nome do arquivo comece com letra minúscula
    newFileName = newFileName.charAt(0).toLowerCase() + newFileName.slice(1);
    return newFileName;
};

export const getFileName = (filePath: string): string => {
    // Divide a string pelo caractere "/" e retorna o último elemento
    return filePath.split('/').pop() || '';
};

export const validateDates = (startDate, finishDate) => {
    const start = new Date(startDate);
    const finish = new Date(finishDate);
    const currentDate = new Date();

    // Verificar se finishDate é maior ou igual a startDate
    if (finish >= start && finish <= currentDate) {
        return true;
    }

    return false;
};
