import "./style.scss";
import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import useCards from "../../../pctHooks/useCards";
import { Text } from "../../atoms";
import useMediaQuery from "pctHooks/useMediaQuery";

type idType = {
  id?: string;
};

type Props = {
  className?: string;
  css?: object;
  collection: BlockElement | any;
  dataParent?: any;
  keyId?: any;
  disabled?: any;
  setbcOpen?: any;
};

const LabelBreadCramb = ({
  collection,
  className = "",
  disabled = false,
  dataParent,
  css = {},
  setbcOpen = null,
}: Props) => {
  const setProgressStateRef = useRef(null as any);
  const progressInfoRef = useRef(null as any);
  const smStatus = useMediaQuery("sm");

  // console.log(dataParent, collection);
  // console.log("-----------------------------");

  const { handleCardMouseEvent } = useCards({
    collection: { data: collection, elementType: "NODE" },
    id: 0,
    dataParent: { data: dataParent, elementType: "NODE" },
    setProgressStateRef,
    progressInfoRef,
  });

  const { shortTitle, title } = collection;

  const sanitizeText = (phrase, limit) => {
    const ret =
      phrase.length > limit ? phrase.substring(0, limit) + "..." : phrase;
    return ret;
  };

  const _shortTitle =
    shortTitle != "" && String(shortTitle).toUpperCase() !== "TÍTULO CURTO"
      ? shortTitle
      : title;
  const labelMax = !smStatus ? 25 : 13;
  const currentLabel = sanitizeText(_shortTitle?.toUpperCase(), labelMax);

  return (
    <div
      className={`labelBreadCramb`}
      style={css}
      onClick={(e) => {
        if (!disabled) {
          handleCardMouseEvent(e);
          setbcOpen && setbcOpen(false);
        }
      }}
    >
      <div className={`divTitle ${className}`} title={title}>
        {" "}
        <Text
          text={currentLabel}
          component={"small"}
          className="breadCard"
        />{" "}
      </div>
    </div>
  );
};

export default LabelBreadCramb;
