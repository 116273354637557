import React, { ReactNode, useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { IoMdClose } from 'react-icons/io';
import { ModalUpgradeCompaignProps } from './modalUpgradeCampaign.model';

import './style.scss';
import CampaignContent from 'pctComponents/organisms/CampaignContent';
export interface ModalUpgradeCampaignConfig extends Partial<ModalUpgradeCompaignProps> {
    title: ReactNode;
    description: ReactNode;
    textBtn: string;
}

const ModalUpgradeCampaign = NiceModal.create(
    ({
        platformName,
        media,
        isAdmin,
        startedPlan,
        upgradeParams,
        activePlan,
        handledClosed,
    }: ModalUpgradeCompaignProps) => {
        const modal = useModal();
        const [configModal, setConfigModal] = useState<ModalUpgradeCampaignConfig>({} as ModalUpgradeCampaignConfig);

        useEffect(() => {
            if (modal.visible) {
                document.body.classList.add('bodyOverflowHidden');
            } else {
                document.body.classList.remove('bodyOverflowHidden');
            }

            return () => {
                document.body.classList.remove('bodyOverflowHidden');
            };
        }, [modal.visible]);

        useEffect(() => {
            if (isAdmin) {
                if (startedPlan) {
                    setConfigModal({
                        title: 'Não deixe para depois',
                        description:
                            'Se está gostando, clique no botão abaixo e compartilhe com a gente. Sua voz é essencial para nós!',
                        textBtn: 'Contrate agora',
                        media,
                    });
                    return;
                }

                setConfigModal({
                    title: (
                        <span>
                            Conheça a plataforma <span className="campaign-app">{platformName}</span> e eleve sua
                            experiência de ensino e aprendizado a um novo nível.
                        </span>
                    ),
                    description:
                        'Desenvolva e aprimore seus próprios programas de treinamento, com acesso exclusivo a uma ampla variedade de bibliotecas, proporcionando um acervo rico em conteúdo e recursos de aprendizado.',
                    textBtn: 'Inicie o seu teste',
                    media,
                });

                return;
            }

            if (startedPlan) {
                setConfigModal({
                    title: 'Não deixe para depois',
                    description:
                        'Se está gostando, clique no botão abaixo e compartilhe com a gente. Sua voz é essencial para nós!',
                    textBtn: 'Diga que está gostando da degustação',
                    media,
                });
                return;
            }

            setConfigModal({
                title: (
                    <span>
                        Solicite o seu teste da plataforma <span className="campaign-app">{platformName}</span> e eleve
                        sua experiência de ensino e aprendizado a um novo nível.
                    </span>
                ),
                description:
                    'Desenvolva e aprimore seus próprios programas de treinamento, com acesso exclusivo a uma ampla variedade de bibliotecas, proporcionando um acervo rico em conteúdo e recursos de aprendizado.',
                textBtn: 'Solicite o seu teste',
                media,
            });
        }, [isAdmin, startedPlan, media, platformName]);

        const closeModal = () => {
            modal.remove();
            handledClosed && handledClosed();
        };

        return (
            <div className={`ModalUpgradeCampaign`}>
                <div className={`modal-body-pct`}>
                    <button className="closeBtn" onClick={closeModal}>
                        <IoMdClose />
                    </button>
                    <CampaignContent
                        {...configModal}
                        modal={modal}
                        activePlan={activePlan}
                        upgradeParams={upgradeParams}
                    />
                </div>
            </div>
        );
    },
);

export default ModalUpgradeCampaign;
