import "./style.scss";

import React from "react";

type Props = {
  rowsNumber?: number;
  columnsNumber?: number;
  css?: object;
  className?: string;
};

function PlaceholderTable({
  rowsNumber = 5,
  columnsNumber = 4,
  css = {},
  className = "",
}: Props) {
  let rows: number[] = [];
  let i: number = 0;

  while (++i <= rowsNumber) {
    rows.push(i);
  }

  let colluns: number[] = [];
  let j: number = 0;

  while (++j <= columnsNumber) {
    colluns.push(j);
  }

  const PlaceholderColluns = () => {
    return (
      <>
        {colluns.map(function (i, ind) {
          return i == 1 ? (
            <div key={ind} className="ph-text shorter ph-animated"></div>
          ) : (
            <div key={ind} className="ph-text short ph-animated"></div>
          );
        })}
      </>
    );
  };

  const PlaceholderItem = ({ style, className }) => {
    return (
      <div className={`ph-wrapper ${className}`} style={style}>
        <PlaceholderColluns />
      </div>
    );
  };

  return (
    <div className={"pg-grid-placeholder-historic"}>
      {rows.map(function (i) {
        return <PlaceholderItem key={i} style={css} className={className} />;
      })}
    </div>
  );
}

export default PlaceholderTable;
