import React from "react";

import { Text } from "pctComponents/atoms";

type Props = {
  listPoint?: any;
};

const ModalCompaingsList = ({ listPoint }: Props) => {
  return (
    <div className="tableCompaings">
      {listPoint ? (
        <table className="rwd-table">
          <tr>
            <th>Nome</th>
            <th>Pontos</th>
          </tr>
          {listPoint.map((it, indice) => {
            return (
              <tr key={indice}>
                <td data-th="Nome">{it.name}</td>
                <td data-th="Pontos">
                  {it.points < 10 ? "0" + it.points : it.points} Pts
                </td>
              </tr>
            );
          })}
        </table>
      ) : (
        <div className="listLoading">
          <Text component={"p"} text={`Carregando...`} />
        </div>
      )}
    </div>
  );
};

export default ModalCompaingsList;
