import "./style.scss";

import React, { useState, useEffect, useCallback, useRef } from "react";
import { BsCamera } from "react-icons/bs";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import "moment/locale/pt-br";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";

import { PlaceholderProfile } from "pctComponents/placehoader";

import { getFeatures,getUserInfo, setUserInfo, renewlogin } from "../../../services/api";
import {  getProfileInfo } from "../../../services/utils";

import ImageUpload from "../../../components/ImageUpload";
import UserPhoto from "../../../components/UserPhoto";

import { GrLinkNext } from "react-icons/gr";

import { updateConfigHeaderFooter } from "../../../actions";

import { Text, Avatar } from "../../atoms";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalTopicsOfInterest from "../../modals/ModalTopicsOfInterest";
import ModalNotification from "../../modals/ModalNotification";
import ModalPassword from "../../modals/ModalPassword";

const Profile = (props) => {
  const form = useRef(null);

  // const [totalPoints, setTotalPoints] = useState(0);
  // const [isGamification, setGamification] = useState();

  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [error, setError] = useState(false);

  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  const [feedbackFail, setFeedbackFail] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [notificationModal, setNotificationModal] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [passwordModal, setPasswordModal] = useState(false);
  const [showRecommendation, setShowRecommendation] = useState(false);

  const [user, setUser] = useState({
    idUser: 1,
    idCompany: 1,
    email: "",
    name: "",
    phone: "",
    // "cpf": "",
    userType: 1,
    userLicenceTermsAccepted: 1,
    communicationAccepted: 1,
    emailNotification: "",
    smsNotification: "",
    gestCompany: "",
    gestCnpj: "",
    admin: 0,
    active: 1,
    photo: "",
    managerName: "",
    role: "",
    department: "",
    guestCompany: "",
    username: "",
    unit: "",
    branch: "",
    employeeCode: "",
  });

  const [userForPhoto, setUserForPhoto] = useState({
    idUser: 1,
    idCompany: 1,
    email: "",
    name: "",
    phone: "",
    // "cpf": "",
    userType: 1,
    userLicenceTermsAccepted: 1,
    communicationAccepted: 1,
    emailNotification: "",
    smsNotification: "",
    gestCompany: "",
    gestCnpj: "",
    admin: 0,
    active: 1,
    photo: "",
  });

  const calltopicsOfInterest = (e) => {
    e.preventDefault();
    NiceModal.show(ModalTopicsOfInterest, {
      obj: {
        closedModal: (e) => {
          // updateTerms()
          //  setRedirectHome(true);
        },
      },
    });
  };

  const callNotification = (e) => {
    e.preventDefault();
    setNotificationModal(true);
  };

  const _getUserInfo = useCallback((id) => {
    setLoading(true);
    if (id) {
      getUserInfo(id)
        .then((res) => {
          if (res.data.success === false) {
            setLoading(false);
            setError(true);
            return;
          }

          //   console.log(res.data.user.role);

          let userData = {
            active: res.data.user.active,
            admin: res.data.user.admin,
            askPWA: res.data.user.askPWA,
            communicationAccepted: res.data.user.communicationAccepted,
            emailNotification: res.data.user.emailNotification,
            smsNotification: res.data.user.smsNotification,
            // cpf: res.data.user.cpf,
            department: res.data.user.department,
            email: res.data.user.email,
            guestCnpj: res.data.user.guestCnpj,
            guestCompany: res.data.user.guestCompany,
            name: res.data.user.name,
            phone: res.data.user.phone,
            photo: res.data.user.photo,
            role: res.data.user.role,
            managerName: res.data.user.managerName,
            idManager: res.data.user.idManager,
            userLicenceTermsAccepted: res.data.user.userLicenceTermsAccepted,
            userType: res.data.user.userType,
            username: res.data.user.username,
            unit: res.data.user.unit,
            branch: res.data.user.branch,
            employeeCode: res.data.user.employeeCode,
          };

          setNotifications(res.data.notifications);
          setUser(userData);
          setUserForPhoto(userData);
          setLoading(false);
          setError(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        });
    }
  }, []);

  const _getInfoFeaturesRecommendation= useCallback(() => {
    getFeatures("RECOMMENDATION_BLOCK")
    .then((res) => {
      const active = res.data?.active;
      setShowRecommendation(active)
     
    })
  }, [])

  useEffect(() => {
    const { updateConfigHeaderFooter } = props;
    updateConfigHeaderFooter({
      prevNavStyle: "closeShow",
      backCloseText: "FECHAR",
    });
  }, []);

  useEffect(() => _getInfoFeaturesRecommendation(), [_getInfoFeaturesRecommendation])

  useEffect(() => {
    // setGamification(getGamigication())

    const _user = getProfileInfo();
    setUserId(_user.idUser);
    _getUserInfo(_user.idUser);
  }, [props, _getUserInfo]);

  const callbackImage = (url) => {
    let obj = { ...userForPhoto, photo: url };
    saveProfile(obj);
    handleModal();
    setUser({ ...user, photo: url });
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    // cpf: yup.string().matches(
    //     /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}/gm,'CPF inválido'
    // ),
    phone: yup
      .string()
      .matches(/^\([1-9]{2}\) (?:[2-8]|[1-9][1-9])[0-9]{3}\-[0-9]{4}$/gm),
  });

  const saveProfile = async (data) => {
    setLoadingSave(true);

    const { managerName, ...rest } = data;

    await setUserInfo(userId, rest)
      .then((res) => {
        if (res && res.data.success === true) {
          // console.log(res.data.newToken);
          // console.log(user);
          // console.log(data);
          renewlogin(res.data.newToken);

          setFeedbackSuccess(true);
          setTimeout(() => {
            setFeedbackSuccess(false);
            setUserForPhoto(user);
          }, 5000);
        } else {
          setErrMessage(
            res.data.message ||
              "Erro ao salvar dados, tente novamente mais tarde."
          );
          setFeedbackFail(true);
          setTimeout(() => {
            setFeedbackFail(false);
          }, 10000);
        }
      })
      .catch((err) => {
        setErrMessage("Erro ao salvar dados, tente novamente mais tarde.");

        setFeedbackFail(true);
        setTimeout(() => {
          setFeedbackFail(false);
        }, 10000);
      });

    setLoadingSave(false);
  };

  const handleModal = () => {
    setShow(!show);
  };

  const handleOnClick = (event) => {
    //.current is verification that your element has rendered
    if (form.current) {
      form.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  return (
    <>
      <div className="pctPage pctPageBlock pct_profile-container">
        <section className="sectionList profile-section">
          <div className="profile-card section-profile">
            {loading ? (
              <PlaceholderProfile number={1} />
            ) : error ? (
              <strong style={{ color: "orangered" }}>
                {" "}
                Error retrieving user data{" "}
              </strong>
            ) : (
              <>
                <Text component="h2" text="Dados pessoais" />

                {show && (
                  <div
                    className={`modalProfile ${show ? "modalProfileShow" : ""}`}
                  >
                    <div
                      className="modalProfileHit"
                      onClick={() => {
                        handleModal();
                      }}
                    ></div>
                    <div className="modalProfileform">
                      <ImageUpload
                        closeHandle={handleModal}
                        callbackImage={callbackImage}
                      />
                    </div>
                  </div>
                )}

                <Formik
                  validationSchema={schema}
                  onSubmit={(values) => {
                    setUser({
                      ...user,
                      name: values.name,
                      email: values.email,
                      phone: values.phone,
                      communicationAccepted: values.notificacao ? 1 : 0,
                      emailNotification: values.notificacaoEmail ? 1 : 0,
                      smsNotification: values.smsNotification ? 1 : 0,
                      username: values.username,
                    });
                    saveProfile({
                      ...user,
                      name: values.name,
                      email: values.email,
                      phone: values.phone,
                      communicationAccepted: values.notificacao ? 1 : 0,
                      emailNotification: values.notificacaoEmail ? 1 : 0,
                      smsNotification: values.smsNotification ? 1 : 0,
                      username: values.username,
                    });
                  }}
                  initialValues={{
                    name: user.name,
                    username: user.username,
                    email: user.email,
                    unit: user.unit,
                    branch: user.branch,
                    employeeCode: user.employeeCode,
                    phone: user.phone,
                    role: user.role,
                    department: user.department,
                    managerName: user.managerName,
                    guestCompany: user.guestCompany,
                    notificacao: user.communicationAccepted === 1,
                    notificacaoEmail: user.emailNotification === 1,
                    notificacaoSms: user.smsNotification === 1,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    setFieldValue,
                    errors,
                  }) => (
                    <>
                      <Form noValidate onSubmit={handleSubmit}>
                        <div ref={form} className="profile-edit section-form">
                          <div className="form-top">
                            <div
                              className="conteudo-icon"
                              onClick={handleModal}
                            >
                              <div className="pct-editar-perfil-icon">
                                <Avatar
                                  className="avatarPerfil"
                                  photoPerfil={user.photo}
                                  namePerfil={user.name}
                                />
                                <BsCamera size={30} className="bs-camera" />
                              </div>

                              <Text component="p" text="Editar foto" />
                            </div>
                            <div className="profile-info-active profile-info-init">
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  Nome
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  value={values.name}
                                  className="form-control edit-login-input"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={touched.name && errors.name}
                                />
                              </Form.Group>
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  Login
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="username"
                                  value={values.username}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>

                          <div className="profile-list">
                            {values.email && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  E-mail
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="email"
                                  value={values.email}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            )}
                            {values.employeeCode && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  Código do colaborador
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="employeeCode"
                                  value={values.employeeCode}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            )}
                            {values.phone && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  Telefone
                                </Form.Label>
                                <InputMask
                                  mask="(99) 99999-9999"
                                  value={values.phone}
                                  className={`form-control edit-login-input ${
                                    touched.phone && errors.phone
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(e) =>
                                    setFieldValue("phone", e.target.value)
                                  }
                                  isInvalid={touched.phone && errors.phone}
                                />
                              </Form.Group>
                            )}
                            {values.managerName && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  Gestor imediato
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="managerName"
                                  value={values.managerName}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            )}
                            {values.role && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  Cargo
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="role"
                                  value={values.role}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            )}
                            {values.department && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  Departamento
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="department"
                                  value={values.department}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            )}

                            {values.unit && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  Unidade
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="unit"
                                  value={values.unit}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            )}

                            {values.branch && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  Filial
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="branch"
                                  value={values.branch}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            )}

                            {/* {values.guestCompany && (
                              <Form.Group className="profile-edit-item section-form-edit">
                                <Form.Label className="profile-edit-label">
                                  {" "}
                                  Empresa
                                </Form.Label>
                                <Form.Control
                                  disabled
                                  name="guestCompany"
                                  value={values.guestCompany}
                                  className="form-control edit-login-input button-disabled"
                                  style={{
                                    opacity: 0.5,
                                    cursor: "no-drop",
                                  }}
                                />
                              </Form.Group>
                            )} */}
                          </div>

                          {/* <div className="profile-list"></div> */}

                          <Text
                            component="h2"
                            text="Outras configurações de usuário"
                            className="subTitle"
                          />

                          {showRecommendation && 
                            <div className="topicsOfInterest">
                              <Text
                                component={"h3"}
                                text={"Temas de interesse"}
                              />
                              <Text
                                component={"p"}
                                text={
                                  "As recomendações de conteúdos e treinamentos que lhe mostramos são inspiradas nestes interesses. <b>Adicione ou remova</b> interesses para refinar as sugestões."
                                }
                              />
                              <button
                                className="topicsOfInterestBtn"
                                type="button"
                                onClick={(e) => {
                                  calltopicsOfInterest(e);
                                }}
                              >
                                <Text
                                  component={"p"}
                                  text={"Gerenciar interesses"}
                                />
                                <GrLinkNext />
                              </button>
                            </div>
                          }

                          <div className="topicsOfInterest">
                            <Text component={"h3"} text={"Notificações"} />
                            <Text
                              component={"p"}
                              text={
                                "As notificações manterão você informado quando houver atualizações nas solicitações de matrícula ou quando algum treinamento que você tenha iniciado for concluído."
                              }
                            />
                            <button
                              className="topicsOfInterestBtn"
                              type="button"
                              onClick={(e) => {
                                callNotification(e);
                              }}
                            >
                              <Text
                                component={"p"}
                                text={"Gerenciar notificações"}
                              />
                              <GrLinkNext />
                            </button>
                          </div>

                          <div className="topicsOfInterest">
                            <Text component={"h3"} text={"Alterar senha"} />
                            <Text
                              component={"p"}
                              text={
                                "Clique na opção abaixo para alterar sua senha de acesso à plataforma."
                              }
                            />
                            <button
                              className="topicsOfInterestBtn"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setPasswordModal(true);
                              }}
                            >
                              <Text component={"p"} text={"Alterar senha"} />
                              <GrLinkNext />
                            </button>
                          </div>

                          <div className="edit-button-group form-edit-button">
                            <button
                              type="submit"
                              disabled={loadingSave}
                              className="btn btnSalvar form-edit-button-flex"
                            >
                              <div className="btnIt">
                                Salvar
                                {loadingSave && <div className="loader" />}
                              </div>
                            </button>
                          </div>
                          {feedbackSuccess && (
                            <div className="edit-button-group">
                              <button
                                style={{ flex: 1, position: "relative" }}
                                className="btn btn-outline-success"
                                disabled
                              >
                                <span>Perfil atualizado com sucesso</span>
                              </button>
                            </div>
                          )}
                          {feedbackFail && (
                            <div className="edit-button-group">
                              <button
                                style={{ flex: 1, position: "relative" }}
                                className="btn btn-outline-danger"
                                disabled
                              >
                                <span>{errMessage}</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </>
            )}
          </div>
        </section>
      </div>
      <ModalNotification
        notificationModal={notificationModal}
        setNotificationModal={setNotificationModal}
        notifications={notifications}
      />
      <ModalPassword
        passwordModal={passwordModal}
        setPasswordModal={setPasswordModal}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default connect(null, mapDispatchToProps)(Profile);