import {
    getExternalCourses,
    getExternalCoursesId,
    setExternalCourses,
    setExternalCoursesId,
    cancelExternalCourses,
    postUploadsExternalCourse,
} from '../../../services/api';

const erroService = (props) => {
    props.setErrorMessageFile('Erro ao salvar curso externo.');
    props.setLoading(false);
    props.setSubmitting(false);
};

export const listExternalCoursesServices = (props) => {
    getExternalCourses(props.params)
        .then((res) => {
            props.itemsLenght.current = res.data.totalRecords;
            props.setDataExternalCourse(res.data.result);
            props.setLoading(false);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const createExternalCoursesServices = (props) => {
    setExternalCourses(props.values)
        .then((res) => {
            if (res) {
                props.setLoading(false);
                props.resetItemOffset();
                props.closeModal();
            } else {
                erroService(props);
            }
        })
        .catch((err) => {
            erroService(props);
        });
};

export const getExternalCoursesServicesId = (props) => {
    getExternalCoursesId(props.idUserExternalCourse)
        .then((res) => {
            if (res) {
                props.setLoading(false);
                // console.log(res.data.result);
                props.setExternalCourseForm(res.data.result);
            } else {
                console.log('Erro ao chamar item');
                // erroService(props);
            }
        })
        .catch((err) => {
            console.log('Erro ao chamar item', err);
            // erroService(props);
        });
};

export const editExternalCoursesServices = (props) => {
    const valuesEdit = {
        idUserExternalCourse: props.values.idUserExternalCourse,
        title: props.values.title,
        type: props.values.type,
        workload: props.values.workload,
        startDate: props.values.startDate,
        finishDate: props.values.finishDate,
        expirationDate: props.values.expirationDate,
        entityName: props.values.entityName,
        evidencePath: props.values.evidencePath,
    };

    setExternalCoursesId(props.values.idUserExternalCourse, valuesEdit)
        .then((res) => {
            if (res) {
                props.setLoading(false);
                props.resetItemOffset();
                props.closeModal();
            } else {
                erroService(props);
            }
        })
        .catch((err) => {
            erroService(props);
        });
};

export const cancelExternalCoursesServices = (props) => {
    cancelExternalCourses(props.idUserExternalCourse)
        .then((res) => {
            props.setLoading(false);
            props.resetItemOffset();
            props.closeModal();
        })
        .catch((err) => {
            console.log(err);
        });
};

export const postImgExternalCoursesServices = (props) => {
    postUploadsExternalCourse(props.dataFormData)
        .then((res) => {
            if (res) {
                props.setLoading(false);
                props.setFieldValue('evidencePath', res.data.externalCourse);
            } else {
                props.setErrorMessageFile('Erro ao salvar o arquivo.');
                props.setLoading(false);
            }
        })
        .catch((err) => {
            props.setErrorMessageFile('Erro ao salvar o arquivo.');
            props.setLoading(false);
        });
};
