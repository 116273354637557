import { CONFIG_FEATURES } from '../actions/actionTypes';

const initialState = {
    configFeatures: {
        externalcourses: false,
    },
};

export const hasFeaturesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONFIG_FEATURES:
            return {
                // ...state,
                configFeatures: {
                    ...state.configFeatures,
                    ...action.configFeatures,
                },
            };
        default:
            return state;
    }
};
