import React from 'react';
import { getAllMenuOptions, transformText, getColors } from '../services/utils';
import { withRouter } from 'react-router-dom';
import Arya from './Arya';

import AudioList from '../components/AudioList';
import BannerMenu from './BannerMenu/index';
import CardGrid from '../container/CardGrid/';
import CardSiteList from '../components/CardSiteList';
import CardMenu from './CardMenu';
import Mosaic from '../components/Mosaic';
import TelaPrincipal from '../components/TelaPrincipal';
import VideoList from './VideoList';
import LiveList from './LiveList';
import SinglePageList from '../components/SinglePageList';
import SinglePageGridList from '../components/SinglePageGridList';
import PostList from './PostList';
import MosaicJourneyList from './MosaicJourneyList';
import MosaicSiteList from './MosaicSiteList';
import Profile from './Profile';
import SinglePageSection from '../components/SinglePageSection';
import AssessmentRedirect from './AssessmentRedirect';
import MosaicWithVideo from '../components/MosaicWithVideo';
import UploadList from './UploadList';
import MultiformatList from './MultiformatList';
import UploadTemplate from '../components/UploadTemplate';
import Library from '../components/Library';
import ListarPerfis from '../components/ListarPerfis';
import MultipleFormatList from './MultipleFormatList';
import Agenda from './Agenda';
import AdminAccess from '../components/AdminAccess';
import RedirectAccess from '../components/RedirectAccess';
import ItemList from './ItemList';
import ItemMenu from './ItemMenu';
import PdfViewer from '../components/PdfViewer';
import PdfList from '../components/PdfList';

const TemplateSelector = (props) => {
  function _getSectionByName() {
    // gets the current path name
    let pathName = props.match.path.replace('/', '');

    // gets all menu options stored on cache
    let allMenuOptions = getAllMenuOptions();

    // gets the menu object according to the path name
    let menuItem = allMenuOptions.filter((obj) => {
      return transformText(obj.text) + '/' + obj.sectionTo === pathName;
    })[0];
    return menuItem;
  }

  function noSection() {
    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          color: 'rgba(255,255,255,0.8)',
          paddingTop: 'calc(25vh - 000px - 54px)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: getColors('menu')?.firstColor,
            width: 250,
            borderRadius: 5,
          }}
        >
          <span>Seção não disponível</span>
        </div>
      </div>
    );
  }

  function selectTemplate(type) {
    type = parseInt(type);
    switch (type) {
      case 1:
        return <TelaPrincipal idSection={_getSectionByName().sectionTo} />;
      case 2:
        return <CardGrid idSection={_getSectionByName().sectionTo} />;
      case 3:
        return <BannerMenu idSection={_getSectionByName().sectionTo} />;
      case 4:
        return <Mosaic idSection={_getSectionByName().sectionTo} />;
      case 5:
        return <LiveList idSection={_getSectionByName().sectionTo} />;
      case 6:
        return <AudioList idSection={_getSectionByName().sectionTo} />;
      case 7:
        return <PostList idSection={_getSectionByName().sectionTo} />;
      case 8:
        return <CardSiteList idSection={_getSectionByName().sectionTo} />;
      case 9:
        return <MosaicSiteList idSection={_getSectionByName().sectionTo} />;
      case 10:
        return <SinglePageList idSection={_getSectionByName().sectionTo} />;
      case 11:
        return <LiveList idSection={_getSectionByName().sectionTo} />;
      case 12:
        return <VideoList idSection={_getSectionByName().sectionTo} />;
      case 13:
        return <SinglePageSection idSection={_getSectionByName().sectionTo} />;
      case 14:
        return <Arya idSection={_getSectionByName().sectionTo} />;
      case 18:
        return <Profile idSection={_getSectionByName().sectionTo} />;
      case 19:
        return <ListarPerfis idSection={_getSectionByName().sectionTo} />;
      case 20:
        return <AdminAccess fromSection idSection={_getSectionByName().sectionTo} />;
      case 21:
        return <AssessmentRedirect idSection={_getSectionByName().sectionTo} />;
      case 25:
        return <MosaicWithVideo idSection={_getSectionByName().sectionTo} />;
      case 31:
        return <SinglePageGridList idSection={_getSectionByName().sectionTo} />;
      case 32:
        return <UploadTemplate idSection={_getSectionByName().sectionTo} />;
      case 33:
        return <UploadList idSection={_getSectionByName().sectionTo} />;
      case 36:
        return <Library idSection={_getSectionByName().sectionTo} />;
      case 38:
        return <MultipleFormatList idSection={_getSectionByName().sectionTo} />;
      case 43:
        return <Agenda idSection={_getSectionByName().sectionTo} />;
      case 45: // Little Journey
        return <MosaicJourneyList idSection={_getSectionByName().sectionTo} />;
      case 48:
        return <ItemMenu idSection={_getSectionByName().sectionTo} />;
      case 49:
        return <ItemList idSection={_getSectionByName().sectionTo} />;
      case 52: //51-48
        return <MultiformatList idSection={_getSectionByName().sectionTo} />;
      case 58: // IMPACT Journey
        return <MosaicJourneyList idSection={_getSectionByName().sectionTo} />;
      case 59:
        return <RedirectAccess fromSection idSection={_getSectionByName().sectionTo} />;
      case 60:
        return <PdfViewer idSection={_getSectionByName().sectionTo} />;
      case 61:
        return <PdfList idSection={_getSectionByName().sectionTo} />;
      default:
        return noSection();
    }
  }

  return selectTemplate(_getSectionByName().idSectionType);
};

export default withRouter(TemplateSelector);
