
import axios from "axios";
import moment from "moment";


const api_key = "AIzaSyD4MJDu22MFh0BlX9JEYI2N0heahEI2S2Q"; // substituir pela api key deles


export const getListDuration = ( idList ) => {
    const url2 = `https://www.googleapis.com/youtube/v3/videos?id=${idList.toString()}&part=snippet,contentDetails&fields=items(etag,id,snippet(publishedAt,title,thumbnails(default(url)),tags),contentDetails(duration))&key=${api_key}`
    return axios.get(url2)
        .then(({ data }) => {
            let newDuration = 0
            data.items.forEach((item) => { 
                newDuration += moment.duration(item.contentDetails.duration).asSeconds(); 
            })
            return newDuration
        })
        .catch((e) => {
            return false;
        });
}

export const getVideosFromPlaylist = (playlistId) => {
    const playlist = playlistId?.split("=");
    const part = "snippet";
    const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=${part}&playlistId=${playlist?.[1]}&key=${api_key}&maxResults=50`;


    return axios.get(url)
        .then(({ data }) => {
            return data.items
        })
        .catch((e) => {
            console.log("Erro do Youtube", e);
            return [];
        });
}