import React, { useState, useEffect, useRef } from "react";
import { contentList } from '../services/api';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';
import ListCard from "../components/ListCard";
import { getDesign } from "../services/utils";

const PostList = (props) => {

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const emphasis = getDesign(props.idSection, 'menuitem')
  const highLight = (Boolean(emphasis) && Boolean(emphasis.showEmphasis)) ? emphasis.emphasisNumber : 0
  const _isMounted = useRef(null);
  	async function getContentList(id) {
		setIsLoading(true);
		const result = await contentList(id);
		if (_isMounted.current) {
			try {
				if (result.data.success === false) {
					return;
				}
				setData(result.data.content);
			} finally {
				setIsLoading(false);
			}
		}
	}

  useEffect(() => {
		_isMounted.current = true;

    const { idSection, updateHeaderStatus, updateFooterStatus } = props
    getContentList(idSection)
    updateFooterStatus(getDesign(props.idSection,'general')?.showBottomMenu || 'true')
    updateHeaderStatus(getDesign(props.idSection,'general')?.showTopBar || 'true')
    return () => {
			_isMounted.current = false;
		};
  }, [props])

    return( <ListCard itens={data} highlight={highLight} isLoading={isLoading} section={props.idSection} />)
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(PostList);