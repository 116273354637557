import React from "react";
import { listFilter } from '../services/api'
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

import ListCard from "./ListCard";
import { getDesign } from "../services/utils";

class SinglePageGrid extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = true;
    this.state = {
      isLoading: false,
      data: [],
      page: 1,
      totalPages: 0,
      searchTags: "",
      totalRecords: 0,
      hasMore: false,
      highLight: 0,
    };
  }

  componentDidMount() {
    const { updateFooterStatus, updateHeaderStatus } = this.props
    updateFooterStatus(getDesign(this.props.idSection,'general')?.showBottomMenu || 'true')
    updateHeaderStatus(getDesign(this.props.idSection,'general')?.showTopBar || 'true')
    this._getContent(this.props.idSection)
    let emphasis = getDesign(this.props.idSection, 'menuitem')
    this.setState({ highLight: (Boolean(emphasis) && Boolean(emphasis.showEmphasis)) ? emphasis.emphasisNumber : 0});
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  async _getContent(id) {
    this.setState({ isLoading: true })

    const result = await listFilter(id, { "page": 1, "searchTags": "" });
    if (this._isMounted) {
      const {data} = result;
			try {
        if (data.success == false) {
          this.setState({ isLoading: false })
          return
        }
        this.setState({
          isLoading: false,
          data: data.content,
          totalPages: Math.ceil((data.totalRecord / data.content.length) - 0.1), // divide by number of total items by page
          totalRecords: data.totalRecord,
          hasMore: data.content.length < data.totalRecord ? true : false
        })
  
        this.getData()
			} catch (err) {
        this.setState({ isLoading: false })

			} 
		}
  }

  getData() {
    const bodyRequest = {
      page: this.state.page + 1,
      searchTags: this.state.searchTags
    }

    if (bodyRequest.page <= this.state.totalPages) {
      this.setState({ page: bodyRequest.page })

      listFilter(this.props.idSection, bodyRequest).then(res => {
        if (!res || res.data.success == false) {
          return
        }
        this.setState({
          isLoading: false,
          data: [...this.state.data, ...res.data.content],
        })
        if (window.screen.width > 768 && bodyRequest.page === 2) {
          this.getData()
        }
      }).catch(err => {
        this.setState({ isLoading: false })
      })
    } else {
      this.setState({ hasMore: false })
    }
  }

  getStyle() {
    if(window.screen.width < 800) {
      return { padding: "0", ...getDesign(this.props.idSection,'general') }
    } else {
      return { padding: "2rem 8% 8rem", ...getDesign(this.props.idSection,'general') }
    }
  }

  render() {
    

    return( <ListCard itens={this.state.data} highlight={this.state.highLight} isLoading={this.state.length > 0} section={this.props.idSection} />)

    
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(SinglePageGrid);
