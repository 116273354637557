import './style.scss';

import React, {useEffect} from "react"
import { withRouter , useParams } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"
import { updateConfigHeaderFooter } from "../../../actions"
import { useFetch } from "../../../pctHooks/useFetch"
import { Text, Preloader } from "../../atoms"
import { CardListing } from "../../organisms"
import { getProfileInfo } from "../../../services/utils"

type idType = {
  id?: string
};

const CardList = (props) => {

    useEffect(() => {
      const { updateConfigHeaderFooter } = props;
      updateConfigHeaderFooter({});
    });
 
    const { id } = useParams<idType>()
    const { data, error } = useFetch({url:`/learning/nodes/${getProfileInfo().idCompany}`})
    if (error) return <p>There is an error.</p>
    if (!data) return <Preloader />

    const fetchData: any = data;
    const block:any = fetchData.blocks.filter(item => (item.idBlock?.toString() === id )) 
    const blocKData = block[0]

    return(
      <div className='pctPage pctPageBlock ptcCardList'>
        <section className="sectionList">

          {blocKData.title &&
            <Text component="h2" text={blocKData.title} className='titleSection' />
          }

          {blocKData.text &&
            <Text component="h4" text={blocKData.text} className='titleSection' />
          }

          <div className="pctCardGrid">
            { CardListing({data:blocKData}) }
          </div>

        </section>
      </div>
    )
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item))
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(CardList);
 

