import React from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { BsXCircle } from 'react-icons/bs';
import { LuPen } from 'react-icons/lu';
import { Tooltip, withStyles } from '@material-ui/core';

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: '12px',
        maxWidth: '600px',
        background: '#000000',
    },
}))(Tooltip);

const ActionBtn = ({ item, callToAction }) => {
    const { status } = item;

    return (
        <div className="list-actions">
            {status !== 'PENDING' && (
                <CustomTooltip title={'Visualizar solicitação'} placement="top">
                    <button
                        className={`${'btn_' + status}`}
                        onClick={() => {
                            callToAction(item);
                        }}
                    >
                        <AiOutlineEye />
                    </button>
                </CustomTooltip>
            )}
            {status === 'PENDING' && (
                <CustomTooltip title={'Cancelar solicitação'} placement="top">
                    <button
                        className={`${'btn_' + status}`}
                        onClick={() => {
                            callToAction(item, true);
                        }}
                    >
                        <BsXCircle />
                    </button>
                </CustomTooltip>
            )}
            {status === 'PENDING' && (
                <CustomTooltip title={'Editar solicitação'} placement="top">
                    <button
                        className={`${'btn_' + status}`}
                        onClick={() => {
                            callToAction(item);
                        }}
                    >
                        <LuPen />
                    </button>
                </CustomTooltip>
            )}
        </div>
    );
};

export default ActionBtn;
