import React, { useRef } from 'react';
import { ErrorMessage } from 'formik';
import { FaCheck, FaRegTrashAlt } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { IoMdCloudUpload } from 'react-icons/io';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';

import { Button, Text, CalendarInput } from '../../../../../pctComponents/atoms';
import { convertToDateISOString } from '../../../../../services/utils';
import StatusLabel from '../../components/StatusLabel';
import { TypeExternalCourse, TypeExternalCourseDescriptions, ExternalCourseType } from '../../ExternalCourse.types';
import { getFileName } from '../../ExternalCourse.utils';

type Props = {
    values: Partial<ExternalCourseType>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    closeModal: () => void;
    errorMessageFile: string | null;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    item: ExternalCourseType | null;
    disableForm: boolean;
};

const FormExternalCourse = (props: Props) => {
    const {
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        closeModal,
        errorMessageFile,
        handleFileChange,
        item,
        disableForm,
    } = props;

    const inputFileRef = useRef<HTMLInputElement | null>(null);

    return (
        <div className="box-notification">
            <button className="closeBtn" onClick={closeModal}>
                <IoMdClose />
            </button>
            {item && <StatusLabel item={item} />}

            <div className="box-notification-header">
                <Text component={'h2'} text={'Cadastrar curso externo'} />
                <Text
                    component={'p'}
                    text={
                        'Cadastre aqui o curso externo que você realizou. Após análise e validação, o curso será exibido em seu histórico. Preencha as informações solicitadas e clique em Salvar para concluir.'
                    }
                    className=""
                />
            </div>

            <div className="box-notification-form">
                <div className="singleInput singleInputInit">
                    <Text component={'h4'} text={'Nome curso *'} />

                    <FormControl className={`inputView`}>
                        <Input
                            type={'text'}
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            disabled={disableForm}
                        />
                    </FormControl>
                    <div className="erroContainer">
                        <ErrorMessage name="title" component="small" />
                    </div>
                </div>

                <div className="singleInput">
                    <Text component={'h4'} text={'Local de realização do curso *'} />

                    <FormControl className={`inputView`}>
                        <Input
                            type={'text'}
                            name="entityName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.entityName}
                            disabled={disableForm}
                        />
                    </FormControl>

                    <div className="erroContainer">
                        <ErrorMessage name="entityName" component="small" />
                    </div>
                </div>

                <div className="multiInput">
                    <div className="blockInput">
                        <Text component={'h4'} text={'Tipo de curso *'} />

                        <Select
                            className="MselectCustom"
                            name="type"
                            id="select"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.type}
                            MenuProps={{
                                PopoverClasses: {
                                    root: 'customPopoverSelect',
                                },
                            }}
                            disabled={disableForm}
                        >
                            {Object.values(TypeExternalCourse).map((type) => (
                                <option key={type} value={type}>
                                    {TypeExternalCourseDescriptions[type]}
                                </option>
                            ))}
                        </Select>

                        <div className="erroContainer">
                            <ErrorMessage name="type" component="small" />
                        </div>
                    </div>

                    <div className="blockInput">
                        <Text component={'h4'} text={'Carga horária *'} />

                        <FormControl className={`inputView`}>
                            <Input
                                type={'number'}
                                name="workload"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputProps={{ min: 1 }}
                                value={values.workload}
                                disabled={disableForm}
                            />
                        </FormControl>

                        <div className="erroContainer">
                            <ErrorMessage name="workload" component="small" />
                        </div>
                    </div>

                    <div className="blockInput">
                        <Text component={'h4'} text={'Validade'} />

                        <FormControl className={`inputView`}>
                            <CalendarInput
                                onChange={(selectedDates) => {
                                    setFieldValue('expirationDate', convertToDateISOString(selectedDates[0]));
                                }}
                                type="date"
                                onBlur={handleBlur}
                                name="expirationDate"
                                value={values.expirationDate}
                                options={{}}
                                disabled={disableForm}
                            />
                        </FormControl>

                        <div className="erroContainer">
                            <ErrorMessage name="expirationDate" component="small" />
                        </div>
                    </div>
                </div>

                <div className="selectMultiInput">
                    <Text component={'h4'} text={'Período de realização *'} />
                    <div className="multiInput multiInputTwo">
                        <div className="blockInput">
                            <Text component={'h4'} text={'De'} />
                            <div className="blockInputView">
                                <FormControl className={`inputView`}>
                                    <CalendarInput
                                        onChange={(selectedDates) => {
                                            setFieldValue('startDate', convertToDateISOString(selectedDates[0]));
                                        }}
                                        name="startDate"
                                        type="date"
                                        value={values.startDate}
                                        options={{
                                            maxDate: new Date(),
                                        }}
                                        disabled={disableForm}
                                    />
                                </FormControl>
                                <div className="erroContainer">
                                    <ErrorMessage name="startDate" component="small" />
                                </div>
                            </div>
                        </div>

                        <div className="blockInput">
                            <Text component={'h4'} text={'Até'} />
                            <div className="blockInputView">
                                <FormControl className={`inputView`}>
                                    <CalendarInput
                                        onChange={(selectedDates) => {
                                            setFieldValue('finishDate', convertToDateISOString(selectedDates[0]));
                                        }}
                                        value={values.finishDate}
                                        options={{
                                            minDate: values.startDate,
                                            maxDate: new Date(),
                                        }}
                                        type="date"
                                        disabled={disableForm}
                                    />
                                </FormControl>
                                <div className="erroContainer">
                                    <ErrorMessage name="finishDate" component="small" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`fileMultiInput ${disableForm ? 'disableForm' : ''}`}>
                    <div className="multiInput">
                        <div className="basTxt">
                            <Text component={'p'} text={'<b>Comprovante de realização do curso externo</b>'} />
                            <Text
                                component={'p'}
                                text={'(arquivo nos formatos PDF, JPG ou PNG, com tamanho máximo de 5 MB)'}
                            />
                        </div>

                        <div className="blockInput">
                            <input
                                id="file-upload"
                                type="file"
                                accept=".pdf, .jpg, .jpeg, .png" // Restrição no seletor de arquivo
                                onChange={handleFileChange}
                                ref={inputFileRef}
                                disabled={disableForm}
                            />
                            <Button
                                ico={IoMdCloudUpload}
                                className={`button05`}
                                text={'Adicionar arquivo'}
                                disabled={disableForm}
                                callEvent={(e) => {
                                    e.preventDefault();
                                    if (inputFileRef.current) {
                                        inputFileRef.current.click(); // Simula um clique no input
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {values.evidencePath && (
                        <div className="blockFile">
                            <div className="infoFile">
                                <FaCheck />
                                <p>{getFileName(values.evidencePath)}</p>
                            </div>
                            <button
                                onClick={(e) => {
                                    setFieldValue('evidencePath', null);
                                }}
                                disabled={disableForm}
                            >
                                <FaRegTrashAlt />
                            </button>
                        </div>
                    )}
                    {errorMessageFile && (
                        <div className="blockFile blockFileErro">
                            <div className="infoFile">
                                <p>{errorMessageFile}</p>
                            </div>
                        </div>
                    )}
                </div>

                {item?.status === 'REJECTED' && (
                    <div className="fileMultiInput">
                        <Text component={'h4'} text={'Motivo da rejeição'} />

                        <div className="blockFile">
                            <div className="infoReasonRejection">
                                <p>{item?.statusReason}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormExternalCourse;
