import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { getProfileInfo, getContentTypeLabel } from "services/utils";
import { createContentEnrollments, learningContentUpdate } from '../services/api';

const useDownloadFile = ({ collection, contentLocation, setProgressStateRef, modalLoading  } ) => {
  
  // const { idEnrollment, idNodeEnrollment,  idElement } = collection;
  
  const onError = (error) =>{
    console.log(error);
  }

  let objDownload : any = {};

  const history = useHistory();

  const preDownloading = ( _frase, closeStatus ) =>{

    // const enrollmentControl = collection.data.contentEnrollment;
    // const { user } = enrollmentControl; 
   
    if(collection.contentEnrollment){
      objDownload = {
        "idNodeEnrollment": collection.contentEnrollment.idNodeEnrollment,
        "idEnrollment": collection.contentEnrollment.idEnrollment,
        "idContentItem": collection.contentEnrollment.idContentItem,
        "idUser": collection.contentEnrollment.idUser,
        "progress": 100,
        "grade": 0,
        "status": "COMPLETED"
      }
    }

    //TRATAMENTO PARA QUANDO FOR A NAVEGAÇÃO FLUÍDA.
    if(!objDownload.idUser){
      
      // const routerLinkTemp = `/contentNode/${objDownload.idContentItem}/${objDownload.idNodeEnrollment}/${idEnrollment}/${getProfileInfo().idUser}`;
      if(collection.contentEnrollment){
        if( !collection.contentEnrollment.idContentEnrollment){

          objDownload.idUser = getProfileInfo().idUser;
  
          modalLoading.show({
            obj: {
              message: "Aguarde, iniciando conteúdo.",
              closeBtn: false,
              loadding: true,
            },
          });
  
          createContentEnrollments(objDownload)
            .then((response) => {
              setTimeout(() => { 
                modalLoading.remove();
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      
      return false;

    }else{
      setTimeout(() => { 
        modalLoading.remove();
      }, 1000);
    }

    if(collection.contentEnrollment){
      if( !collection.data.contentEnrollment || !collection.data.contentEnrollment.idContentEnrollment ){
        createContentEnrollments(objDownload);
      }
    }
   
    modalLoading.show({
      obj: {
        message: _frase,
        closeBtn: closeStatus,
        loadding: true,
      },
    });


  }

  const postDownloading = (responseDownload) =>{
      setTimeout(()=>{
        setProgressStateRef.current = 100;
        // modalLoading.remove();
        //ATUALIZA TODOS OS BLOCOS DA TELA, SE DEIXAR O PARAMETRO COMO UM OBJETO VAZIO ELE BUSCA NO BANCO OS NOVOS UPDATES, MAS SE QUISER ENVIAR APENAS O OBJETO ATUALIZADO COLOQUE NO LUGAR DO OBEJTO VAZIO QUE NÃO VAI ACONTECER UM NOVO CHAMADO NO BANCO.
        
        // const enrollmentControl = collection.data.contentEnrollment;
        // collection.setFetchDataUpdate({});

        if(collection.contentEnrollment){
            
            if(collection.contentEnrollment && collection.contentEnrollment.status != "COMPLETED"){
              learningContentUpdate(objDownload, collection.contentEnrollment.idContentEnrollment)
                .then((response) => {
                  setTimeout(() => { 
                    // history.push(routerLinkTemp);
                    modalLoading.remove();

                    if(responseDownload)
                      responseDownload();
                  }, 1000);
                })
                .catch((error) => {
                  console.log(error);
                });
            }


        }

        


      }, 1000 * 2);
  }
  
  

  const callDownload = (responseDownload:any = null) => {
    if( contentLocation && contentLocation !== 'undefined' ){

      preDownloading("Atenção, download em progresso!", false);
      const a = document.createElement("a");
      a.href = contentLocation;
      a.target = '_blank';
  
      const clickEvnt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
      });
  
      a.dispatchEvent(clickEvnt);
      a.remove();
  
      postDownloading(responseDownload);
    }else{
      preDownloading("Arquivo inválido.", true);
    }
   
  }

  return {callDownload}
}

export default useDownloadFile;