import React, { useState, useEffect, useRef } from 'react';
import '../../themes/import.scss';
import '../../assets/css/CardGrid.scss';
import { contentList } from '../../services/api';
import { getFooterSize, getDesign } from '../../services/utils';
import { updateFooterStatus, updateHeaderStatus } from '../../actions';
import { connect } from 'react-redux';
import Card from '../../components/Card';
import styles from './styles.module.scss'

export const CardGrid = ({
	updateHeaderStatus,
	updateFooterStatus,
	idSection,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const _isMounted = useRef(null);

	async function _getContent(id) {
		setIsLoading(true);
		const result = await contentList(id);
		if (_isMounted.current) {
			try {
				if (result.data.success === false) {
					return;
				}
				setData(result.data.content);
			} finally {
				setIsLoading(false);
			}
		}
	}
	useEffect(() => {
		_isMounted.current = true;
		_getContent(idSection);
		updateFooterStatus('false');
		updateHeaderStatus(getDesign(idSection, 'general')?.showTopBar || 'true');
		return () => {
			_isMounted.current = false;
		};
	}, [updateFooterStatus, idSection, updateHeaderStatus]);

	return (
		<div
			className="cardGridContainer card-margin"
			style={{
				padding: 0,
				...getFooterSize(),
			}}
		>
			<div
				className="row"
				style={{ padding: 0, paddingBottom: 10, overflowX: 'hide', margin: 0 }}
			>
				{isLoading ? (
					<div className="loader loader-center loader-big loader-purple"></div>
				) : (
					data.map((item, index) => {
						if (
							Boolean(item.contentLocation) &&
							item.contentLocation !== 'undefined'
						) {
							return <Card item={item} idSection={idSection} key={index} />;
						}
					})
				)}
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
	updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(CardGrid);
