import { Trial, TextPlansStatus } from "../@model/ManagerTrial.model";

export const textPlansStatus = ( model: TextPlansStatus ) => {

    let textPlans:string | null = null;
    
    if( model.screen === 'RequestUpgrade' && model.trialStatusPlan ){
        if( model.element === 'h3' ){
            textPlans =   {
                [Trial.notStarted]: `Comece sua degustação`,
                [Trial.started]: `Você está na degustação do <span>${model.plan}</span> e ${model.deadline === 1 ? 'resta' : 'restam'  } <span>${model.deadline} ${model.deadline === 1 ? 'dia' : 'dias' }</span>.`,
                [Trial.finish]: `Seu período de degustação do <span>Plano ${model.plan}</span> se encerrou.`,
            }[model.trialStatusPlan]
        }
        if( model.element === 'p' ){
            textPlans =   {
                [Trial.notStarted]: `Desenvolva e aprimore seus próprios programas de treinamento, com acesso exclusivo a uma ampla variedade de bibliotecas, proporcionando um acervo rico em conteúdo e recursos de aprendizado.`,
                [Trial.started]: `Se você tem gostado da experiência, imagine ter a solução completa sem a preocupação do prazo da degustação. Contrate o <span>${model.plan}</span> agora mesmo e tenha tudo isso agora mesmo:`,
                [Trial.finish]: `Se você tem gostado da experiência, imagine ter a solução completa. Contrate o <span>${model.plan}</span> agora mesmo e tenha tudo isso agora mesmo:`,
            }[model.trialStatusPlan]
        }
        if( model.element === 'button' ){
            textPlans =   {
                [Trial.notStarted]: `${ model.trialPlan ? 'Começar a degustação' :  'Entrar em contato'  }`,
                [Trial.started]: `${ model.trialPlan ? 'Contratar plano' :  'Entrar em contato'  }`,
                [Trial.finish]: `${model.trialPlan ? 'Contratar plano' :  'Entrar em contato' }`,
            }[model.trialStatusPlan]
        }
        
    }   

    if( model.screen === 'SelectedPlanUpgrade' && model.trialStatusPlan ){
        if( model.element === 'h1' ){
            textPlans =   {
                [Trial.started]: `Você está na degustação do plano <span>${model.plan}</span> e ${model.deadline === 1 ? 'resta' : 'restam'} <span>${model.deadline} ${model.deadline === 1 ? 'dia' : 'dias'}</span>.`,
                [Trial.finish]: `Seu período de degustação do plano <span>${model.plan}</span> se encerrou.`,
            }[model.trialStatusPlan]
        }
        if( model.element === 'p' ){
            textPlans =   {
                [Trial.started]: `Se você tem gostado da experiência, imagine ter a solução completa sem a preocupação do prazo da degustação. Contrate o Plano <span>${model.plan}</span> e tenha tudo isso agora mesmo:`,
                [Trial.finish]: `Se você tem gostado da experiência, imagine ter a solução completa sem a preocupação do prazo da degustação. Contrate o <span>Plano ${model.plan}</span> e tenha tudo isso agora mesmo:`,
            }[model.trialStatusPlan]
        }
        if(model.element === 'requests-info'){
            textPlans =   {
                [Trial.started]: `<span>${model.requestsInfo} ${model.requestsInfo === 1 ? 'usuário' : 'usuários'}</span> da sua plataforma também ${model.requestsInfo === 1 ? 'está interessado' : 'estão interessados'} em utilizar a degustação.`,
                [Trial.finish]: ``,
            }[model.trialStatusPlan]
        }
        
    }

     if( model.screen === 'FormUpgade' && model.trialStatusPlan ){
        if( model.element === 'h3' ){
            textPlans =   {
                [Trial.notStarted]: `Se cadastre para a degustação de <span>${model.deadline} ${model.deadline === 1 ? 'dia' : 'dias' } </span> do <span>${model.plan}</span> !`,
                [Trial.started]: model.trialPlan ? `Você está na degustação do <span>${model.plan}</span> e ${model.deadline === 1 ? 'resta' : 'restam'  } <span>${model.deadline} ${model.deadline === 1 ? 'dia' : 'dias' }</span>.` : `Fale com o nosso time comercial pelo <span>${model.plan}</span>!`,
                [Trial.finish]: model.trialPlan ? 'Gostaria de mais tempo para o sua degustação?<br>Fale com nosso time comercial!' :  `Gostaria de mais tempo para o sua degustação? Fale com nosso time comercial!!`,
            }[model.trialStatusPlan]
        }
        if( model.element === 'button' ){
            textPlans =   {
                [Trial.notStarted]: model.trialPlan ? 'Iniciar a degustação' :  'Enviar Mensagem',
                [Trial.started]: model.trialPlan ? 'Contratar plano' :  'Entrar em contato',
                [Trial.finish]: model.trialPlan ? 'Enviar Mensagem' :  'Entrar em contato',
            }[model.trialStatusPlan]
        }
     }
    
    return textPlans;

}