import React, { useState, useEffect, useRef, useCallback } from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { listExternalCoursesServices } from './ExternalCourse.services';
import {
    ExternalCourseType,
    StatusExternalCourse,
    StatusObjExternalCourseType,
    StatusObjExternalCourseInit,
} from './ExternalCourse.types';
import ModalCancel from './modal/ModalCancel';
import ModalExternalCourse from './modal/ModalExternalCourse';

const ExternalCourseController = () => {
    const [status, setStatus] = useState<StatusObjExternalCourseType>(StatusObjExternalCourseInit);

    const [loading, setLoading] = useState(false);
    const [dataExternalCourse, setDataExternalCourse] = useState<ExternalCourseType[] | null>(null);

    const [itemOffset, setItemOffset] = useState(0);

    const itemsLenght = useRef(0);
    const itemsPerPage = 10;
    const pageNum = useRef(0);
    const pageCount = Math.ceil(itemsLenght.current / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % itemsLenght.current;

        pageNum.current = event.selected;
        setItemOffset(newOffset);
    };

    const callExternalCouses = useCallback(() => {
        const concatenateStatus = () => {
            // Definir os status possíveis
            const allStatuses = Object.values(StatusExternalCourse);

            // Se ALL ou nenhum status for selecionado, retornar todos os status
            if (status.ALL || !status) {
                return allStatuses.join(',');
            }

            // Filtrar os status ativos
            const activeStatuses = allStatuses.filter((statusKey) => status[statusKey]);

            // Retornar a string concatenada
            return activeStatuses.join(',');
        };

        setLoading(true);
        setDataExternalCourse(null);

        const params = {
            offset: itemOffset,
            limit: itemsPerPage,
            statuses: concatenateStatus(),
        };

        listExternalCoursesServices({
            params,
            itemsLenght,
            setDataExternalCourse,
            setLoading,
        });
    }, [itemOffset, status]);

    useEffect(() => {
        callExternalCouses();
    }, [callExternalCouses, itemOffset, status]);

    const handleChange = (event) => {
        setItemOffset(0);

        if (event.target.name === 'ALL') {
            // Definir os status possíveis
            const allStatuses = Object.values(StatusExternalCourse);
            const res = Object.fromEntries(allStatuses.map((status) => [status, event.target.checked]));

            setStatus(res as StatusObjExternalCourseType);
        } else {
            setStatus({ ...status, [event.target.name]: event.target.checked });
        }
    };

    // Função que atualiza o estado
    const resetItemOffset = () => {
        pageNum.current = 0;
        itemsLenght.current = 0;
        setItemOffset(0);
        callExternalCouses();
    };

    const callToAction = (item, cancel = null) => {
        if (item === null) {
            NiceModal.show(ModalExternalCourse, { resetItemOffset }); /// Criar um novo
        } else {
            if (cancel) {
                NiceModal.show(ModalCancel, { item, resetItemOffset });
            } else {
                NiceModal.show(ModalExternalCourse, { item, resetItemOffset });
            }
        }
    };

    return {
        handlePageClick,
        handleChange,
        pageCount,
        loading,
        dataExternalCourse,
        status,
        itemsLenght,
        pageNum,
        callToAction,
    };
};

export default ExternalCourseController;
