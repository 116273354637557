import { Button } from 'pctComponents/atoms';
import './style.scss';
import CloseIcon from '@material-ui/icons/Close';

import React, { useState, useEffect } from 'react';
import { getMenuData, renewlogin } from 'services/api';
import { Redirect } from 'react-router-dom';

export const exitSupportAccess = async ({ setLoadingAccessSupport = (boolean) => {} }) => {
    const { accessSupport } = getMenuData().loginInfo;
    if (accessSupport) {
        await renewlogin(accessSupport.currentUserToken);
        document.body.classList.remove('support-access-container');     
        setLoadingAccessSupport(true);
    }
};

function SupportAccess({ accessSupportData, resetHeader = () => {} }) {
    const [loadingAccessSupport, setLoadingAccessSupport] = useState(false);
    const {
        supportAccessUser: { name },
    } = accessSupportData;

    useEffect(() => { 
        document.body.classList.add('support-access-container'); 
    }, []);

    useEffect(()=>{
        resetHeader();
    }, [resetHeader])

    if (loadingAccessSupport) {
        return <Redirect to="/" />;
    }

    return (
        <div className="supportAccessHeader">
            <span>Você está fazendo acesso suporte para</span> <span>- {name}</span>
            <Button ico={CloseIcon} text="Sair" callEvent={() => exitSupportAccess({ setLoadingAccessSupport })} />
        </div>
    );
}

export default SupportAccess;
