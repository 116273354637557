import { createContentEnrollments } from '../services/api';
import { useHistory } from "react-router-dom";
import { getProfileInfo } from "../services/utils";

const useContentEnrollment = ({ collection, modalLoadingContentEnrollment, titleParent } ) => {

  const history = useHistory();
  
  const callContentEnrollment = () =>{

    const { idElement, idNodeEnrollment, idEnrollment } = collection;
    
    modalLoadingContentEnrollment.show({
      obj: {
        message: "Aguarde, iniciando conteúdo.",
        closeBtn: false,
        loadding: true,
      },
    });

    
    const objEnrollment = {
          idNodeEnrollment: Number(idNodeEnrollment),
          idEnrollment: Number(idEnrollment),
          idContentItem: Number(idElement),
          idUser: getProfileInfo().idUser,
          progress: 0,
          status: "IN_PROGRESS",
    };

    createContentEnrollments(objEnrollment)
      .then((response) => {

        modalLoadingContentEnrollment.remove();

        history.push(
          `/contentNode/${idElement}/${idNodeEnrollment}/${idEnrollment}/${
            getProfileInfo().idUser
          }/${"-"}`
        ); 
      })
      .catch((error) => {
        console.log(error);
      });   
  }

  return { callContentEnrollment }
}

export default useContentEnrollment;