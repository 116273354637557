import React from "react";
import { compose } from 'redux'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { getUploadList } from '../services/api'
import { getFooterSize, getDesign } from '../services/utils';
import { updateFooterStatus, updateHeaderStatus } from '../actions';

import Loader from '../components/Loader';
import '../assets/css/UploadList.scss'

class UploadList extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = true

    this.state = {
      data: [],
      errorOnLoading: false,
      isLoading: false,
    }
  }

  firstElementIsOfTypeImage(list) {
    const firstFileItemIsImage = this.checkFileIsImage(list[0]?.mimetype) 
    if (firstFileItemIsImage) return list

    const index = list.findIndex(({ mimetype }) => this.checkFileIsImage(mimetype))
    list.splice(0, 0, list.splice(index, 1)[0])

    if (list[0] === undefined) { return [] }

    return list
  }

  async _getUploadList() {
    this.setState({ isLoading: true })
    
    const pathParams =  new URLSearchParams(this.props.location.search)
    const parentSection = pathParams.get("idSection")
    const currentIdSection = this.props?.idSection || parentSection
    const result  = await getUploadList(currentIdSection);
    if (this._isMounted) {
			try {
        if(!result.data?.success){
          this.setState({ errorOnLoading: true, isLoading: false })
          return;
        }
        const list = this.firstElementIsOfTypeImage(result.data.data)
        this.setState({ data: list, isLoading: false })
		
			} catch (err) {
        this.setState({ errorOnLoading: true, isLoading: false })
			} 
		}
  
  }

  checkFileIsImage(file) {
    return file ? file.includes('image') : false
  }

  componentDidMount() {
    this._getUploadList()
    const { updateHeaderStatus, updateFooterStatus, idSection } = this.props
    const pathParams =  new URLSearchParams(this.props.location.search)
    const parentSection = pathParams.get("idSection")
    const currentIdSection = idSection || parentSection   
    updateFooterStatus(getDesign(currentIdSection, 'general')?.showBottomMenu || 'true')
    updateHeaderStatus(getDesign(currentIdSection, 'general')?.showTopBar || 'true')
  }
  componentWillUnmount(){
    this._isMounted = false;
  }
  render() {
    const { data: uploadList, errorOnLoading, isLoading } = this.state
    const { idSection } = this.props
    const pathParams =  new URLSearchParams(this.props.location.search)
    const parentSection = pathParams.get("idSection")
    const currentIdSection = idSection || parentSection
    return (
      <div className="upload-list" style={{ ...getDesign(currentIdSection, 'general'), ...getFooterSize() }}>
        {
          isLoading ?
            <Loader />
            :
            errorOnLoading ?
              <div style={{ marginTop: 280, textAlign: 'center' }}>Houve algum erro durante o carregamento das informações</div>
              :
              <ul className="upload-list__content">
                {
                  uploadList?.length > 0
                  ? uploadList.map(({ idUserUpload, title, name, description, mimetype, extension, url }, index) => (
                    <li key={index} className="upload-list__item">
                      <Link
                        key={idUserUpload}
                        className="upload-list__item--link"
                        to={`/userupload/${idUserUpload}?idSection=${currentIdSection}`}
                      >
                        <div className="upload-list__item--image">
                          {
                            this.checkFileIsImage(mimetype) ?
                              <img src={url} alt="" />
                              :
                              <i className={extension || 'generic'} />
                          }
                        </div>

                        <div className="upload-list__item--info">
                          <h3 className="title" style={getDesign(currentIdSection, 'itemText')}>{title}</h3>
                          <p className="author" style={getDesign(currentIdSection, 'itemDescription')}>por {name}</p>
                          <p style={getDesign(currentIdSection, 'itemDescription')}>{description}</p>
                        </div>
                      </Link>
                    </li>
                  ))
                  : <div className="no-content">
                      Nenhum conteúdo disponível
                    </div>
                }
              </ul>
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(UploadList);
