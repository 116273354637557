import "./style.scss";

import React from "react";

import { BsUnlock, BsLockFill } from 'react-icons/bs';


type Props = {
  text?:string
  status?: boolean | number
  css?: object
  className?: string
}

function Sequential({ status = true, css = {}, className = "" }: Props) {
  
  return (
      <div className={`pctAtom pctSequential ${className}`} style={css}>
          {(status) ? <BsUnlock className='lockIco'/> : <BsLockFill className='unlockIco'/>} 
      </div>
  )
}

export default Sequential
