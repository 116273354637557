import { useState, useEffect, useRef, useCallback } from 'react';

interface UseInfiniteScrollProps {
  limit: number;
  fetchItems: any;
  params?: any
}

interface UseInfiniteScrollReturn {
  items: any[];
  loading: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
}

const useInfiniteScroll = ({ limit, fetchItems, params = {} }: UseInfiniteScrollProps): UseInfiniteScrollReturn => {
  const [items, setItems] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const loadMoreItems = async () => {
    setLoading(true);
   
    if(params){
        const newItensInList = await fetchItems({offset, limit, ...params});
        setItems(prevItems => [...prevItems, ...newItensInList.data.result]);
        setTotalRecords(newItensInList.data.totalRecords);
        setOffset(prevOffset => prevOffset + limit);
        setLoading(false);
    }
  };

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && !loading) {

        if(items.length < totalRecords)
            loadMoreItems();
      }
    }
  }, [loading, offset, limit, fetchItems]);


  useEffect(() => {
    const currentRef = containerRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      return () => currentRef.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    // Carrega o primeiro lote de itens quando o *hook* é montado
    if(params && items.length === 0){
        loadMoreItems();
    }
  }, []);

  return { items, loading, containerRef };
};

export default useInfiniteScroll;