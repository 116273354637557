import React, {  useState, useEffect } from "react";

import ReactPlayer from "react-player";

import { getMenuData } from '../../../services/api';

import { IoMdClose } from "react-icons/io";
import "./style.scss";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { Text } from "../../atoms";

type Props = {
  obj: any;
};

const ModalOnboard = NiceModal.create(({ obj }: Props) => {
  
  const modal = useModal();
  const { onBoardUrl } = getMenuData().loginInfo;

  // const [contentLocation, setContentLocation] = useState('https://be-br.wistia.com/medias/7ik7403m3f');
 
  const closed = (e) => {
    modal.remove();
    obj.closedModal(e);
  };

  return (
    <div className="ModalOnboard">
      <div className="modal-body-pct">
        
        <div className="player-wrapper-top">
          {/* <Text component="h1" text={`Onboard`} /> */}
          <button className="closeBtn" onClick={ (e) => closed(e) }>
            <IoMdClose />
          </button>
        </div> 

        <iframe className="iframeOnBoard" src={onBoardUrl} title="OnBoard" />

      </div>
    </div>
  );
});

export default ModalOnboard;
