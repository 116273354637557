import "./style.scss";
import React, { useState, useRef } from "react";
import useCards from "../../../pctHooks/useCards";
import { Label, Text, Mandatory, PinPct } from "../../atoms";
import { BsLockFill } from "react-icons/bs";
import { getContentTypeLabel } from "../../../services/utils";
import ModalLoadingInfo from "../../modals/ModalLoadingInfo";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { connect } from "react-redux";
import { License } from "reducers/license";
import usePlan from "pctHooks/usePlan";
import { OriginNavigation } from "pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model";

type Props = {
  collection: any;
  contentCollection: any;
  nodeEnrollment?: any;
  prevItem: any;
  expandButton?: any;
  onClick?: any;
  closeMap?: any;
  isCurrentChild?: any;
  forceCurrentComplete?: any;
  planUpgrade: License;
};

const CardBlockMap = ({
  collection,
  onClick,
  contentCollection,
  prevItem,
  expandButton,
  nodeEnrollment,
  closeMap,
  isCurrentChild,
  forceCurrentComplete,
  planUpgrade,
}: Props) => {
  const setProgressStateRef = useRef(null as any);
  const progressInfoRef = useRef(null as any);
  const objEnrollment = useRef(null as any);
  const [currentCollection, setCurrentCollection] = useState(collection);

  const modalLoading = useModal("ModalLoadingInfo");
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  const { openModalUpgrade } = usePlan();
  let {
    contentEnrollment,
    questionnaire,
    elementType,
    idContentType,
    idElement,
    mandatory,
    sequential,
    nodeCode,
    title,
    label,
  } = currentCollection;

  objEnrollment.current = contentEnrollment
    ? contentEnrollment
    : nodeEnrollment;

  let isCurrentContent = false;
  const isExpanded = useRef(false);

  if (
    contentEnrollment &&
    contentCollection.hasOwnProperty("contentEnrollment")
  ) {
    if (!contentCollection.contentEnrollment) {
      isCurrentContent =
        contentCollection.currentItem.idContentItem ===
          contentEnrollment.idContentItem &&
        contentCollection.currentItem.idNodeEnrollment ===
          contentEnrollment.idNodeEnrollment;
    } else {
      isCurrentContent =
        contentCollection.contentEnrollment.idContentItem ===
          contentEnrollment.idContentItem &&
        contentCollection.contentEnrollment.idNodeEnrollment ===
          contentEnrollment.idNodeEnrollment;
    }
  } else if (
    questionnaire &&
    contentCollection.hasOwnProperty("idQuestionnaire")
  ) {
    isCurrentContent =
      questionnaire.idQuestionnaire === contentCollection.idQuestionnaire &&
      questionnaire.idNodeEnrollment === contentCollection.idNodeEnrollment;
  } else if (
    contentEnrollment &&
    contentCollection.hasOwnProperty("objScorm")
  ) {
    isCurrentContent =
      contentCollection.rootContent.idContentItem ===
        contentEnrollment.idContentItem &&
      contentCollection.rootContent.idNodeEnrollment ===
        contentEnrollment.idNodeEnrollment;
  } else if (
    contentEnrollment &&
    contentCollection.hasOwnProperty("currentContentCollection")
  ) {
    //QUANDO CLICA PARA EXPANDIR OS FILHOS
    if (contentCollection.currentContentCollection.contentEnrollment) {
      //CONTEUDO NORMAL

      isCurrentContent =
        contentCollection.currentContentCollection.contentEnrollment
          .idContentItem === contentEnrollment.idContentItem &&
        contentCollection.currentContentCollection.contentEnrollment
          .idNodeEnrollment === contentEnrollment.idNodeEnrollment;
    } else if (
      contentCollection.currentContentCollection.hasOwnProperty("objScorm")
    ) {
      isCurrentContent =
        contentCollection.currentContentCollection.rootContent.idContentItem ===
          contentEnrollment.idContentItem &&
        contentCollection.currentContentCollection.rootContent
          .idNodeEnrollment === contentEnrollment.idNodeEnrollment;
    }
  } else if (
    questionnaire &&
    contentCollection.hasOwnProperty("currentContentCollection")
  ) {
    if (
      questionnaire &&
      contentCollection.currentContentCollection.hasOwnProperty(
        "idQuestionnaire"
      )
    ) {
      //AVALIAÇÃO
      isCurrentContent =
        questionnaire.idQuestionnaire ===
          contentCollection.currentContentCollection.idQuestionnaire &&
        questionnaire.idNodeEnrollment ===
          contentCollection.currentContentCollection.idNodeEnrollment;
    }
  }

  var currentLabel = label
    ? label
    : idContentType
    ? getContentTypeLabel(idContentType)
    : collection.elementType === "EVALUATION"
    ? "Avaliação"
    : "Conteúdo";

  const currentCollectionToCard = {
    data: {
      contentEnrollment: contentEnrollment,
      nodeEnrollment: nodeEnrollment,
      questionnaire: questionnaire,
      contentLocation: null,
      title: title,
      label: currentLabel,
      idContentType: idContentType,
      idEnrollment: objEnrollment.current
        ? objEnrollment.current.idEnrollment
        : null,
      idNodeEnrollment: objEnrollment.current
        ? objEnrollment.current.idNodeEnrollment
        : null,
    },

    elementType: elementType,
    idContentType: idContentType,
    idBlockElement: null,
    idElement: idElement,
    idEnrollment: objEnrollment.current
      ? objEnrollment.current.idEnrollment
      : null,
    idNodeEnrollment: objEnrollment.current
      ? objEnrollment.current.idNodeEnrollment
      : null,
    prerequisitesMet: null,
  };

  const {
    handleCardMouseEvent,
    callNodeEnrollment,
    modalLoadingNodeEnrollment,
  } = useCards({
    id: objEnrollment.current ? objEnrollment.current.idEnrollment : null,
    collection: currentCollectionToCard,
    dataParent: currentCollectionToCard,
    setProgressStateRef,
    progressInfoRef,
  });

  let grade: any;
  let finishDate: any;

  if (objEnrollment.current) {
    grade = objEnrollment.current.grade;
    finishDate = objEnrollment.current.finishDate;
  } else {
    objEnrollment.current = {};
  }

  let prevHasCompleted = true;


  if (prevItem) {
    if (prevItem.elementType === "CONTENT" && prevItem.contentEnrollment) {
      if (prevItem.contentEnrollment.status !== "COMPLETED") {
        prevHasCompleted = false;
      }
    } else if (
      prevItem.elementType === "CONTENT" &&
      !prevItem.contentEnrollment
    ) {
      prevHasCompleted = false;
    } else if (prevItem.elementType === "NODE" && prevItem.nodeEnrollment) {
      if (prevItem.nodeEnrollment.status !== "COMPLETED") {
        prevHasCompleted = false;
      }
    } else if (prevItem.elementType === "NODE" && !prevItem.nodeEnrollment) {
      prevHasCompleted = false;
    } else if (prevItem.elementType === "EVALUATION") {
      if (prevItem.questionnaire) {
        if (!prevItem.questionnaire.finishDate) {
          prevHasCompleted = false;
        }
      } else {
        prevHasCompleted = false;
      }
    }
  }
  // forceCurrentComplete
  // isCurrentContent
  const prerequisites = sequential?.toString() === "1" && !prevHasCompleted ? true : false;

  let concluidedText = "";

  let progress = 0;
  let status = "NOT_ATTEMPTED";

  if (collection.elementType === "NODE" && collection.nodeEnrollment) {
    progress = collection.nodeEnrollment.progress;
    status = collection.nodeEnrollment.status;
  } else if (
    collection.elementType === "CONTENT" &&
    collection.contentEnrollment
  ) {
    if (collection.idContentType === 26) {
      //SCORM
      progress = collection.contentEnrollment.startDate ? 50 : 100;
      status = collection.contentEnrollment.status;
    } else {
      progress = collection.contentEnrollment.progress;
      status = collection.contentEnrollment.status;
    }
  } else if (
    collection.elementType === "EVALUATION" &&
    collection.questionnaire
  ) {
    progress = !collection.questionnaire.finishDate ? 50 : 100;
    status = progress === 100 ? "COMPLETED" : "INCOMPLETE";
  }

  progress = Math.round(progress);

  if (forceCurrentComplete && isCurrentContent) {
    concluidedText =
      "<span style='color:green'><i class='fa fa-check' aria-hidden='true'></i> Concluído</span>";
  } else if (status === "COMPLETED") {
    concluidedText =
      "<span style='color:green'><i class='fa fa-check' aria-hidden='true'></i> Concluído</span>";
  } else if (status === "NOT_ATTEMPTED" || status === undefined) {
    concluidedText = "Não iniciado";
  } else if (status === "IN_PROGRESS") {
    concluidedText = `Em andamento ${onClick ? progress + "%" : ""}`;
  } else if (status === "INCOMPLETE") {
    concluidedText = "Em andamento";
  } else if (status === "PASSED") {
    concluidedText =
      "<span style='color:green'><i class='fa fa-check' aria-hidden='true'></i> Aprovado<span>";
  } else if (status === "FAILED") {
    concluidedText =
      "<span style='color:red'><i class='fa fa-xmark'></i> Reprovado<span>";
  }

  const itemClass = `cardBlockMap ${prerequisites ? "disabledCard" : ""}`;
  const _onFinishedNodeEnrollment = (enrollmentResult) => {
    let tempObj = currentCollection;
    tempObj.nodeEnrollment = {
      idNodeEnrollment: enrollmentResult.data.newId,
      idEnrollment: nodeEnrollment.idEnrollment,
      idTeam: nodeEnrollment.idTeam,
      idUser: nodeEnrollment.idUser,
    };

    setCurrentCollection(tempObj);
    onClick(enrollmentResult);
  };

  if (isCurrentChild && !isExpanded.current) {
    isExpanded.current = true;
    setTimeout(() => {
      onClick(true);
    }, 100);
  }



  return (
    <div
      className={itemClass}
      onClick={(e) => {
        if (!nodeCode) {
          //CASO O CONTEÚDO ATUAL SEJA UM SCORM PRECISO DAR UM SCORM FINISH NELE ANTES DE AVANÇAR PARA O PRÓXIMO.
          const currentContent = contentCollection.currentContentCollection
            ? contentCollection.currentContentCollection
            : contentCollection;

          if (currentContent.hasOwnProperty("callLmsFinish")) {
            modalLoading.show({
              obj: {
                loadding: true,
                closeBtn: false,
                message: "Aguarde, salvando dados do conteúdo!",
                className: "navigationModal",
              },
            });

            currentContent.callLmsFinish(false);

            setTimeout(() => {
              handleCardMouseEvent(e);
              modalLoading.remove();
            }, 2000);
          } else {
            handleCardMouseEvent(e);
          }
        } else {
          if (!currentCollection.nodeEnrollment) {
            const dataParent = {
              node: {
                nodeEnrollment: nodeEnrollment,
              },
            };

            const collectionToNodeEnrollment = {
              data: {
                nodeEnrollment: { enrollment: nodeEnrollment },
                nodeCode: currentCollection.nodeCode,
              },
            };
            callNodeEnrollment({
              collection: collectionToNodeEnrollment,
              modalLoadingNodeEnrollment: modalLoadingNodeEnrollment,
              _parent: dataParent,
              _onFinished: _onFinishedNodeEnrollment,
              doestGoToLevelPage: true,
            });
          } else {
            onClick();
          }
        }

        if (closeMap) {
          if (currentCollection.elementType !== "NODE") {
            closeMap(e);
          }
        }

        e.stopPropagation();
      }}
    >
      <PinPct
        text={"Você está aqui!"}
        className={`pin ${isCurrentContent ? "current" : ""}`}
      />
      <Label text={currentLabel} />

      <div className={`infosContainer`}>
        <div className="headerInfos">
          {title && <Text component="p" text={title} />}
          {/* <ProgressBar percentage={progress}/> */}
          {/* {description && <Text component="p" text={description} />} */}
        </div>

        <div className="footerInfos">
          {concluidedText && <Text component="small" text={concluidedText} />}
          {/* {numberOfAccess > 0  && <Visualization text={numberOfAccess} />}
          {workload && <Duration text={timeToMinutes(workload)} timeFormat={"seconds"} />}
          {grade && <Grade grade={grade} masterScore={masteryScore} />} */}
        </div>
      </div>

      <div className="rightInfos">
        {mandatory ? <Mandatory /> : <></>}
        {prerequisites === false ? <></> : <BsLockFill className="lock" />}
        {expandButton}
        {/* {prerequisites == false ? <BsUnlock className="lock"/> : <BsLockFill className="lock" />} */}
      </div>
    </div>
  );
};

const mapStateToProps = (store: { planUpgrade: License }) => ({
  planUpgrade: store.planUpgrade,
});

export default connect(mapStateToProps)(CardBlockMap);
