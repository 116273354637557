import "./style.scss";

import React, { useState } from "react";

import { Text } from "pctComponents/atoms";
import { CardListing, CardGrid } from "pctComponents/organisms";
import { CarrousselView } from "pctComponents/molecules";

import { FaAngleRight } from "react-icons/fa6";

import useCardsFormat from "pctHooks/useCardsFormat";

const Carroussel = (props) => {
  const { data, _parentData } = props;
  const { className = "", parameters } = data;
  const { title, description } = parameters;
  const [openGrid, setOpenGrid] = useState(false);

  const { displayColumnsElementsCalc } = useCardsFormat(parameters);
  const activeVerTodos = data.elements.length > displayColumnsElementsCalc;

  return (
    <>
      <div
        className={`pctOrganism pctCarroussel ${
          description ? "pctCarrousselInText" : ""
        } ${className}`}
      >
        <div
          className={`pctControlBase ${
            title ? "pctControlBaseWithTitle" : ""
          }  ${title || activeVerTodos ? "pctControlLine" : ""}`}
        >
          {title && (
            <Text component="h2" text={title} className="titleSection" />
          )}
          {activeVerTodos && (
            <button onClick={(e) => setOpenGrid(true)}>
              <Text component="p" text={"Ver todos"} className="" />
              <FaAngleRight />
            </button>
          )}
        </div>
        {description && (
          <Text component="p" text={description} className="textSection" />
        )}
        <CarrousselView>
          {CardListing({ data: data, _parentData: _parentData })}
        </CarrousselView>

        {openGrid && (
          <CardGrid
            data={data}
            parentData={_parentData}
            setOpenGrid={setOpenGrid}
            openGrid={openGrid}
          />
        )}
      </div>
    </>
  );
};

export default Carroussel;
