import "./styles.scss";

import React from "react";

const Switch = (props) => {
  return (
    <div className="pctFormControlLabelSwitch">
      <input
        disabled={props.disabled}
        checked={props.checked}
        type="checkbox"
        onChange={props.onChange}
        name={props.name}
        id={props.name}
      />
      {props.label ? (
        <label htmlFor={props.name} label-text={props.label} />
      ) : null}
    </div>
  );
};

export default Switch;
