type Props = {};

const useBackControll = ({}) => {
    const setNavigationHistory = (routerRef) => {
        let navigationHistoryStorage = localStorage.getItem('navigationHistory');
        let navigationHistory = navigationHistoryStorage
            ? JSON.parse(navigationHistoryStorage)
            : { history: ['/'], redirectedFrom: null, isfluidNavigation: null, lastPage: null, currentPage: null };

        const currentRouterRef = routerRef.pathname.split('/')[1];

        navigationHistory.lastPage = navigationHistory.currentPage;
        navigationHistory.currentPage = currentRouterRef;
        localStorage.setItem('navigationHistory', JSON.stringify(navigationHistory));

        if (routerRef.pathname === '/') {
            localStorage.setItem(
                'navigationHistory',
                JSON.stringify({ history: ['/'], redirectedFrom: null, isfluidNavigation: null, lastPage: null, currentPage: null }),
            );
        } else if (currentRouterRef === 'searchResult') {
            localStorage.setItem(
                'navigationHistory',
                JSON.stringify({
                    history: ['/'],
                    redirectedFrom: null,
                    isfluidNavigation: null,
                    lastPage: navigationHistory.lastPage,
                    currentPage: navigationHistory.currentPage,
                }),
            );
        } else if (
            navigationHistory.history.indexOf(routerRef.pathname) === -1 &&
            routerRef.pathname !== '/login' &&
            routerRef.pathname !== '/landing-page' &&
            currentRouterRef !== 'searchResult' &&
            !isfluidNavigationFn()
        ) {
            // TRATAMENTO PARA QUANDO UM NÓ FILHO VEM DA PESQUISA NÃO PASSANDO PELO PAI
            if (navigationHistory.lastPage === 'searchResult' && routerRef.pathname.split('/').length === 4) {
                const tempArray = routerRef.pathname.split('/');
                // tempArray.shift();
                tempArray.pop();

                navigationHistory.history.push(tempArray.join('/'));
            }

            navigationHistory.history.push(routerRef.pathname);

            localStorage.setItem('navigationHistory', JSON.stringify(navigationHistory));
        }
    };

    const backControllFn = (history) => {
        const navigationHistoryStorage = localStorage.getItem('navigationHistory');
        const navigationHistory = navigationHistoryStorage
            ? JSON.parse(navigationHistoryStorage)
            : { history: ['/'], redirectedFrom: null, isfluidNavigation: null, lastPage: null, currentPage: null };
        let tempObj: any;

        if (!isfluidNavigationFn()) {
            tempObj = navigationHistory.history.slice(0, navigationHistory.history.length - 1);

            if (navigationHistory.history.length <= 1) {
                history.goBack();
            } else {
                history.push(navigationHistory.history[navigationHistory.history.length - 2]);
            }
        } else if (isfluidNavigationFn() && !navigationHistory.redirectedFrom) {
            tempObj = navigationHistory.history;
            history.push(navigationHistory.history[navigationHistory.history.length - 1]);
        } else if (isfluidNavigationFn() && navigationHistory.redirectedFrom) {
            // console.log("TRATAR OS QUE SOFREM REDIRECT NA NAVEGAÇÃO FLUÍDA");
            const redirectIndex = navigationHistory.history.indexOf(navigationHistory.redirectedFrom[0]);

            if (redirectIndex !== -1) {
                tempObj = navigationHistory.history.slice(0, redirectIndex);
                history.push(tempObj[tempObj.length - 1]);
            } else {
                tempObj = navigationHistory.history;
                history.push(navigationHistory.history[navigationHistory.history.length - 1]);
            }
        }

        localStorage.setItem(
            'navigationHistory',
            JSON.stringify({
                history: tempObj,
                redirectedFrom: null,
                isfluidNavigation: false,
                lastPage: navigationHistory.lastPage,
                currentPage: navigationHistory.currentPage,
            }),
        );
    };

    const redirectFn = (history) => {
        const pathLocation = history.location.pathname;
        const navigationHistoryStorage = localStorage.getItem('navigationHistory');
        const navigationHistory = navigationHistoryStorage ? JSON.parse(navigationHistoryStorage) : {};
        let tempObj = {
            history: navigationHistory.history,
            redirectedFrom: navigationHistory.redirectedFrom ? navigationHistory.redirectedFrom : [],
            isfluidNavigation: navigationHistory.isfluidNavigation,
            lastPage: navigationHistory.lastPage,
            currentPage: navigationHistory.currentPage,
        };

        tempObj.redirectedFrom.push(pathLocation);
        localStorage.setItem('navigationHistory', JSON.stringify(tempObj));
    };

    const cameFrom = () => {
        const navigationHistoryStorage = localStorage.getItem('navigationHistory') as any;
        let rtFrom;

        if (navigationHistoryStorage) {
            rtFrom = JSON.parse(navigationHistoryStorage).lastPage;
        }

        return rtFrom;
    };

    const lastRecord = () => {
        const navigationHistoryStorage = localStorage.getItem('navigationHistory');
        let lastRec = null;

        if (navigationHistoryStorage) {
            const navigationHistory = JSON.parse(navigationHistoryStorage);
            lastRec = navigationHistory.history[navigationHistory.history.length - 1];
        }

        return lastRec;
    };

    const isfluidNavigationFn = (bl: any = null) => {
        const navigationHistoryStorage = localStorage.getItem('navigationHistory');
        const navigationHistory = navigationHistoryStorage ? JSON.parse(navigationHistoryStorage) : {};
        let tempObj: any;

        if (bl == null) {
            return navigationHistory.isfluidNavigation;
        } else {
            tempObj = {
                history: navigationHistory.history,
                redirectedFrom: navigationHistory.redirectedFrom,
                isfluidNavigation: bl,
                lastPage: navigationHistory.lastPage,
                currentPage: navigationHistory.currentPage,
            };
            localStorage.setItem('navigationHistory', JSON.stringify(tempObj));
        }
    };

    return {
        setNavigationHistory,
        backControllFn,
        redirectFn,
        isfluidNavigationFn,
        cameFrom,
        lastRecord,
    };
};

export default useBackControll;
