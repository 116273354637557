import * as React from 'react'
import { useLocation } from "react-router-dom";
import { getColors } from '../services/utils'
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import UserIcon from '../assets/images/no-gender-user.svg';

const PerfilUsusario = () => {

    
    const rota = useLocation().search;
    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            justifyContent: "center",
            "& > *": {
                margin: theme.spacing(1)
            }
        },
        large: {
            width: theme.spacing(10),
            height: theme.spacing(10)
        }
    }));

    const classes = useStyles();
    const photo = new URLSearchParams(rota).get('photo');
    const nome = new URLSearchParams(rota).get('nome');
    const email = new URLSearchParams(rota).get('email');
    const hasPhoto = Boolean(photo) && photo !== 'null';
    return (
        <div className="profile-edit-container">
            <div className="profile-top-bar" style={{ paddingTop: 15, backgroundColor: getColors('menu').fourthColor }}>
                <div style={{ fontSize: 24, marginBottom: 15, fontWeight: 500, color: 'white', textAlign: 'center' }}> Perfil Usuário </div>
                <div className={classes.root}>
                    <Avatar
                        alt="Remy Sharp"
                        src={hasPhoto ? photo : UserIcon} style={{ backgroundColor: getColors('menu').firstColor }}
                        className={classes.large}

                    />
                </div>
                {
                    <div className="profile-edit">
                        <div className="profile-edit-item">
                            <span>Nome</span>
                            <input
                                disabled
                                defaultValue={nome}
                                className="form-control edit-login-input button-disabled"
                                style={{ marginBottom: 10  }}
                            />
                        </div>
                        <div className="profile-edit-item">
                            <span>E-mail</span>
                            <input
                                defaultValue={email}
                                className="form-control edit-login-input"

                                style={{ marginBottom: 10 }}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default PerfilUsusario