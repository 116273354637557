import { UPDATE_COURSES_IN_PROGRESS } from '../actions/actionTypes';


const initialState = {
    courses_in_progress: { newNode: null }
  };

export const coursesProgress = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_COURSES_IN_PROGRESS:
        return {
            ...state,
            courses_in_progress: action.courses_in_progress
        };
       
        default:
        return state;
    }
};