import './style.scss';

import React, { useState, useEffect, useContext } from 'react';
import { CreateEvaluationKnowledgeContext } from '../../../pctContexts/contextEvaluationKnowledge';
import { Button, Text } from 'pctComponents/atoms';
import { TbListCheck, TbArrowBackUp } from 'react-icons/tb';
import { LuTimer } from 'react-icons/lu';
import { GrLinkNext } from 'react-icons/gr';

import useTimeMoment from '../../../pctHooks/useTimeMoment';

const EvaluationKnowledgeIntroduction = () => {
    const { contextCurrentEvaluation, contextGoToEvaluationScreen, setEvaluationCurrentIndex } = useContext(
        CreateEvaluationKnowledgeContext,
    );

    const timeMoment = useTimeMoment({
        temp: contextCurrentEvaluation.parameters.timeLimit,
        format: 'hh __ mm __',
        dur: 's',
    });

    return (
        <div
            className={`ptcQuestionnaireIntroduction ${
                !contextCurrentEvaluation.description ? 'questionnaireIntroductionNoDescription' : ''
            }`}
        >
            <div className="questionnaireIntroductionBase">
                <div className="leftContent">
                    <Text component={'h1'} text={contextCurrentEvaluation.title} className="tittle" />

                    <div className="evaluationInfos">
                        <Text component={'p'} className="tittle" text="<strong>Informações importantes</strong>" />
                        <div className="evaluationInfosList">
                            <div className="listItem">
                                <TbListCheck />
                                <Text
                                    component={'p'}
                                    text={`Quantidade de questões: <strong>${contextCurrentEvaluation.parameters.questionAmount}</strong>`}
                                />
                            </div>
                            {contextCurrentEvaluation.parameters.timeLimit !== '0' && (
                                <div className="listItem">
                                    <LuTimer />
                                    <Text component={'p'} text={`Tempo de execução: <strong>${timeMoment}</strong>`} />
                                </div>
                            )}
                            {contextCurrentEvaluation.parameters.lockNavigation == 1 && (
                                <div className="listItem">
                                    <GrLinkNext className="icoNext" />
                                    <Text
                                        component={'p'}
                                        text="Só será possível avançar para as próximas questões ao responder uma a uma."
                                    />
                                </div>
                            )}
                            {contextCurrentEvaluation.parameters.maxTries && (
                                <div className="listItem">
                                    <TbArrowBackUp />
                                    <Text
                                        component={'p'}
                                        text={`Quantidade de tentativas: <strong>${contextCurrentEvaluation.parameters.maxTries}</strong>`}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="rightContent">
                    {contextCurrentEvaluation.description ? (
                        <div className="description">
                            <Text component={'p'} text={contextCurrentEvaluation.description} />
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="btnsIntro">
                        <Button
                            className={'button05'}
                            ico={GrLinkNext}
                            text="Iniciar avaliação"
                            callEvent={() => {
                                setEvaluationCurrentIndex(0);
                                contextGoToEvaluationScreen('PLAYER');
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EvaluationKnowledgeIntroduction;
