import "./style.scss";
import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { ButtonCertificate, Text } from "../../atoms";
import { PlaceholderTable } from "pctComponents/placehoader";

import { useFetch } from "pctHooks/useFetch";
import { MdUnfoldMore } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import { convertToBrDate, getProfileInfo } from "../../../services/utils";
import { getCertificate } from "../../../services/api";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalLoadingInfo from "../../modals/ModalLoadingInfo";
import AlertComponent from "../AlertComponent";

type Props = {
  userId: number;
};

const MyCertificates = ({ userId }: Props) => {
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  const modalLoading = useModal("ModalLoadingInfo");

  const filterAcessor = (columns, dataRow) => {
    switch (columns.accessor) {
      case "finishDate":
        return convertToBrDate(dataRow[columns.accessor]);

      case "validityDate":
        return convertToBrDate(dataRow[columns.accessor]);

      case "grade":
        return !dataRow[columns.accessor] && dataRow[columns.accessor] != 0
          ? "-"
          : dataRow[columns.accessor];

      case "status":
        return statusTranslate(dataRow[columns.accessor]);

      default:
        return dataRow[columns.accessor];
    }
  };

  const renderRows = (dataRow: any) => {
    let _row = columns.map((colum: any, k) => {
      return (
        <td key={k}>
          <div>{filterAcessor(columns[k], dataRow)}</div>
        </td>
      );
    });

    return _row;
  };

  const [sortType, setSortType] = useState("");

  // COMPLETED, EXPIRED, FAILED
  const columns = [
    {
      header: "Título",
      accessor: "title",
    },
    {
      header: "Conclusão",
      accessor: "finishDate",
    },
    {
      header: "Expira em",
      accessor: "validityDate",
    },

    // {
    //   header: "Aproveitamento",
    //   accessor: "grade",
    // },
  ];

  const statusTranslate = (currentStatusTranslate) => {
    const arrStatus = [
      "NOT_ATTEMPTED",
      "IN_PROGRESS",
      "COMPLETED",
      "PASSED",
      "FAILED",
      "EXPIRED",
    ];
    const arrStatusTranslate = [
      "Não iniciado",
      "Em progresso",
      "Concluído",
      "Aprovado",
      "Reprovado",
      "Expirado",
    ];
    const translateWordIndex = arrStatus.indexOf(currentStatusTranslate);
    let translatedStatus = "-";

    if (translateWordIndex) {
      translatedStatus = arrStatusTranslate[translateWordIndex];
    }

    return translatedStatus;
  };

  const { data, error } = useFetch({
    url: `learning/students/${userId}/history/?onlyWithCertificate=1`,
  });

  if (error) return <p>There is an error.</p>;
  if (!data) return <PlaceholderTable rowsNumber={10} columnsNumber={3} />;

  let fetchData: any = data["history"];
  let currentData: any = data["history"];

  fetchData = fetchData.filter(
    (item) =>
      item.idCertificate &&
      (item.enrollmentStatus == "COMPLETED" ||
        item.enrollmentStatus == "PASSED")
  );
  currentData = currentData.filter(
    (item) =>
      item.idCertificate &&
      (item.enrollmentStatus == "COMPLETED" ||
        item.enrollmentStatus == "PASSED")
  );

  if (sortType !== "") {
    currentData = fetchData.sort((a, b) =>
      a[sortType] > b[sortType] ? 1 : -1
    );
  }

  return (
    <div className="ptcCertificates">
      {fetchData.length === 0 && (
        // <Text component="h3" text="Sem certificados." className='noCertificate' />
        <AlertComponent message="Sem certificados." fullpage={false} />
      )}

      {fetchData.length > 0 && (
        <>
          <div className="mobTable">
            {currentData.map((item, j) => {
              const handleCertificateMouseEvent = (
                e: React.MouseEvent<HTMLElement>
              ) => {
                modalLoading.show({
                  obj: {
                    message: "Aguarde, gerando e baixando o certificado!",
                    closeBtn: false,
                    loadding: true,
                  },
                });

                const objDownload = {
                  idUser: getProfileInfo().idUser,
                  idEnrollment: item.idEnrollment,
                  // idNodeEnrollment: item?.nodeEnrollment?.idNodeEnrollment
                };

                getCertificate(objDownload)
                  .then((response) => {
                    setTimeout(() => {
                      modalLoading.remove();
                    }, 2000);

                    const url = window.URL.createObjectURL(
                      new Blob([response.data], { type: "application/pdf" })
                    );
                    const link = document.createElement("a");

                    link.href = url;
                    link.download = `${item.title?.replace(
                      /[^\w]/g,
                      ""
                    )}_certificate.pdf`;
                    link.click();
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                e.stopPropagation();
              };

              return (
                <Fragment key={j}>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <p>{item.title}</p>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <strong>Conclusão:</strong>{" "}
                            {convertToBrDate(item.finishDate)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <strong>Expira em:</strong>{" "}
                            {convertToBrDate(item.validityDate)}
                          </div>
                        </td>
                      </tr>

                      

                      {/* <tr>  
                            <td>
                              <div>
                              <strong>Aproveitamento:</strong> {!item.grade && item.grade != 0 ? "-": item.grade}
                              </div>
                            </td>
                          </tr> */}

                      <tr>
                        <td>
                          <div>
                            <strong>Baixar certificado:</strong>
                          </div>
                          <div className="actions">
                            {item.idCertificate &&
                              (item.enrollmentStatus == "COMPLETED" ||
                                item.enrollmentStatus == "PASSED") && (
                                <ButtonCertificate
                                  onClick={handleCertificateMouseEvent}
                                  verifyCompletedStatus={true}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Fragment>
              );
            })}
          </div>

          <div className="deskTable">
            <table>
              <thead>
                <tr>
                  {columns.map((colum: any, i) => {
                    let { header, accessor } = colum;
                    return (
                      <th key={i}>
                        <button
                          type="button"
                          onClick={() => {
                            setSortType(accessor);
                          }}
                        >
                          <p>{header}</p> <MdUnfoldMore />
                        </button>
                      </th>
                    );
                  })}

                  <th>
                    <p>Baixar certificado</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {currentData.map((item, j) => {
                  const handleCertificateMouseEvent = (
                    e: React.MouseEvent<HTMLElement>
                  ) => {
                    modalLoading.show({
                      obj: {
                        message: "Aguarde, gerando e baixando o certificado!",
                        closeBtn: false,
                        loadding: true,
                      },
                    });

                    const objDownload = {
                      idUser: getProfileInfo().idUser,
                      idEnrollment: item.idEnrollment,
                      // idNodeEnrollment: item?.nodeEnrollment?.idNodeEnrollment
                    };

                    getCertificate(objDownload)
                      .then((response) => {
                        setTimeout(() => {
                          modalLoading.remove();
                        }, 2000);

                        const url = window.URL.createObjectURL(
                          new Blob([response.data], { type: "application/pdf" })
                        );
                        const link = document.createElement("a");

                        link.href = url;
                        link.download = `${item.title?.replace(
                          /[^\w]/g,
                          ""
                        )}_certificate.pdf`;
                        link.click();
                      })
                      .catch((error) => {
                        console.log(error);
                      });

                    e.stopPropagation();
                  };

                  return (
                    <tr key={j}>
                      {renderRows(item)}
                      <td>
                        <div className="actions">
                          {item.idCertificate &&
                            (item.enrollmentStatus == "COMPLETED" ||
                              item.enrollmentStatus == "PASSED") && (
                              <ButtonCertificate
                                onClick={handleCertificateMouseEvent}
                                verifyCompletedStatus={true}
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default MyCertificates;
