import { Label } from "pctComponents/atoms"
import React from "react"
import { FaShare } from "react-icons/fa"

import './style.scss'
import NiceModal from "@ebay/nice-modal-react"
import ModalShareLink from "pctComponents/modals/ModalShareLink"
import { getProfileInfo } from "services/utils"
import { postLogsShare } from "services/api"
type Props  = {
  className?: string,
  link: string,
  idUser: number,
  idContent: number
  callEvent?: (e?) => void
}

const ShareButton =  ({className, link, callEvent, idContent, idUser}: Props) => {
  
  const openModalShare = (e) => {
    NiceModal.show(ModalShareLink, {
      obj: {
        closedModal: (e) => {
          console.log(e)
        },
        link,
        idContent,
        idUser
      },      
    })
    if(callEvent)
      callEvent(e)
    updateLog()
  }

  const updateLog = async () => {
    if(idContent && idUser){
      try{
        const data = {
          
            origin: "content/share/click",
            message: {idUser:idUser,idContent:idContent}
          
        }
         postLogsShare(data);

      }
      catch(e){
        console.log(e)
      }
    }
  }
  return (
    <div className={`share-button ${className}`} onClick={openModalShare}>
        <FaShare />
        <small >Compartilhar</small>
    </div>
  )

}

export default ShareButton