import React, { useEffect, useState } from 'react';

import { CarrousselView } from 'pctComponents/molecules';
import { Text } from 'pctComponents/atoms';

import CardUpgrade from '../CardUpgrade';

import { useManagerTrial } from '../../context/contextManagerTrial';
import { textPlansStatus } from '../../utils/TextPlansStatus';
import { PlaneProps, TextPlansStatus } from '../../@model/ManagerTrial.model';

const RequestUpgrade = () => {
    const [showBtnDetail, setShowBtnDetail] = useState<boolean>(false);
    const [showDetail, setshowDetail] = useState<boolean>(false);

    const { listPlans, currentPlan = {}, trialStatusPlan } = useManagerTrial();

    useEffect(() => {
        listPlans?.map((elem, index) => {
            if (elem.products && elem.products.length > 0) {
                setShowBtnDetail(true);
            }
            return elem;
        });
    }, [listPlans]);

    let planText: TextPlansStatus = {
        screen: 'RequestUpgrade',
        trialStatusPlan: trialStatusPlan,
    };

    if (currentPlan) {
        const { name, deadline } = currentPlan as PlaneProps;
        planText = {
            ...planText,
            plan: `Plano ${name}`,
            deadline,
        };
    }

    return (
        <>
            <div className="title">
                <Text
                    component={'h3'}
                    text={textPlansStatus({
                        ...planText,
                        element: 'h3',
                    })}
                />
                <Text
                    component={'p'}
                    text={textPlansStatus({
                        ...planText,
                        element: 'p',
                    })}
                />
            </div>

            <div className={`carroussel`}>
                {listPlans && (
                    <CarrousselView
                        options={{
                            cellAlign: 'center',
                            selectedAttraction: 1,
                            friction: 1,
                            contain: true,
                            // groupCells: 3,
                        }}
                        reloadOnUpdate={true}
                    >
                        {listPlans.map((elem, index) => (
                            <CardUpgrade key={`card-upgrade-${elem.idRef}`} showDetail={showDetail} elem={elem} />
                        ))}
                    </CarrousselView>
                )}
            </div>
            {showBtnDetail && (
                <button className="showDetail" onClick={(e) => setshowDetail(!showDetail)}>
                    Ver {!showDetail ? 'mais' : 'menos'} detalhes
                </button>
            )}
        </>
    );
};

export default RequestUpgrade;
