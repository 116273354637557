import React, { useState, useEffect, useRef, Fragment } from "react";

import "./style.scss";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import ReactPaginate from "react-paginate";

import { Text, Button, Preloader } from "../../atoms";
import { IoMdClose } from "react-icons/io";
import { TbSearch } from "react-icons/tb";
import { FaCheck } from "react-icons/fa6";
import { LiaPlusSolid } from "react-icons/lia";

import { getProfileInfo } from "services/utils";

import {
  getLearningTags,
  getLearningTagsUser,
  postLearningTagsUser,
} from "services/api";

type Props = {
  obj: any;
};

const ModalTopicsOfInterest = NiceModal.create(({ obj }: Props) => {
  const modal = useModal();

  const [loading, setLoading] = useState(true);

  const inputSearchRef = useRef<any>(null);
  const [searchText, setSearchText] = useState("");

  const [itemOffset, setItemOffset] = useState<number | null>(null);
  const itemsLenght = useRef(0);
  const pageNum = useRef(0);

  const itemsPerPage = 10;

  const [elements, setElements] = useState<any>([]);
  const [elementsActive, setElementsActive] = useState<any>([]);
  const pageCount = Math.ceil(itemsLenght.current / itemsPerPage);

  const idUser = getProfileInfo().idUser;

  const closed = (e: any = null) => {
    modal.remove();
    obj.closedModal(e);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % itemsLenght.current;

    pageNum.current = event.selected;
    setItemOffset(newOffset);
  };

  const callTagUser = () => {
    setLoading(true);
    getLearningTagsUser(idUser)
      .then((res) => {
        const tagsUser = res.data.tags.map((it) => {
          return it.tag;
        });
        setElementsActive(tagsUser);
        callSearch(tagsUser);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callSearch = (tagsUserCurrent) => {
    setLoading(true);
    const params = {
      searchString: searchText,
      offset: itemOffset || 0,
      limit: itemsPerPage,
    };

    getLearningTags(params)
      .then((res) => {
        itemsLenght.current = res.data.totalRecords;

        const tags = res.data.result.map((it) => {
          const statusTag =
            tagsUserCurrent.filter((elem) => elem === it).length > 0;

          return { tag: it, status: statusTag ? "check" : "noSelect" };
        });

        setElements(tags);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (itemOffset !== null) {
      callSearch(elementsActive);
    } else {
      callTagUser();
    }
  }, [itemOffset]);

  const goToSearchPageResult = () => {
    setItemOffset(0);
    pageNum.current = 0;
    callSearch(elementsActive);
  };

  const callSaveTags = (e) => {
    const dataTags = {
      tags: elementsActive,
    };

    setLoading(true);
    postLearningTagsUser(idUser, dataTags)
      .then((res) => {
        setLoading(false);
        closed();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ItemTag = ({ tag, status, it = { status: "" } }) => {
    const clickTag = (e) => {
      if (status === "noSelect") {
        setElementsActive([...elementsActive, tag]);
        if (it) {
          it.status = "check";
        }
      } else if (status === "select") {
        elements
          .filter((item) => item.tag === tag)
          .map((it) => {
            it.status = "noSelect";
            return it;
          });

        const elementsActiveFilterRemove = elementsActive.filter(
          (item) => item !== tag
        );

        setElementsActive(elementsActiveFilterRemove);
      }
    };

    return (
      <>
        <div
          className={`itemList ${status}`}
          onClick={(e) => {
            clickTag(e);
          }}
        >
          {tag}
          {status === "check" && <FaCheck className="check" />}
          {status === "select" && <IoMdClose className="select" />}
          {status === "noSelect" && <LiaPlusSolid className="noSelect" />}
        </div>
      </>
    );
  };

  return (
    <div className="ModalTopicsOfInterest">
      <div className="modal-body-pct">
        <div className="player-wrapper-top">
          <button className="closeBtn" onClick={(e) => closed(e)}>
            <IoMdClose />
          </button>
        </div>
        <>
          <div className="topicsOfInterestContent">
            <Text
              component={"h2"}
              text={"Escolha assuntos que você mais gosta."}
            />
            <Text
              component={"h4"}
              text={
                "A seleção ajudará a recomendar conteúdos e treinamentos de acordo com os seus interesses."
              }
            />

            <div className="listBase listActive">
              {elementsActive.map((item) => {
                return (
                  <Fragment key={item}>
                    <ItemTag tag={item} status={"select"} />
                  </Fragment>
                );
              })}
            </div>

            <div className="searchText">
              <button
                className="btnSearchIco"
                onClick={(e) => {
                  goToSearchPageResult();
                }}
              >
                <TbSearch />
              </button>
              <input
                spellCheck="false"
                className="menu-search-input"
                placeholder="Pesquisar..."
                ref={inputSearchRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    goToSearchPageResult();
                  }
                }}
                onChange={(c) => {
                  setSearchText(c.target.value);
                }}
                value={searchText}
                maxLength={400}
                type="search"
              />
            </div>

            <div className="listBase listSearch">
              {elements.map((item) => {
                return (
                  <Fragment key={item.tag}>
                    <ItemTag tag={item.tag} status={item.status} it={item} />
                  </Fragment>
                );
              })}
            </div>

            {itemsLenght.current > 0 ? (
              <div className="paginate">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="próximo >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  forcePage={pageNum.current}
                  previousLabel="< anterior"
                  renderOnZeroPageCount={null}
                />
              </div>
            ) : null}

            <Button
              className="button03"
              text="Salvar"
              callEvent={callSaveTags}
            />
          </div>
        </>
        {loading ? <Preloader /> : null}
      </div>
    </div>
  );
});

export default ModalTopicsOfInterest;
