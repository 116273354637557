import "./style.scss"

import React from "react";

import { Text } from '../'

type Props = {
  text?: string,
  css?: object,
  className?: string
}

function Label({ text , css = {}, className = "" }: Props) {

  text = (  text?.toUpperCase() === "SCORM" || text?.toUpperCase() === "SITE" ) ? 'Conteúdo' : text;
  
  return (
    (!!text)?
      <div className={`pctAtom pctLabel ${className}`} data-testid='label'>
      <Text component={"div"} text={`${text}`} />
    </div>
    :null
  )
}

export default Label
