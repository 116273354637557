import './style.scss';

import React, { useState, useEffect, useContext } from 'react';

import { Text, Avatar, Button } from '../../atoms';
import { getProfileInfo } from 'services/utils';
import { CreateEvaluationKnowledgeContext } from '../../../pctContexts/contextEvaluationKnowledge';
import { useHistory } from 'react-router-dom';

import { initEvaluation } from '../../../services/api';

type Props = {};

const EvaluationKnowledgeCardFeedback = ({}: Props) => {
    const {
        modalLoading,
        contextCurrentEvaluation,
        contextCurrentAvaliationResult,
        contextCurrentIdUser,
        contextCurrentIdEnrollment,
        contextCurrentIdEvaluation,
        contextCurrentNodeEnrollment,
    } = useContext(CreateEvaluationKnowledgeContext);

    const userProfile = getProfileInfo();
    const { name } = userProfile;

    const [statusFeed, setStatusFeed] = useState(true);
    const history = useHistory();

    const pretest = contextCurrentEvaluation.questionnaireType === 'PRE_TEST';

    const [valueAttempt, setValueAttempt] = useState(contextCurrentEvaluation.maxTries - contextCurrentAvaliationResult.attempts);
    const [valueMaxAttempt] = useState(contextCurrentEvaluation.maxTries);

    const [valueFeed] = useState(contextCurrentAvaliationResult.grade);
    const [valueMedia] = useState(contextCurrentAvaliationResult.masteryScore);

    const [updatedSpeed, setuUdatedSpeed] = useState(-45);

    useEffect(() => {
        let upSpeed = Math.round((valueFeed * 180) / 100) - 45;

        if (valueFeed >= valueMedia) {
            setStatusFeed(true);
            if (contextCurrentEvaluation.parameters.lockAfterApproval === '1') {
                setValueAttempt(0);
            }
        } else {
            setStatusFeed(false);
        }

        setTimeout(() => {
            setuUdatedSpeed(upSpeed);
        }, 1000 * 0.5);
    }, [valueFeed]);

    const restartEvaluation = () => {
        modalLoading.show({
            obj: {
                content: () => {
                    return (
                        <div className="alertText">
                            <p>Aguarde, sua avaliação está sendo reiniciada.</p>
                        </div>
                    );
                },
                closeBtn: false,
                loadding: true,
                className: 'evaluationsModal',
            },
        });

        const objInit = {
            idEnrollment: contextCurrentIdEnrollment,
            idNodeEnrollment: contextCurrentNodeEnrollment,
            idEvaluation: contextCurrentIdEvaluation,
            idUser: contextCurrentIdUser,
            questionnaireType: contextCurrentAvaliationResult.questionnaireType,
        };

        initEvaluation(objInit)
            .then((response) => {
                modalLoading.remove();
                history.push(`/evaluations/${objInit.idEnrollment}/${objInit.idEvaluation}/${response.data.idQuestionnaire}`);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const statusFeedStatus = () => {
        let statusFeedTxt = statusFeed ? 'statusFeedPassed' : 'statusFeedfaield';
        // statusFeedTxt = pretest ? 'statusFeedPreTest' : statusFeedTxt;

        return statusFeedTxt;
    };

    const statusFeedText = () => {
        let statusFeedTxt = statusFeed ? 'Aprovado' : 'Reprovado';
        statusFeedTxt = pretest ? 'Aproveitamento' : statusFeedTxt;

        return statusFeedTxt;
    };

    return (
        <div className={`cardFeedbackEvaluationKnowledge ${statusFeedStatus()}`}>
            <div className={`blockCF blockCF01`}>
                <Avatar className="avatarHrader" />

                <div className="speedContainer">
                    <div className="speedbox">
                        <div className="speedbox__score" style={{ transform: 'rotate(' + updatedSpeed + 'deg)' }} />
                        <div className="speedbox__groove" />
                    </div>

                    <div className="pointContainer">
                        <div className="pCTitle">Pontuação</div>
                        <div className="pcPoint">{valueFeed}</div>
                        <div className="pCPorc">de 100</div>
                    </div>
                </div>

                <div className="statusContainer">{statusFeedText()}</div>
            </div>
            {!pretest && (
                <div className="blockCF blockCF02">
                    <Text component="h3" className={'infoTitle'} text={`${statusFeed ? 'Parabéns,' : 'Que pena,'} ${name}!`} />
                    <Text component="p" className={'infoDatas'} text={`Você precisava de ${valueMedia}% e acertou ${valueFeed}%`} />
                    {valueAttempt > 0 && (
                        <div className={'infoAttempt'}>
                            <Text component="p" className={'infoAttemptTxt'} text={`* Restam ${valueAttempt}/${valueMaxAttempt} tentativas`} />
                            <Button
                                text={'Tentar novamente'}
                                className="button03"
                                callEvent={() => {
                                    // COMO AINDA EXISTEM TENTATIVAS VOU INICIAR A AVALIAÇÃO NOVAMENTE.
                                    restartEvaluation();
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default EvaluationKnowledgeCardFeedback;
