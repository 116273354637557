import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import './style.scss'
import PostListCard from '../PostListCard';
import HighlightedPostListCard from '../HighlightedPostListCard'
import AudioListCard from '../AudioListCard'
import Card from '../Card'
import ListCard from '../ListCard'
import { Carousel } from 'react-bootstrap'
import CarouselCard from './components/CarouselCard';
import MultipleFormatComponent from '../MultipleFormatComponent';
import LiveList from '../../container/LiveList';
import VideoCard from '../VideoCard';

const CarouselComponent = ({itens, idSection, sliderSize=3, type}) => {

    const ref = useRef(null);
    const [carrouselArray, setCarrouselArray] = useState([])
    const [mobile, setMobile] = useState(false)
    const [max, setMax] = useState(3);

    const startCarousel = () => {
        let array = []
        let aux = [...itens];
        
        while(Boolean(aux.length)){
            array.push(aux.splice(0,max))
        }
        setCarrouselArray(array)
    }
        function updateSize() {
            setMobile(window.innerWidth <= 800)
            setMax(maxNumber(type))
        }
        window.addEventListener('resize', updateSize);

    useEffect(() => {
        startCarousel()
    },[itens, sliderSize, max])

    useEffect(() => {
      setMax(maxNumber(type))
      function updateMax() {
        setMax(maxNumber(type))
      }
      window.addEventListener('resize', updateMax);

        setMobile(window.innerWidth <= 800)
    },[])

    const innerWidth = ref?.current?.element?.offsetWidth

    function selectTemplate(type, item, idSection, index) {
      type = parseInt(type);
      const template = {
        2: <Card item={item} idSection={idSection} key={index} />,
        6: <AudioListCard index={index} key={index} idSection={idSection} item={item}/>,
        7: <PostListCard idSection={idSection} key={item.idContentItem} item={item} index={index}/>,
        13: (<VideoCard 
                mediaLocation={item.mediaLocation} 
                pathname={`ytplayer/${item?.resourceId?.videoId}?idSection=${idSection}`}
                title={item.title} 
                description={item.description}
                key={index} 
                imgsrc={ item.thumbnails ? item.thumbnails : null }
                concluded={item.concluded} 
                progress={item.progress} 
                idSection={idSection} 
                duration={item.duration} 
            />),
        31: <PostListCard idSection={idSection} key={item.idContentItem} item={item} index={index}/>,
        38: (
            <div style={{"maxWidth":"340px", "maxHeight":"120px"}}>
                <MultipleFormatComponent idSection={idSection} key={item.idContentItem} item={item} index={index}/>
            </div>
        ),
      }

      return template[type] ? template[type] : <CarouselCard idSection={idSection} item={item} index={index} key={index} />
    }

    function maxNumber(type) {
      
      const width = window.innerWidth
      if( width >= 992){ return 3 } else if( width >= 800){ return 2 } else { return 1 }
      /*type = parseInt(type);
      const template = {
        2: <Card item={item} idSection={idSection} key={index} />,
        6: <AudioListCard index={index} key={index} idSection={idSection} item={item}/>,
        7: <PostListCard idSection={idSection} key={item.idContentItem} item={item} index={index}/>,
        31: <PostListCard idSection={idSection} key={item.idContentItem} item={item} index={index}/>,
      }

      return template[type] ? template[type] : <CarouselCard idSection={idSection} item={item} index={index} key={index} />*/
    }

    return (
        <div className="carousel">
            <Carousel variant="dark" ref={ref}>
                {
                    carrouselArray.map((itens,index1)=>{
                        return(
                            <Carousel.Item key={index1}>
                                <div className="carouselInnerWrapper">
                                    {
                                      itens.map((item,index2) => {
                                            return selectTemplate(type, item, idSection, index2)
                                        }) 
                                    }
                                </div>
                            </Carousel.Item>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

export default CarouselComponent