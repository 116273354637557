import "./style.scss";

import React from "react";
import { MdSportsScore } from "react-icons/md"

import moment from "moment"
import 'moment-duration-format'

import { Text } from '..'

type Props = {
  grade?:any
  masterScore?:any
  className?: string
}

function Grade({ grade, masterScore, className = "" }: Props ) {


  return (

    <div className={`pctAtom pctGrade ${className}`} >
        <MdSportsScore/>
        <div className="point">
            <Text component="small" text={masterScore?`${grade}% / ${masterScore}%`:`${grade}%`} />
        </div>
    </div>
  )
}

export default Grade
