import "./style.scss";

import React from "react";


type Props = {
  number?: number 
  css?: object
  className?: string
}

function PlaceholderContactus({ number = 1 , css = {}, className = "" }: Props) {

  let rows:number[] = []; 
  let i:number = 0; 

  while (++i <= number) {
    rows.push(i);
  }
  const PlaceholderItem = ({style, className}) =>{

    return (
      <div className={`ph-wrapper ${className}`} style={style}>
        <div className="ph-text shorter ph-animated"></div>
        
        <div className="ph-text short ph-animated"></div>  
        <div className="ph-text short ph-animated"></div>  
        <div className="ph-text short ph-animated"></div> 
        <div className="ph-text short ph-animated"></div> 

        {/* <div className="ph-image1 ph-animated"></div> */}
        <div className="ph-image2 ph-animated"></div>
     
        <div className="ph-text shortLabel ph-animated"></div>  
      </div> 
    )
  }

  return (
    <div className={'pg-grid-placeholder-contactus'}>
      {rows.map(function (i) {
        return <PlaceholderItem key={i} style={css} className={className}/>;
      })} 
    </div>
    
  )
}

export default PlaceholderContactus
