import React, { useState, useEffect, useRef } from "react";

import { useHistory } from "react-router-dom";

import { convertToBrDate, getProfileInfo } from "services/utils";

import { getEnrollmentRequests, cancelEnrollmentRequests } from "services/api";

type ActionProps = {reason:String | null, status:String, idEnrollmentRequest:null | Number | String }

const useRequestRegistered = () => {


    const [itemAction, setItemAction] = useState<null | ActionProps>(null);
    const [status, setStatus] = useState({
        all: false,
        approved: false,
        denied: false,
        pending: true,
        canceled: false
    });
    const [statusDenied, setStatusDenied] = useState<null | boolean>(null);
    
    const statusFilter = ( _st ) =>{
        const filt = {
            'APPROVED': 'Aprovado',
            'DENIED': 'Reprovado',
            'PENDING': 'Aguardando',
            'CANCELED': 'Cancelado'
        }

        return filt[ _st ] || 'Aguardando' 
    }

    const [loading, setLoading] = useState(false);
    const [dataRequestRegistered, setDataRequestRegistered] = useState<any>(null);

    const history = useHistory();
   
    const [itemOffset, setItemOffset] = useState(0);
    const itemsLenght = useRef(0);
    const itemsPerPage = 10;
    const pageNum = useRef(0);
    const pageCount = Math.ceil(itemsLenght.current / itemsPerPage);
    
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % itemsLenght.current;

        pageNum.current = event.selected;
        setItemOffset(newOffset);
    };

    const concatenateStatus = () => {
        
        let statusConc = '';

        if(status.approved){
            statusConc += 'APPROVED,' 
        }
        if(status.denied){
            statusConc += 'DENIED,' 
        }
        if(status.pending){
            statusConc += 'PENDING,' 
        }
       
        if(status.canceled){
            statusConc += 'CANCELED,' 
        }
        if(status.all || !status){
            statusConc = 'APPROVED,DENIED,PENDING,CANCELED' 
        }

        if (statusConc.endsWith(',')) {
            statusConc = statusConc.slice(0, -1);
        }

        return statusConc;
    }

    
    useEffect(() => {

        const params = {
            "idUser" : getProfileInfo().idUser,
            'offset' : itemOffset,
            'limit' : itemsPerPage,
            'status' : concatenateStatus()
        };

        setLoading(true);
        setDataRequestRegistered(null)
        getEnrollmentRequests(params)
            .then((res) => {
                itemsLenght.current = res.data.totalRecords;
                setDataRequestRegistered(res.data.result)
                setStatusDenied(false);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
        
        
    }, [itemOffset, status, statusDenied]);


    
    const handleChange = (event) => {

        setItemOffset(0);

        if(event.target.name === 'all' ){

            let res = { all: false, approved: false, denied: false, pending: false, canceled: false}

            if(event.target.checked === true){
                res = { all: true, approved: true, denied: true, pending: true, canceled: true}
            }

            setStatus( res );
        }else{
            setStatus({ ...status, [event.target.name]: event.target.checked });
        }
    };

    const callToAction = (item) => {

        if(item.status === 'APPROVED'){
            history.push(`/level/${item.idEnrollment}`);
        }else{
            setItemAction(item);
        }
    } 

    const confirmPending = () => {
        setItemAction(null);
        setLoading(true);

        if(itemAction){
            cancelEnrollmentRequests(itemAction.idEnrollmentRequest)
                .then((res) => {
                    setItemOffset(0);
                    setStatusDenied(true);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }


  return { handlePageClick, handleChange, pageCount, loading, dataRequestRegistered, status, itemsLenght, pageNum, convertToBrDate, statusFilter, callToAction, itemAction, setItemAction, confirmPending };
};

export default useRequestRegistered;
