import React from 'react';

export default function MenuFullScreen({ content = [{image: null}] }) {

  const [ firstItem ] = content

  const getStyles = () => ({
    "height": "100%"
  })

  return (
    <div className="home-image-container" style={{ ...getStyles() }}>
      <div style={{ flex: 1}}>
        <div className="menu-item-image" 
          style={{ backgroundImage: `url('${firstItem.image}')`}} 
          alt="img" 
        />
      </div>
    </div>
  );
}