import './style.scss';

import React, { Fragment } from 'react';
import parse from 'html-react-parser';


import { Text, EvaluationKnowAlternative } from 'pctComponents/atoms';
import EvaluationKnowCorrelate from 'pctComponents/atoms/EvaluationKnowCorrelate';

const EvaluationKnowMultipleChoice = ({ currentQuestion, viewType = '', evaluationCurrentIndex, selectItemEvent }) => {
    const { statement, questionType, options, optionAnswers } = currentQuestion.question;
    // const ref = useRef<HTMLIFrameElement>(null);
    // const [height, setHeight] = useState('0px');
    // const onLoad = () => {
    //   setHeight(Math.round(Number(ref.current?.contentWindow?.document.body.scrollHeight) + 40) + 'px');
    // };

    // useEffect(() => {
    //   onLoad();
    // }, []);

    const getlabel = () => {
        if (['SINGLE_OPTION', 'TRUE_OR_FALSE'].includes(questionType)) return 'Selecione a alternativa correta:';

        if ('MULTI_OPTION' === questionType) return 'Selecione as alternativas corretas:';
        if ('CORRELATE' === questionType) return 'Correlacione as alternativas corretas:';
    };
    return (
        <div className={`ptcEvaluationKnowMultipleChoice`}>
            <>
                <Text component={'h3'} className="questionInd" text={`Questão ${evaluationCurrentIndex}`} />
                <div className="contentTitle">{parse(`${statement}`)}</div>

                {/* <iframe
          ref={ref}
          className="contentTitle"
          srcDoc={statement}
          height={height}
          width="100%"
          onLoad={onLoad}
          allowFullScreen
        /> */}

                <Text component={'p'} className="titleSelec" text={getlabel()} />

                {questionType === 'CORRELATE' ? (
                      <EvaluationKnowCorrelate answerData={currentQuestion.answerData} viewType={viewType}  selectItemEvent={selectItemEvent} options={options} optionAnswers={optionAnswers}/>
                ) : (
                    <div className="listAlternatives">
                        {options.map((item, indice) => {
                            return (
                                <Fragment key={item.idQuestionOption}>
                                    <EvaluationKnowAlternative
                                        item={item}
                                        indice={indice}
                                        viewType={viewType}
                                        selectItemEvent={selectItemEvent}
                                        questionType={questionType}
                                        answerData={currentQuestion.answerData}
                                    />
                                </Fragment>
                            );
                        })}
                    </div>
                )}
            </>
        </div>
    );
};

export default EvaluationKnowMultipleChoice;
