import React, { useState, useEffect } from "react";

import { convertToBrDate } from "services/utils";

type Props = {
  campaign?: any;
};

const ModalCompaingsMultiple = ({ campaign }: Props) => {
  return (
    <div className="tableCompaings">
      <table className="rwd-table">
        <tr>
          <th>Nome</th>
          <th>Início</th>
          <th>Fim</th>
        </tr>
        {campaign.map((it, indice) => {
          return (
            <tr key={indice}>
              <td data-th="Nome">{it.name}</td>
              <td data-th="Início">{convertToBrDate(it.startDate)}</td>
              <td data-th="Fim">{convertToBrDate(it.endDate)}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default ModalCompaingsMultiple;
