import "./style.scss";

import React, { ReactNode } from "react";

import Flickity from "react-flickity-component";

type Props = {
  children: JSX.Element | JSX.Element[] | string | string[] | ReactNode;
  options?: any;
  reloadOnUpdate?: boolean;
  flktyReady?: (any?, e?) => void;
  flktyChange?: (any?, e?) => void;
};

const CarrousselView = ({
  children,
  options,
  reloadOnUpdate = false,
  flktyReady,
  flktyChange,
}: Props) => {
  const loadflktyEvents = (flkty: any) => {
    flkty.on("ready", (e) => {
      flktyReady && flktyReady(flkty, e);
    });
    flkty.on("change", (indice) => {
      flktyChange && flktyChange(flkty, indice);
    });
    flkty.on("dragStart", () =>
      flkty.slider.childNodes.forEach(
        (slide: { style: { pointerEvents: string } }) =>
          (slide.style.pointerEvents = "none")
      )
    );
    flkty.on("dragEnd", () =>
      flkty.slider.childNodes.forEach(
        (slide: { style: { pointerEvents: string } }) =>
          (slide.style.pointerEvents = "all")
      )
    );
  };

  let flickityOptions = {
    autoPlay: false,
    pageDots: true,
    accessibility: true,
    groupCells: true,
    cellAlign: "left",
    freeScroll: true,
    contain: true,
    ...options,
  };

  return (
    <Flickity
      flickityRef={(c) => loadflktyEvents(c)}
      options={flickityOptions}
      reloadOnUpdate={reloadOnUpdate}
    >
      {children}
    </Flickity>
  );
};

export default CarrousselView;
