import './style.scss';

import React from 'react';

import { Text } from '../../atoms';
import { CardBlock } from '../../molecules';

import usePreTest from '../../../pctHooks/usePreTest';

import { connect } from 'react-redux';
import { updatePreTest } from 'actions';

function PreTest(props: any) {
    const { data: dataBlock, _parentData: dataParent, className } = props;

    usePreTest(props);

    return (
        <div className={`pctOrganism pctNodeLink ${className}`}>
            {dataBlock.parameters.title && <Text component="h2" text={dataBlock.parameters.title} className="titleSection" />}

            {dataBlock.parameters.description && <Text component="p" text={dataBlock.parameters.description} className="textSection" />}

            {dataBlock.elements && (
                <CardBlock
                    id={dataBlock.elements[0].idBlock}
                    collection={dataBlock.elements[0]}
                    dataParent={dataParent}
                    parameters={dataBlock.parameters}
                    preTest
                />
            )}
        </div>
    );
}

const mapStateToProps = (store) => ({
    configPreTest: store.configPreTestState,
});

const mapDispatchToProps = (dispatch) => ({
    updatePreTest: (item) => dispatch(updatePreTest(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreTest);
