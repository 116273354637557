import React from 'react';
import { PlaceholderTable } from 'pctComponents/placehoader';
import ReactPaginate from 'react-paginate';
import { convertToBrDate } from 'services/utils';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Button, Text } from '../../atoms';
import AlertComponent from '../AlertComponent';
import ActionBtn from './components/ActionBtn';
import ExternalCourseController from './ExternalCourse.controller';
import { StatusExternalCourse, StatusExternalCourseDescriptions } from './ExternalCourse.types';
import './style.scss';

type Props = {
    userId: number;
};

const ExternalCourse = ({ userId }: Props) => {
    const {
        pageCount,
        dataExternalCourse,
        status,
        itemsLenght,
        pageNum,
        loading,
        handlePageClick,
        handleChange,
        callToAction,
    } = ExternalCourseController();

    return (
        <div className="ptcExtenalCourse">
            {loading && <PlaceholderTable rowsNumber={11} columnsNumber={5} />}

            {!loading && (
                <>
                    <div className="extenalCourseHeader">
                        <div className={'filterRequestRegistered'}>
                            {Object.values(StatusExternalCourse).map((st) => (
                                <FormControlLabel
                                    key={st}
                                    control={
                                        <Checkbox
                                            checked={status[st] as boolean}
                                            onChange={handleChange}
                                            name={st as string}
                                        />
                                    }
                                    label={StatusExternalCourseDescriptions[st]}
                                />
                            ))}
                        </div>
                        <Button
                            text="Cadastrar curso externo"
                            className="button03"
                            callEvent={(e) => {
                                callToAction(null);
                            }}
                        />
                    </div>
                </>
            )}

            {!loading && dataExternalCourse && dataExternalCourse.length === 0 && (
                <AlertComponent message="NÃO FORAM ENCONTRADOS RESULTADOS!" fullpage={false} />
            )}

            {!loading && dataExternalCourse && dataExternalCourse.length > 0 && (
                <>
                    <div className="pctTable">
                        <table className="rwd-table">
                            <thead>
                                <tr>
                                    <th>Título</th>
                                    <th>Data solicitação</th>
                                    <th>Status da solicitação</th>
                                    <th>Data validação / rejeição</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataExternalCourse.map((item, indice) => {
                                    return (
                                        <tr key={item.idUserExternalCourse}>
                                            <td data-th="Título">{item.title}</td>
                                            <td data-th="Data solicitação">
                                                {convertToBrDate(item.creationDate)}
                                            </td>
                                            <td data-th="Status da solicitação">
                                                <Text
                                                    component={'small'}
                                                    text={`• ${StatusExternalCourseDescriptions[item.status]}`}
                                                    className={`${item.status}`}
                                                />
                                            </td>
                                            <td data-th="Data validação / rejeição">
                                                {convertToBrDate(item.lastModifiedDate)}
                                            </td>

                                            <td data-th="Ações">
                                                <ActionBtn
                                                    item={item}
                                                    callToAction={callToAction}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        {itemsLenght.current > 0 ? (
                            <div className="pctPaginate">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="próximo >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={2}
                                    pageCount={pageCount}
                                    forcePage={pageNum.current}
                                    previousLabel="< anterior"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        ) : null}
                    </div>
                </>
            )}
        </div>
    );
};

export default ExternalCourse;
