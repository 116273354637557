import React from 'react';

import { Button } from '../../../../atoms';
import { ExternalCourseType } from '../../ExternalCourse.types';

type Props = {
    closeModal: () => void;
    handleSubmit: () => void;
    item: ExternalCourseType | null;
    disableForm: boolean;
    setDisableForm: any;
};

const ButtonsWrapperModal = ({ item, closeModal, handleSubmit, disableForm, setDisableForm }: Props) => {
    // const { status } = item;

    const createExternalCourse = !item?.status;
    const disableExternalCourse =
        item &&
        (item.status === 'CANCELED' ||
            item.status === 'APPROVED' ||
            (item.status === 'REJECTED' && item.hasResubmission));
    const editPending = item && item.status === 'PENDING';
    const editRejectd = item && item.status === 'REJECTED' && !item.hasResubmission;

    return (
        <>
            {createExternalCourse && (
                <div className="buttonsWrapper">
                    <Button callEvent={closeModal} className={`button01`} text={'Cancelar'} />

                    <Button
                        className={`button06`}
                        text={'Confirmar'}
                        type="submit"
                        callEvent={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    />
                </div>
            )}
            {disableExternalCourse && (
                <div className="buttonsWrapper">
                    <Button
                        className={`button06`}
                        text={'Ok entendi'}
                        type="submit"
                        callEvent={(e) => {
                            e.preventDefault();
                            closeModal();
                        }}
                    />
                </div>
            )}
            {editPending && (
                <div className="buttonsWrapper">
                    <Button callEvent={closeModal} className={`button01`} text={'Cancelar'} />

                    <Button
                        className={`button06`}
                        text={'Editar'}
                        type="submit"
                        callEvent={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    />
                </div>
            )}

            {editRejectd && (
                <div className="buttonsWrapper">
                    <Button callEvent={closeModal} className={`button01`} text={'Cancelar'} />

                    <Button
                        className={`button06`}
                        text={disableForm ? 'Editar' : 'Salvar e reenviar'}
                        type={disableForm ? 'button' : 'submit'}
                        callEvent={(e) => {
                            e.preventDefault();
                            if (disableForm) setDisableForm(false);
                            else handleSubmit();
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default ButtonsWrapperModal;
