import { CONFIG_CONTENTS_MAP } from '../actions/actionTypes';


const initialState = {
    configContentsMap: {}
};

export const configContentsMap = (state = initialState, action) => {
    switch (action.type) {
        case CONFIG_CONTENTS_MAP:
        return {
            ...state,
            configContentsMap: { ...initialState.configContentsMap , ...action.configContentsMap }
        };
       
        default:
        return state;
    }
};