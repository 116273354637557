import React, { useEffect, useCallback } from 'react';

import { Text } from 'pctComponents/atoms';

import BreadCramb from '../../BreadCramb';

import { IoMdClose } from 'react-icons/io';
import { GrFormPrevious } from 'react-icons/gr';

import useBackControll from 'pctHooks/useBackControll';
import usePlan from 'pctHooks/usePlan';

function HeadeNavControlls({ prevNavStyle, closeFn, navHistory, backCloseText, nodeCollection, updatePlan }) {
    const { backControllFn } = useBackControll({});
    const { getData } = usePlan();

    const collectionEmpty = !nodeCollection || !nodeCollection.node || !nodeCollection.node.parentNodes;

    const setInitialStatePlan = useCallback(async () => {
        const data = await getData();
        updatePlan(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatePlan]);

    useEffect(() => {
        setInitialStatePlan();
    }, [setInitialStatePlan]);

    return (
        <div className="navControlls">
            <button
                className={`prevNavHeader ${prevNavStyle}`}
                onClick={() => {
                    if (closeFn) {
                        closeFn();
                    } else {
                        backControllFn(navHistory);
                    }
                }}
            >
                <div className="prevNavBase prevNavIco">
                    <GrFormPrevious />
                    <Text component="small" text={backCloseText || backCloseText === '' ? backCloseText : 'VOLTAR'} className="prevTxt" />
                </div>

                <div className="prevNavBase closeNavIco">
                    <IoMdClose />
                    <Text component="small" text={backCloseText || backCloseText === '' ? backCloseText : 'FECHAR'} className="prevTxt" />
                </div>
            </button>

            <div className={`headerBreadMenuContent ${collectionEmpty ? 'headerBreadMenuContentNoBreadCramb' : ''}`}>
                {!collectionEmpty && <BreadCramb collection={nodeCollection} />}
            </div>
        </div>
    );
}

export default HeadeNavControlls;
