import { CONFIG_CAMPAIGN } from "../actions/actionTypes";

const initialState = {
  configCampaign: {
    earnPointsStatus: false,
  },
};

export const configCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_CAMPAIGN:
      return {
        // ...state,
        configCampaign: {
          ...initialState.configCampaign,
          ...action.configCampaign,
        },
      };
    default:
      return state;
  }
};
