import './style.scss';
import React, { Component, useEffect, useState } from 'react';
import { withRouter, Redirect, NavLink, useHistory, Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import logoFooter from '../../../assets/images/logo-footer-powered.svg';
import { updateHeaderStatus, updateFooterStatus } from '../../../actions';
import { getColors } from '../../../services/utils';

const PctFooter = (props) => {
    const [footerStyleContainer, footerStyleStatus] = useState({});

    useEffect(() => {
        const { updateFooterStatus, updateHeaderStatus } = props;

        footerStyleStatus(props.configHeaderFooter);
        updateFooterStatus('false');
        updateHeaderStatus('false');
    }, [props]);

    const { footerStyle } = footerStyleContainer;

    return (
        <>
            {footerStyle !== 'hide' ? (
                <footer className={`pctOrganism pctFooter ${footerStyle}`}>
                    <div className="companiesBase">
                        {/* <small className="textInfo">
                            LEARNING STREAMING EXPERIENCE BY
                        </small> */}

                        <img src={getAppIcon()} className="cliente" />

                        <a href="https://www.afferolab.com.br/" target="_blank">
                            <img src={logoFooter} className="bossa" alt="Afferolab" />
                        </a>
                    </div>
                </footer>
            ) : null}
        </>
    );
};

const getAppIcon = () => {
    // const appDetails = JSON.parse(localStorage.getItem("appDetails"));
    // const menuHeaderIcon = getColors("menu").headerIcon;
    // return menuHeaderIcon || appDetails?.headerIcon;

    const menuData = JSON.parse(localStorage.getItem('menu-data'));
    return menuData.companyDesign.headerIcon;
};

const mapStateToProps = (store) => ({
    configHeaderFooter: store.configHeaderFooterState.configHeaderFooter,
});

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PctFooter);
