import React, {useState} from 'react'
import styles from "./styles.module.scss"
import MosaicText from "../MosaicText";
import ImageLoader from '../../../ImageLoader/ImageLoader';
import { validImage, transformText, getDesign } from '../../../../services/utils';
import { Link } from "react-router-dom";
import Rate from '../../../Rate'
import ProgressBar from '../../../ProgressBar'

const MosaicCard = ({index, item, idSection, dataLength}) => {

    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const [showFooter] = useState(
        getDesign(idSection, "general")?.showBottomMenu || "true"
    );

    window.addEventListener("resize", () =>
        setIsDesktop(window.innerWidth > 768)
    );

    const mosaicStyle = {
      tile: () => {
        return  (
            <ImageLoader 
              src={item.image}
            />
        )
      },
      hybrid: () => {
        return (
            <ImageLoader 
              src={item.image}
              secondarySrc={ validImage(item.imageSecondary) && item.imageSecondary}
            />
        )
      },
      icon: () => {
        return (
            <img
                src={item.image}
                className={styles.iconImage}
                alt=""
            />
        )
      },
      default: () => {
        return (<>
          <Link to={`/content/${item.idContentItem}?idSection=${idSection}`} className={styles.defaultCardLink}>
            <div className="gradient-support" />
            <img src={item.image} style={{ minWidth: 165, minHeight: 200, backgroundColor: 'lightgray' }} alt="" />
          </Link>
          <div className={styles.mosaicText}>
            <Link to={`/content/${item.idContentItem}?idSection=${idSection}`}>
              <div style={ getDesign(idSection, 'itemText') }>
                <span> {item.text} </span>
              </div>
            </Link>
            <div style={{ display: "block", ...getDesign(idSection,'itemText','textAlign') }}>
              <Rate 
                stars={item.rating}
                id={item.id} 
                numberVotes={item.numberVotes} 
                avalie={false} 
                click={false} 
                classNameContainer={styles.rateContainer}
                classNameRatingContainer={styles.classNameRatingContainer}
                rateContainer={{padding: "0px"}}
              />
            </div>
            {
              item?.progress ? (
                <div style={{ display: "block", ...getDesign(idSection,'itemText','textAlign') }}>
                  <ProgressBar percent={item?.progress} completed={item?.completed} legend={false}/>
                </div>
              ) : null

            }
            <div style={ getDesign(idSection,'itemDescription') }>
              <span> {item.description} </span>
            </div>
          </div>
        </>)
      }
    }

    const showStyle = () => {
        if(mosaicStyle[item.imageStyle]){
            return mosaicStyle[item.imageStyle]()
        }else{
          return mosaicStyle["default"]()
        }
    }

    const divStyleArray =  {
        icon: {
          border: `3px solid ${getDesign( idSection, "general", "backgroundColor", true)}`,
          backgroundColor: item.color,
          backgroundSize: "cover",
          backgroundPosition: "center",
        },
        hybrid: {
          border: `3px solid ${getDesign( idSection, "general", "backgroundColor", true)}`,
          backgroundColor: item.color,
          height: dataLength === 1 ? null : isDesktop ? "55vh" : "35vh",
        },
        tile: {
          border: `3px solid ${getDesign(idSection, "general", "backgroundColor", true)}`,
          height: dataLength === 1 ? null : isDesktop ? "55vh" : "35vh",
          ...getStylesItem(dataLength, index),
        },
        default:{
          border: `3px solid ${getDesign( idSection, "general", "backgroundColor", true)}`,
          backgroundColor: item.color,
          height: dataLength === 1 ? null : isDesktop ? "55vh" : "35vh",
          ...getStylesItem(dataLength, index),
          border: "0px"
        }
    };

    const divStyle = divStyleArray[item.imageStyle] || divStyleArray["default"]

    function getStylesItem(items, indexItem) {
      if(items === 1){
        return isDesktop
          ? {
            width: "100%",
            minWidth: "100%",
            height: `calc(100vh - 54px ${showFooter && "- 5rem"})`,
          } : {
            width: "100%",
            minWidth: "100%",
            height: `calc(50vh - 27px ${showFooter && "- 2.5rem"})`,
          };
      } else if (items === 2) {
        return isDesktop
          ? {
            width: "50%",
            minWidth: "50%",
            height: `calc(100vh - 54px ${showFooter && "- 5rem"})`,
          } : {
            width: "100%",
            minWidth: "100%",
            height: `calc(50vh - 27px ${showFooter && "- 2.5rem"})`,
          };
      }else if (items > 4) {
        if (indexItem === 0) {
          return { minWidth: "66.66%" };
        }
      }
    }

    return(
        <Link
            to={`/${transformText(item.text)}/${item.sectionTo}?idSection=${item.sectionTo}`}
            className="tile"
            key={index}
            style={divStyle}
        >
            <div className={styles.internWrapper}>
                { validImage(item.image) && showStyle() }
                { item.showText === 1 && ( <MosaicText index={index} idSection={idSection} item={item}/>) }
            </div>
        </Link>
    )
}

export default MosaicCard