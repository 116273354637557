import "./style.scss";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Text } from "pctComponents/atoms";
import { GrFormCheckmark, GrFormClose } from "react-icons/gr";

const EvaluationKnowAlternative = ({
  item,
  indice,
  selectItemEvent,
  questionType,
  answerData,
  viewType,
}) => {
  const inputRef = useRef<any>(null);
  const [statusClassFeed, setStatusClassFeed] = useState("");

  useEffect(() => {
    const checkInit = answerData.filter(
      (el) => el.idQuestionOption === item.idQuestionOption
    );
    inputRef.current.checked = checkInit.length > 0;

    if (viewType === "modalFeed") {
      if (item.selected === 1) {
        if (item.isCorrect === 1) {
          setStatusClassFeed("isCorrectSelected");
        } else {
          setStatusClassFeed("isErroSelected");
        }
      } else {
        if (item.isCorrect === 1) {
          setStatusClassFeed("isCorrectNotSelected");
        }
      }
    }
  }, [item]);

  return (
    <label
      className={`alt ${statusClassFeed} ${
        questionType === "SINGLE_OPTION" || questionType === "TRUE_OR_FALSE"
          ? "alt_single"
          : "alt_mult"
      }`}
      htmlFor={`alt${indice}`}
    >
      <div className="controlFeed">
        <GrFormCheckmark className={"statusIco posIco"} />
        <GrFormClose className={"statusIco negIco"} />
      </div>

      <input
        ref={inputRef}
        type={
          questionType === "SINGLE_OPTION" || questionType === "TRUE_OR_FALSE"
            ? "radio"
            : "checkbox"
        }
        className="d-none input"
        name="alt"
        id={`alt${indice}`}
        onChange={(e) => {
          selectItemEvent(e.currentTarget.checked, item, e);
        }}
      />
      <label className="fake-input" htmlFor={`alt${indice}`} />
      <Text component={"p"} className="altTxt" text={item.description} />
    </label>
  );
};

export default EvaluationKnowAlternative;
