import "./style.scss";
import React, { useEffect, useCallback, useState } from "react";
import { Image, Text } from "../../atoms";
import PctUpgrade from "../PctUpgrade";
import { updatePlan } from "actions";
import { connect } from "react-redux";
import { OriginNavigation } from "pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model";
import placeholderImg from "../../../assets/images/placeholder.png";
import { License } from "reducers/license";

type Props = {
  className?: string;
  data?: any;
  fullpage?: boolean;
  planUpgrade: License;
  updatePlan: (item: License) => void;
};

const Upgrade = ({
  data,
  fullpage = true,
  className = "",
  planUpgrade,
  updatePlan,
}: Props) => {
  if (!planUpgrade?.isUpgradeButtonActive) {
    return <></>;
  }

  if (!planUpgrade.userIsAdmin && planUpgrade.hasRequestedUpgrade) {
    return <></>;
  }

  return (
    <section
      className={`pctUpgradeBlock ${fullpage} ${className} ${
        fullpage ? "fullpage" : ""
      }`}
    >
      <div>
        <div className="pctContainer">
          <div className="row">
            <div className="col-50">
              <div className="content">
                <Text component="h2" text={data.parameters.title} />
                <Text component="small" text={data.parameters.description} />
                <div className="content-footer">
                  <PctUpgrade
                    disableRuleIconModal={true}
                    updatePlan={updatePlan}
                    originNavigation={OriginNavigation.header}
                    hideNotification={true}
                    {...planUpgrade}
                  />
                </div>
              </div>
            </div>
            <div
              className={`col-50 ${
                data.parameters.mediaType === "image" ||
                !data.parameters.mediaUrl
                  ? "image"
                  : ""
              }`}
            >
              {data.parameters.mediaType === "image" ||
              !data.parameters.mediaUrl ? (
                <Image path={placeholderImg ?? placeholderImg} />
              ) : (
                <iframe
                  className="iframeUpgrade"
                  src={data.parameters.mediaUrl ?? ""}
                  title="Upgrade"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (store) => ({
  planUpgrade: store.planUpgrade,
});

const mapDispatchToProps = (dispatch) => ({
  updatePlan: (item) => dispatch(updatePlan(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);
