import './style.scss'

import React from "react";
import { useHistory } from 'react-router-dom'
import { Text, Label, Star, Visualization, Image } from "../../atoms"


type Props = {
    className?: string
    css?: object
    collection: any
}


const CardAvatar = ({ collection, className="", css={} }: Props) => {

    const { image, idContentItem, nodeLevel,  text, label,  views, star  } = collection;
    const history = useHistory();
  

    const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        
        if(collection.idContentItem){
            history.push(`/contentNode/${idContentItem}`); 
        }else{
            history.push(`/level?nodeLevel=${nodeLevel}`);
        }
        
    } 

    return (
        <div className="pctMolecule pctCard pctCardAvatar" style={css} onClick={ (e) =>{ handleMouseEvent(e) } } >
            {(star)&&<Star text={star}/>}
            {(image)&&
                <div className='containerData'>
                    <Image path={image}/>
                </div>
            }
            {(text)&&<Text component={"h4"} text={text} className='titleCard' />}
            {(label)&&<Label text={label}  className='label'/>}
            {(views)&&<Visualization text={views} />}
        </div>
    )

};

export default CardAvatar