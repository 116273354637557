import "./style.scss";
import React, { useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import useCards from "../../../pctHooks/useCards";
import { Text } from "../../atoms";
import {
  GrFormPreviousLink,
  GrFormNextLink,
  GrFormPrevious,
  GrPrevious,
} from "react-icons/gr";
import useBackControll from "../../../pctHooks/useBackControll";
import { getProfileInfo } from "services/utils";
import {
  findContentStudents,
  createNodeEnrollments,
} from "../../../services/api";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalLoadingInfo from "../../modals/ModalLoadingInfo";
import { OriginNavigation } from "pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model";
import usePlan from "pctHooks/usePlan";
type Props = {
  collection: BlockElement | any;
  text: string;
  direction: string;
  unlockNext: boolean;
  numberRequest: number;
  userIsAdmin: boolean;
  hasRequestedUpgrade?: boolean;
  isUpgradeButtonActive?: boolean;
};

const ContentNavigationBtn = ({
  collection,
  text = "",
  direction,
  unlockNext = false,
  numberRequest = 0,
  userIsAdmin = false,
  hasRequestedUpgrade = false,
  isUpgradeButtonActive = false,
}: Props) => {
  const userProfile = getProfileInfo();
  const { openModalUpgrade } = usePlan();
  const modalLoading = useModal("ModalLoadingInfo");
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  const setProgressStateRef = useRef(null as any);
  const progressInfoRef = useRef(null as any);
  const { isfluidNavigationFn } = useBackControll({});
  const openModal = () => {
    if (!isUpgradeButtonActive) {
      return;
    }
    if (!userIsAdmin && hasRequestedUpgrade) {
      return;
    }
    openModalUpgrade({
      userIsAdmin,
      hasRequestedUpgrade,
      numberRequest,
      originNavigation: OriginNavigation.fluidNavigation,
    });
  };

  let currentContentCollection = {
    data: {},
    elementType: "",
    contentEnrollment: {},
    idNodeEnrollment: 0,
    idEnrollment: 0,
    idElement: 0,
    idEvaluation: null,
    nodeCode: null,
  };

  if (
    (direction === "next" && collection.nextItem != null) ||
    (direction === "prev" && collection.previousItem != null)
  ) {
    currentContentCollection = {
      data: {
        contentEnrollment:
          direction === "next"
            ? { idContentEnrollment: collection.nextItem.idContentEnrollment }
            : {
                idContentEnrollment:
                  collection.previousItem.idContentEnrollment,
              },
        idContentType:
          direction === "next"
            ? collection.nextItem.idContentType
            : collection.previousItem.idContentType,
        idEnrollment:
          direction === "next"
            ? collection.nextItem.idEnrollment
            : collection.previousItem.idEnrollment,
        idEvaluation:
          direction === "next"
            ? collection.nextItem.idEvaluation
            : collection.previousItem.idEvaluation,
        idNodeEnrollment:
          direction === "next"
            ? collection.nextItem.idNodeEnrollment
            : collection.previousItem.idNodeEnrollment,
        idQuestionnaire:
          direction === "next"
            ? collection.nextItem.hasOwnProperty("idQuestionnaire")
              ? collection.nextItem?.idQuestionnaire
              : collection.nextItem.hasOwnProperty("questionnaire")
              ? collection.nextItem?.questionnaire.idQuestionnaire
              : null
            : collection.previousItem.hasOwnProperty("idQuestionnaire")
            ? collection.previousItem?.idQuestionnaire
            : collection.previousItem.hasOwnProperty("questionnaire")
            ? collection.previousItem?.questionnaire.idQuestionnaire
            : null,
        sequential:
          direction === "next"
            ? collection.nextItem.sequential
            : collection.previousItem.sequential,
        nodeCode:
          direction === "next"
            ? collection.nextItem.nodeCode
            : collection.previousItem.nodeCode,
      },

      elementType:
        direction === "next"
          ? collection.nextItem.idEvaluation
            ? "EVALUATION"
            : "CONTENT"
          : collection.previousItem.idEvaluation
          ? "EVALUATION"
          : "CONTENT",
      contentEnrollment:
        direction === "next"
          ? { idContentEnrollment: collection.nextItem.idContentEnrollment }
          : {
              idContentEnrollment: collection.previousItem.idContentEnrollment,
            },
      idNodeEnrollment:
        direction === "next"
          ? collection.nextItem.idNodeEnrollment
          : collection.previousItem.idNodeEnrollment,
      idEnrollment:
        direction === "next"
          ? collection.nextItem.idEnrollment
          : collection.previousItem.idEnrollment,
      idElement:
        direction === "next"
          ? collection.nextItem.idContentItem
          : collection.previousItem.idContentItem,
      idEvaluation:
        direction === "next"
          ? collection.nextItem.idEvaluation
          : collection.previousItem.idEvaluation,
      nodeCode:
        direction === "next"
          ? collection.nextItem.nodeCode
          : collection.previousItem.nodeCode,
    };
  }

  const { handleCardMouseEvent } = useCards({
    collection: currentContentCollection,
    id: 0,
    dataParent: currentContentCollection,
    setProgressStateRef,
    progressInfoRef,
  });

  const hasNextSequential = () => {
    let rt = false;
    let isEvaluationAndFinished = collection.hasOwnProperty("evaluation")
      ? collection.finishDate != null
      : false;

    if (collection.nextItem) {
      const currentStatus = collection.contentEnrollment
        ? collection.contentEnrollment.status
        : "";

      if (
        collection.nextItem.sequential &&
        currentStatus != "COMPLETED" &&
        unlockNext == false
      ) {
        rt = true;
      }

      if (isEvaluationAndFinished) {
        rt = false;
      }
    }

    return rt;
  };

  const isScormContent = () => {
    let rt = false;
    const hasObjScorm = collection.hasOwnProperty("objScorm");

    if (hasObjScorm) {
      rt = true;
    }

    return rt;
  };

  const isEvaluationContent = () => {
    let rt = false;
    const hasEvaluation = collection.hasOwnProperty("evaluation");

    if (hasEvaluation) {
      rt = true;
    }

    return rt;
  };

  const sequentialText = () => {
    return (
      <div className="finishTxt">
        <h3>
          {
            "O próximo conteúdo possuí pré-requisito, conclua o atual para avançar."
          }
        </h3>
      </div>
    );
  };

  const scormContentNextContent = (e) => {
    modalLoading.show({
      obj: {
        loadding: true,
        closeBtn: false,
        message: "Aguarde, salvando dados do conteúdo!",
        className: "navigationModal",
      },
    });

    collection.callLmsFinish(false);

    setTimeout(() => {
      findContentStudents(
        userProfile.idUser,
        collection.rootContent.idEnrollment,
        collection.rootContent.idContentItem,
        collection.rootContent.idNodeEnrollment
      )
        .then((response) => {
          modalLoading.remove();
          handleCardMouseEvent(e);
          // if (
          //   (collection.nextItem ? collection.nextItem.sequential == 1 : 0) &&
          //   !response.data.contentEnrollment.finishDate
          // ) {
          //   modalLoading.show({
          //     obj: {
          //       content: sequentialText,
          //       closeBtn: true,
          //       className: "navigationModal",
          //     },
          //   });
          // } else {
          //   handleCardMouseEvent(e);
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 2000);

    e.stopPropagation();
  };

  const createNodeEnrollmentForFluidNavigation = (
    nodeEnrollmentObj,
    _onFinishedEnrollment,
    e
  ) => {
    modalLoading.show({
      obj: {
        loadding: true,
        closeBtn: false,
        message: "Aguarde!",
        className: "navigationModal",
      },
    });

    createNodeEnrollments(nodeEnrollmentObj)
      .then((result) => {
        modalLoading.remove();

        currentContentCollection.idNodeEnrollment = result.data.newId;
        _onFinishedEnrollment(e);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handMouseNextPrevEvent = (e: React.MouseEvent<HTMLElement>) => {
    console.log("teste");
    if (isScormContent()) {
      // É UM CONTEÚDO DO TIPO SCORM.
      // console.log('SCORM');

      if (currentContentCollection.idNodeEnrollment) {
        isfluidNavigationFn(true);
        scormContentNextContent(e);
      } else {
        const nodeEnrollmentObj = {
          idUser: userProfile.idUser,
          idEnrollment: currentContentCollection.idEnrollment,
          nodeCode: currentContentCollection.nodeCode,
          idTeam: collection.rootContent.idTeam,
          idParentNodeEnrollment: collection.rootContent.idNodeEnrollment,
        };

        createNodeEnrollmentForFluidNavigation(
          nodeEnrollmentObj,
          handMouseNextPrevEvent,
          e
        );
      }
    } else if (isEvaluationContent()) {
      // É UM CONTEÚDO DO TIPO AVALIAÇÃO.
      // console.log('AVALIAÇÃO');

      if (currentContentCollection.idNodeEnrollment) {
        isfluidNavigationFn(true);
        handleCardMouseEvent(e);
      } else {
        const nodeEnrollmentObj = {
          idUser: collection.idUser,
          idEnrollment: currentContentCollection.idEnrollment,
          nodeCode: currentContentCollection.nodeCode,
          idTeam: collection.idTeam,
          idParentNodeEnrollment: collection.idNodeEnrollment,
        };

        createNodeEnrollmentForFluidNavigation(
          nodeEnrollmentObj,
          handMouseNextPrevEvent,
          e
        );
      }
    } else {
      // É OUTRO TIPO DE CONTEÚDO E O REQUISITO FOI CUMPRIDO.

      if (currentContentCollection.idNodeEnrollment) {
        isfluidNavigationFn(true);
        handleCardMouseEvent(e);
      } else {
        const nodeEnrollmentObj = {
          idUser: collection.contentEnrollment.idUser,
          idEnrollment: currentContentCollection.idEnrollment,
          nodeCode: currentContentCollection.nodeCode,
          idTeam: collection.team[0].idTeam,
          idParentNodeEnrollment: collection.contentEnrollment.idNodeEnrollment,
        };

        createNodeEnrollmentForFluidNavigation(
          nodeEnrollmentObj,
          handMouseNextPrevEvent,
          e
        );
      }
    }

    // else if (!hasNextSequential()) {
    //   // É OUTRO TIPO DE CONTEÚDO E O REQUISITO FOI CUMPRIDO.

    //   if (currentContentCollection.idNodeEnrollment) {
    //     isfluidNavigationFn(true);
    //     handleCardMouseEvent(e);
    //   } else {
    //     const nodeEnrollmentObj = {
    //       idUser: collection.contentEnrollment.idUser,
    //       idEnrollment: currentContentCollection.idEnrollment,
    //       nodeCode: currentContentCollection.nodeCode,
    //       idTeam: collection.team[0].idTeam,
    //       idParentNodeEnrollment: collection.contentEnrollment.idNodeEnrollment,
    //     };

    //     createNodeEnrollmentForFluidNavigation(
    //       nodeEnrollmentObj,
    //       handMouseNextPrevEvent,
    //       e
    //     );
    //   }
    // }

    // else {
    //   modalLoading.show({
    //     obj: {
    //       content: sequentialText,
    //       closeBtn: true,
    //       className: "navigationModal",
    //     },
    //   });
    // }
    openModal();
    e.stopPropagation();
  };

  return (
    <div className="contentNavBtn">
      {direction === "prev" && (
        <button
          className={`prevContentBtn ${
            !collection.previousItem ? "disabled" : ""
          }`}
          onClick={(e) => handMouseNextPrevEvent(e)}
        >
          <GrFormPreviousLink />
          <Text text={text} component={"small"} />
        </button>
      )}

      {direction === "next" && (
        <button
          className={`nextContentBtn ${
            !collection.nextItem ? "disabled" : ""
          } ${hasNextSequential() ? "blocked" : ""}`}
          onClick={(e) => handMouseNextPrevEvent(e)}
        >
          <Text text={text} component={"small"} />
          <GrFormNextLink />
        </button>
      )}
    </div>
  );
};

export default ContentNavigationBtn;
