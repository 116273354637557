import { Preloader, Text } from "pctComponents/atoms";
import "./style.scss";

import React, { useState, useEffect, useRef } from "react";
import { getnode } from "services/api";
import { useFetch } from "pctHooks/useFetch";
import CardBlockJourney from "../CardBlockJourney";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { getProfileInfo } from "services/utils";
import { getUserEnrollment } from "services/api";
import CardBlockLMS from "../CardBlockLMS";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from 'redux';
import { updateCoursesInProgress } from "../../../actions";



function MenuInProgress(props) {

  const [newNodeProgress, setnewNodeProgress] = useState({} as LearningNode);
  const history = useHistory();
  const fetchData = useRef( [] as Array<LearningNode> );
  
  useEffect(() => {
    if(props.courses_in_progress.newNode){
        if(fetchData.current.indexOf(props.courses_in_progress.newNode) == -1){
          setnewNodeProgress(props.courses_in_progress.newNode);
        }
    }
  }, [props.courses_in_progress]);


  let endPointUrl = newNodeProgress.idNode ? '' : `/learning/students/${ getProfileInfo().idUser }/enrollments-in-progress`;

  const { data, error } = useFetch({
    url: endPointUrl,
  });

  if (error) return <p>There is an error.</p>;
  if (!data) return <Preloader className="pctPreloaderLists" />;

  
  if(!newNodeProgress.idNode){
    fetchData.current = data["data"];
  }else{
    let tempArray = fetchData.current;

    if(fetchData.current.indexOf(props.courses_in_progress.newNode) == -1){
      tempArray.unshift(newNodeProgress);
    }
  }


  fetchData.current = fetchData.current.filter(
    (item) =>
      (item.status === "PUBLISHED")
  );

  
  return (
    <div className="menuInProgress">
      <ul>
        {fetchData.current?.map((item: any, i) => {
          let realTitle: string | any;

          if(!item){
            return;
          }

          realTitle =
            item.title != null ? item.title : ("Sem título" as string);

          return (
            <li key={i}>
              <div
                className="item"
                onClick={(e) => {
                  props.closeRoot(e)
                  history.push(`/level/${item.enrollment.idEnrollment}`);
                }}
              >
                <>
                  <Text
                    component={"p"}
                    text={realTitle + " "}
                    className="primary-color"
                  />
                </>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

const mapStateToProps = (store) => ({
  courses_in_progress: store.coursesProgress.courses_in_progress
});

const mapDispatchToProps = (dispatch) => ({
  updateCoursesInProgress: (item) => dispatch(updateCoursesInProgress(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuInProgress);