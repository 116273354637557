import React, { Component } from "react";
import "./style.scss";

import parse from "html-react-parser";

import TrophySVG from "../../svg/TrophySVG";

import useCampaingns from "pctHooks/useCampaigns";

type Props = {
  idElement?: number | string | null;
  css?: object;
  className?: string;
};

function Trophy({ idElement, css = {}, className = "" }: Props) {
  const ico = TrophySVG();

  const { listPointCampaign, statusPointCampaign, STATUS_POINT } =
    useCampaingns({ idElement });

  return statusPointCampaign !== STATUS_POINT.NULL ? (
    <button
      className={`pctAtom pctTrophy ${className} ${statusPointCampaign}`}
      style={css}
      onClick={listPointCampaign}
    >
      {parse(`${ico}`)}
    </button>
  ) : (
    <></>
  );
}

export default Trophy;
