import React from 'react';
import './style.scss';
import { Button, Text } from 'pctComponents/atoms';
import { CardUpgradeProps, DialogModel, ViewScreen } from '../../@model/ManagerTrial.model';

import CheckListUpgrade from '../CheckListUpgrade';

import { useManagerTrial } from '../../context/contextManagerTrial';
import { textPlansStatus } from '../../utils/TextPlansStatus';
import { dialogContainerModel } from '../../utils/DialogContainerModel';
import { useModal } from '@ebay/nice-modal-react';

const CardUpgrade = ({ active = false, elem, showDetail = false }: CardUpgradeProps) => {
    const { name, description, features, products } = elem;
    const modal = useModal();
    const { setViewScreenPlan, viewScreenPlan, currentPlan, setCurrentPlan, trialStatusPlan, setDialogPlan } = useManagerTrial();

    const trialPlan = name !== 'Enterprise';

    const planText = {
        screen: 'RequestUpgrade',
        trialStatusPlan: trialStatusPlan,
        trialPlan: trialPlan,
    };

    const showView = () => {
        if (currentPlan) {
            setViewScreenPlan(ViewScreen.calculatorPlan);

            // setViewScreenPlan(ViewScreen.dialog);
            // setDialogPlan(
            //   dialogContainerModel({
            //     type: DialogModel.ConfirmPlan,
            //     plan: name,
            //     buttonConfirm: {
            //       callEvent: async () => {
            //         const _menuData = localStorage.getItem('menu-data');
            //         const {token} = _menuData ? JSON.parse(_menuData) : null;

            //         const _appDetails = localStorage.getItem('appDetails');
            //         const {adminBucket} = _appDetails ? JSON.parse(_appDetails) : null;

            //         window.open(`https://${adminBucket}?token=${token}#/login`);
            //         modal.remove();
            //       },
            //     },
            //     buttonCancel: {
            //       callEvent: async () => {
            //         modal.remove();
            //       },
            //     }
            //   })
            // );

            return;
        }

        setCurrentPlan(elem);
        setViewScreenPlan(ViewScreen.form);
    };

    const openAgreement = () => {
        setCurrentPlan(elem);
        setViewScreenPlan(ViewScreen.terms);
    };

    return (
        <div>
            <div className={`pctCardUpgrade  ${active && 'activeDefault'}`}>
                <div className="cardContent">
                    <h2 className="colorWhite">
                        Plano <span className="active">{name}</span>
                    </h2>
                    {description && <p className="subtitle">{description}</p>}
                    {/* <p className="price colorWhite">
            {formatValue(item ? item.price.pricePerUser : 0)}
            <span> / por usuário mês</span>
          </p> */}

                    <Text
                        component={'p'}
                        className="requestPlan"
                        text={'Crie e edite seus próprios treinamentos, além de ter acessos a novas bibliotecas.'}
                    />

                    <Button
                        callEvent={(e) => {
                            showView();
                        }}
                        className={'button05 button-cofing'}
                        text={textPlansStatus({ ...planText, element: 'button' }) || ''}
                    />

                    {viewScreenPlan !== ViewScreen.selectedPlan && (
                        <p className="terms">
                            Ao clicar no botão acima, você aceita os
                            <span
                                onClick={(e) => {
                                    openAgreement();
                                }}
                            >
                                {' '}
                                termos e condições
                            </span>{' '}
                            de {name}.
                        </p>
                    )}

                    <div className="hr"></div>
                    <CheckListUpgrade
                        text="Crie e edite seus próprios treinamentos"
                        features={features || []}
                        products={products || []}
                        showDetail={showDetail}
                    />
                </div>
            </div>
        </div>
    );
};

export default CardUpgrade;
