import React, {
  useState,
  useEffect
} from "react";

const useMotionQuestion = (navCurrentIndex = {time: 1000* 0.5}) => {
  const [qCurrent, setQCurrent] = useState<null | boolean>(null);
  useEffect(() => {
    setQCurrent(null);
    setTimeout(() => {
      setQCurrent(true);
    }, navCurrentIndex.time);
  }, [navCurrentIndex]);

  return qCurrent;
};

export default useMotionQuestion;