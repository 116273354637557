import "./style.scss";

import React, { useEffect, useState, useRef, useCallback } from "react";

import { Text } from "pctComponents/atoms";
import { FaArrowLeftLong } from "react-icons/fa6";

import useMediaQuery from "pctHooks/useMediaQuery";

const PlaceholderItem = ({ style, className, format }) => {
  return (
    <div className={`ph-wrapper ${className}`} style={style}>
      <div className="ph-text shorter shorter1 ph-animated"></div>
      <div className="ph-image ph-animated" data-aspectratio={format}></div>
      <div className="ph-text shorter ph-animated"></div>
      <div className="ph-text shortLabel ph-animated"></div>
    </div>
  );
};

const CardGridCatalog = ({
  setOpenGrid,
  className = null,
  flktyChange,
  elements,
  CreateItem,
  widthCardStyle,
  loading,
  indiceOffsetMobile,
  elementAll,
  format,
  completeloading,
  setCompleteLoading,
  colunsElements,
  openGrid,
}) => {
  const containerRef = useRef<any>(null);
  const indiceRef = useRef<any>(0);
  const [scrollTopContainer, setScrollTopContainer] = useState(0);

  const smStatus = useMediaQuery("sm"); ///mobile

  const handleScroll = useCallback((event) => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef?.current;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setScrollTopContainer(scrollTop);
      indiceRef.current = indiceRef.current + (!smStatus ? 2 : 5);
      flktyChange(null, indiceRef.current);
    }
  }, []);

  useEffect(() => {
    if (openGrid) {
      document.body.classList.add("bodyOverflowHidden");
      containerRef?.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      containerRef?.current.removeEventListener("scroll", handleScroll);
    };
  }, [openGrid]);

  useEffect(() => {
    if (elements.length >= elementAll) {
      containerRef?.current.removeEventListener("scroll", handleScroll);
      containerRef?.current.classList.remove("blockScroll");
    }
  });

  useEffect(() => {
    if (loading && elements.length < elementAll) {
      containerRef?.current.removeEventListener("scroll", handleScroll);
      containerRef?.current.classList.add("blockScroll");
    }

    if (completeloading && elements.length < elementAll) {
      containerRef?.current.addEventListener("scroll", handleScroll);
      containerRef?.current.scrollTo({ top: scrollTopContainer });
      containerRef?.current.classList.remove("blockScroll");
      setCompleteLoading(null);
    }
  }, [loading, completeloading]);

  const closeGrid = (e) => {
    document.body.classList.remove("bodyOverflowHidden");
    setOpenGrid(false);
  };

  return (
    <div
      ref={containerRef}
      className={`pctOrganism pctCardGridCatalog ${
        className ? className : ""
      } `}
    >
      <div className={`cardGridNav`}>
        <button onClick={closeGrid}>
          <FaArrowLeftLong />
          <Text component="h4" text={"Voltar"} className="" />
        </button>
      </div>

      <div className="cardGridBase sectionList">
        {elements.map((item, i) => {
          return <CreateItem collection={item} key={i} />;
        })}
        {loading &&
          Array.from({ length: colunsElements - 1 }).map(function (_, i) {
            return (
              <PlaceholderItem
                key={i}
                style={widthCardStyle}
                className={""}
                format={format}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CardGridCatalog;
