import "./style.scss";

import React, { useState, useEffect, useContext, Fragment } from "react";
import parse from "html-react-parser";

import useMotionQuestion from "../../../pctHooks/useMotionQuestion";
import { CreateEvaluationKnowledgeContext } from "../../../pctContexts/contextEvaluationKnowledge";
import { Text, EvaluationKnowAlternative } from "pctComponents/atoms";
import { EvaluationKnowMultipleChoice } from "../index";

const EvaluationKnowPlayerContent = ({ currentQuestion, viewType = "" }) => {
  const { statement, questionType, options } = currentQuestion.question;

  const {
    motionCurrentIndex,
    evaluationCurrentIndex,
    updateEvaliationAnswers,
    contextUpdateFooterRef,
  } = useContext(CreateEvaluationKnowledgeContext);

  const selectItemEvent = (checked, item, e) => {
    const answerData = currentQuestion.answerData.filter(
      (it) => questionType === 'CORRELATE' ? it.idQuestionOptionAnswer !== item.idQuestionOptionAnswer : it.idQuestionOption !== item.idQuestionOption
    );
    contextUpdateFooterRef.current(String(e.timeStamp));

    if (checked) {
      if (
        questionType === "SINGLE_OPTION" ||
        questionType === "TRUE_OR_FALSE"
      ) {
        currentQuestion.answerData = [item];
      } else {
        currentQuestion.answerData = [...answerData, item];
      }
    } else {
      currentQuestion.answerData = [...answerData];
    }

    const answerDataFilter = currentQuestion.answerData.map((it) => {
      return {
        ...it,
        idQuestionOption: it.idQuestionOption,
        idAnswer: currentQuestion.idAnswer,
      };
    });
    updateEvaliationAnswers(currentQuestion, answerDataFilter);
  };
  return (
    <div
      className={`evaluationKnowPlayerContent ${
        useMotionQuestion(motionCurrentIndex) ? "fadeInUp" : "fadeIn"
      }`}
    >
      {useMotionQuestion(motionCurrentIndex) && (
        <>
          <EvaluationKnowMultipleChoice
            currentQuestion={currentQuestion}
            selectItemEvent={selectItemEvent}
            evaluationCurrentIndex={evaluationCurrentIndex + 1}
          />
        </>
      )}
    </div>
  );
};

export default EvaluationKnowPlayerContent;
