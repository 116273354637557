import React from 'react';
import ReactStars from 'react-stars';

const ShowRating = ({ stars, numberVotes }) => {
    return (
        <div className="rating-container-existing" style={{justifyContent: 'flex-start'}}>
            <div style={{ marginRight: 5, fontFamily: 'PT Sans Narrow' }} > </div>
            <ReactStars
                count={1}
                color1={'lightgray'}
                value={stars}
                size={18}
                edit={false}
                color2={'#ffd700'} 
            />
            {
                stars > 1 ?
                    <div className="rating-number-votes">
                        <span>
                            <span style={{color: '#ffd700', fontWeight: 'bold', marginRight: 5}}> {stars} </span>
                            <span>( {numberVotes} )</span>
                        </span>
                    </div>
                :
                    <div className="rating-number-votes-zero">
                        <span>( - )</span>
                    </div>
            }
        </div>
    )
}

export default ShowRating;