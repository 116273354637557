import { UPDATE_PRE_TEST } from '../actions/actionTypes';

type initialProp = {
    statusPreTest: boolean;
};

const initState: initialProp = {
    statusPreTest: false,
};

export const updatePreTestReducer = (state: initialProp = initState, action) => {
    switch (action.type) {
        case UPDATE_PRE_TEST:
            return {
                ...state,
                ...action.configPreTest,
            };
        default:
            return state;
    }
};
