import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { License } from 'reducers/license';

import { OriginNavigation } from 'pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model';
import PctUpgrade from '../PctUpgrade';
import { Image, Text } from '../../atoms';
import placeholderImg from '../../../assets/images/placeholder.png';
import usePlan from 'pctHooks/usePlan';
import './style.scss';
import PctPlanContract from '../PctPlanContract';

import { updatePlan } from 'actions';

type Props = {
    data: Block;
    className: string;
    planUpgrade?: License;
    fullpage?: boolean;
    updatePlan?: (item: License) => void;
};
const Campaing = ({ data, planUpgrade, updatePlan, fullpage = true, className = '' }: Props) => {
    const { getActivePlanData } = usePlan();
    const [usePlain, setUsePlain] = useState<any>();

    useEffect(() => {
        let response = getActivePlanData();
        setUsePlain(response);
    }, []);

    const title = planUpgrade?.userIsAdmin
        ? 'Conheça agora a plataforma <b>LMS+LXP by Afferolab</b> e eleve sua experiência de ensino e aprendizado a um novo nível.'
        : 'Solicite agora a degustação da plataforma <b>LMS+LXP by Afferolab</b> e eleve sua experiência de ensino e aprendizado a um novo nível.';

    const description =
        'Desenvolva e aprimore seus próprios programas de treinamento, com acesso exclusivo a uma variedade de bibliotecas, proporcionando um acervo rico em conteúdo e recursos de aprendizado.';

    const titleTrial = planUpgrade?.userIsAdmin
        ? 'Você está na degustação do plano @@namePlan@@ e <b>restam @@deadLine@@ dias</b>'
        : 'Como está sendo sua experiência durante o período de Degustação?<br />Compartilhe conosco se está gostando!';

    const descriptionTrial =
        'Se você tem gostado da experiência, imagine ter a solução completa sem a preocupação do prazo da degustação.<br />Contrate o Plano @@namePlan@@ e tenha tudo isso agora mesmo';

    const textButton = planUpgrade?.userIsAdmin
        ? usePlain?.trial === 1
            ? 'Contratar agora'
            : ''
        : usePlain?.trial === 1
        ? 'Sim, estou gostando da degustação'
        : 'Solicite a degustação LMS+LXP by Afferolab';

    if (!planUpgrade?.isUpgradeButtonActive || usePlain?.deadline <= 0) {
        return <></>;
    }

    // if (!planUpgrade.userIsAdmin && planUpgrade.hasRequestedUpgrade ) {
    //   return <></>;
    // }

    return (
        <section className={`pctCampaignBlock ${fullpage} ${className} ${fullpage ? 'fullpage' : ''}`}>
            <div>
                <div className="pctContainer">
                    <div className="row">
                        <div
                            className={`col-50 ${
                                data.parameters.mediaType === 'image' || !data.parameters.mediaUrl ? 'image' : ''
                            }`}
                        >
                            {data.parameters.mediaType === 'image' || !data.parameters.mediaUrl ? (
                                <Image path={placeholderImg ?? placeholderImg} />
                            ) : (
                                <iframe
                                    className="iframeCampaign"
                                    src={data.parameters.mediaUrl ?? ''}
                                    title="Campaign"
                                />
                            )}
                        </div>
                        <div className="col-50">
                            <div
                                className={'content ' + (usePlain?.trial && planUpgrade?.userIsAdmin ? ' trial ' : '')}
                            >
                                <Text
                                    component="h2"
                                    text={
                                        usePlain?.trial === 1
                                            ? titleTrial
                                                  .replace('@@deadLine@@', usePlain?.deadline)
                                                  .replace('@@namePlan@@', usePlain?.name)
                                            : title
                                    }
                                />
                                {/* data.parameters.title */}
                                <Text
                                    component="p"
                                    text={
                                        usePlain?.trial === 1
                                            ? descriptionTrial.replace('@@namePlan@@', usePlain?.name)
                                            : description
                                    }
                                />
                                {/* data.parameters.description */}
                                <div className="content-footer">
                                    {usePlain ? (
                                        <PctPlanContract
                                            text={textButton}
                                            hideNotification={true}
                                            {...usePlain}
                                            planUpgrade={planUpgrade}
                                            updatePlan={updatePlan}
                                        />
                                    ) : (
                                        <PctUpgrade
                                            text={textButton}
                                            disableRuleIconModal={true}
                                            updatePlan={updatePlan}
                                            originNavigation={OriginNavigation.header}
                                            hideNotification={true}
                                            {...planUpgrade}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (store) => ({
    planUpgrade: store.planUpgrade,
});
const mapDispatchToProps = (dispatch) => ({
    updatePlan: (item) => dispatch(updatePlan(item)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Campaing);
