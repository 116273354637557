/* eslint-disable */
import React, { Component } from 'react';
import io from 'socket.io-client';
import { getToken, chatLoadRoom, chatSaveRoom } from '../../services/api';
import './ChatRoom.scss';
import { getColors } from '../../services/utils';
import SendLogo from '../../assets/images/send-live.svg';
import jwtDecode from 'jwt-decode';

class ChatRoom extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: {},
      messages: [],
      newMessage: '',
      isDesktop: false,
      isLoading: false,
      error: false
    }

    this.socket = io('https://chat.bedigital.net.br:3000');
    this.messagesToSave = [];
    this.setNewMessage = this.setNewMessage.bind(this);
    this.handleSendMsg = this.handleSendMsg.bind(this);
    this.resize = this.resize.bind(this);
  }

  getProfileInfo(){
    let token = {}
    try {
        token = jwtDecode(getToken())
    }catch{
        console.error('error on token get')
    }
    return token
  }

  resize() {
    this.setState({ isDesktop: window.innerWidth > 800 });
  }

  async _chatLoadRoom(room) {
    this.setState({ isLoading: true });
    await chatLoadRoom(room).then(res => {
      if (res && res.data && res.data.success == false) {
        console.error("error: ", res.data);
        this.setState({ isLoading: false, error: true });
        return
      } else {
        if(res.data.data.length > 0) {
          this.setState({ messages: res.data.data[res.data.data.length - 1].messages });
        }
      }
    }).catch(err => {
      console.error('catch err', err);
      this.setState({ isLoading: false, error: true })
    })
  }

  async componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize);
    this.setState({
      user: this.getProfileInfo()
  })
    await this._chatLoadRoom(this.props.match.params.room);
    this.socket.on('connect', async () => {
      this.socket.emit('room', this.props.match.params.room);
    });

    this.socket.on('NEW_MESSAGE_FROM_SERVER', message => {
      this.AddNewMsg(message);
    })

    this.userJoinMsg();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.msgEnd.scrollIntoView({ behavior: 'smooth' })
  }

  handleKeyDown = (event) => {
    if(event.key == "Enter"){
      this.handleSendMsg(event)
    }
  }

  AddNewMsg(message) {
    let m = this.state.messages
    m.push(message)
    this.setState(
      {
        messages: m
      }, () => { })
  }

  render() {
    let vh = window.innerHeight * 0.01;
    const { isDesktop } = this.state
    let msgList;

    msgList = this.state.messages.map((m, k) => (
      m.user === this.state.user.name ?
        <div className="chat-container-mine" key={k}>
          <div className={1 == 1 ? 'chat-mine' : 'chat-mine chat-mine-arrow'} style={{ justifyContent: 'center', backgroundColor: getColors('menu').secondColor }} key={`chat-${m.user}-${m.moment}`}>
            <span className="msg" style={{ color: '#f0f0f0', fontSize: 16 }}>
              {m.message} 
              <div style={{fontSize: '0.7rem', textAlign: 'end', color: 'lightgray'}}>
                {m.moment}
              </div>
            </span>
          </div>
        </div>
        :
        <div className="chat-container-other" key={k}>
          <div className="chat-logged" style={{ marginTop: 7 }} key={`chat-${m.user}-${m.moment}`}>
            <b className="name" style={{ fontSize: 15 }}> {m.user}: </b>
            <span className="msg" style={{ fontSize: 16 }}> {m.message}</span>
          </div>
        </div>
    ));

    return (
      <div className="chat-room-container" style={{ width: isDesktop ? "50%" :  "100%", margin: isDesktop ? "0 25%" :  "0" }}>
        <div className="chat-room-msg" style={{ height: `calc(${vh * 82 - 75}px )`, backgroundColor: getColors('menu').firstColor }}>
          <h1 className="title">Chat</h1>
          <div className="chatbox-container" style={{ backgroundColor: getColors('menu').fourthColor }}>
            {msgList}
            <div ref={msgEnd => { this.msgEnd = msgEnd; }} />
          </div>
        </div >
        <div className="bottomBar">
          <input
            className="chatInput"
            style={{ backgroundColor: getColors('menu').firstColor }}
            type="text"
            placeholder="Digite uma mensagem..."
            onChange={this.setNewMessage}
            onKeyDown={this.handleKeyDown}
            value={this.state.newMessage} />
          <img onClick={this.handleSendMsg} src={SendLogo} className="chat-send-logo"
            alt="send" />
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    this.userLeaveMsg();
    this.socket.close();
  }

  setNewMessage(event) {
    this.setState({
      newMessage: event.target.value
    })
  }

  sendMsgToserver(usr, msg) {
    if(msg !== '') {
      let now = new Date();
      let msgObj = {
        "moment": `${this.dateFormat(now.getDate())}-${this.dateFormat(now.getMonth())}-${now.getFullYear()} ${this.dateFormat(now.getHours())}:${this.dateFormat(now.getMinutes())}:${this.dateFormat(now.getSeconds())}`,
        "idUser": this.state.user.idUser,
        "user": usr,
        "message": msg,
        "room": this.props.match.params.room
      };
      this.socket.emit('NEW_MESSAGE_FROM_CHAT', msgObj);
      this.prepareDataToSave(msgObj);
    }
  }

  handleSendMsg(event) {
    event.preventDefault();
    this.sendMsgToserver(this.state.user.name, this.state.newMessage)
    this.setState({
      newMessage: ''
    });
  }

  dateFormat(num) {
    return (num < 10) ? `0${num}` : num;
  }

  prepareDataToSave(msg) {
    let d = new Date();

    const data = {
      "data": [
        {
          "date": `${this.dateFormat(d.getDate())}-${this.dateFormat(d.getMonth())}-${d.getFullYear()}`,
          "messages": [
            {"moment": msg.moment, "user":this.state.user.idUser, "message": msg.message}
            ]
        }
        ]
    };

    if(msg.user !== 'Bot' || msg.user !== ''){
      this._chatSaveRoom(data);
    }
}

_chatSaveRoom(data) {
  chatSaveRoom(this.props.match.params.room, data).then(res => {
    if (!res || res.data.success === false) {
      console.error('success false')
      return
    }
  }).catch(err => {
    console.error('save chat err ', err);
  })
}

userJoinMsg() {
  this.sendMsgToserver('', `${this.state.user.name} entrou na sala.`);
}

userLeaveMsg() {
  this.sendMsgToserver('', `${this.state.user.name} saiu da sala.`);
}
}

export default ChatRoom;