import React from "react";
import { Link } from "react-router-dom";

import "../../assets/css/MenuRow.scss";
import { getColors } from "../../services/utils";
import ImageLoader from "../ImageLoader/ImageLoader";

export default function HomeMenuRow({ content }) {
  const getFooterSize = () =>
    getColors("footer").showfooter === "true" ||
    getColors("footer").showfooter === true
      ? { paddingBottom: "5rem" }
      : {};

  return (
    <div className="menu-row-container" style={{ ...getFooterSize() }}>
      {content?.map((item, index) => (
        <Link
          key={item.contentLocation}
          to={item.contentLocation}
          className="menu-row-item"
          style={{ minWidth: index === 0 && content.length > 4 && "66.66%" }}>
          <div
            className="menu-row-image"
            alt="img"
          >
          <ImageLoader 
            src={item.image} 
            srcStyle={{ height: '100%', width: '100%', objectFit: 'cover'}}
            loadingStyle={{ height: '100%', zIndex: 1 }}
          />
          </div>
        </Link>
      ))}
    </div>
  );
}
