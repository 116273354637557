import "./style.scss";

import React from "react";

import { Text } from "../";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { getProfileInfo } from "services/utils";

type Props = {
  seta?: boolean | null;
  css?: object;
  className?: string;
  photoPerfil?: any;
  namePerfil?: any;
};

function Avatar({
  seta = null,
  css = {},
  className = "",
  photoPerfil = null,
  namePerfil = null,
}: Props) {
  const userProfile = getProfileInfo();
  const { name, photo } = userProfile;
  let nameIt = namePerfil ? namePerfil : name;

  const firstLetter = nameIt ? nameIt.substr(0, 1) : "";

  return (
    <>
      <div className={`pctAvatar avatarImg ${className}`} style={css}>
        <div className={"avtContainer"}>
          {photo ? (
            <img
              src={!photoPerfil ? photo : photoPerfil}
              className="avatarImg"
            />
          ) : (
            <div className="avatarTxt">{firstLetter}</div>
          )}
        </div>

        {seta !== null &&
          (seta === true ? <AiFillCaretUp /> : <AiFillCaretDown />)}
      </div>
    </>
  );
}

export default Avatar;
