import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';
import {useHistory} from 'react-router-dom';
import ProgressBar from '../../../ProgressBar';
import Rate from '../../../Rate';

import {getDesign, getColors} from '../../../../services/utils';

const CarouselCard = ({item, idSection, link = null, customSize = 300}) => {
  const history = useHistory();
  function handleClick(item) {
    item = link ? link : `/content/${item.idContentItem}?idSection=${idSection}`;
    history.push(item);
  }
  
  return (
    <>
      <div onClick={() => handleClick(item)} className={styles.alignCards} >
        <div className="card cardGrid" style={{height: customSize, width: customSize, borderRadius: '10px'}}>
          <div
            className="front column"
            style={{
              backgroundImage: item.imageStyle !== 'icon' ? `url( ${item.image} )` : '',
              backgroundColor: `${item.color}`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: item.imageStyle === 'icon' ? 'contain' : 'cover',
              margin: '0px'
            }}
          />
          <div style={{zIndex: 0, display: 'flex', flexDirection: 'column', height:'100%', justifyContent:'end'}}>
            {['hybrid','icon'].includes(item.imageStyle)   && (
              <div className="div-card-hibridy">
                <img src={item.imageSecondary || item.image} />
              </div>
            )}
            <div className="title-front-card" style={{marginLeft: 0}}>
              <div style={{ ...getDesign('itemText', idSection), marginLeft: '0px', padding: '0 0 15px 0',}} className="title-span">
                <span style={{...getDesign('itemDescription', idSection), display: 'inherit', textAlign: 'center'}}>
                </span>
              </div>
              <div style={{width: '100%'}}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselCard;
