import './style.scss';

import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { updateConfigHeaderFooter, updateApiReplication } from '../../../actions';
import { getProfileInfo } from 'services/utils';
import { Preloader } from '../../atoms';
import TreeList from 'pctComponents/organisms/TreeList';
import { useHistory } from 'react-router-dom';
import ErrorComponent from 'pctComponents/organisms/ErrorComponent';

import { findContentStudents } from 'services/api';

import { useQuery } from '@tanstack/react-query';

type idType = {
    idEnrollment?: string;
    idContentItem?: string;
    idNodeEnrollment?: string;
    isFluidNavigation?: string;
};

const Lms = (props) => {
    const { updateConfigHeaderFooter, updateApiReplication } = props;
    const { idEnrollment, idNodeEnrollment, idContentItem } = useParams<idType>();
    const userProfile = getProfileInfo();
    const history = useHistory();
    const [callSingle, setCallSingle] = useState(false);
    const [lmsObjectData, setLmsObjectData] = useState<any>(null);

    const { data: response, error } = useQuery({
        queryKey: ['findContentStudents', userProfile.idUser, idEnrollment, idContentItem, idNodeEnrollment],
        queryFn: () => findContentStudents(userProfile.idUser, idEnrollment, idContentItem, idNodeEnrollment),
    });

    useEffect(() => {
        if (response) {
            updateApiReplication({ findContentStudents: response });

            const fetchData: any = response?.data;
            const contentData = fetchData.content;
            const contentEnrollment = fetchData.contentEnrollment ? fetchData.contentEnrollment : {};

            const lmsObject: any = {
                image: contentData.image,
                title: contentData.text,
                description: contentData.description,
                label: 'SCORM',
                nodeCode: null,
                enrollment: idEnrollment,
                idContentItem: idContentItem,
                lessons: contentData.scorm.scos,
                completed: contentEnrollment.progress === 100 ? true : false,
                scormStatus: contentEnrollment.status,
                progress: contentEnrollment.progress,
                grade: contentEnrollment.grade,
                scormProcessStatus: fetchData.content.scorm.status,
            };

            setLmsObjectData(lmsObject);

            // TRATAMENTO PARA REALIZAR O REDIRECT QUANDO EXISTIR APENAS 1 SCO.
            if (lmsObject.lessons.length === 1 && callSingle === false && lmsObject.scormProcessStatus === 'SUCCESS') {
                const scoObj = lmsObject.lessons[0];
                setCallSingle(true);

                setTimeout(() => {
                    history.push(
                        `/lessons/${idEnrollment}/${idContentItem}/${scoObj.scoEnrollment.idSco}/${idNodeEnrollment}/single/${lmsObject.completed}/${fetchData.team[0].idTeam}`,
                    );
                    updateConfigHeaderFooter({
                        footerStyle: 'hide',
                        prevNavStyle: 'closeShow',
                        backCloseText: 'FECHAR CONTEÚDO',
                        contentNavigation: fetchData,
                        scoSingle: true,
                    });
                }, 1000);
            } else {
                updateConfigHeaderFooter({
                    footerStyle: 'hide',
                    prevNavStyle: 'closeShow',
                    backCloseText: 'FECHAR CONTEÚDO',
                    contentNavigation: fetchData,
                });
            }
        }
    }, [response]);

    if (error) return <ErrorComponent error={error} />;
    if (!response || callSingle) return <Preloader />;

    return (
        <div className="pctPage pctScormTree">
            {lmsObjectData && <TreeList collection={lmsObjectData} treeType="scorm" />}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
    updateApiReplication: (item) => dispatch(updateApiReplication(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Lms);
