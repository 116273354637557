import "./style.scss";

import React, { ReactElement, useEffect, useState } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import parse from "html-react-parser";

import { Text } from "../";

type Props = {
  css?: object;
  className?: string;
  closedActive?: boolean;
  duration?: number;
  ico?: { class: string; element: ReactElement };
  status: boolean;
  text?: string;
  ContentToast?: ReactElement | React.ReactNode | null | undefined;
  // toastClosed?: () => {} | null;
  toastClosed?: any;
};

function Toast({
  css = {},
  className = "",
  status = false,
  closedActive = true,
  duration = 8,
  text = "",
  ContentToast = null,
  ico,
  toastClosed = null,
}: Props) {
  const [open, setOpen] = useState(status);

  useEffect(() => {
    setOpen(status);
  }, [status]);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    toastClosed();
  };

  return (
    <div className={`pctSnackbar ${className}`} style={css}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        TransitionComponent={Slide}
        autoHideDuration={1000 * duration}
        onClose={handleClose}
        message={""}
        action={
          <div className="containerSnackbar">
            {ico && <div className={ico.class}>{parse(`${ico.element}`)}</div>}
            {text && <Text component={"h4"} text={`${text}`} />}
            {ContentToast && ContentToast}
            {closedActive && (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        }
      />
    </div>
  );
}

export default Toast;
