import './style.scss';

import React, { Component } from "react";
import ReactDOM from 'react-dom';

import { Text } from '../../atoms';

type Props = {
    css?: object
    className?: string
    html?: string
    model?: string
  }

const TitleSection = (prop: Props) => {

    const { html , className="" } = prop;

    return (
        <section className={`pctOrganism pctTitleSection ${className}`}>
            <div className="titleSectionBase">
                <Text component="h2" text={html} />
            </div>
        </section>
    )
};

export default TitleSection