import React, { useState } from "react";
import { BsX, BsBoxArrowLeft, BsCircleFill, BsCircle } from "react-icons/bs";

const JourneyStepSideMenu = (props) => {
  let rows = [];

  props.pages.map((page, index) => {
    rows.push(
      <div
        key={index}
        className="step-side-menu-body"
        onClick={() => {
          props.pageClickHandler(index);
        }}
      >
        <button className="step-side-menu-button">
          <div className="step-side-menu-body-items">
            <div className="step-side-menu-body-icon">
              {index < props.index && (
                <BsCircleFill size={20} color="lightseagreen" />
              )}
              {index == props.index && (
                <BsCircle size={20} color="lightseagreen" />
              )}
              {index > props.index && <BsCircle size={20} color="gray" />}
            </div>
            <div
              className="step-side-menu-body-steps"
              style={{
                color: index == props.index ? "lightseagreen" : "white",
              }}
            >
              <b>{page.text.toUpperCase()}</b>
            </div>
          </div>
        </button>
      </div>
    );
  });

  return (
    <div
      className={
        "step-menu-expand " + (props.display ? "step-menu-expand-open" : "")
      }
    >
      <div className="step-side-menu-header">
        <div>
          <button
            className="step-side-menu-header-button"
            onClick={props.closeButtonHandler}
          >
            <BsX size={30} />
          </button>
        </div>
        <div className="step-side-menu-header-text">MAPA DE LOCALIZAÇÃO</div>
      </div>
      <div className="step-side-menu-title">
        <div className="step-side-menu-title-top">MÓDULO:</div>
        <div className="step-side-menu-title-bottom">
          {props.pages[props.index]?.parentName}
        </div>
      </div>
      {rows}
      <div className="step-side-menu-footer">
        <div>
          <BsBoxArrowLeft size={30} />
        </div>
        <div className="step-side-menu-footer-text">SAIR DESTE MÓDULO</div>
      </div>
    </div>
  );
};

export default JourneyStepSideMenu;
