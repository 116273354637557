import React from "react";
import { contentList } from '../services/api';
import { getDesignById, getFooterSize } from '../services/utils';
import { Link } from 'react-router-dom';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

class CardSiteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: []
    };
  }

  componentDidMount() {
    this._getContent(this.props.idSection)
  }

  _getDesign(item, only = false, valueOnly = false) {
    let design = getDesignById(this.props.idSection)

    if (!design[item]) { return null } // return null if theres no item specified

    if (only) { // parameter only means it will only return the property specified
      if (valueOnly) { return design[item][only] } // parameter valueOnly means it will only return the property value if specified

      design = { [only]: design[item][only] }
      return design
    }

    return design[item]
  }

  _getContent(id) {
    this.setState({ isLoading: true })
    contentList(id).then(res => {
      if (res.data.success === false) {
        this.setState({ isLoading: false })
        return
      }
      this.setState({
        isLoading: false,
        data: res.data.content
      })
    }).catch(err => {
      this.setState({ isLoading: false })
    })
  }

  setContentParams(item) {
    if (item === null || typeof allMenu == "undefined") { return }
    let queryString = ''

    if (item.showBottomMenu == 0) { queryString = queryString.concat('showBottomMenu=0&') }
    if (item.showTopBar == 0) { queryString = queryString.concat('showTopBar=0&') }
    if (item.backConfirmation == 1) { queryString = queryString.concat('backConfirmation=1&') }
    if (item.logoutConfirmation == 1) { queryString = queryString.concat('logoutConfirmation=1&') }

    return queryString
  }


  render() {
    const { updateHeaderStatus, updateFooterStatus, footerStatus } = this.props
    updateFooterStatus(this._getDesign('general')?.showBottomMenu || 'true')
    updateHeaderStatus(this._getDesign('general')?.showTopBar || 'true')
    return (
      <div className="pato-container" style={{ ...this._getDesign('general'), ...getFooterSize() }}>
        <div className="pato-wrapper">
          {
            this.state.isLoading ?
              <div className="loader loader-center loader-big"></div>
              :
              this.state.data.map((item, index) => {
                return (
                  <Link
                    to={`/content/${item.idContentItem}?idSection=${this.props.idSection}`}
                    key={index}
                    className="new-pato-wrapper"
                    style={{
                      margin: this._getDesign('general', 'padding', true) / 2,
                      backgroundColor: `${item.backgroundColor}`,
                      height: this?.state?.data.length > 1 ? `calc(50vh - 42px${footerStatus ? ' - 5rem' : ''})` : `calc(100vh - 74px${footerStatus ? ' - 5rem' : ''})`
                    }}
                  >
                    <a className="new-pato-wrapper-inside">
                      <div className="new-pato-wrapper-div">
                        <img className={'new-pato-img' + (item.imageStyle == 'tile' ? ' new-pato-tile' : '')} src={item.image} alt="img" />
                        <div style={{ ...this._getDesign('itemText'), zIndex: 10 }}>
                          <p className="new-pato-text">
                            {item.text}
                          </p>
                        </div>
                      </div>
                    </a>
                    {
                      item.imageStyle == 'tile' &&
                      <div className="gradient-support" />
                    }
                  </Link>
                )
              })
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

const mapStateToProps = store => ({
  footerStatus: store.footerState.footerStatus
});

export default connect(mapStateToProps, mapDispatchToProps)(CardSiteList);