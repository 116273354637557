
import React, { useState, useEffect } from "react";



const useSrcSetVerify = ( urlBase, format ) => {

    
    const [url, setUrl] = useState( urlBase );
    if(urlBase){
        let baseForatURL = urlBase.split('/');
        let lastURL = baseForatURL.pop();

        const joinBaseForatURL = baseForatURL.join('/')
        const lastURLArray = lastURL.split('.');
        const createURL = joinBaseForatURL + "/" + lastURLArray[0] + '-format-' + format + '.' + lastURLArray[1];
    
        
        const img = new Image();
        img.src = createURL;
        img.onerror = (e: Event | string | any) => {
            if (e.preventDefault) {
                e.preventDefault();
                setUrl(urlBase);
            }
        };
        img.onload = () => {
            setUrl(createURL);
        }; 
    }
    
    return url;
};



export default useSrcSetVerify;
