import "./style.scss";

import React, { useState } from "react";

import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { IoMdClose } from "react-icons/io";
import { BiLoaderCircle } from "react-icons/bi";
import { HiOutlineEmojiHappy } from "react-icons/hi";

import { Button, Text, Preloader } from "pctComponents/atoms";

import { getProfileInfo } from "services/utils";

import { changePassword } from "services/api";

const ModalPassword = ({ passwordModal, setPasswordModal }) => {
  const [sucessChangePassword, setSucessChangePassword] = useState(false);

  const cancelPassword = () => {
    setPasswordModal(false);
  };

  const PasswordManager = ({ cancelPassword, setSucessChangePassword }) => {
    const schema = yup.object().shape({
      passwordCurrent: yup.string().required("Senha atual obrigatório"),
      passwordNew: yup
        .string()
        .required("Nova senha obrigatório")
        .min(8, "Ao menos 8 caracteres")
        .matches(/[0-9]/, "Ao menos um número")
        .matches(/[a-zA-Z]+/gi, "Ao menos uma letra"),
      passwordConfirm: yup
        .string()
        .oneOf(
          [yup.ref("passwordNew"), null],
          "Esta senha tem que ser idêntica a digitada no campo Nova Senha."
        ),
    });

    const [passwordData, setPasswordData] = useState({
      showPasswordCurrent: false,
      showPasswordNew: false,
      showPasswordConfirm: false,
    });

    const handleClickShowPassword = (prop) => {
      setPasswordData({
        ...passwordData,
        [prop]: !passwordData[prop],
      });
    };

    return (
      <>
        <Formik
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, setStatus, setErrors }) => {
            const idUser = getProfileInfo().idUser;
            const data = {
              currentPassword: values.passwordCurrent,
              newPassword: values.passwordNew,
            };
            setStatus("Verificando...");

            changePassword(idUser, data)
              .then((res) => {
                if (res) {
                  setStatus("Senha salva com sucesso.");
                  setSucessChangePassword(true);
                } else {
                  setErrors({ passwordCurrent: "Senha atual incorreta" });
                  setStatus(null);
                  setSubmitting(false);
                }
              })
              .catch((err) => {
                setErrors({ passwordCurrent: "Senha atual incorreta" });
                setStatus(null);
                setSubmitting(false);
              });
          }}
          initialValues={{
            passwordCurrent: "",
            passwordNew: "",
            passwordConfirm: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            setFieldValue,
            errors,
            status,
          }) => (
            <>
              <Form noValidate>
                <div className="htmlBody">
                  <div className="notification-container">
                    <Text
                      component={"h4"}
                      text={
                        "A senha deve ter no mínimo 8 caracteres e incluir pelo menos uma letra, um número."
                      }
                    />
                    <div className="box-notification">
                      <div className="linePassword">
                        <Text component={"h4"} text={"Senha atual*"} />

                        <FormControl
                          className={`inputPassword ${
                            passwordData.showPasswordCurrent
                              ? "activePassword"
                              : ""
                          }`}
                        >
                          <Input
                            type={
                              passwordData.showPasswordCurrent
                                ? "text"
                                : "password"
                            }
                            name="passwordCurrent"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.passwordCurrent}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) =>
                                    handleClickShowPassword(
                                      "showPasswordCurrent"
                                    )
                                  }
                                >
                                  {passwordData.showPasswordCurrent ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <ErrorMessage
                          name="passwordCurrent"
                          component="small"
                        />
                      </div>

                      <div className="linePassword">
                        <Text component={"h4"} text={"Nova senha*"} />
                        <FormControl
                          className={`inputPassword ${
                            passwordData.showPasswordNew ? "activePassword" : ""
                          }`}
                        >
                          <Input
                            type={
                              passwordData.showPasswordNew ? "text" : "password"
                            }
                            name="passwordNew"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.passwordNew}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) =>
                                    handleClickShowPassword("showPasswordNew")
                                  }
                                >
                                  {passwordData.showPasswordNew ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <ErrorMessage name="passwordNew" component="small" />
                      </div>

                      <div className="linePassword">
                        <Text component={"h4"} text={"Confirmar nova senha*"} />
                        <FormControl
                          className={`inputPassword ${
                            passwordData.showPasswordConfirm
                              ? "activePassword"
                              : ""
                          }`}
                        >
                          <Input
                            type={
                              passwordData.showPasswordConfirm
                                ? "text"
                                : "password"
                            }
                            id="password-confirm"
                            name="passwordConfirm"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.passwordConfirm}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) =>
                                    handleClickShowPassword(
                                      "showPasswordConfirm"
                                    )
                                  }
                                >
                                  {passwordData.showPasswordConfirm ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <ErrorMessage
                          name="passwordConfirm"
                          component="small"
                        />
                      </div>

                      {status && isValid && (
                        <div className="loaderContainer">
                          <BiLoaderCircle className="loaderIco" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="buttonsWrapper">
                  <Button
                    callEvent={cancelPassword}
                    className={`button01`}
                    text={"Cancelar"}
                  />

                  <Button
                    className={`button06`}
                    text={"Confirmar"}
                    callEvent={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  />
                </div>
              </Form>
            </>
          )}
        </Formik>
      </>
    );
  };

  return passwordModal ? (
    <div className={`modalPassword`}>
      <div className="modalDialogHit" onClick={() => setPasswordModal(false)} />
      <div className="modalWrapper">
        {!sucessChangePassword ? (
          <>
            <div className="topbarHeader">
              <Text component="h3" text="Alterar senha" />
              <button className="closeBtn" onClick={cancelPassword}>
                <IoMdClose />
              </button>
            </div>
            <PasswordManager
              cancelPassword={cancelPassword}
              setSucessChangePassword={setSucessChangePassword}
            />
          </>
        ) : (
          <>
            <div className="topbarHeader">
              <button className="closeBtn" onClick={cancelPassword}>
                <IoMdClose />
              </button>
            </div>
            <div className="htmlBody">
              <div className="notification-container">
                <div className="icoBase">
                  <HiOutlineEmojiHappy />
                </div>
                <Text
                  component={"h1"}
                  text={"Senha alterada <span>com sucesso!</span>"}
                />
                <Text
                  component={"p"}
                  text={
                    "Use a nova senha a partir de agora para fazer o login na plataforma."
                  }
                />
              </div>
            </div>
            <div className="buttonsWrapper buttonsWrapperSucess">
              <Button
                callEvent={() => {
                  cancelPassword();
                  setSucessChangePassword(false);
                }}
                className={`button06`}
                text={"Ok, entendi"}
              />
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ModalPassword;
