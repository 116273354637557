import React from "react";
import {
    getFooterSize,
    getDesign,
  } from "../../services/utils";
  import MosaicCard from "./components/MosaicCard";

const MosaicList = ({data, idSection}) => {
    return(
        <div
            className="container-mosaic"
            style={{ ...getDesign(idSection, "general"), ...getFooterSize() }}
        >
            {data &&
                data.map((item, index) => {
                return (
                    <MosaicCard key={index} item={item} index={index} idSection={idSection} dataLength={data.length}/>
                );
                })
            }
        </div>
    )
}

export default MosaicList;