import React, { useState, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import useModalOverflowHidden from '../../../../../pctHooks/useModalOverflowHidden';
import {
    postImgExternalCoursesServices,
    createExternalCoursesServices,
    editExternalCoursesServices,
    getExternalCoursesServicesId,
} from '../../ExternalCourse.services';
import { ExternalCourseType, externalCourseInit } from '../../ExternalCourse.types';
import { validateFileName, validateDates } from '../../ExternalCourse.utils';
import { ModalExternalCourseProps } from '../ModalExternalCourse';

type PartialExternalCourseType = Partial<ExternalCourseType>;

const ModalExternalCourseController = ({ item = null, resetItemOffset }: ModalExternalCourseProps) => {
    const modal = useModal();

    const [loading, setLoading] = useState(false);
    // const [elemExternalCourses, setElemExternalCourses] = useState(null);

    const [errorMessageFile, setErrorMessageFile] = useState<string | null>(null);

    const [externalCourseForm, setExternalCourseForm] = useState<any>(null);
    const [disableForm, setDisableForm] = useState(
        Boolean(item) && (item?.status === 'CANCELED' || item?.status === 'APPROVED' || item?.status === 'REJECTED'),
    );

    useModalOverflowHidden(modal);

    useEffect(() => {
        if (item) {
            setLoading(true);
            getExternalCoursesServicesId({
                idUserExternalCourse: item?.idUserExternalCourse,
                setExternalCourseForm,
                setLoading,
            });
        } else {
            setExternalCourseForm(externalCourseInit);
        }
    }, [item]);

    const handleFileApi = (file, setFieldValue) => {
        // Define o arquivo selecionado
        setLoading(true);

        const filename = file.name.split('.')[0].replace(/\s+/g, '');

        let dataFormData = new FormData();
        dataFormData.append('fileData', file);
        dataFormData.append('fileName', filename);

        postImgExternalCoursesServices({ dataFormData, setLoading, setFieldValue, setErrorMessageFile });
    };

    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    ) => {
        setFieldValue('evidencePath', null);
        setErrorMessageFile(null); // Limpa mensagens de erro anteriores
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            // Valida formato
            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            if (!allowedTypes.includes(file.type)) {
                setErrorMessageFile('Apenas arquivos PDF, JPG ou PNG são permitidos.');
                return;
            }

            // Valida tamanho
            const maxFileSize = 5 * 1024 * 1024; // 5MB
            if (file.size > maxFileSize) {
                setErrorMessageFile('O arquivo deve ter no máximo 5MB.');
                return;
            }
            // Validar e Renomeia o arquivo criando um novo objeto File
            const fileRename = new File([file], validateFileName(file.name), { type: file.type });

            handleFileApi(fileRename, setFieldValue);
            // setLoading(false);
        }
    };

    const closeModal = () => {
        modal.remove();
    };

    const onSubmitExternalCourse = (values, setSubmitting) => {
        const isValidDate = validateDates(values.startDate, values.finishDate);

        if (!isValidDate) {
            setErrorMessageFile(
                'Período de realização inválido. A data final deve ser maior ou igual à data de início e não pode ser superior à data atual. Por favor, verifique as datas e tente novamente.',
            );
            setSubmitting(false);
            return;
        }

        setErrorMessageFile(null); // Limpa mensagens de erro anteriores
        setLoading(true);

        let ExternalCoursesServicesObj = {
            values,
            setLoading,
            resetItemOffset,
            closeModal,
            setErrorMessageFile,
            setSubmitting,
        };

        if (item) {
            if (externalCourseForm.status !== 'REJECTED') {
                editExternalCoursesServices({
                    idUserExternalCourse: item.idUserExternalCourse,
                    ...ExternalCoursesServicesObj,
                });
            } else if (externalCourseForm.status === 'REJECTED' && !externalCourseForm.hasResubmission) {
                const valuesRejected: Partial<ExternalCourseType> = {
                    title: values.title,
                    type: values.type,
                    workload: values.workload,
                    expirationDate: values.expirationDate,
                    startDate: values.startDate,
                    finishDate: values.finishDate,
                    entityName: values.entityName,
                    evidencePath: values.evidencePath,
                    idParentUserExternalCourse: values.idUserExternalCourse,
                };

                ExternalCoursesServicesObj = { ...ExternalCoursesServicesObj, values: valuesRejected };
                createExternalCoursesServices(ExternalCoursesServicesObj);
            }
        } else {
            createExternalCoursesServices(ExternalCoursesServicesObj);
        }
    };

    return {
        externalCourseForm,
        loading,
        errorMessageFile,
        handleFileChange,
        closeModal,
        onSubmitExternalCourse,
        disableForm,
        setDisableForm,
    };
};

export default ModalExternalCourseController;
