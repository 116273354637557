import React from 'react';
import '../assets/css/YTPlaylist.scss';
import { getDesignById, getColors } from '../services/utils';
import moment from "moment";
import {Rating} from './Rating';
import ZoomIcon from '../assets/images/zoom-icon.png';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

class ZoomPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: false,
            controls: true,
            idContentItem: 0,
            timeStart: moment().utc().format('YYYY-MM-DD HH:mm:ss')
        };

        this.resize = this.resize.bind(this);
    }

    resize() {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize);
        this.state.timeStart= moment().utc().format('YYYY-MM-DD HH:mm:ss');
    }

    _getDesign(item, only = false, valueOnly = false) {
        let design = getDesignById(this.props.idSection)
        if (!design[item]) { return null } // return null if theres no item specified
        if (only) { // parameter only means it will only return the property specified
            if (valueOnly) { return design[item][only] } // parameter valueOnly means it will only return the property value if specified
            design = { [only]: design[item][only] }
            return design
        }
        return design[item]
    }
    
    getFooterSize(){
        const { footerStatus } = this.props
        if((getColors('footer').showfooter == 'true' || getColors('footer').showfooter == true) || footerStatus == 'true'){
            return { minHeight: 'calc(100vh - 54px - 5rem' }
        }else{
            return {}
        }
      }

    render() {
        const { controls } = this.state
        const { isDesktop } = this.state
        const data = this.props.data
        const { updateHeaderStatus, updateFooterStatus } = this.props
        updateFooterStatus(this._getDesign('general')?.showBottomMenu || 'true')
        updateHeaderStatus(this._getDesign('general')?.showTopBar || 'true')

        return (
            <div className="container-fluid live-container" style={{...this._getDesign('general'), ...this.getFooterSize()}}>
                <Rating stars={data.rating} numberVotes={data.numberVotes} />
                <div
                    className="ytplayer-container"
                    style={{
                        paddingLeft: isDesktop ? '20%' : 2,
                        paddingRight: isDesktop ? '20%' : 2,
                        paddingTop: isDesktop ? '3%' : 10,
                        paddingBottom: isDesktop ? '5%' : 10
                    }}
                    onClick={()=> window.open(`${data.referenceLink}`, "_blank")}
                >
                    <div className="ytplayer-title" style={this._getDesign('itemText')}>
                        <h2>{data.text}</h2>
                        <hr className="ytplayer-barra" /><br></br>
                    </div>

                    <div style={{ position: 'relative' }}>
                        <img src={data.image} className="zoom-img" alt="img" />
                        <div className="gradient-support" style={{ bottom: 0 }} />
                        <span className="zoom-text">
                            <img src={ZoomIcon} style={{ height: 20, width: 20, marginRight: 5 }} alt="img" />
                            Clique para entrar
                            </span>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <span>
                            {data.description}
                        </span>
                    </div>

                </div>
                <div className="modal-player-rating">
                    <Rating id={data.idContentItem} userStar={data.vote} />
                </div>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  });

const mapStateToProps = store => ({
    footerStatus: store.footerState.footerStatus
});

export default connect(mapStateToProps, mapDispatchToProps)(ZoomPage);