import React, { useState } from 'react';
import './style.scss';

import { Text, Button, Preloader } from 'pctComponents/atoms';

import { useManagerTrial } from '../../context/contextManagerTrial';

import { DialogModel, PlaneProps, ViewScreen } from '../../@model/ManagerTrial.model';
import { dialogContainerModel } from '../../utils/DialogContainerModel';
import { getRequestPlan, renewlogin } from 'services/api';
import { useModal } from '@ebay/nice-modal-react';
import { formatValueReal } from 'services/utils';

const DetailPlanUpgrade = (props) => {
    const { currentPlan, setViewScreenPlan, setDialogPlan } = useManagerTrial();
    const { name: planName, numberOfEmployees, totalMonthlyPrice, idPlan } = currentPlan as PlaneProps;
    const modal = useModal();
    const [loading, setLoading] = useState(false);

    const confirm = async () => {
        setLoading(true);
        try {
            const {
                data: { statusCode },
            } = await getRequestPlan({
                idPlan,
                numberOfLicences: numberOfEmployees,
                price: totalMonthlyPrice,
            });

            if (statusCode && statusCode !== 200) {
                modal.remove();
                return;
            }

            renewlogin();
            props.updatePlan({
                ...props.planUpgrade,
                isUpgradeButtonActive: false,
                isPlanContractButtonActive: false,
            });

            setLoading(false);
            setViewScreenPlan(ViewScreen.dialog);

            setDialogPlan(
                dialogContainerModel({
                    type: DialogModel.ConfirmPlan,
                    plan: planName,
                    buttonConfirm: {
                        ///Abre uma aba novo Direcionando para a área do Adm
                        callEvent: async () => {
                            // const _menuData = localStorage.getItem('menu-data');
                            // const { token } = _menuData ? JSON.parse(_menuData) : null;

                            // const _appDetails = localStorage.getItem('appDetails');
                            // const { adminBucket } = _appDetails ? JSON.parse(_appDetails) : null;

                            // window.open(`https://${adminBucket}?token=${token}#/login`);

                            modal.remove();
                        },
                    },

                    ///Fecha o modal de confirmarção da compra do plano
                    // buttonCancel: {
                    //     callEvent: async () => {
                    //         modal.remove();
                    //     },
                    // },
                }),
            );
        } catch (e) {
            console.error(e);
            modal.remove();
        }
    };

    return (
        <>
            {loading ? <Preloader className="pctPreloaderBlur" /> : <></>}
            <div className="pctDetailPlanUpgrade">
                <Text component={'h2'} text={'Detalhes do seu pedido'} />
                <div className="pctDetailPlanUpgradeBase">
                    <Text component={'p'} text={'Resumo da contratação:'} />
                    <div className="containerInfo">
                        <div className="lineInfo">
                            <Text component={'p'} text={'Plano contratado:'} />
                            <Text component={'p'} className="linePlane" text={`Plano ${planName}`} />
                        </div>
                        <div className="lineInfo">
                            <Text component={'p'} text={'Quantidade de licenças desejadas:'} />
                            <Text component={'p'} className="linePlane" text={`${numberOfEmployees}`} />
                        </div>
                        <div className="lineInfo">
                            <Text component={'p'} text={'Valor mensal a ser pago:'} />
                            <Text
                                component={'p'}
                                className="linePlane"
                                text={`${formatValueReal(totalMonthlyPrice)}`}
                            />
                        </div>
                    </div>
                </div>

                <div className="buttonsWrapper">
                    {<Button callEvent={() => confirm()} className={`button06`} text={`Finalizar solicitação`} />}
                </div>
            </div>
        </>
    );
};

export default DetailPlanUpgrade;
