
import './style.scss';
import React, {useEffect, useState, useRef } from "react"
import { FilterLabels } from 'pctComponents/organisms';
import { withRouter, useParams } from "react-router-dom";
import { getSearch } from "../../../services/api";
import { getProfileInfo } from "../../../services/utils"
import { Preloader, Text } from 'pctComponents/atoms';
import { PlaceholderBlock } from 'pctComponents/placehoader';
import { CardBlock, CardStandard } from 'pctComponents/molecules';
import { updateConfigHeaderFooter } from "../../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import ReactPaginate from 'react-paginate';



type idType = {
  searchValue?: string;
};

const SearchResult = (props) => {

  const { updateConfigHeaderFooter } = props;

  const { searchValue } = useParams<idType>();
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsLenght = useRef(0);
  const pageNum = useRef(0);
  const itemsPerPage = 5;
  const currentItems = elements.filter(
    (item) =>
      (item !== null) 
  );
  const pageCount = Math.ceil(itemsLenght.current / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % itemsLenght.current;

    pageNum.current = event.selected;
    setItemOffset(newOffset);
  };

  function Items({ currentItems }) {
    return(
      currentItems.map((_collectionData:any, i:number) => {
        let convertToBlockElement = {} as BlockElement;
        convertToBlockElement.data = _collectionData;
        convertToBlockElement.data.parentType = "CATALOG";
        convertToBlockElement.elementType = "NODE";
    
        return <CardBlock 
            key={ i }
            id={ i }
            collection={ convertToBlockElement }    
          />
    
      })
    )
  };


  useEffect(() => {
    const { updateConfigHeaderFooter } = props;
    updateConfigHeaderFooter({});
    // updateConfigHeaderFooter({prevNavStyle: "hide"});
  }, []);
  

  useEffect(() => {
    setLoading(true);

    const params = {
      'searchString' : searchValue,
      'offset' : itemOffset,
      'limit' : itemsPerPage
    };

    getSearch(params)
      .then((res) => {
        itemsLenght.current = res.data.totalRecords;
        setElements(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchValue, itemOffset]);

  
  if (loading){ return( 
    <div className='pctSearchLoader sectionList'>
      {/* <Preloader className="pctPreloaderLists" />  */}
      {/* <h3> Aguarde, filtrando resultados para a busca <b>"{searchValue}"</b>.</h3> */}
      <Text component="h3" 
            text={`Aguarde, filtrando resultados para a busca <b>"${searchValue}"</b>.`} />
      <PlaceholderBlock number={1} />
    </div>
  )}


  return(
      <div className='pctSearch sectionList'>
          
            <div className='searchBody'>
               
              <div className='title'>
                <Text component="h3" text={itemsLenght.current > 0 ? `${itemsLenght.current} Resultados para a busca <b>"${searchValue}"</b>.` : `Não foram encontrados resultados para <b>"${searchValue}"</b>.` } />
              </div>
            
                
              <div className="searchResults">
                  <Items currentItems={currentItems} />
              </div>

              
              {
                (itemsLenght.current > 0)
                ?(<div className='paginate'>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="próximo >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        forcePage={pageNum.current}
                        previousLabel="< anterior"
                        renderOnZeroPageCount={null}
                        />
                  </div>)
                : null
              }

          </div>
      </div>
    )
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(SearchResult);
