import { Preloader, Text } from "pctComponents/atoms";
import { PlaceholderTree } from "pctComponents/placehoader";
import "./style.scss";

import React, { useState, useEffect, useRef } from "react";
import { getnode } from "services/api";
import { useFetch } from "pctHooks/useFetch";
import CardBlockJourney from "../CardBlockJourney";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { getProfileInfo } from "services/utils";
import { getUserEnrollment } from "services/api";
import CardBlockLMS from "../CardBlockLMS";
import AlertComponent from "pctComponents/organisms/AlertComponent";

type Props = {
  nodeCode?: string;
  className?: string;
  collection?: Array<BlockElement> | any;
  treeType?: string;
};

function MenuTree({
  nodeCode,
  collection,
  className = "menuTree01",
  treeType = "node",
}: Props) {
  const [enrollmentsArrObjects, setEnrollmentsArrObjects] = useState();
  const [displayChildren, setDisplayChildren] = useState({});

  const setUserEnrollmentArr = async () => {
    let tempArray = [] as Array<any>;

    await getUserEnrollment()
      .then((response) => {
        setEnrollmentsArrObjects(response["data"]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let fetchData = {} as LearningNode | any;
  let apiUrl = "" as string;

  if (treeType === "node") {
    if (collection == null) {
      apiUrl = `/learning/catalogs/enrollments`;
    }else{
      apiUrl = "";
    }
  }

  const { data, error } = useFetch({
    url: apiUrl,
    property: "nodeTree"
  });

  if (error) return <p>There is an error.</p>;
  if (!data) return <PlaceholderTree number={5} className={'plTree'} />;


  if(collection == null){
    if(data[0]){
      collection = data[0]["childNodes"];
    }else{
      // return <Text text="Sem matrículas." />;
      return <AlertComponent message="Sem matrículas." fullpage={false}/>
    }
    
  }
  
  // let filterData = [] as Array<Block>;
  let items = [] as Array<BlockElement> | any;
  items = collection;

  const getUserEnrollmentByNodeCode = (idNode) => {
    if (!enrollmentsArrObjects) {
      setUserEnrollmentArr();
    }

    let tempArray = [] as Array<any>;

    if (enrollmentsArrObjects) {
      const ArrObjects = enrollmentsArrObjects as Array<any>;

      if (ArrObjects.length > 0) {
        tempArray = ArrObjects.filter((item) => item["idNode"] === idNode);
      }
    }

    let rt =
      tempArray.length > 0 && tempArray[0].enrollment
        ? tempArray[0].enrollment
        : false;


    return rt;
  };

  switch (treeType) {
    case "node": {

        return (
          <div className={className}>
            <ul>
              {items?.map((item: BlockElement, i) => {
                let realTitle: string | any;
                item = item.data ? item : { data: item };
                realTitle =
                  item.data.title != null
                    ? item.data.title
                    : ("Sem título" as string);

                const hasChilds =
                  item.data["childNodes"].length > 0;

                return (
                  
                  <div  key={i}>

                      {/* {(hasChilds) &&  ( */}

                        <li key={i}>
                          <div className="item">
                            {hasChilds && (
                              <>
                                <Text
                                  component={"p"}
                                  text={realTitle + " "}
                                  className="primary-color"
                                />

                                <button
                                  className={
                                    displayChildren[realTitle] ? "" : "openTree"
                                  }
                                  onClick={() => {
                                    setDisplayChildren({
                                      ...displayChildren,
                                      [realTitle]: !displayChildren[realTitle],
                                    });
                                  }}
                                >
                                  {displayChildren[realTitle] ? <BsChevronUp /> : "+"}
                                </button>
                              </>
                            )}

                            {(!hasChilds) && (
                              <CardBlockJourney
                                key={i}
                                id={item.data.idNode}
                                collection={item}
                              />
                            )}
                          </div>

                          {displayChildren[realTitle] && (
                            // <MenuTree nodeCode={item.data.nodeCode} />
                            <MenuTree collection={item.data["childNodes"]} />
                          )}
                        </li>

                      {/* )} */}
                  </div>

                );
              })}
            </ul>
          </div>
        );
      

      break;
    }

    case "scorm": {
      return (
        <div className={className}>
          <ul>
            {collection.lessons?.map((item: any, i) => {
              return (
                <li key={i}>
                  <div className="item">
                    <CardBlockLMS
                      key={i}
                      collection={item}
                      idContentItem={collection.idContentItem}
                      idEnrollment={collection.enrollment}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
      break;
    }
    default: {
      return <></>;
      break;
    }
  }
}

export default MenuTree;
