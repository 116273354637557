import "./style.scss";

import React, { useEffect } from "react";

import { Text } from "../../atoms";
import { CardListing } from "..";
import { FaArrowLeftLong } from "react-icons/fa6";

const CardGrid = ({
  tableRows = null,
  data = null,
  parentData = null,
  setOpenGrid,
  openGrid,
  className = null,
}) => {
  useEffect(() => {
    if (openGrid) {
      document.body.classList.add("bodyOverflowHidden");
    }
  }, [openGrid]);

  const closeGrid = (e) => {
    document.body.classList.remove("bodyOverflowHidden");
    setOpenGrid(false);
  };

  return (
    <div
      className={`pctOrganism pctCardGridBase ${className ? className : ""}`}
    >
      <div className={`cardGridNav`}>
        <button onClick={closeGrid}>
          <FaArrowLeftLong />
          <Text component="h4" text={"Voltar"} className="" />
        </button>
      </div>
      <div className="cardGridBase sectionList">
        {data && CardListing({ data: data, _parentData: parentData })}
        {tableRows && tableRows}
      </div>
    </div>
  );
};

export default CardGrid;
