import { useState } from 'react'


export function useCopyToClipboard() {
  
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

  return [copyTextToClipboard]
}