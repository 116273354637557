import React, { useState, createContext, ReactNode, useContext } from "react";

import {
  PlaneProps,
  ViewScreen,
  Trial,
  DialogProps,
  DialogModel,
} from "../@model/ManagerTrial.model";

import { dialogContainerModel } from "../utils/DialogContainerModel";

interface ContextType {
  viewScreenPlan: ViewScreen;
  setViewScreenPlan: React.Dispatch<React.SetStateAction<ViewScreen>>;
  currentPlan: PlaneProps | null;
  setCurrentPlan: React.Dispatch<React.SetStateAction<PlaneProps | null>>;
  listPlans: PlaneProps[] | null;
  setListPlans: React.Dispatch<React.SetStateAction<PlaneProps[] | null>>;
  trialStatusPlan: Trial;
  setTrialStatusPlan: React.Dispatch<React.SetStateAction<Trial>>;
  dialogPlan: DialogProps | null;
  setDialogPlan: React.Dispatch<React.SetStateAction<DialogProps | null>>;
}

interface ProviderProps {
  children: ReactNode;
}

export const CreateManagerTrial = createContext<ContextType | undefined>(
  undefined
);

export const ContextManagerTrial = ({ children }: ProviderProps) => {
  const [viewScreenPlan, setViewScreenPlan] = useState<ViewScreen>(
    ViewScreen.upgrade
  );
  const [trialStatusPlan, setTrialStatusPlan] = useState<Trial>(
    Trial.notStarted
  );
  const [listPlans, setListPlans] = useState<PlaneProps[] | null>(null);
  const [currentPlan, setCurrentPlan] = useState<PlaneProps | null>(null);

  const [dialogPlan, setDialogPlan] = useState<DialogProps | null>(
    dialogContainerModel({
      type: DialogModel.ConfirmTrial,
      plan: "Light",
      buttonConfirm: {
        callEvent: () => {},
      },
    })
  );

  const value = {
    viewScreenPlan,
    setViewScreenPlan,
    trialStatusPlan,
    setTrialStatusPlan,
    listPlans,
    setListPlans,
    currentPlan,
    setCurrentPlan,
    dialogPlan,
    setDialogPlan,
  };

  return (
    <CreateManagerTrial.Provider value={value}>
      {children}
    </CreateManagerTrial.Provider>
  );
};

export const useManagerTrial = (): ContextType => {
  const context = useContext(CreateManagerTrial);
  if (!context) {
    throw new Error("Deve ser usado dentro ContextManagerTrial");
  }
  return context;
};
