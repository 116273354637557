import React, { useState, useEffect, useContext } from "react";

import {} from "services/api";

import { Text, Button } from "pctComponents/atoms";

import { Model } from "../index";

const ModalCompaingsTitle = ({ model, title = "" }) => {
  if (model === Model.SINGLE) {
    return (
      <Text
        component={"h2"}
        text={`Você está participando da Campanha: ${title}`}
      />
    );
  } else {
    return (
      <Text
        component={"h2"}
        text={`${
          model === Model.MULTIPLE
            ? "Confira as campanhas das quais você está participando:"
            : "Este Artigo pertence a estas campanhas:"
        }`}
      />
    );
  }
};

export default ModalCompaingsTitle;
