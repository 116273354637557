import React from 'react';
import { contentList, findContent } from '../services/api';
import { getDesignById } from '../services/utils';
import ContentSelector from '../container/ContentSelector';
import LoaderWhite from './LoaderWhite';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

class SinglePageSection extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = true;
		this.state = {
			isLoading: true,
			data: [],
		};
	}

	componentDidMount() {
		if (this._isMounted) {
			this._getContent(this.props.idSection);
		}
	}
    componentWillUnmount(){
        this._isMounted = false;
    }

	async _getContent(id) {
		this.setState({ isLoading: true });

		const result = await contentList(id);

		try {
			if (result.data.success === false) {
				this.setState({ isLoading: false });
				return;
			}
			id = result.data.content[0].idContentItem;

			await findContent(id)
				.then((response) => {
					this.setState({
						isLoading: false,
						data: response.data.content,
					});
				})
				.catch((err) => {
					this.setState({ isLoading: false });
				});
		} catch (err) {
			this.setState({ isLoading: false });
		}
	}

	_getDesign(item, only = false, valueOnly = false) {
		let design = getDesignById(this.props.idSection);

		if (!design[item]) {
			return null;
		} // return null if theres no item specified

		if (only) {
			// parameter only means it will only return the property specified
			if (valueOnly) {
				return design[item][only];
			} // parameter valueOnly means it will only return the property value if specified

			design = { [only]: design[item][only] };
			return design;
		}

		return design[item];
	}

	render() {
		return (
			<div>
				{this.state.isLoading ? (
					<LoaderWhite />
				) : (
					this.state.data.map((item, index) => {
						return (
							<div key={item.idContentItem}>
								{!this.state.isLoading && (
									<ContentSelector
										idContentItem={item.idContentItem}
										idSection={this.props?.idSection}
									/>
								)}
							</div>
						);
					})
				)}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
	updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(SinglePageSection);
