import React from "react";

const SmileUnhappy = () => {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g id="unhappy-smile" transform="translate(-2 -2)">
        <g id="Grupo_4464" data-name="Grupo 4464">
          <path id="Caminho_3195" data-name="Caminho 3195" d="M9,11a1,1,0,1,0-1-1A1,1,0,0,0,9,11Z" transform="translate(2.667 3.111)" fill="#feac0e" stroke="#000" stroke-width="0"/>
          <path id="Caminho_3196" data-name="Caminho 3196" d="M14,17a2,2,0,0,0-4,0H8a4,4,0,0,1,8,0Z" transform="translate(4 5.5)" fill="#feac0e" stroke="#000" stroke-width="0"/>
          <path id="Caminho_3197" data-name="Caminho 3197" d="M16,10a1,1,0,1,1-1-1A1,1,0,0,1,16,10Z" transform="translate(5.333 3.111)" fill="#feac0e" stroke="#000" stroke-width="0"/>
          <path id="Caminho_3198" data-name="Caminho 3198" d="M30,16A14,14,0,1,1,16,2,14,14,0,0,1,30,16Zm-2.8,0A11.2,11.2,0,1,1,16,4.8,11.2,11.2,0,0,1,27.2,16Z" fill="#feac0e" stroke="#000" stroke-width="0" fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
  `
}

export default SmileUnhappy;