import { useEffect, useRef, useState } from 'react'

import {updateStatusLike} from 'services/api';

enum likeMessage  {
    LIKE = 'Curtir',
    CANENROLLMENT = 'Para curtir, é necessário estar matriculado',
}

enum likeStatus  {
    DISLIKE = 'dislike',
    LIKE = 'like',
    INACTIVE = 'inactive',
    CANENROLLMENT = 'canEnrollment',
}


const useLike = (collection, statusRegistered) => {

  const { data, elementType, idElement } = collection;
  const { liked, likes, idNode, nodeType } = data;


  /// Para ter Like - tem que ser um Content ou NODE -> ( LEARNING ) 
  const createLike = (elementType === 'CONTENT' || (elementType === 'NODE' && nodeType === 'LEARNING'));

  const [inactiveTooltip, setInactiveTooltip] = useState(true);
  const [messageTooltip, setMessageTooltip] = useState(likeMessage.LIKE);
  const [toLike, setToLike] = useState(liked ? likeStatus.LIKE : likeStatus.DISLIKE);

  const likeCountRef = useRef(likes ? likes : 0);

  
  useEffect(()=>{

    if(collection && data){
        likeCountRef.current = likes || 0;
        setToLike(liked ? likeStatus.LIKE : likeStatus.DISLIKE)
        // console.log(collection);
    }
    

    if(statusRegistered === 'inactive' || statusRegistered === 'canEnrollment'){
        setToLike(statusRegistered)
        if(statusRegistered === 'canEnrollment'){
            setMessageTooltip(likeMessage.CANENROLLMENT);
            setInactiveTooltip(false);
        }
    }

  },[collection])

  const callHeart = (e) => {
    e.stopPropagation();

    if(toLike !== likeStatus.CANENROLLMENT){
        
        const id = elementType === 'NODE' ? idNode : idElement;

        if(toLike === likeStatus.DISLIKE){
            likeCountRef.current += 1;
            updateStatusLike('POST', elementType, id );
        }else{
            likeCountRef.current -= 1;
            updateStatusLike('DELETE', elementType, id );
        }

        setToLike(toLike === likeStatus.DISLIKE ? likeStatus.LIKE : likeStatus.DISLIKE);
    }
    
  };


  return { 
           createLike,
           toLike, 
           likeCount:likeCountRef.current, 
           inactiveTooltip,
           messageTooltip, 
           callHeart
        }
}

export default useLike