import "./style.scss";

import React, { useContext } from "react";
import { CreateEvaluationKnowledgeContext } from "../../../pctContexts/contextEvaluationKnowledge";
import {
  EvaluationKnowPlayerHeader,
  EvaluationKnowPlayerContent,
  EvaluationKnowPlayerFooter,
} from "pctComponents/molecules";

type Props = {
  finishEvent?: any;
};

const EvaluationKnowPlayer = ({ finishEvent }: Props) => {
  const { evaluationCurrentQuestion } = useContext(
    CreateEvaluationKnowledgeContext
  );

  return (
    <div className="ptcEvaluationKnowPlayer">
      <div className="ptcEvaluationKnowPlayerHeader">
        <EvaluationKnowPlayerHeader />
      </div>

      <div className="ptcEvaluationKnowPlayerContent">
        <EvaluationKnowPlayerContent
          currentQuestion={evaluationCurrentQuestion()}
        />
      </div>

      <div className="ptcEvaluationKnowPlayerFooter">
        <EvaluationKnowPlayerFooter finishEvent={finishEvent} />
      </div>
    </div>
  );
};

export default EvaluationKnowPlayer;
