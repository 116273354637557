import "./style.scss";
import React from "react";
import { AiFillHome as HomeIco } from "react-icons/ai";

import { Tooltip, withStyles } from "@material-ui/core";

const BreadCrumb = (props) => {
  const { edit = false, catalogItem, key = "title" } = props;
  const limit = 40;

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: "12px",
      maxWidth: "600px",
      background: "#000000",
    },
  }))(Tooltip);

  const limitText = (text) => {
    const newText = text.length > limit ? `${text.substr(0, limit)}...` : text;
    return newText;
  };

  const parentNodes = catalogItem.parentNodes.filter(
    (it, indice) => indice > 0
  );

  const parentF =
    parentNodes.length > 0
      ? parentNodes
          .map((it) => {
            return limitText(it[key]);
          })
          .join(" / ") + " / "
      : "";
  const stringParentNodes = parentF + limitText(catalogItem.title);

  const parentFTooltip =
    parentNodes.length > 0
      ? parentNodes
          .map((it) => {
            return it[key];
          })
          .join(" / ") + " / "
      : "";

  const stringParentNodesTooltip = parentFTooltip + catalogItem.title;

  return (
    <div className={`KEBreadCrumb mig ${edit ? "migEdit" : ""}`}>
      <CustomTooltip title={stringParentNodesTooltip} placement="top">
        <div className="migText">
          <HomeIco /> {stringParentNodes}
        </div>
      </CustomTooltip>
    </div>
  );
};

export default BreadCrumb;
