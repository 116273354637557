import "./style.scss";

import React from "react";
import { BiTime } from "react-icons/bi"

import moment from "moment"
import 'moment-duration-format'

import { Text } from '../'

type Props = {
  text?:any
  status?: boolean
  css?: object
  className?: string
  timeFormat?: any
}

function Duration({ text , status = true, css = {}, className = "", timeFormat = "seconds" }: Props ) {

  // const timeDuration = moment.duration(text, 'minutes');
  const formatted = ( 
      !!text 
        ? moment.duration(text, timeFormat).format( (text > 59) ? "h [h] m [m]" : "s [s]" ) 
        : "-");
  
  
  
  //const formatted = duration.hours() + "min";
  // if(duration.hours() === 0 && duration.minutes() > 0 ){
  //   formatted = duration.minutes() + "min"
  // }
  
  return (

    (!!text) ? <div className={`pctAtom pctDuration ${className}`} style={css} >
        <BiTime/>
        <div className="point">
            <Text component="small" text={`${formatted}`} />
        </div>
    </div> : null
  )
}

export default Duration
