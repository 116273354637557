import React from 'react';
import { Button, Preloader, Text } from 'pctComponents/atoms';
import { BsExclamationOctagon } from 'react-icons/bs';
import NiceModal from '@ebay/nice-modal-react';

import { ExternalCourseType } from '../../ExternalCourse.types';
import ModalCancelController from './ModalCancel.controller';
import './style.scss';

interface ModalCancel {
    item: Partial<ExternalCourseType>;
    resetItemOffset: () => void | null;
}

const ModalExternalCourse = NiceModal.create(({ item, resetItemOffset }: ModalCancel) => {
    const { loading, closeModal, onHandledCancel } = ModalCancelController({
        item,
        resetItemOffset,
    });

    return (
        <div className={`modalExternalCourseCancel`}>
            {loading && <Preloader />}
            <div className={`modal-body-pct`}>
                <div className="modalExternalWrapper">
                    <div className="box-notification">
                        <div className="headerIcon">
                            <BsExclamationOctagon />
                        </div>
                        <Text component={'h2'} text={'Cancelar solicitação de curso externo?'} />
                        <Text
                            component={'p'}
                            text={`Você está prestes a cancelar a solicitação de 
                                inclusão do curso externo <span>${item.title}</span>. Esta ação não poderá 
                                ser desfeita. Deseja continuar?`}
                            className=""
                        />
                    </div>
                    <div className="buttonsWrapper">
                        <Button callEvent={closeModal} className={`button01`} text={'Manter solicitação'} />

                        <Button
                            className={`button06`}
                            text={'Cancelar solicitação'}
                            type="submit"
                            callEvent={(e) => {
                                e.preventDefault();
                                onHandledCancel();
                                // props.handleSubmit();
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ModalExternalCourse;
