import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";

import { api, contentList, recordTracking } from "../../services/api";
import "./styles.scss";

import Loader from "../Loader";
import folderIcon from "../../assets/images/folder-icon.png";

export default function Teste({ idSection }) {
  const [data, setData] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [timeStart, setTimeStart] = useState(
    moment().utc().format("YYYY-MM-DD HH:mm:ss")
  );
  const [idContentItem, setIdContentItem] = useState(0);
  const [error, setError] = useState(false);

  async function _getContent(id) {
    setError(false);
    await contentList(id)
      .then((res) => {
        if (res.data.success === false) {
          return;
        }
        const idContentItem = res.data.content[0].idContentItem;
        setIdContentItem(idContentItem);
        api.get(`/mediacenter/${idContentItem}`, { timeout: 30000 })
          .then((response) => {
            setData(response.data.files);
          })
          .catch((error) => {
            console.log("error", error);
            setError(true);
          });
      })
      .catch((err) => {
        console.log("error", err);
        setError(true);
      });
  }

  useEffect(() => {
    if (idSection) {
      setTimeStart(moment().utc().format("YYYY-MM-DD HH:mm:ss"));
      _getContent(idSection);
    }
  }, [idSection]);

  useEffect(() => {
    if (!folders.length) return;

    if (currentFolder) {
      const nextFolder = folders.find(({ key }) => currentFolder.key === key);
      setCurrentFolder(nextFolder);
    } else {
      setCurrentFolder(folders.find(({ folderLevel }) => folderLevel === 1));
    }
  }, [folders]);

  useEffect(() => {
    if (!data.length) return;

    let listFolders = [];

    data.forEach((folder) => {
      const { key, isFolder } = folder;

      if (!isFolder) return;

      const folderFiles = data
        .map((file) => {
          const { isFolder, key: keyFile } = file;

          if (isFolder) return "";

          if (key.split("/").length !== keyFile.split("/").length) return "";

          if (!keyFile.includes(key)) return "";

          return file;
        })
        .filter(String);

      const foldersInThisFolder = data
        .map((folder) => {
          const { isFolder, key: keyFolder } = folder;

          if (!isFolder || key === keyFolder) return "";

          if (key.split("/").length + 1 !== keyFolder.split("/").length)
            return "";

          if (!keyFolder.includes(key)) return "";

          return folder;
        })
        .filter(String);

      const folderLevel = key.split("/").length - 2;

      listFolders.push({
        ...folder,
        folderLevel,
        folders: foldersInThisFolder,
        files: folderFiles,
      });
    });

    setFolders(listFolders);
    return () => {
      recordTracking(timeStart, idContentItem, 1, idSection)
        .then((res) => {})
        .catch((err) => {});
    };
  }, [data]);

  const getExtension = (key) => {
    const lastIndex = key.split(".").length - 1;
    const extension = key.split(".")[lastIndex];
    return extension;
  };

  const getFileName = (key) => {
    const lastIndex = key.split("/").length - 1;
    const name = key.split("/")[lastIndex];
    return name;
  };

  const getFolderName = (key) => {
    const lastIndex = key.split("/").length - 2;
    const name = key.split("/")[lastIndex];
    return name;
  };

  const setNextFolderLevel = (nextKey) => {
    const nextFolder = folders.find(({ key }) => nextKey === key);
    setCurrentFolder(nextFolder);
  };

  const handleBreadcrumbs = (index) => {
    const nextFolder = currentFolder.key
      .split("/")
      .filter(String)
      .map((key, i) => (i <= index ? key : ""))
      .filter(String)
      .join("/");

    setNextFolderLevel(`/${nextFolder}/`);
  };

  return (
    <>
      {error ? (
        <div className="library-error">Erro ao carregar conteúdo</div>
      ) : (
        <div className="library">
          {!data.length ? (
            <Loader />
          ) : (
            <div className="library__content">
              <div className="breadcrumbs">
                {currentFolder &&
                  currentFolder.key
                    .split("/")
                    .filter(String)
                    .map((item, index) => (
                      <h3
                        key={index}
                        onClick={() => {
                          handleBreadcrumbs(index);
                        }}
                      >
                        <span>{item}</span>
                        {currentFolder.key.split("/").filter(String).length -
                          1 !==
                          index && <i>{" / "}</i>}
                      </h3>
                    ))}
              </div>

              <div className="library__header">
                <span>Nome</span>
                <span>Modificado</span>
              </div>

              {currentFolder && (
                <>
                  {currentFolder.folders.map(({ key, date }, index) => (
                    <div className="library__folder" key={index}>
                      <img src={folderIcon} alt={""} />
                      <span
                        className="span__text_folder"
                        onClick={() => setNextFolderLevel(key)}
                      >
                        {getFolderName(key)}
                      </span>
                      <span onClick={() => setNextFolderLevel(key)}>
                        {moment(date).format("LL")}
                      </span>
                    </div>
                  ))}

                  {currentFolder.files.map(({ key, url, date }, index) => (
                    <div key={index} className="library__file">
                      <i className={getExtension(key)} />
                      <a href={url} download target="_blank">
                        <span className="span__text_folder">
                          {getFileName(key)}
                        </span>
                      </a>
                      <span>{moment(date).format("LL")}</span>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
