import { initEvaluation } from '../services/api';
import { useHistory } from 'react-router-dom';

const useEvaluation = ({ collection, evaluationEnrollment, modalLoadingEvaluation, preTest }) => {
    const history = useHistory();

    const callEvaluation = () => {
        const objInit = {
            idEnrollment: evaluationEnrollment().enrollment.idEnrollment,
            idNodeEnrollment: evaluationEnrollment().idNodeEnrollment,
            idEvaluation: collection.idEvaluation ? collection.idEvaluation : collection.idElement,
            idUser: evaluationEnrollment().user.idUser,
            questionnaireType: preTest ? 'PRE_TEST' : collection.data.evaluationType,
        };

        let idQuestionnaireFromQuestionnary = null;

        if (collection.data.hasOwnProperty('questionnaire')) {
            if (collection.data.questionnaire.length > 0) {
                idQuestionnaireFromQuestionnary = collection.data.questionnaire[0].idQuestionnaire;
            }
        }

        if (!collection.data.idQuestionnaire && !idQuestionnaireFromQuestionnary) {
            modalLoadingEvaluation.show({
                obj: {
                    message: 'Aguarde, iniciando a avaliação!',
                    closeBtn: false,
                    loadding: true,
                },
            });

            initEvaluation(objInit)
                .then((response) => {
                    modalLoadingEvaluation.remove();
                    history.push(
                        `/evaluations/${objInit.idEnrollment}/${objInit.idEvaluation}/${
                            response.data.hasOwnProperty('idQuestionnaire')
                                ? response.data.idQuestionnaire
                                : response.data.questionnaire[0].idQuestionnaire
                        }`,
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            history.push(
                `/evaluations/${objInit.idEnrollment}/${objInit.idEvaluation}/${
                    collection.data.hasOwnProperty('idQuestionnaire') ? collection.data.idQuestionnaire : idQuestionnaireFromQuestionnary
                }`,
            );
        }
    };

    return { callEvaluation };
};

export default useEvaluation;
