import React from 'react';
import { Text } from 'pctComponents/atoms';
import { FaExclamationCircle } from 'react-icons/fa';

const AlertNotification = ({ item }) => {
    const textNotification = item?.hasResubmission
        ? '<b>Essa solicitação já foi reenviada</b>. Aguarde a análise do administrador ou gestor responsável!'
        : '<b>Sua solicitação foi rejeitada</b>. Se o motivo for alguma informação inserida incorretamente, você pode fazer os ajustes clicando em  “Editar” e reenviar uma nova solicitação com os dados corretos.';
    return (
        <>
            {item?.status === 'REJECTED' ? (
                <div className="alertNotification">
                    <FaExclamationCircle className="exclamation" />
                    <Text component={'p'} text={textNotification} />
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default AlertNotification;
