import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { startCDN } from "services/api";
import { SCORM_CDN_TEST } from "services/utils";

const ScormCDNTest: React.FC = () => {
  const [cdnTestUrl, setCdnTestUrl] = useState<string>();
  const [cookies, setCookie] = useCookies([SCORM_CDN_TEST.COOKIE_NAME]);

  const startCDNTest = useCallback(async () => {
    const { data } = await startCDN();
    setCdnTestUrl(data.url);
  }, []);

  const handlerMessage = useCallback(
    (e: MessageEvent) => {
      if (e.data === "CDN_TEST_PASSED") {
        setCookie(SCORM_CDN_TEST.COOKIE_NAME, SCORM_CDN_TEST.PASSED, {
          path: "/",
          maxAge: 60 * 60 * 24 * 7,
        });
      } else if (e.data === "CDN_TEST_FAILED") {
        setCookie(SCORM_CDN_TEST.COOKIE_NAME, SCORM_CDN_TEST.FAILED, {
          path: "/",
          maxAge: 60 * 60 * 24 * 7,
        });
      }
    },
    [setCookie]
  );

  useEffect(() => {
    startCDNTest();

    window.addEventListener("message", handlerMessage);
    return () => {
      window.removeEventListener("message", handlerMessage);
    };
  }, [cookies, handlerMessage, startCDNTest]);

  return (
    <>
      {cdnTestUrl && (
        <iframe
          style={{ display: "none" }}
          title="scormCDNTest"
          src={cdnTestUrl}
        />
      )}
    </>
  );
};
export default ScormCDNTest;
