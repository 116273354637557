import "./style.scss";
import React from "react";
import { Duration, Label, Text, Grade, ProgressBar, Visualization } from "../../atoms";
import { BsUnlock, BsLockFill } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";

type Props = {
  collection: any;
  idContentItem: string;
  idEnrollment: string;
  idNodeEnrollment?: string;
};


const CardBlockLMS = ({ collection, idContentItem, idEnrollment }: Props) => {
  const history = useHistory();

  let { scoEnrollment } = collection;
  const { idNodeEnrollment } = useParams<Props>();

  const {
    title,
    label,
    workload,
    idSco,
    masteryScore
  } = collection;


  let grade: any;
  let numberOfAccess: any;
  let idScoEnrollment: any;
  let finishDate: any;
  let status: any;
  let totalTime: any;


  
  if(scoEnrollment){
    grade= scoEnrollment.grade;
    numberOfAccess= scoEnrollment.numberOfAccess;
    idScoEnrollment= scoEnrollment.idScoEnrollment;
    finishDate= scoEnrollment.finishDate;
    status= scoEnrollment.status;
    totalTime= scoEnrollment.totalTime;
  }else{
    scoEnrollment = {};
  }


  const prerequisites = collection.prerequisites == null || collection.prerequisites == false ? "0" : (scoEnrollment.prerequisitesMet != true? "1" : "0");

  const timeToMinutes = (time) => {                
    let [hours, mins] = time.split(":");
    hours = hours/12 > 1 ? hours-12 : hours;
    
    return hours;
  }
  

  let concluidedText = status;
  let progress = 0;

  if (status === "COMPLETED") {
    concluidedText = "<i class='fa fa-check' aria-hidden='true'></i> Concluído";
    progress = 100;
  } else if (status === "NOT_ATTEMPTED" || status === undefined) {
    concluidedText = "Não iniciado";
  } else if (status === "IN_PROGRESS") {
    concluidedText = "Em andamento";
    progress = 50;
  }else if (status === "INCOMPLETE") {
    concluidedText = "Em andamento";
    progress = 50;
  } else if (status === "PASSED") {
    concluidedText = "<i class='fa fa-check' aria-hidden='true'></i> Aprovado";
    progress = 100;
  } else if (status === "FAILED") {
    concluidedText = "<i class='fa fa-xmark'></i> Reprovado";
    progress = 100;
  }

  
  
  const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    history.push(`/lessons/${idEnrollment}/${idContentItem}/${idSco}/${idNodeEnrollment}/multi/${progress === 100}`);
  };

  const itemClass = `cardBlockLMS ${prerequisites === "1"?'disabledCard':''}`;

  return (
    <div
      className={itemClass}
      onClick={(e) => {
        if(prerequisites === "0"){
            handleMouseEvent(e);
        }
        
      }}
    >
      
      <Label text={label ? label : "Lição"} />

      <div className={`infosContainer`}>
        <div className="headerInfos">
          {title && <Text component="p" text={title} />}
          <ProgressBar percentage={progress}/>
          {/* {description && <Text component="p" text={description} />} */}
        </div>

        <div className="footerInfos">
          {concluidedText && <Text component="small" text={concluidedText} />}
          {numberOfAccess > 0  && <Visualization text={numberOfAccess} />}
          {workload && <Duration text={timeToMinutes(workload)} timeFormat={"seconds"} />}
          {grade && <Grade grade={grade} masterScore={masteryScore} />}
        </div>
      </div>

      {prerequisites == "0" ? <BsUnlock className="lock"/> : <BsLockFill className="lock" />}

      
    </div>
  );
};

export default CardBlockLMS;
