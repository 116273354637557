import React from 'react';

import { Text } from '../../../../atoms';
import { StatusExternalCourseDescriptions } from '../../ExternalCourse.types';

const StatusLabel = ({ item }) => {
    return (
        <div className="labelModal">
            {item?.status && (
                <Text
                    component={'small'}
                    text={`${StatusExternalCourseDescriptions[item?.status]}`}
                    className={`smallLabelModal ${item?.status}`}
                />
            )}
        </div>
    );
};

export default StatusLabel;
