import "./style.scss";
import React, { useState, useEffect } from "react";

import { Tooltip, withStyles } from "@material-ui/core";
import ReactPaginate from "react-paginate";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { Text } from "../../atoms";
import { PlaceholderTable } from "pctComponents/placehoader";
import ModalDialog from "pctComponents/modals/ModalDialog";

import AlertComponent from "../AlertComponent";

import { BsArrowUpRightCircle, BsXCircle, BsInfoCircle } from "react-icons/bs";

import useRequestRegistered from "./hooks/useRequestRegistered";

type Props = {
  userId: number;
};

const RequestEnrollmentStatus = ({ userId }: Props) => {
  const {
    handlePageClick,
    handleChange,
    convertToBrDate,
    pageCount,
    dataRequestRegistered,
    status,
    itemsLenght,
    pageNum,
    statusFilter,
    itemAction,
    setItemAction,
    callToAction,
    confirmPending,
    loading,
  } = useRequestRegistered();

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: "12px",
      maxWidth: "600px",
      background: "#000000",
    },
  }))(Tooltip);

  const ActionBtn = ({ item }) => {
    const { status } = item;

    return (
      <>
        {status === "APPROVED" && (
          <CustomTooltip title={"Ir à página"} placement="top">
            <button
              className={`${"btn_" + status}`}
              onClick={() => {
                callToAction(item);
              }}
            >
              <BsArrowUpRightCircle />
            </button>
          </CustomTooltip>
        )}
        {status === "DENIED" && (
          <CustomTooltip title={"Visualizar justificativa"} placement="top">
            <button
              className={`${"btn_" + status}`}
              onClick={() => {
                callToAction(item);
              }}
            >
              <BsInfoCircle />
            </button>
          </CustomTooltip>
        )}
        {status === "PENDING" && (
          <CustomTooltip title={"Cancelar solicitação"} placement="top">
            <button
              className={`${"btn_" + status}`}
              onClick={() => {
                callToAction(item);
              }}
            >
              <BsXCircle />
            </button>
          </CustomTooltip>
        )}
      </>
    );
  };

  const ModalDenied = () => {
    return (
      <div className="containerModal">
        <BsInfoCircle />
        <div className="containerModalInfo">
          <Text
            component={"h4"}
            text={"Justificativa de reprovação da matrícula:"}
          />
          {itemAction && itemAction.reason && (
            <Text component={"p"} text={`${itemAction.reason}`} />
          )}
        </div>
      </div>
    );
  };

  const ModalPending = () => {
    return (
      <div className="containerModal">
        <BsXCircle />
        <Text
          component={"h4"}
          text={
            "Tem certeza de que deseja cancelar a solicitação de matrícula?"
          }
        />
      </div>
    );
  };

  return (
    <div className="ptcRequestRegistered">
      {loading && (
        <PlaceholderTable
          rowsNumber={11}
          columnsNumber={3}
          className="shortGamification"
        />
      )}

      {!loading && (
        <>
          <ModalDialog
            className="darkDilog"
            htmlBody={<ModalDenied />}
            btn1Info={{
              event: () => {
                setItemAction(null);
              },
              className: "",
              text: "Ok, entendi",
            }}
            show={!!(itemAction && itemAction.status === "DENIED")}
          />

          <ModalDialog
            className="darkDilog"
            htmlBody={<ModalPending />}
            btn1Info={{
              event: () => {
                setItemAction(null);
              },
              className: "light",
              text: "Sair",
            }}
            btn2Info={{
              event: () => {
                confirmPending();
              },
              text: "Cancelar Solicitação",
              className: "active",
            }}
            show={!!(itemAction && itemAction.status === "PENDING")}
          />

          <div className={"filterRequestRegistered"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={status.all}
                  onChange={handleChange}
                  name="all"
                />
              }
              label="Todos"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={status.approved}
                  onChange={handleChange}
                  name="approved"
                />
              }
              label="Aprovado"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={status.denied}
                  onChange={handleChange}
                  name="denied"
                />
              }
              label="Reprovado"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={status.pending}
                  onChange={handleChange}
                  name="pending"
                />
              }
              label="Aguardando"
            />
                <FormControlLabel
              control={
                <Checkbox
                  checked={status.canceled}
                  onChange={handleChange}
                  name="canceled"
                />
              }
              label="Cancelado"
            />
          </div>
          
        </>
      )}

      {!loading &&
        dataRequestRegistered &&
        dataRequestRegistered.length === 0 && (
          <AlertComponent
            message="Sem Solicitações de matrícula"
            fullpage={false}
          />
        )}

      {!loading &&
        dataRequestRegistered &&
        dataRequestRegistered.length > 0 && (
          <>
            <div className="pctTable">
              <table className="rwd-table">
                <tr>
                  <th>Título</th>
                  <th>Data solicitação</th>
                  <th>Data aprovação</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
                {dataRequestRegistered.map((item, indice) => {
                  return (
                    <tr key={indice}>
                      <td data-th="Título">{item.title}</td>
                      <td data-th="Data solicitação">
                        {convertToBrDate(item.creationDate)}
                      </td>
                      <td data-th="Data aprovação">
                        {convertToBrDate(item.statusDate)}
                      </td>
                      <td data-th="Status">
                        <Text
                          component={"small"}
                          text={`• ${statusFilter(item.status)}`}
                          className={`${item.status}`}
                        />
                      </td>
                      <td data-th="Ações">
                        <ActionBtn item={item} />
                      </td>
                    </tr>
                  );
                })}
              </table>

              {itemsLenght.current > 0 ? (
                <div className="pctPaginate">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="próximo >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    forcePage={pageNum.current}
                    previousLabel="< anterior"
                    renderOnZeroPageCount={null}
                  />
                </div>
              ) : null}
            </div>
          </>
        )}
    </div>
  );
};

export default RequestEnrollmentStatus;
