import React from "react";

import { IoMdClose } from "react-icons/io";
import "./style.scss";
import { Label, Preloader, Text, Duration } from "pctComponents/atoms";

import parse from "html-react-parser";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

type Props = {
  collection: BlockElement | any;
};

const ModalSyllabus = NiceModal.create(({ collection }: Props) => {
  const modal = useModal();

  const { title, label, description, syllabus, workload } = collection.data;

  return (
    <div className="ModalSyllabus">
      <div className="modal-body-pct">
        <div className="info">
          <div className="left">
            {title && <Text component="p" text={title} className="title" />}
            <div className="leftinfo">
              {label && <Label text={label} className="label" />}
              {workload && (
                <Duration
                  text={workload}
                  className="RightInfo"
                  timeFormat={"seconds"}
                />
              )}
            </div>
          </div>
          <div className="right">
            {description && <Text component="p" text={description} />}
          </div>
        </div>

        <Text component="h2" text={"Ementa"} className="ementaTitle" />
        <div className="syllabusContainer">{parse(`${syllabus}`)}</div>

        <button className="closeBtn" onClick={() => modal.remove()}>
          <IoMdClose />
        </button>
      </div>
    </div>
  );
});

export default ModalSyllabus;
