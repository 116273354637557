import "./style.scss";
import React, { useEffect } from "react";
import NotFoundLogo from '../../../assets/images/not-found.svg';
import { Button, Text } from "../../atoms";
import { MdOutlineSimCardAlert } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import useBackControll from "pctHooks/useBackControll";
import { useHistory } from "react-router-dom";

type Props = {
  message ?: string,
  fullpage ?: boolean,
  imageUrl ?: string
};



const AlertComponentRestrict = ({ message, fullpage = true, imageUrl }: Props) => {

  const { backControllFn } = useBackControll({});
  const history = useHistory();
  const onConfirm = () => {
    backControllFn(history);
  };

  return (
    <>
      <div style={{backgroundImage: `url(${imageUrl})`}} className={`pctAlertRestrict ${fullpage ? 'fullpage' : ''}`}>
          <div className="pctErrorContainer"> 
              
              <div className="headerMensage">
                <FaLock/>
                <Text component={'h1'} text={'Acesso restrito'} />
              </div>
              
              <Text className="mensage" text={message} />

              <Button className="button06" 
                      text="OK, entendi"
                      callEvent= {()=>{
                        onConfirm();
                      }}
                      />
          </div>
      </div>
    </>
  );
};


export default AlertComponentRestrict;
