import "./style.scss";

import React from "react";

const FailedSVG = () => {
  const svgStr = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-width="4" d="M3,3 L21,21 M3,21 L21,3"></path></svg>`;

  return svgStr;
};

export default FailedSVG;
