import React, { useEffect, useState } from "react";
import "../themes/import.scss";
import "../assets/css/CardPlayer.scss";
import { contentList } from "../services/api";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Loader from "./Loader";
import { updateFooterStatus, updateHeaderStatus } from "../actions";
import { connect } from "react-redux";
import style from "../container/ItemMenu/styles.module.scss";
import { NavLink } from "react-router-dom";
import { getFooterSize } from "../services/utils";

const PdfList = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contentData, setContentData] = useState([]);

  const _getContent = async (id) => {
    contentList(id)
      .then((res) => {
        if (res.data.success === false) {
          setError(true);
          return;
        }
        setContentData(res.data?.content || []);
      })
      .catch((err) => {
        console.log("error", error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    _getContent(props.idSection);
  }, []);

  return (
    <div style={{ ...getFooterSize() }}>
      <div className={[style.listPlugs]}>
        <div className="pdf-list-header-margin" />
        {loading ? (
          <Loader />
        ) : (
          contentData.map((item, index) => {
            return (
              <NavLink 
                index={index} 
                to={`/content/${item.idContentItem}`}
                key={index}
                className={"pdf-list-wrapper listPlugsItem"}
                style={{
                  display: "inline-block",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                  <div
                    className="pdf-list-img-wrapper"
                    style={{
                      backgroundColor: item.color,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "7px",
                      backgroundImage:
                        item.imageStyle === "hybrid"
                          ? `url( ${item.image} )`
                          : "",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "center center",
                    }}
                  >
                    <img
                      src={item.image}
                      alt=""
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  </div>
                  <div className="pdf-list-text-wrapper" style={{  }}>
                    <div className="pdf-list-text">
                      <PictureAsPdfIcon style={{ color: 'gray', marginRight: 5 }} />
                      <div style={{ fontWeight: 'bold', textDecoration: 'none', color: 'black' }}>{item.text}</div>
                    </div>
                  </div>
              </NavLink>
            );
          })
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(PdfList);
