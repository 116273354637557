import React, { useState } from 'react';
import usePlan, { PropsOpenModal } from 'pctHooks/usePlan';
import { ModalUpgradeCampaignConfig } from 'pctComponents/modals/ModalUpgradeCampaign';
import { License } from 'reducers/license';
import { NiceModalHandler } from '@ebay/nice-modal-react';
import ReactPlayer from 'react-player';
import useMediaQuery from 'pctHooks/useMediaQuery';
import { Skeleton } from '@material-ui/lab';
import './style.scss';
import { postRequestTrial } from 'services/api';
import PctPlanContract from '../PctPlanContract';
import PctUpgrade from '../PctUpgrade';
import { OriginNavigation } from '../ManagerTrial/@model/ManagerTrial.model';
interface CampaignContentProps extends ModalUpgradeCampaignConfig {
    upgradeParams?: {
        planUpgrade: License;
        updatePlan: any;
    };
    modal: NiceModalHandler; 
    activePlan?: any;
}

const CampaignContent = ({ title, description, textBtn, media, upgradeParams, modal, activePlan }: CampaignContentProps) => {
    const isMobile = useMediaQuery('md');
    const [loading, setLoading] = useState(true);
    const [playing, setPlaying] = React.useState(false);

    const onMouseEnter = (e) => {
        e.preventDefault();
        setPlaying(true);
    };

    const onMouseLeave = (e) => {
        e.preventDefault();
        setPlaying(false);
    };

    return (
        <div className="campaign-container-content">
            <div className="campaign-frame">
                {media?.mediaType === 'image' ? (
                    <img className="campaign-frame-content" src={media?.mediaUrl} alt={'title'} />
                ) : media?.mediaType === 'video' ? (
                    <>
                        {!media ? (
                            <Skeleton className="video-wrapper" variant="rect" />
                        ) : (
                            <div className="video-wrapper" {...(!isMobile && { onMouseEnter, onMouseLeave })}>
                                <ReactPlayer
                                    onReady={() => setLoading(false)}
                                    onError={() => setLoading(false)}
                                    playing={playing}
                                    url={media?.mediaUrl}
                                    controls
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        )}
                    </>
                ) : (
                    <iframe className="campaign-frame-content" src={media?.mediaUrl} title="campaign" />
                )}
            </div>
            <div className="campaign-text-content">
                <h2>{title}</h2>
                <p>{description}</p>
                {Boolean(activePlan) ? (
                    <PctPlanContract
                        callEvent={() => modal.remove()}
                        disableRuleIconModal={true}
                        text={textBtn}
                        hideNotification={true}
                        {...activePlan}
                        {...upgradeParams}
                    />
                ) : (
                    <PctUpgrade
                        callEvent={() => modal.remove()}
                        text={textBtn}
                        disableRuleIconModal={true}
                        originNavigation={OriginNavigation.header}
                        hideNotification={true}
                        {...upgradeParams}
                        {...upgradeParams?.planUpgrade}
                    />
                )}
            </div>
        </div>
    );
};

export default CampaignContent;
