import "./style.scss";

import React from "react";

import { Text } from '../'
import { AiFillEye } from "react-icons/ai"

type Props = {
  text:any
  css?: object
  className?: string
}

function Visualization({ text , css = {}, className = "" }: Props) {

    return (
      ( text !== null && text >= 0 )? <div className={`pctAtom pctVisualization ${className} 
        ${( +text > 0 ) ? "viewsAtivo" : '' }`} style={css} >
        <div className="point">
          <Text component="small" text={`${+text?.toString()}`} />
        </div>
        <AiFillEye/>
      </div>:null
    )
}

export default Visualization
