import './style.scss';
import React, { useState, useEffect, useCallback } from 'react';
import useIsMobile from 'pctHooks/useIsMobile';

const EvaluationKnowCorrelate = ({ optionAnswers, options, selectItemEvent, answerData, viewType }) => {
    const [optionsValue, setOptions] = useState<any>([]);
    const isMobile = useIsMobile();
    const [answerCorret, setAnswerCorret] = useState<
        {
            isCorrect: boolean;
            label: string;
            class: string;
        }[]
    >([]);
    useEffect(() => {
        const values: { index: number; value: number }[] = [];
        answerData.forEach((elem) => {
            const index = options.findIndex((item) => item.idQuestionOption === elem.idQuestionOption);
            values.push({ index: elem.idQuestionOptionAnswer, value: index + 1 });
        });
        setOptions(values);
        if (viewType === 'modalFeed') {
            const _answerCorret: any[] = [];
            optionAnswers.forEach((elem) => {
                const isCorrect = elem.idQuestionOptionAnswer === elem.idQuestionOptionAnswerCorrect;
                let index = 0;
                if (!isCorrect) {
                    index = options.findIndex((item) => item.idQuestionOption === elem.idQuestionOptionChosenCorrect);
                }
                _answerCorret.push({
                    isCorrect,
                    label: isCorrect ? 'Parabéns! Você acertou!' : `Você errou! Resposta: <span class="strong-number">${index + 1}</span> `,
                    class: isCorrect ? 'isCorrectSelected' : 'isErroSelected',
                });
            });
            setAnswerCorret(_answerCorret);
        }
    }, [answerData, options, optionAnswers, viewType]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, optionAnswer, index) => {
        let value = e.target.value;
        const numericValue = Number(value);
        if ((!isNaN(numericValue) && numericValue <= options.length && numericValue > 0) || !value) {
            const item = { idQuestionOption: value ? options[numericValue - 1].idQuestionOption : null, ...optionAnswer };
            selectItemEvent(!!value, item, e);
            setOptions((prevState) => {
                const newState = [...prevState];

                const _index = newState.findIndex((item) => item.index === index);

                if (_index > -1) {
                    newState[_index] = { index, value };
                } else {
                    newState.push({ index, value });
                }
                return newState;
            });
           
        }
    }, [options,selectItemEvent]);
    const getValue = useCallback((index) => {
        const option = optionsValue.find((elem) => elem.index === index);
        return option?.value || '';
    }, [optionsValue]);
    const optionAnswersComponent = useCallback((optionAnswers, index) => {
        return (
            <div
                key={optionAnswers.idQuestionOptionAnswer}
                className={`line ${answerCorret[index]?.class}`}
            >
                <div className="block">
                    <input
                        id={`alt${index}`}
                        disabled={viewType === 'modalFeed'}
                        placeholder="0"
                        type={'number'}
                        className="d-none input"
                        onChange={(e) => handleChange(e, optionAnswers, optionAnswers.idQuestionOptionAnswer)}
                        value={getValue(optionAnswers.idQuestionOptionAnswer)}
                    />
                    <span className="line-content">
                        {optionAnswers.description}
                        {viewType === 'modalFeed' && (
                            <div className={answerCorret[index]?.isCorrect ? 'box-sucess' : 'box-error'}>
                                <span dangerouslySetInnerHTML={{ __html: answerCorret[index]?.label }}></span>
                            </div>
                        )}
                    </span>
                </div>
            </div>
        );
    }, [answerCorret,  handleChange, getValue, viewType]);
    return (
        <div className="EvaluationKnowCorrelate">
            <div className="box">
                {options.map((option, index) => (
                    <div className="columnCorrrelate">
                        <div key={option.idQuestionOption} className="line">
                            <div className="block">
                                <div className="circle-number">{index + 1}</div>
                                <span className="line-content">{option.description} </span>
                            </div>
                        </div>
                        {!isMobile && 
                            <>
                                <div className="verticalLine" />
                                {optionAnswersComponent(optionAnswers[index], index)}
                            </>
                        }
                    </div>
                ))}
                {
                   isMobile &&  (
                    <>
                        <div className="horizontalLine" />
                       { optionAnswers.map((option, index) => (
                            <div className="columnCorrrelate">
                            { optionAnswersComponent(option, index)}
                            </div>
                            
                        ))}
                     </>
                   )                   
                         
                    }
            </div>
        </div>
    );
};

export default EvaluationKnowCorrelate;
