import "./style.scss";

import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import placeholderImg from "../../../assets/images/placeholder.png";

type Props = {
  path?: string | null | undefined;
  pathSet?: string | null | undefined;
  className?: string;
  placeholder?: boolean;
  cardEffect?: boolean;
  onError?: any;
};

function Image({
  path,
  pathSet = null,
  className = "",
  placeholder = false,
  onError = null,
  cardEffect = false
}: Props) {

  return (
    <div>
      {path !== null && path !== undefined && pathSet === null && (
        <LazyLoadImage
          className={`pctAtom pctImage ${className}`}
          src={path}
          loading={"lazy"}
          alt=""
          effect="blur"
        />
      )}

      {pathSet !== null && (
        <>
          <LazyLoadImage
            className={`pctAtom pctImage ${className}`}
            src={path}
            srcSet={pathSet}
            loading={"lazy"}
            alt=""
            effect="blur"
            onError={onError}
          />
          {cardEffect &&
            <div className={'cardEffect'}> </div>
          }
        </>
      )}

      {!!path === false && placeholder && (
        <LazyLoadImage
          className={`pctAtom pctImage ${className}`}
          src={placeholderImg}
          loading={"lazy"}
          alt=""
          effect="blur"
        />
      )}
    </div>
  );
}

export default Image;
