import React from 'react';

import GroupIcon from '@material-ui/icons/Group';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import { DrawerContent, DrawerTabs, EntityTypeEnum } from 'pctComponents/organisms/InteractionDrawer/InteractionDrawer.types';

const createTabLabel = (_isMobile, icon, text, notificationCount = null) => (
    <div className="interaction-tab-label">
        {notificationCount !== null ? (
            <div className="interaction-tab-label-enrolled-Badge">
                {icon}
                {/* <span className="interaction-tab-notification">{notificationCount}</span> */}
            </div>
        ) : (
            icon
        )}
        {!_isMobile && text}
    </div>
);

export const CreateDrawerContainer = (commentsObj, isMobile) => {
    const allowPageComments = Boolean(commentsObj.node.allowComments);
    const allowTeamComments = Boolean(commentsObj.team.allowComments);
    const tabs: DrawerTabs[] = [];
    const contents: DrawerContent[] = [];

    const pageCommentsLabel = Boolean(commentsObj.node.commentsLabel) ? commentsObj.node.commentsLabel : 'Interações e comentários';
    const teamCommentsLabel = Boolean(commentsObj.team.commentsLabel) ? commentsObj.team.commentsLabel : 'Interações e comentários na minha turma';

    if (allowPageComments) {
        tabs.push({
            id: 0,
            title: pageCommentsLabel,
            icon: <ChatBubbleOutlineIcon className="interaction-tab-icon" />,
            label: createTabLabel(isMobile, <ChatBubbleOutlineIcon className="interaction-tab-icon" />, pageCommentsLabel),
        });
        contents.push({
            title: pageCommentsLabel,
            entityType: EntityTypeEnum.NODE,
            idEntity: `${commentsObj.node.idEntity}`,
        });
    }

    if (allowTeamComments) {
        tabs.push({
            id: 1,
            title: teamCommentsLabel,
            icon: <GroupIcon className="interaction-tab-icon" />,
            label: createTabLabel(isMobile, <GroupIcon className="interaction-tab-icon" />, teamCommentsLabel),
        });
        contents.push({
            title: teamCommentsLabel,
            entityType: EntityTypeEnum.TEAM,
            idEntity: `${commentsObj.team.idEntity}`,
        });
    }

    return {
        showInteractionDrawer: allowPageComments || allowTeamComments,
        drawerContainer: {
            tabs,
            contents,
            enabledTabs: tabs.length >= 2,
        },
    };
};
