import { Preloader, Text } from 'pctComponents/atoms';
import './style.scss';

import React, { useState, useEffect, useRef } from 'react';
import { VscListTree } from 'react-icons/vsc';
import { CgCloseR } from 'react-icons/cg';
import { TreeMap } from '..';
import { connect } from 'react-redux';

type Props = {
    updateConfigContentsMap?: any;
    collection?: Array<object> | any;
};
function ContentTreeMap({ collection, updateConfigContentsMap }: Props) {
    const [menuState, setMenuState] = useState('');
    const [dataMap, setDataMap] = useState(collection);

    const openCloseControl = (e) => {
        setMenuState(menuState == '' || menuState == 'close' ? 'open' : 'close');
        e.stopPropagation();
    };

    useEffect(() => {
        if (updateConfigContentsMap.forceCurrentComplete) {
            let tempCollection = dataMap;
            tempCollection.forceCurrentComplete = true;
            setDataMap(tempCollection);
        } else {
            setDataMap(collection);
        }
    });

    const scorm = !!collection.content && !!collection.content.scorm;
    if (!collection.contentEnrollment || scorm) return <></>;

    return (
        <div className="contentTreeMap">
            {menuState == 'open' && (
                <div
                    className="overlayMap"
                    onClick={(e) => {
                        openCloseControl(e);
                    }}
                />
            )}

            <button
                className="contentTreeMapBtn"
                onClick={(e) => {
                    openCloseControl(e);
                }}
            >
                <VscListTree />
            </button>

            <div className={`treeMapContent ${menuState}`}>
                <div
                    className="closeBtn"
                    onClick={(e) => {
                        openCloseControl(e);
                    }}
                >
                    <CgCloseR />
                </div>

                {menuState == 'open' && <TreeMap collection={dataMap} closeMap={openCloseControl} />}
            </div>
        </div>
    );
}

const mapStateToProps = (store) => ({
    updateConfigContentsMap: store.configContentsMapState.configContentsMap,
});

export default connect(mapStateToProps, null)(ContentTreeMap);
