import React from 'react';
import { useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import ModalPreTest from 'pctComponents/modals/ModalPreTest';

import useCards from 'pctHooks/useCards';

import useBackControll from 'pctHooks/useBackControll';

type Props = {};

const usePreTest = (props) => {
    const { data: dataBlock, _dataParent } = props;

    const { backControllFn } = useBackControll({});

    const history = useHistory();
    const setProgressStateRef = useRef<any>(null);
    const progressInfoRef = useRef<any>(null);

    const cardData = {
        collection: dataBlock.elements[0],
        id: dataBlock.elements[0].idBlockElement,
        _dataParent,
        setProgressStateRef,
        progressInfoRef,
        statusPreTest: props.configPreTest.statusPreTest || false,
        preTest: true,
    };

    const { image, initialProgressState, statusElement, handleCardMouseEvent } = useCards(cardData);

    const handleClose = (e) => {
        props.updatePreTest({ statusPreTest: false });
        backControllFn(history);
    };

    const handleEvaluation = (e) => {
        props.updatePreTest({ statusPreTest: false });
        handleCardMouseEvent(e);
    };

    useEffect(() => {
        const { blockType, elements } = dataBlock;
        if (blockType === 'PreTest' && elements.length > 0) {
            const evaluationData = elements[0].data;
            const questionnaire = evaluationData.questionnaire;
            if (!questionnaire || (questionnaire && !questionnaire.finishDate)) {
                props.updatePreTest({ statusPreTest: true });
                const dataPreTest = { ...cardData, image, initialProgressState, statusElement };
                NiceModal.show(ModalPreTest, { dataPreTest, handleClose, handleEvaluation });
            }
        }
    }, []);
};

export default usePreTest;
