import React, { useEffect, useRef, useState } from 'react';
import { FaEllipsisV, FaRegTrashAlt } from 'react-icons/fa';
import './style.scss';
import { MdReportProblem, MdOutlineEdit } from 'react-icons/md';
import { IconType } from 'react-icons';
import MenuOption from 'pctComponents/atoms/MenuOption';

type OptionsMenu = {
    label: string;
    labelProps: any;
    icon: IconType;
    iconProps: any;
    onClick: () => void;
};

type Props = {
    menuOpitions: any;
    idComment: string;
    message?: string;
    handleReplyOpen?: any;
    removeButton: boolean;
    editButton: boolean;
    reportButton: boolean;
    reportedByMe: boolean;
    type: string;
    isReply: boolean; 
};
const InteractionMessageMenu = ({
    menuOpitions,
    idComment,
    message,
    handleReplyOpen,
    removeButton,
    editButton,
    reportButton,
    reportedByMe,
    isReply, 
    type,
}: Props) => {
    const [visible, setVisible] = useState(false);
    const [availableOptions, setAvailableOptions] = useState<OptionsMenu[]>([]);
    const menuRef = useRef<HTMLDivElement>(null);
    const toggleMenu = () => {
        setVisible(!visible);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        let availableOptions: OptionsMenu[] = [];

        if (editButton) {
            availableOptions = [
                ...availableOptions,
                {
                    label: 'Editar',
                    labelProps: {
                        style: {
                            marginLeft: 8,
                        },
                    },
                    icon: MdOutlineEdit,
                    iconProps: {
                        size: 18,
                    },
                    onClick: () => {
                        let data: any = { idComment, action: 'edit' };
                        if (!isReply && message != 'undefined' && message) {
                            data.replyEditText = message;
                        } else if (message != 'undefined' && message) {
                            data.type = 'replyEdit';
                            data.replyEditText = message;
                            handleReplyOpen(message);
                        } else {
                            data.type = '';
                        }
                        menuOpitions.setData(data);
                    },
                },
            ];
        }

        if (removeButton) {
            availableOptions = [
                ...availableOptions,
                {
                    label: 'Excluir',
                    labelProps: {
                        style: {
                            color: '#FF5353',
                            marginLeft: 8,
                        },
                    },
                    icon: FaRegTrashAlt,
                    iconProps: {
                        size: 18,
                        color: '#FF5353',
                    },
                    onClick: () => {
                        menuOpitions.setData({
                            idComment,
                            action: 'delete',
                            show: true,
                            textButton: 'Excluir',
                            title: 'Tem certeza que deseja excluir permanentemente esta mensagem?',
                            content: 'Atenção! Esta é uma ação irreversível',
                            type,
                        });
                    },
                },
            ];
        }
        if (reportButton) {
            availableOptions = [
                ...availableOptions,
                {
                    label: reportedByMe ? 'Denunciado' : 'Denunciar',
                    labelProps: {
                        style: {
                            color: '#FF5353',
                            opacity: reportedByMe ? 0.6 : 1,
                            marginLeft: 8,
                            cursor: 'default',
                        },
                    },

                    icon: MdReportProblem,
                    iconProps: {
                        size: 18,
                        color: '#FF5353',
                        opacity: reportedByMe ? 0.6 : 1,
                        cursor: 'default',
                    },
                    onClick: !reportedByMe
                        ? () => {
                              menuOpitions.setData({
                                  idComment,
                                  action: 'report',
                                  show: true,
                                  textButton: 'Denunciar',
                                  title: 'Denunciar esta mensagem como imprópria',
                                  content: 'Exemplos de conteúdo impróprio incluem assédio, violência, nudez e conteúdo ofensivo.',
                              });
                          }
                        : () => {},
                },
            ];
        }

        setAvailableOptions(availableOptions);
    }, [editButton, removeButton, reportButton, message, reportedByMe, idComment, isReply]);

    return (
        <div className={`menu-container-options`} ref={menuRef}>
            <FaEllipsisV className="menu-icon" onClick={toggleMenu} />
            {visible && (
                <div className="menu-options">
                    {availableOptions.map((option, index) => (
                        <MenuOption
                            key={index}
                            {...option}
                            onClick={() => {
                                option.onClick();
                                setVisible(false);
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default InteractionMessageMenu;
