import "./style.scss";

import React, { useState, useEffect } from "react";

import useMediaQuery from "pctHooks/useMediaQuery";

import { getProfileInfo } from "services/utils";

import { Text } from "pctComponents/atoms";
import { PlaceholderStandard } from "pctComponents/placehoader";
import { CardStandard, CarrousselView } from "pctComponents/molecules";
import { getLearningRecommendations } from "services/api";

import { FaAngleRight } from "react-icons/fa6";

import useCardsFormat from "pctHooks/useCardsFormat";

import { CardGridCatalog } from "pctComponents/organisms";

const Recommendation = (props) => {
  const { parameters } = props.data;
  const { displayColumnsElements, title, description } = parameters;

  const idUser = getProfileInfo().idUser;

  const [loading, setLoading] = useState(true);
  const [elementAll, setElementAll] = useState(0);
  const [elements, setElements] = useState<any[]>([]);
  const [indiceOffset, setIndiceOffset] = useState(0);
  const [indiceOffsetMobile, setIndiceOffsetMobile] = useState(false);

  const [completeloading, setCompleteLoading] = useState<Boolean | null>(null);

  const [openGrid, setOpenGrid] = useState(false);

  const flktyReady = (flkty, e) => {
    flkty.select(indiceOffset);
  };

  const flktyChange = (flkty, indice) => {
    if (indiceOffset < indice && elements.length <= elementAll) {
      if (!indiceOffsetMobile) {
        if (indice % 2 === 0) setIndiceOffset(indice);
      } else {
        if (indice % 5 === 0) setIndiceOffset(indice);
      }
    }
  };

  const { displayColumnsElementsCalc, formatCard, effectCard } =
    useCardsFormat(parameters);

  ///Calculo da dimensão da largura de cada card
  let colunsElements = displayColumnsElementsCalc
    ? displayColumnsElementsCalc
    : 5;
  // let colunsElements = 3;
  const widthCard = `${100 / colunsElements}%`;
  const widthCardStyle = { width: widthCard };
  let params = {};

  const activeVerTodos = elementAll > displayColumnsElementsCalc;

  ///Controle do offset e do limit da fn getSearchCatalog
  /// O offset é o indice do carrossel (indice * nu de itens por slide + (plus) para add o avançar em uma nova tela)
  /// O limit é o nu de itens por slide * 2 | no indice 0 é inclementado um (plus)
  /// O plus tem que ter um valor de no min 1 elemento para que sempre exista o avançar

  const smStatus = useMediaQuery("sm");
  const mdStatus = useMediaQuery("md");
  const lgStatus = useMediaQuery("lg");
  const mvStatus = useMediaQuery("mv");

  // 2 elem
  if (mdStatus) colunsElements = 2;

  // 3 elem
  if (lgStatus) colunsElements = 3;

  // 4 ou 5 elem
  // if (mvStatus)
  //   colunsElements = displayColumnsElements ? displayColumnsElements : 5;

  const offsetBase = indiceOffset * colunsElements;
  const plusOffsetInit = 1; // esse valor atendeu de 2 ate 5 card
  params = {
    idUser: idUser,
    offset: indiceOffset !== 0 ? offsetBase + plusOffsetInit : 0,
    limit:
      indiceOffset !== 0
        ? colunsElements * 2
        : colunsElements * 2 + plusOffsetInit,
  };

  /// Versao de 1 elemento
  if (smStatus) {
    params["offset"] = indiceOffset !== 0 ? indiceOffset + 1 : 0;
    params["limit"] = indiceOffset !== 0 ? 5 : 6;
  }

  useEffect(() => {
    if (smStatus) {
      setIndiceOffsetMobile(true);
    }
  }, [smStatus, mdStatus, lgStatus, mvStatus]);

  useEffect(() => {
    setLoading(true);
    createMap();
  }, [indiceOffset]);

  const createMap = async () => {
    try {
      const res = await getLearningRecommendations(idUser, params);

      if (res.data.totalRecords) setElementAll(res.data.totalRecords);

      if (elements.length < res.data.totalRecords)
        setElements((prev) => [...prev, ...res.data.result]);

      // if (res.data.data.length === 0) setElements([]);
      if (res.data.result.length === 0) setElements((prev) => [...prev]);

      setLoading(false);
      setCompleteLoading(true);
    } catch (err) {
      console.log(err);
    }
  };

  const CreateItem = ({ collection }) => {
    let convertToBlockElement = {} as BlockElement;
    convertToBlockElement.data = collection;
    convertToBlockElement.data.parentType = "CATALOG";
    convertToBlockElement.elementType = "NODE";

    return (
      <CardStandard
        css={widthCardStyle}
        collection={convertToBlockElement}
        format={formatCard}
        effects={effectCard}
      />
    );
  };

  if (loading && !openGrid)
    return (
      <PlaceholderStandard
        number={colunsElements}
        css={widthCardStyle}
        format={formatCard}
      />
    );

  if (!loading && elements.length === 0)
    return (
      <Text
        component="h3"
        text={`Não foram encontradas recomendações.`}
        className="notSearch"
      />
    );

  return (
    <div
      className={`pctOrganism pctCarroussel pctRecommendation ${
        description && "pctCarrousselInText"
      }
      `}
    >
      {/* {title && <Text component="h2" text={title} className="titleSection" />} */}
      <div className={`listTitle ${!title ? "listWithoutTitle" : ""}`}>
        {title && <Text component="h2" text={title} className="titleSection" />}

        <div className="containerCatalog">
          <div className="controlFilterVerTodos">
            {activeVerTodos && (
              <button className="verTodos" onClick={(e) => setOpenGrid(true)}>
                <Text component="p" text={"Ver todos"} className="" />
                <FaAngleRight />
              </button>
            )}
          </div>
        </div>
      </div>
      {description && (
        <Text component="p" text={description} className="textSection" />
      )}
      <CarrousselView
        flktyReady={(flkty, e) => flktyReady(flkty, e)}
        flktyChange={(flkty, e) => flktyChange(flkty, e)}
        options={{ selectedAttraction: 1, friction: 1 }}
      >
        {elements.map((item, i) => {
          return <CreateItem collection={item} key={i} />;
        })}
      </CarrousselView>

      {openGrid && (
        <CardGridCatalog
          setOpenGrid={setOpenGrid}
          flktyChange={flktyChange}
          elements={elements}
          CreateItem={CreateItem}
          widthCardStyle={widthCardStyle}
          loading={loading}
          indiceOffsetMobile={indiceOffsetMobile}
          elementAll={elementAll}
          format={formatCard}
          completeloading={completeloading}
          setCompleteLoading={setCompleteLoading}
          colunsElements={colunsElements}
          openGrid={openGrid}
        />
      )}
    </div>
  );
};

export default Recommendation;
