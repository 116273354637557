import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { Collapse } from "react-collapse";
import { TbSearch } from "react-icons/tb";
import { IoMdClose } from "react-icons/io";

function HeaderSearchBox({ searchOpen, setSearchOpen }) {
  const inputSearchRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const history = useHistory();

  const handleCollapse = (e) => {
    setSearchOpen(!searchOpen);
    inputSearchRef.current.focus();
  };

  const goToSearchPageResult = () => {
    if (searchText.length > 0) {
      history.push(`/searchResult/${searchText}`);
      setSearchOpen(false);
    }
  };

  return (
    <div className="searchBox">
      <div
        className={`searchBoxHit ${searchOpen ? "searchBoxHitOpen" : ""}`}
        onClick={(e) => {
          handleCollapse(e);
        }}
      ></div>
      <Collapse isOpened={searchOpen}>
        <div className="searchBarCatalog">
          <div className="searchText">
            <button
              className="btnSearchIco"
              onClick={(e) => {
                goToSearchPageResult();
              }}
            >
              <TbSearch />
            </button>
            <input
              spellCheck="false"
              className="menu-search-input"
              placeholder="Pesquisar..."
              ref={inputSearchRef}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  goToSearchPageResult();
                }
              }}
              onChange={(c) => {
                setSearchText(c.target.value);
              }}
              value={searchText}
              maxLength={300}
              type="search"
            />

            <button
              className="btnCloseIco"
              onClick={(e) => {
                if (searchText.length > 0) {
                  setSearchText("");
                  inputSearchRef.current.focus();
                } else {
                  handleCollapse(e);
                }
              }}
            >
              <IoMdClose className="btnCloseIco" />
            </button>
          </div>
        </div>
      </Collapse>

      {!searchOpen && (
        <button
          className="searchBtn"
          onClick={(e) => {
            handleCollapse(e);
          }}
        >
          <TbSearch />
        </button>
      )}
    </div>
  );
}

export default HeaderSearchBox;
