import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { usersList } from '../services/api'
import { useHistory, useLocation } from "react-router-dom";
import SearchIcon from '../assets/images/search.svg';
import "../assets/css/SearchList.scss";
import UserIcon from '../assets/images/no-gender-user.svg';
import { getColors, getAllMenuOptions, transformText, getDesignBySectionType, getFooterSize } from '../services/utils';
import Loader from './Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },

}));

export default function AlignItemsList() {

    function _getSectionTypeByName() {
        
        let pathName = useLocation().pathname.replace("/", "")
        let allMenuOptions = getAllMenuOptions()
        let menuItem = allMenuOptions.filter(obj => {
            return (transformText(obj.text)+"/"+obj.sectionTo) === pathName
        })[0]

        return menuItem
    }

    const sectionType = _getSectionTypeByName().idSectionType;
    const design = getDesignBySectionType(sectionType);

    const [listTemp, setListTemp] = useState([]);
    const classes = useStyles();
    const history = useHistory()
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [page, setPage] = useState(1);
    const quantidade = 10;

    const pagination = (value) => {
        let calc = page * quantidade
        let array = [];
        if (value && value.length >= calc) {
            for (let i = 0; i < calc; i++) {
                array.push(value[i])
            }
        }

        if (value.length < 10 && value.length > 0) {
            for (let i = 0; i < value.length; i++) {
                array.push(value[i])
            }
        }

        return array;
    }

    const count = () => {
        setPage(page + 1)
    }

    const searchFilter = (value) => {
        setSearch(value.target.value)
        let page = pagination(userList.filter(el => el.name.toLowerCase().indexOf(value.target.value) > -1))
        if (page.length > 0) {
            setListTemp(page)
        }
    }

    const getUsersList = () => {
      usersList().then(res => {
        if(res.data.success === true) {
          setUserList(res.data.userList)
          setListTemp(pagination(res.data.userList))
        }
      }).catch(err => {
        console.log('err', err)
      }).finally(() => {
        setLoading(false)
      })
    }

    useEffect(() => {
        getUsersList()
    }, [])

    useEffect(() => {
        let page = pagination(userList.filter(el => el.name.toLowerCase().indexOf(search) > -1))
        if (page.length > 0) {
            setListTemp(page)
        }
    }, [page])

    const redictToPerfilUsuario = (user) => {
        history.push(`/PerfilUsuario?nome=${user.name}&email=${user.email}&photo=${user.photo}`);
    };

    const textStyle = {
        ...design['itemText'],
        color: getColors('menu').firstColor
    }

    return (

        <div className={"body"}>

            <div className={"search"}>
                <input
                    onChange={searchFilter}
                    type={"text"}
                    value={search}
                    id={"placeholder-text"}
                    className={"searchInput"}
                    placeholder="Buscar Usuário"
                    style={{...design['itemText'],color:'#000'}}
                />
                <img
                    id={"img"}
                    alt={"img"}
                    style={{ height: 20, width: 20, paddingLeft: 5 }}
                    src={SearchIcon} 
                />
            </div>

            <List className={classes.root} style={{ paddingTop: 0 }}>
                {
                  loading ? (
                    <Loader />
                  ) : (
                    listTemp?.map((user, index) => {
                        const hasPhoto = Boolean(user.photo);
                        return (
                            <div key={index}>
                                <ListItem className="listar-perfis-item" onClick={() => { redictToPerfilUsuario(user) }}>
                                    <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src={hasPhoto ? user.photo : UserIcon} style={{ backgroundColor: getColors('menu').firstColor }}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <React.Fragment>
                                                <Typography
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                    component={'span'} 

                                                >
                                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%", flexDirection: "row" }}>
                                                        <span style={textStyle}>{user.name}</span>
                                                    </div>
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />

                            </div>
                        )
                    })
                  )
                }
            </List>
            <div className="div-btn" style={getFooterSize()}>
                <button id="button" onClick={count}  style={{ backgroundColor: getColors('menu').firstColor, color: '#fff' }}>
                    ver mais
                </button>
            </div>
        </div >
    );
}
