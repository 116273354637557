import React from "react";
import io from "socket.io-client";
import { chatLoadRoom, chatSaveRoom } from "../services/api";
import "../assets/css/YTPlaylist.scss";
import "../assets/css/LiveChat.scss";
import ReactPlayer from "react-player";
import {
  getDesignById,
  getFooterSize,
  getColors,
  getGamigication,
} from "../services/utils";
import { getToken } from "../services/api";
import moment from "moment";
import { Rating } from "./Rating";
import Spacer from "./Spacer";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import {
  updateFooterStatus,
  updateHeaderStatus,
  updatePoints,
} from "../actions";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import SendLiveIcon from "../assets/images/send-live.svg";
import chatToggleIcon from "../assets/images/ellipsis-live.svg";
import { recordTracking } from "../services/api";
import ModalContinueMedia from "./ModalContinueMedia";
import IdleTimer from "react-idle-timer";

class LivePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimerRef = null;
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);

    this.state = {
      user: {},
      showChat: false,
      messages: [],
      newMessage: "",
      isDesktop: false,
      hasChat: false,
      controls: true,
      idContentItem: 0,
      timeStart: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      arrayTime: [],
      modal: false,
      timeElapsed: 0,
      lastPosition: 0,
      allowSaving: false,
      playing: false,
      shouldRecordTracking: true,
      timeRefresh: 300000, // 300000ms / 5min
    };

    this.socket = io("https://chat.bedigital.net.br:3001");
    this.messagesToSave = [];
    this.setNewMessage = this.setNewMessage.bind(this);
    this.handleSendMsg = this.handleSendMsg.bind(this);
    this.toggleChat = this.toggleChat.bind(this);
    this.resize = this.resize.bind(this);
    this.videoPlay = React.createRef();
    this.startedTime = this.props.data.timeExecuted;
  }

  handleOnActive() {
    this.setState({ ignoreRecordTracking: false });
  }

  handleOnIdle() {
    if (this.state.playing && this.idleTimerRef) {
      this.idleTimerRef.reset();
    } else {
      this._recordTracking();
    }
  }

  resetTime() {
    if (this.idleTimerRef) {
      this.idleTimerRef.reset();
    }
  }

  retrieveVideoData() {
    const lastPosition = this.props.data.lastPosition;
    if (!(lastPosition === null || lastPosition === 0)) {
      this.setState({ modal: true });
    } else {
      this.setState({ allowSaving: true });
    }
  }

  start() {
    this.setState({ modal: false });
  }
  beginVideo() {
    this.setState({
      timeElapsed: 0,
      allowSaving: true,
      playing: true,
    });
  }
  prepareVideo(time) {
    this.setState(
      {
        timeElapsed: time,
        allowSaving: true,
        playing: true,
      },
      () => {
        this.videoPlay.current.seekTo(time, "seconds");
      }
    );
  }

  updateExecutedTime = (time) => {
    if (this.state.allowSaving) {
      this.setState({ timeElapsed: time });
      this.startedTime = time;
    }
  };

  async componentDidMount() {
    this.setState({
      timeElapsed: this.props.data.timeExecuted,
    });
    this.retrieveVideoData();

    this.resize();
    window.addEventListener("resize", this.resize);
    this.setState({
      user: this.getProfileInfo(),
    });
    this.setState({ timeStart: moment().utc().format("YYYY-MM-DD HH:mm:ss") });

    if (
      this.props.data.idChatRoom !== null &&
      this.props.data.idChatRoom !== undefined
    ) {
      await this._chatLoadRoom(this.props.data.idChatRoom);

      this.socket.on("connect", async () => {
        this.socket.emit("room", this.props.data.idChatRoom);
      });

      this.setState(
        {
          hasChat: true,
        },
        () => {}
      );

      this.socket.on("NEW_MESSAGE_FROM_LIVE_SERVER", (message) => {
        this.AddNewMsg(message);
      });
    }
    const { updateHeaderStatus, updateFooterStatus } = this.props;
    updateFooterStatus(this._getDesign("general")?.showBottomMenu || "true");
    updateHeaderStatus(this._getDesign("general")?.showTopBar || "true");
    this.resize();
  }

  currentTime() {
    return this.videoPlay.current.getCurrentTime() !== null
      ? this.videoPlay.current.getCurrentTime()
      : this.startedTime;
  }

  async _recordTracking() {
    if (this.state.shouldRecordTracking) {
      // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audi dentro do html(frame))
      const timeStart = this.state.timeStart;
      const navItem = this.props.data.idContentItem;
      const navType = 1;
      const idSection = this.props.idSection;
      const timeExecuted = Math.floor(this.currentTime());
      await recordTracking(timeStart, navItem, navType, idSection, timeExecuted)
        .then((res) => {
          if (getGamigication()) {
            const totalUserPoints = res.data.totalUserPoints;
            this.props.updatePoints(totalUserPoints);
          }
        })
        .catch((err) => {
          console.log("Error record tracking live player", err);
        });
    }
  }

  componentWillUnmount() {
    this._recordTracking();
    this.socket.close();
  }

  resize() {
    this.setState({ isDesktop: window.innerWidth > 800 });
  }

  async _chatLoadRoom(room) {
    this.setState({ isLoading: true });
    await chatLoadRoom(room)
      .then((res) => {
        if (res && res.data && res.data.success === false) {
          this.setState({ isLoading: false, error: true });
          return;
        } else {
          if (res.data.data.length > 0) {
            this.setState({
              messages: res.data.data[res.data.data.length - 1].messages,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false, error: true });
      });
  }

  componentDidUpdate() {
    if (
      this.props.data.idChatRoom !== null &&
      this.props.data.idChatRoom !== undefined
    ) {
      this.scrollToBottom();
    }
  }

  getProfileInfo() {
    let token = {};
    try {
      token = jwtDecode(getToken());
    } catch {
      console.error("error on token get");
    }
    return token;
  }

  scrollToBottom = () => {
    this.msgEnd.scrollIntoView({ behavior: "smooth" });
  };

  _getDesign(item, only = false, valueOnly = false) {
    let pathParams = new URLSearchParams(this.props.location.search); // search for sectionId on path
    let parentSection = pathParams.get("idSection");
    let design = getDesignById(parentSection || 0);

    if (!design[item]) {
      return { showBottomMenu: getColors("footer").showfooter };
    } // return null if theres no item specified

    if (only) {
      // parameter only means it will only return the property specified
      if (valueOnly) {
        return design[item][only];
      } // parameter valueOnly means it will only return the property value if specified

      design = { [only]: design[item][only] };
      return design;
    }

    return design[item];
  }

  /* chat - start */
  toggleChat() {
    let tc = !this.state.showChat;
    this.setState({ showChat: tc });
  }

  handleKeyDown = (event) => {
    if (event.key == "Enter") {
      this.handleSendMsg(event);
    }
  };

  AddNewMsg(message) {
    let m = this.state.messages;
    m.push(message);
    this.setState(
      {
        messages: m,
      },
      () => {}
    );
  }

  dateFormat(num) {
    return num < 10 ? `0${num}` : num;
  }

  setNewMessage(event) {
    this.setState({
      newMessage: event.target.value,
    });
  }

  sendMsgToserver(usr, msg) {
    if (msg !== "") {
      let now = new Date();
      let msgObj = {
        moment: `${this.dateFormat(now.getDate())}-${this.dateFormat(
          now.getMonth()
        )}-${now.getFullYear()} ${this.dateFormat(
          now.getHours()
        )}:${this.dateFormat(now.getMinutes())}:${this.dateFormat(
          now.getSeconds()
        )}`,
        idUser: this.state.user.idUser,
        user: usr,
        message: msg,
        room: this.props.data.idChatRoom,
      };

      this.socket.emit("NEW_MESSAGE_FROM_LIVE_CHAT", msgObj);
      this.prepareDataToSave(msgObj);
    }
  }

  handleSendMsg(event) {
    event.preventDefault();
    this.sendMsgToserver(this.state.user.name, this.state.newMessage);
    this.setState({
      newMessage: "",
    });
  }

  prepareDataToSave(msg) {
    let d = new Date();

    const data = {
      data: [
        {
          date: `${this.dateFormat(d.getDate())}-${this.dateFormat(
            d.getMonth()
          )}-${d.getFullYear()}`,
          messages: [
            {
              moment: msg.moment,
              user: this.state.user.idUser,
              message: msg.message,
            },
          ],
        },
      ],
    };

    this._chatSaveRoom(data);
  }

  _chatSaveRoom(data) {
    chatSaveRoom(this.props.data.idChatRoom, data)
      .then((res) => {
        if (!res || res.data.success === false) {
          console.error("sucess false");
          return;
        }
      })
      .catch((err) => {
        console.error("save chat err ", err);
      });
  }
  /* chat - end */

  render() {
    const { isVideo = false } = this.props;
    const { controls } = this.state;
    const { isDesktop } = this.state;
    const { hasChat } = this.state;
    const data = this.props.data;

    const currentMoment = moment();
    const itemMoment = moment(data.initDate);
    const isLive = currentMoment.isSame(itemMoment, "day");
    const saveTimeExecuted = ({ playedSeconds }) => {
      this.updateExecutedTime(playedSeconds);
    };

    /* chat - start */
    const { showChat } = this.state;
    let vh = window.innerHeight * 0.01;
    let nd = new Date();
    let msgList;

    msgList = this.state.messages.map((m, k) =>
      m.user === this.state.user.name ? (
        <div className="chat-container-mine" key={k}>
          <div
            className={1 == 1 ? "chat-mine" : "chat-mine chat-mine-arrow"}
            style={{
              justifyContent: "center",
              backgroundColor: getColors("menu").secondColor,
            }}
            key={`chat-${m.user}-${m.moment}`}
          >
            <span className="msg" style={{ color: "#f0f0f0", fontSize: 16 }}>
              {m.message}
              <div
                style={{
                  fontSize: "0.7rem",
                  textAlign: "end",
                  color: "lightgray",
                }}
              >
                {m.moment}
              </div>
            </span>
          </div>
        </div>
      ) : (
        <div className="chat-container-other" key={k}>
          <div
            className="chat-logged"
            style={{ marginTop: 7 }}
            key={`chat-${m.user}-${m.moment}`}
          >
            <b className="name" style={{ fontSize: 15 }}>
              {" "}
              {m.user}{" "}
            </b>
            :
            <span
              className="msg"
              style={{ color: getColors("menu").firstColor, fontSize: 16 }}
            >
              {" "}
              {m.message}
            </span>
          </div>
        </div>
      )
    );
    /* chat - end */

    const beggining = () => {
      this.beginVideo();
    };

    const continueLastTime = () => {
      this.prepareVideo(this.props.data.lastPosition);
    };

    const onStart = () => {
      this.setState({ playing: true });
      this.start();
    };

    const onPause = () => {
      this.setState({ playing: false });
    };

    return (
      <>
        <ModalContinueMedia
          startMedia={beggining}
          continueMedia={continueLastTime}
          mediaType="video"
          idParentSection={this.props.idSection}
          show={this.state.modal}
        />
        <IdleTimer
          ref={(ref) => {
            this.idleTimerRef = ref;
          }}
          timeout={this.state.timeRefresh}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          debounce={250}
        />
        <div
          className={
            "container-fluid live-container" +
            (hasChat ? " live-container-with-chat" : "")
          }
          style={{
            ...this._getDesign("general"),
            paddingBottom: getFooterSize() ? "8rem" : "3rem",
            paddingTop: isDesktop ? "3rem" : "1.5rem",
          }}
        >
          <div
            className={
              "ytplayer-container live" + (hasChat ? " live-no-margin" : "")
            }
            style={
              hasChat
                ? {
                    height: isDesktop ? "auto" : 400,
                    width: isDesktop ? "60%" : "100%",
                  }
                : {}
            }
          >
            <div
              style={{
                padding: "10px 30px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Rating
                stars={data.rating}
                numberVotes={data.numberVotes}
                isDesktop={isDesktop}
              />
            </div>
            {isLive || isVideo ? (
              <ReactPlayer
                ref={this.videoPlay}
                playing={this.state.playing}
                className="react-player"
                style={{ paddingTop: hasChat ? 30 : 0 }}
                width={hasChat ? "80%" : "100%"}
                url={data.contentLocation}
                controls={controls}
                onProgress={saveTimeExecuted}
                onStart={onStart}
                onPause={onPause}
              />
            ) : (
              <div style={{ width: "100%" }}>
                <img
                  src={data.image}
                  style={{ width: "100%" }}
                  alt={data.text && data.text}
                />
              </div>
            )}

            <div className="ytplayer-title-container">
              <div className="container-fluid ">
                <div className="" style={this._getDesign("itemText")}>
                  <Spacer verticalSize={10} />
                </div>

                <div>
                  <div
                    className="ytplayer-title"
                    style={this._getDesign("itemText")}
                  >
                    <h4>
                      <b>{data.text}</b>
                      <Spacer verticalSize={20} />
                      <p style={{ fontSize: "20px" }}>{data.description}</p>
                      <Spacer verticalSize={10} />
                    </h4>
                    <Rating id={data.idContentItem} userStar={data.vote} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.props.data.idChatRoom !== null &&
          this.props.data.idChatRoom !== undefined ? (
            <div
              className="live-chat"
              style={{
                height: showChat ? (isDesktop ? "auto" : 500) : 30,
                padding: showChat ? "0 0 5px 0" : 0,
                margin: isDesktop ? 0 : "10px 0 0 0",
              }}
            >
              <div
                className="live-chat-header"
                style={{ backgroundColor: getColors("menu").fourthColor }}
                onClick={this.toggleChat}
              >
                Chat
                <img
                  src={chatToggleIcon}
                  className="live-chat-toggle-logo"
                  alt="abrir"
                />
              </div>
              <div
                className="live-chat-messages"
                style={{ display: showChat ? "flex" : "none" }}
              >
                {msgList}
                <div
                  ref={(msgEnd) => {
                    this.msgEnd = msgEnd;
                  }}
                />
              </div>
              <div
                className="live-chat-footer"
                style={{
                  height: isDesktop ? 40 : 60,
                  display: showChat ? "flex" : "none",
                }}
              >
                <input
                  className="live-chatInput"
                  style={{ backgroundColor: getColors("menu").fourthColor }}
                  type="text"
                  placeholder="Digite uma mensagem..."
                  onChange={this.setNewMessage}
                  onKeyDown={this.handleKeyDown}
                  value={this.state.newMessage}
                />
                <img
                  onClick={this.handleSendMsg}
                  src={SendLiveIcon}
                  className="live-chat-send-logo"
                  alt="send"
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  updatePoints: (item) => dispatch(updatePoints(item)),
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(LivePlayer);
