import "./style.scss";
import React, { Component, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { updateConfigHeaderFooter } from "../../../actions";
import { Preloader, Text } from "pctComponents/atoms";
import { BsPatchCheckFill, BsFillPatchExclamationFill } from "react-icons/bs"
import { useFetch } from "pctHooks/useFetch";
import AlertComponent from "pctComponents/organisms/AlertComponent";



type idType = {
  authenticityID: string;
};

const CertificateAuthenticity = (props) => {
  
  const { authenticityID } = useParams<idType>();
  const { data, error } = useFetch({ url: `/learning/students/certificate-authenticity/${authenticityID}` });

  // useEffect(() => {
  //   const { updateConfigHeaderFooter } = props;
  //   updateConfigHeaderFooter({ prevNavStyle: "hide", menuStyle: "" });
  // });

  if (error){
    const fetchData: any = data;

    return (
      <div className="pctCertificateAuthenticity">
        <div className="certificateContainer">
            <AlertComponent fullpage={true} message={`Certificado não encontrado.`}/>
        </div>
      </div>
    );
  } 

  if (!data) return <Preloader />; 
   
  const fetchData: any = data;

  return (
    <div className="pctCertificateAuthenticity">
      <div className="certificateContainer">
      
          <BsPatchCheckFill className="check"/>
          <Text component={"h1"} text={"Certificado de autenticidade"}/>
          <Text component={"h4"} text={`Certificamos que <strong>${fetchData.username}</strong>`}/>
          <Text component={"h4"} text={`concluiu a Trilha/Curso <strong>${fetchData.title}</strong> no período de <strong>${fetchData.startDate} a ${fetchData.finishDate}</strong>.`}/>
          <Text component={"h4"} text={`O percentual de conclusão obtido foi de <strong>${fetchData.progress}%</strong>.`}/>

          <Text component={"small"} className="tokenKey" text={`${fetchData.token}`}/>
      </div>
      
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(CertificateAuthenticity);
