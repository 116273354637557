import React, {useEffect, useState} from 'react';
 
import {getDesign,getSectionById, getFooterSize,transformText } from '../../services/utils';
import Card from '../../components/Card/';
import {updateFooterStatus, updateHeaderStatus} from '../../actions';
import {connect} from 'react-redux';

import {findContent} from '../../services/api';
const CardMenu = ({idSection}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const getDataMenu = id => {
    if (id) {
      setLoading(true);
       let  _elem = getSectionById(idSection)
       if(_elem.menuOption){
        setData(_elem.menuOption)
       }
       setLoading(false)
    }
  };


  useEffect(() => {
    getDataMenu(idSection)
    
    updateFooterStatus(getDesign(idSection, 'general')?.showBottomMenu || 'true');
    updateHeaderStatus(getDesign(idSection, 'general')?.showTopBar || 'true');
  }, [idSection]);
  
  return (
    <div
      className="cardGridContainer card-margin "
      style={{
        padding: 0,
        ...getFooterSize(),
      }}>
      <div
        className="row"
        style={{
          padding: 0,
          paddingBottom: 10,
          overflowX: 'hide',
          margin: 0,
        }}>
        {loading ? (
          <div className="loader loader-center loader-big loader-purple"></div>
        ) : (
          data.map((item, index) => 
            <Card 
              item={item} 
              link={`/${transformText(item.text)}/${item.sectionTo}`} key={item.idContentItem} idSection={item.idContentGroup} 
              getDesign={getDesign} 
            />
          )
        )}
      </div>
    </div>
  );
};
const mapDispatchToProps = dispatch => ({
  updateFooterStatus: item => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: item => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(CardMenu);
