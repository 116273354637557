import "./style.scss";

import React from "react";

const NodePlaceHolderSVG = () => {
  const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" 
                    width="105" 
                    height="69" 
                    viewBox="0 0 105 69">
                      <g id="Grupo_2826" data-name="Grupo 2826" transform="translate(-10122 -17997)">
                        <rect id="Retângulo_2529" 
                                  data-name="Retângulo 2529" 
                                  width="105" height="69" 
                                  transform="translate(10122 17997)" 
                                  className="svgBg"/>
                        <path id="Combined_Shape" 
                              data-name="Combined Shape" 
                              d="M1.8,12A1.8,1.8,0,0,1,0,10.2V1.8A1.8,1.8,0,0,1,1.8,0h8.4A1.8,1.8,0,0,1,12,1.8v8.4A1.8,1.8,0,0,1,10.2,12Zm8.4-1.2a.6.6,0,0,0,.6-.6V8.049l-2.4-2.4L3.248,10.8Zm-9-9v8.4a.6.6,0,0,0,.39.562L7.976,4.376a.6.6,0,0,1,.849,0L10.8,6.351V1.8a.6.6,0,0,0-.6-.6H1.8A.6.6,0,0,0,1.2,1.8ZM2.4,3.9A1.5,1.5,0,1,1,3.9,5.4,1.5,1.5,0,0,1,2.4,3.9Z" 
                              transform="translate(10169 18026)" 
                              className="svgBorder"/>
                      </g>
                  </svg>`;

  return svgStr;
};

export default NodePlaceHolderSVG;
