import React, { useRef, useState } from 'react';

import MenuPrincipal from '../../MenuPrincipal';

import { FiAlignJustify } from 'react-icons/fi';

function HeaderMenuPrincipal({ hasEnrollmentRequests, userIsInCampaign, externalcourses, menuStyle }) {
    const childFunctionRef = useRef();
    const [menuStatus, setMenu] = useState(false);

    return (
        <>
            <button
                className={`menuHeader ${menuStyle}`}
                onClick={() => {
                    menuStatus ? setMenu(false) : setMenu(true);
                }}
            >
                <FiAlignJustify />
            </button>

            <MenuPrincipal
                hasEnrollmentRequests={hasEnrollmentRequests}
                isInCampaign={userIsInCampaign}
                externalcourses={externalcourses}
                expanded={menuStatus}
                expand={() => {
                    setMenu(false);
                }}
                menuData={() => {
                    return [];
                }}
                ref={childFunctionRef}
            />
        </>
    );
}

export default HeaderMenuPrincipal;
