import "./style.scss";

import React from "react";

import { Text } from '../'

type Props = {
  status?: string
  css?: object
  className?: string
  statusElement?:string
}

type regProps = ( ty:string ) => {
   text:string
   class:string
}

function Registered({ status = 'inactive', css = {}, className = "" , 
statusElement = '' }: Props) {

  const registedType: regProps = ( ty ) => {
    
    const reg = {
      'processing':  { text:'Matricula em processamento', class:'processing'},
      'isNodeEnrollment' : { text:'acessar conteúdo', class:'registered'},
      'isCatalog' : { text:'acessar conteúdo', class:'registered'},
      'freeContent' : { text:'acessar conteúdo', class:'registered'},
      'isEnrollment' : { text:'matriculado', class:'registered'},
      'canEnrollment' : { text:'fazer matrícula', class:'btnRegistration'},
      're-enroll' : { text:'faça a sua rematrícula', class:'btnRegistration'},
      'inactive' : { text:'', class:'inactive'},
    }

    return reg[ty] ?? { text:'', class:'inactive'};
  }

  const reg = registedType(status);
  
  return (
      <div className={`pctAtom pctRegistered containerRegistration ${className}`} style={css}>
        {(status && statusElement !== "ARCHIVED")  && (
          <Text
            component="small"
            text={reg.text}
            className={reg.class}
          />
        )}
      </div>
  )
}

export default Registered
