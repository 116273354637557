import React, { useCallback, useEffect, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import ModalUpgradeCampaign from 'pctComponents/modals/ModalUpgradeCampaign';
import { getActiveCampaigns, getMenuData } from 'services/api';
import { License } from 'reducers/license';
import { Campaign } from 'pctComponents/modals/ModalUpgradeCampaign/modalUpgradeCampaign.model';
import usePlan from 'pctHooks/usePlan';
import { connect } from 'react-redux';
import { updatePlan } from 'actions';

const CampaignModalManager = ({
    planUpgrade,
    updatePlan,
    setFrequency = true,
    configHeaderFooter,
}: {
    planUpgrade: License;
    updatePlan: any;
    setFrequency?: boolean;
    configHeaderFooter: any;
}) => {
    const { getActivePlanData } = usePlan();
    const [campaignData, setCampaignData] = useState<Campaign | undefined>(undefined);

    const [modalHome, setModalHome] = useState(false);
    const [closedModalTime, setClosedModalTime] = useState(false);

    const _getActiveCampaigns = async () => {
        const {
            data: { result: activeCampaigns },
        } = await getActiveCampaigns();
        setCampaignData(activeCampaigns[0]);
    };

    useEffect(() => {
        if (planUpgrade.isUpgradeButtonActive) {
            _getActiveCampaigns();
        }
    }, [planUpgrade]);

    const valueInMilliseconds = (valueInSeconds) => {
        return valueInSeconds * 1000;
    };

    const isAdmin = () => {
        const menuData = getMenuData();
        if (menuData) {
            return [1, 2].includes(menuData.loginInfo.admin);
        }

        return false;
    };

    const hasDateAvailabilityPeriod = useCallback(() => {
        const { startDate = null, finishDate = null } = campaignData || {};
        const availabilityPeriod = startDate && finishDate ? `${startDate}|${finishDate}` : '';

        const dateArray = availabilityPeriod?.split('|');
        if (!dateArray?.length) {
            return false;
        }
        const dateInit = new Date(dateArray[0]);
        const dateEnd = new Date(dateArray[1]);
        const dateNow = new Date();
        return dateNow >= dateInit && dateNow <= dateEnd;
    }, [campaignData]);

    const showModalCampaignUpdgrade = useCallback(
        async (type) => {
            const _activePlan = await getActivePlanData();
            const menuData = getMenuData();
            const { userLicenceTermsAccepted, agreementVersion } = menuData.loginInfo;

            if (_activePlan && (_activePlan?.deadline || 0) === 0) {
                return;
            }

            if (!campaignData) {
                return;
            }

            if (!configHeaderFooter.home) {
                return;
            }

            if (!hasDateAvailabilityPeriod()) {
                return;
            }

            if (document.querySelectorAll('.ModalUpgrade').length) {
                return;
            }

            if (userLicenceTermsAccepted !== agreementVersion) {
                return;
            }

            if (document.querySelectorAll('.ModalTopicsOfInterest').length > 0) {
                return;
            }

            if (document.querySelectorAll('.modalCampaingn').length > 0) {
                return;
            }

            // console.log(
            //     'type',
            //     type,
            //     'closedModalTime',
            //     closedModalTime,
            //     'modalHome',
            //     modalHome,
            //     'configHeaderFooter',
            //     configHeaderFooter,
            // );
            setClosedModalTime(false);
            setModalHome(true);

            NiceModal.show(ModalUpgradeCampaign, {
                isAdmin: isAdmin(),
                startedPlan: Boolean(_activePlan?.trial || 0),
                platformName: 'LMS+LXP by Afferolab',
                upgradeParams: {
                    planUpgrade: {
                        ...planUpgrade,
                        hasRequestedUpgrade: Boolean(_activePlan?.trial || 0),
                        userIsAdmin: isAdmin(),
                    },
                    updatePlan,
                },
                activePlan: _activePlan,
                media: {
                    mediaType: campaignData.mediaType,
                    mediaUrl: campaignData.mediaUrl,
                },
                handledClosed: () => {
                    setTimeout(
                        () => {
                            setClosedModalTime(true);
                        },
                        valueInMilliseconds(campaignData?.frequencyToShow || 300),
                        // 1000 * 20,
                    );
                },
            });
        },
        [getActivePlanData, campaignData, configHeaderFooter.home, hasDateAvailabilityPeriod, planUpgrade, updatePlan],
    );

    useEffect(() => {
        if (!closedModalTime && !modalHome) {
            showModalCampaignUpdgrade('init');
        } else {
            if (closedModalTime && setFrequency) {
                showModalCampaignUpdgrade('setTimeout');
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignData, configHeaderFooter, closedModalTime, modalHome]);

    return null;
};

const mapStateToProps = (store) => ({
    planUpgrade: store.planUpgrade,
    configHeaderFooter: store.configHeaderFooterState.configHeaderFooter,
});

const mapDispatchToProps = (dispatch) => ({
    updatePlan: (item) => dispatch(updatePlan(item)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CampaignModalManager);
