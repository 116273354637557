import './style.scss';

import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Text } from 'pctComponents/atoms';
import { CatalogListing } from 'pctComponents/organisms';

import { TbSearch } from 'react-icons/tb';
import { IoMdClose } from 'react-icons/io';
import { FaAngleRight } from 'react-icons/fa6';

import { getAvailableTags } from 'services/api';
import ReactTags from 'react-tag-autocomplete';
import { Collapse } from 'react-collapse';

import useCardsFormat from 'pctHooks/useCardsFormat';

type Props = {
    data: Block;
    sectionMClass: string;
};

const Catalog = ({ data, sectionMClass }: Props) => {
    const [searchString, setSearchString] = useState('');

    const [searchOpen, setSearchOpen] = useState(false);
    const [suggestionsTags, setSuggestionsTags] = useState([{}]);
    const [tags, setTags] = useState<any[]>([]);
    const reactTags = useRef<any>(null);
    const inputSearchRef = useRef<any>(null);
    const [openGrid, setOpenGrid] = useState(false);

    const [elementAll, setElementAll] = useState<number | null>(null);
    const [elementInit, setElementInit] = useState<boolean | null>(null);

    const { displayColumnsElementsCalc } = useCardsFormat(data.parameters);
    const activeVerTodos = elementAll && elementAll > displayColumnsElementsCalc;

    // data.parameters.textSearch ="1";
    // data.parameters.tagsFilter ="1";

    const onDelete = useCallback(
        (tagIndex) => {
            setTags(tags.filter((_, i) => i !== tagIndex));
        },
        [tags],
    );

    const onAddition = useCallback(
        (newTag) => {
            setTags([...tags, newTag]);
        },
        [tags],
    );

    const handleCollapse = (e: any) => {
        setSearchOpen(!searchOpen);
    };

    const goToSearch = () => {
        setSearchString(inputSearchRef.current.value);
    };

    //BUSCANDO O ARRAY DE TAGS DISPONÍVEIS NO NÓ
    useEffect(() => {
        if (data && data.parameters.nodeCode) {
            getAvailableTags({ nodeCode: data.parameters.nodeCode })
                .then((res) => {
                    // if (!res.data.success) {
                    //   return;
                    // }
                    let array: Array<object> = [];

                    res.data.map((item: any, i) => {
                        array.push({ id: Math.round(i + 1), name: item });

                        return item;
                    });

                    setSuggestionsTags(array);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [data]);

    useEffect(() => {
        if (elementInit === null && elementAll && elementAll > 0) {
            setElementInit(true);
        }
    }, [elementAll, elementInit]);

    return data.parameters.nodeCode ? (
        <section className={`sectionList sectionCatalog ${sectionMClass} ${elementInit ? 'sectionCatalogShow' : ''}`}>
            <div className={`pctOrganism pctCarroussel ${data.parameters.description ? 'pctCarrousselInText' : ''}`}>
                <div className={`listTitle ${!data.parameters.title ? 'listWithoutTitle' : ''}`}>
                    {data.parameters.title && (
                        <Text component="h2" text={data.parameters.title} className="titleSection" />
                    )}

                    <div className="containerCatalog">
                        {(data.parameters.textSearch === '1' || data.parameters.tagsFilter === '1') && (
                            <Collapse isOpened={searchOpen}>
                                <div className="searchBarCatalog">
                                    {data.parameters.tagsFilter === '1' && (
                                        <div className="searchTags">
                                            <ReactTags
                                                ref={reactTags}
                                                tags={tags}
                                                suggestions={suggestionsTags.filter((x) => !tags.includes(x))}
                                                onDelete={onDelete}
                                                onAddition={onAddition}
                                                placeholderText="Filtrar tags"
                                                autocomplete={true}
                                                autofocus={true}
                                            />
                                        </div>
                                    )}

                                    {data.parameters.textSearch === '1' && (
                                        <div className="searchText">
                                            <button
                                                className="btnSearchIco"
                                                onClick={(e) => {
                                                    goToSearch();
                                                }}
                                            >
                                                <TbSearch />
                                            </button>
                                            <input
                                                spellCheck="false"
                                                className="menu-search-input"
                                                placeholder="Pesquisar"
                                                ref={inputSearchRef}
                                                onKeyDown={(e: any) => {
                                                    if (e.keyCode === 13) {
                                                        goToSearch();
                                                    }
                                                }}
                                                onChange={(c) => {}}
                                                // value={}
                                                maxLength={25}
                                                type="search"
                                            />

                                            <button
                                                className="btnCloseIco"
                                                onClick={(e) => {
                                                    if (inputSearchRef.current.value.length > 0) {
                                                        setSearchString('');
                                                        inputSearchRef.current.value = '';
                                                        inputSearchRef.current.focus();
                                                    } else {
                                                        reactTags.current = null;
                                                        //setSuggestionsTags([]);
                                                        setTags([]);
                                                        handleCollapse(e);
                                                    }
                                                }}
                                            >
                                                <IoMdClose className="btnCloseIco" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </Collapse>
                        )}

                        <div className="controlFilterVerTodos">
                            {!searchOpen &&
                                (data.parameters.textSearch === '1' || data.parameters.tagsFilter === '1') && (
                                    <button
                                        className="searchBtn"
                                        onClick={(e) => {
                                            inputSearchRef.current.focus();
                                            handleCollapse(e);
                                        }}
                                    >
                                        <TbSearch />
                                    </button>
                                )}
                            {activeVerTodos && (
                                <button className="verTodos" onClick={(e) => setOpenGrid(true)}>
                                    <Text component="p" text={'Ver todos'} className="" />
                                    <FaAngleRight />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {data.parameters.description && (
                    <Text component="p" text={data.parameters.description} className="textSection" />
                )}

                <CatalogListing
                    catalogData={data}
                    searchString={searchString}
                    tags={tags}
                    elementAll={elementAll as number}
                    setElementAll={setElementAll}
                    openGrid={openGrid}
                    setOpenGrid={setOpenGrid}
                />
            </div>
        </section>
    ) : (
        <></>
    );
};

export default Catalog;
