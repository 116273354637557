import React, { useCallback, useEffect, useRef } from "react";
import "./App.scss";
import { BrowserRouter as Router, HashRouter } from "react-router-dom";
import RouterContainer from "./components/RouterContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useClearCacheCtx } from "react-clear-cache";

import Preloader from 'pctComponents/atoms/Preloader';
import NiceModal from '@ebay/nice-modal-react';
import ReactGA from 'react-ga4';
import ScormCDNTest from "components/ScormCDNTest"; 
// import { ModalProvider } from "react-simple-modal-provider";
// import modals from "pctComponents/modals";


function App() {
  const { isLatestVersion, emptyCacheStorage, loading } = useClearCacheCtx();  
  const _isMounted = useRef();

  const host_location = window.location.hostname;
  const trackindIds = {
    "default": "G-53FQ2SMF6Z",
    "educacao.totvs.com": "G-JM1BTQMMCG",
    "afferolabhub.peoplecraft.com.br": "G-FV6C00Q2CN",
    "totvs.peoplecraft.com.br": "G-3VRQ7BSNGD",
  }

  const TRACKING_ID = trackindIds[host_location] ? trackindIds[host_location] : trackindIds['default'];

  ReactGA.initialize(TRACKING_ID);

  const emptyCache = useCallback(() => {
    if (_isMounted.current) {
      if (!isLatestVersion) {
        emptyCacheStorage();
      }
    }
  }, [isLatestVersion, emptyCacheStorage]);

  useEffect(() => {
    _isMounted.current = true;
    emptyCache();
    return () => {
      _isMounted.current = false;
    };
  }, [emptyCache]);
  
  return (
    <> 
      {!loading ? (
        <HashRouter> 
          <div className="App" translate="no">
            <NiceModal.Provider>
              <RouterContainer />
              <ToastContainer />
              <ScormCDNTest />
            </NiceModal.Provider>
          </div>
        </HashRouter>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default App;
