import React, { useEffect } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from 'pctHooks/useMediaQuery';

import './style.scss';

import ListItens from './ListItens';

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    idTeam: any;
};
const AttachmentSideBar = ({ idTeam, handleClose, isOpen = false }: Props) => {
    const isMobile = useMediaQuery('md');

    const toggleSidebar = () => {
        handleClose();
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('bodyOverflowHidden');
        } else {
            document.body.classList.remove('bodyOverflowHidden');
        }

        return () => {
            document.body.classList.remove('bodyOverflowHidden');
        };
    }, [isOpen]);

    return (
        <>
            <Drawer anchor="right" open={isOpen} onClose={toggleSidebar} className="attachmentSideBar">
                <div className="interaction-drawer-container" style={{ width: isMobile ? '100vw' : 800 }} role="presentation">
                    <div className={`interaction-drawer-header-mobile`}>
                        <p>Anexos</p>
                        <IconButton onClick={toggleSidebar} className={`interaction-drawer-close-button right-align${isMobile ? 'mobile' : ''}`}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {idTeam && <ListItens idTeam={idTeam} />}
                </div>
            </Drawer>
        </>
    );
};

export default AttachmentSideBar;
