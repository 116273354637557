import "./style.scss";
import React, { useState, useRef } from "react";
import { GrFormNextLink } from "react-icons/gr";
import useSrcSetVerify from "../../../pctHooks/useSrcSetVerify";
import useMediaQuery from "../../../pctHooks/useMediaQuery";
import SVG from "react-inlinesvg";
import { LiaSitemapSolid } from "react-icons/lia";
import { CgNotes } from "react-icons/cg";

import {
  Text,
  Label,
  Button,
  Image,
  Rate,
  ProgressBar,
  Duration,
  Visualization,
  Icon,
  ButtonCertificate,
  Like,
  ViewSyllabus,
  Registered,
} from "../../atoms";

import useCards from "pctHooks/useCards";
import { Link } from "react-router-dom";

type Props = {
  className?: string;
  collection: any;
};

const CardMenu = ({
  className,
  collection
}: Props) => {
  
  const setProgressStateRef = useRef(null as any);
  const progressInfoRef = useRef(null as any);

  
  const {
    image,
    mobileImage,
    concluidedText,
    currentLabel,
    isEnrollment,
    canEnrollment,
    noTeamAvaliable,
    initialProgressState,
    evaluationEnrollment,
    statusRegistered,
    prerequisitesMet,
    routerLink,
    handleCardMouseEvent,
    handleCertificateMouseEvent,
    hasCertificate,
    statusElement,
    verifyCompletedStatus,
  } = useCards({
    collection: {data: collection, elementType: "NODE"},
    setProgressStateRef,
    progressInfoRef,
  });

  const [progressInfo, setProgressState] = useState(initialProgressState());

  setProgressStateRef.current = setProgressState;
  progressInfoRef.current = progressInfo;

  const {
    title,
    nodeType,
  } = collection;

  const showStatus = (statusRegistered() !== "isEnrollment" && statusRegistered() !== "isCatalog" && statusRegistered() !== "inactive");

  return (
    <div
      className={`pctMolecule pctCardMenu ${className ? className : ''}`}
    >
        <div
            onClick={(e) => {
              handleCardMouseEvent(e);
          }}
          className="pctCardMenuConteiner"
        >
            {/* <div className="thumb_image">
              { nodeType === "ORGANIZATION" ? <LiaSitemapSolid/> : <CgNotes/> }
            </div> */}

            <div>           
              <Text
                component="p"
                text={`${title}`}
              />
              {(showStatus) &&
                <Registered status={statusRegistered()} statusElement={statusElement()} />
              }
            </div>

        </div>
      
    </div>
  );

};

export default CardMenu;