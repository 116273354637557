import React from 'react';
import { AiOutlineAudio } from 'react-icons/ai';
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { FiMonitor } from 'react-icons/fi';
import { GrDocumentPdf } from 'react-icons/gr';
import { IoDocumentOutline } from 'react-icons/io5';
import { LuImage } from 'react-icons/lu';

type Props = {
    icon: string;
};

const Icons = ({ icon }: Props) => {
    const icons = {
        PDF: <GrDocumentPdf />,
        Apresentação: <FiMonitor />,
        Planilha: <BsFileEarmarkSpreadsheet />,
        Documento: <IoDocumentOutline />,
        Imagem: <LuImage />,
        Áudio: <AiOutlineAudio />,
        Vídeo: <FaRegCirclePlay />,
    };
    return <span style={{ color: 'var(--primary-color)', marginTop: 5, verticalAlign: 'middle', marginRight: 8 }}>{icons[icon]}</span>;
};

export default Icons;
