const WhatsappSVG = () => `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="54.146" height="54.388" viewBox="0 0 54.146 54.388">
  <defs>
    <linearGradient id="linear-gradient" x1="-0.412" y1="0.096" x2="-0.412" y2="0.586" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#20b038"/>
      <stop offset="1" stop-color="#60d66a"/>
    </linearGradient>
  </defs>
  <g id="Grupo_2353" data-name="Grupo 2353" transform="translate(0)">
    <path id="Caminho_935" data-name="Caminho 935" d="M3334.564,460.874a26.962,26.962,0,0,0-42.432,32.519l-3.825,13.965,14.292-3.747a26.942,26.942,0,0,0,12.883,3.281h.011a26.961,26.961,0,0,0,19.071-46.018Zm-19.071,41.466h-.008a22.38,22.38,0,0,1-11.405-3.123l-.819-.485-8.481,2.224,2.264-8.266-.533-.847a22.409,22.409,0,1,1,18.982,10.5Z" transform="translate(-3288.307 -452.97)" fill="#e0e0e0"/>
    <path id="Caminho_936" data-name="Caminho 936" d="M3345.194,547.9l3.65-13.327a25.729,25.729,0,1,1,22.293,12.881h-.011a25.72,25.72,0,0,1-12.293-3.13l-13.639,3.576Z" transform="translate(-3343.957 -495.058)" fill="url(#linear-gradient)"/>
    <path id="Caminho_937" data-name="Caminho 937" d="M3922.3,1113.368c-.5-1.109-1.024-1.131-1.5-1.151-.388-.017-.833-.016-1.276-.016a2.447,2.447,0,0,0-1.775.833,7.464,7.464,0,0,0-2.331,5.553c0,3.276,2.386,6.442,2.719,6.887s4.607,7.382,11.375,10.051c5.625,2.218,6.77,1.777,7.991,1.666s3.94-1.611,4.495-3.166a5.563,5.563,0,0,0,.388-3.166c-.167-.278-.61-.444-1.276-.777s-3.94-1.944-4.55-2.166-1.055-.333-1.5.334-1.719,2.166-2.108,2.609-.777.5-1.443.167a18.213,18.213,0,0,1-5.355-3.3,20.07,20.07,0,0,1-3.7-4.611c-.388-.666-.042-1.027.292-1.359.3-.3.666-.777,1-1.166a4.574,4.574,0,0,0,.665-1.11,1.226,1.226,0,0,0-.055-1.167c-.167-.333-1.461-3.626-2.053-4.943Z" transform="translate(-3901.776 -1097.86)" fill="#fff" fill-rule="evenodd"/>
    <path id="Caminho_938" data-name="Caminho 938" d="M3348.318,461.016a26.653,26.653,0,0,0-41.945,32.146l-3.781,13.805,14.128-3.7a26.631,26.631,0,0,0,12.735,3.243h.011a26.652,26.652,0,0,0,18.852-45.49Zm-18.852,40.99h-.009a22.124,22.124,0,0,1-11.274-3.087l-.809-.48-8.384,2.2,2.238-8.171-.527-.838a22.151,22.151,0,1,1,18.764,10.377Z" transform="translate(-3302.281 -453.198)" fill="#fff"/>
  </g>
</svg>  

`

export default WhatsappSVG;