import React, { Component } from 'react';
import '../themes/import.scss';
import '../assets/css/CardPlayer.scss';
import { contentList } from '../services/api';
import { getFooterSize, getDesign } from '../services/utils';
import Loader from './Loader';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';
import AudioListCard from '../components/AudioListCard';


class AudioList extends Component {
  constructor(props) {
    super(props);
    this._isMounted = true;

    this.state = {
      isOpen: false,
      isLoading: false,
      data: [],
      rowWidth: 0,
    }
    this.handlerBack = this.handlerBack.bind(this)
    this.handlerNext = this.handlerNext.bind(this)
    this.openModal = this.openModal.bind(this)
  }

  handlerBack(id) {
    this.setState({
      isOpen: {
        [id - 1]: true
      }
    });
  }

  handlerNext(id) {
    this.setState({
      isOpen: {
        [id + 1]: true,
      }
    });
  }

  openModal(id) {
       this.setState({
       isOpen: {
         [id]: true
      }
    });
  }

  async _getContent(id) {
    this.setState({ isLoading: true })
    const result = await contentList(id);
    if (this._isMounted) {
			try {
        if (result.data.success === false) {
          this.setState({ isLoading: false })
          return
        }
        this.setState({
          isLoading: false,
          data: result.data.content
        })
		
			} catch (err) {
        this.setState({ isLoading: false })

			} 
		}   
  }

  componentDidMount() {
    const { updateHeaderStatus, updateFooterStatus } = this.props
    updateFooterStatus(getDesign(this.props.idSection,'general')?.showBottomMenu || 'true')
    updateHeaderStatus(getDesign(this.props.idSection,'general')?.showTopBar || 'true')
    this._getContent(this.props.idSection)
  }

  componentWillUnmount(){
    this._isMounted= false;
  }

  render() {
   
    return (
      <div className="cardPlayerContainer" style={{...getDesign(this.props.idSection,'general'), ...getFooterSize(), paddingRight: "0", paddingLeft: "0",}}>
        <div className="card-deck">
          <div className="row rowPlayer" style={{
            width: '90%',
            marginRight: 'auto', 
            marginLeft: 'auto', 
            justifyContent: 'center',
            }}>
            {
              this.state.isLoading ?
                <div className="audio-list-loading-container">
                  <Loader />
                </div>
                :
                this.state.data.map((data, key) => {
                  return (
                    <AudioListCard index={key} key={key} idSection={this.props.idSection} item={data}/>
                  )
                })}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(AudioList);