import "./styles.scss";

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { AiOutlineCalendar } from "react-icons/ai";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import Modal from "@material-ui/core/Modal";

import { Text, Label } from "../../atoms";

import {
  getSchedules,
  getSchedulesTypes
} from "../../../services/api";

import "react-big-calendar/lib/sass/styles.scss";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "moment/locale/pt-br";

const Agenda = (props) => {
  
  const isMountedRef = useRef(null);
  const isMountedRefSchendulesTypes = useRef(null);

  const [contentModal, setContentModal] = React.useState("");
  const [displayTypes, setDisplayTypes] = useState([]);
  const [fixedList, setFixedList] = useState([]);
  const [selectedTypeIds, setSelectedTypeIds] = useState([]);
  const [loadingCalendar, setLoadingCalendar] = useState(true);
  const [listTypes, setListTypes] = useState("");
  const [eventsList, setEventsList] = useState([]);
  const [state, setState] = useState({
    events: eventsList,
    displayDragItemInCell: true,
  });
  const [open, setOpen] = React.useState(false);

  
  const handleTypeFilterChange = (event) => {
    const value = event.target.value;

    setDisplayTypes(value);
    let selectedItems = [];
    fixedList.map((item) => {
      value.map((selectedItem) => {
        if (item.description === selectedItem) {
          selectedItems.push(item.idScheduleType); // storing only the type id's
        }
        return selectedItem
      });

      return item;
    });
    setSelectedTypeIds(selectedItems);

    _eventsList(selectedItems);
  };

  const _eventsList = useCallback(async (scheduleTypeFilter) => {
    setLoadingCalendar(true);
    const convertedScheduleTypeList = scheduleTypeFilter?.join(",");
    const result = await getSchedules(convertedScheduleTypeList);
    let _aux = [];
    if (isMountedRefSchendulesTypes.current) {
      try {
        if (result?.data.success === true) {
          const schedules = result?.data.scheduleResult.schedules;
          _aux = schedules.map((item, index) => {
            let dt = item.startDate.replace("Z", "").replace("z", "");
            let dateItemStart = new Date(dt);

            let dtEnd = item.endDate.replace("Z", "").replace("z", "");
            let dateItemEnd = new Date(dtEnd);
            return {
              ...item,
              start: dateItemStart,
              end: dateItemEnd,
              id: item.idSchedule,
            };
          });
          setEventsList(result);
          setState({ ...state, events: _aux });
        }
      } catch (e) {
        setEventsList([]);
        setState({ ...state, events: [] });
      } finally {
        setLoadingCalendar(false);
      }
    }
  }, []);

  // const _getGroupList = useCallback(async () => {
  //   if (listGroupList === "") {
  //     await getGroupList()
  //       .then((response) => {
  //         if (response) {
  //           let listagem = [];
  //           response.forEach((value) => {
  //             if (value.idGroups) {
  //               listagem[value.idGroups] = value;
  //             }
  //           });
  //           setListGroupList(listagem);
  //         }
  //       })
  //       .catch((error) => {
  //         setListGroupList([]);
  //         console.log("error", error);
  //       });
  //   }
  // }, [listGroupList]);

  const _getSchedulesTypes = useCallback(async () => {
    const result = await getSchedulesTypes();
    if (isMountedRefSchendulesTypes.current) {
      if (listTypes === "") {
        if (result) {
          let listagem = [];

          result.forEach((value) => {
            if (value.idScheduleType) {
              listagem[value.idScheduleType] = value;
            }
          });

          setFixedList(listagem); // stores the original response type list
          setListTypes(listagem);
        } else {
          setListTypes([]);
        }
      }
    }
  }, [listTypes]);

  const localizer = momentLocalizer(moment);

  const eventStyleGetter = (event) => {
    return {
      // style: style
      className: "eventStyleCuston",
    };
  };

  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    p: 4,
  };

  // useEffect(() => {
  //   if (listTypes === "") {
  //     getSchedulesTypes()
  //       .then((response) => {
  //         let listagem = [];
  //         response.map((value, index) => {
  //           listagem[value.idScheduleType] = value;
  //         });
  //         setListTypes(listagem);
  //       })
  //       .catch((error) => {});
  //   }
  // }, [listTypes]);

  

  useEffect(() => {
    isMountedRef.current = true;
    _eventsList()
    return () => (isMountedRef.current = false);

  }, [_eventsList]);

  useEffect(() => {
    isMountedRefSchendulesTypes.current = true;
    if (isMountedRefSchendulesTypes.current) {
      _getSchedulesTypes();
    }
    return () => (isMountedRefSchendulesTypes.current = false);
  }, []);

  const infoEvent = (e) => {
    let title = e.title;
    // let tipo = e.idScheduleType
    //   ? listTypes[e.idScheduleType] ? " - Tipo: " + listTypes[e.idScheduleType].description : "-"
    //   : null;
    // // let user = "";
    // // if( e.idUser  ){
    // //   user =' - Usuário: ' + dataUser.name;
    // // }
    // // let grupos = ' - Grupos:' + ( (e.groups).length > 0 ? (e.groups).map( (value,index)=> ( Object.keys(listGroupList).includes( value.toString() ) ? ' ' + listGroupList[value].title : null ) ) : ' Todos' );
    // let url = typeof e.url != "undefined" && e.url ? " - Url: " + e.url : "";
    // let descricao =
    //   typeof e.description != "undefined" &&
    //   e.description &&
    //   e.description !== "null"
    //     ? " - Descrição: " + e.description
    //     : "";
    // return title + tipo + url + descricao;
    return title;
  };

  const infoEventModal = (e) => {
    let title = e.title;
    let tipo = e.idScheduleType
      ? listTypes[e.idScheduleType].description
      : null;
    // let grupos = ( (e.groups).length > 0 ? (e.groups).map( (value,index)=> ( Object.keys(listGroupList).includes( value.toString() ) ? ' ' + listGroupList[value].title : null ) ) : ' Todos' );
    let url = typeof e.url != "undefined" && e.url ? e.url : "";
    let descricao =
      typeof e.description != "undefined" &&
      e.description &&
      e.description !== "null"
        ? e.description
        : "";
    let data =
      moment(e.startDate).format("DD/MM/YYYY HH:mm:ss") +
      " - " +
      moment(e.endDate).format("DD/MM/YYYY HH:mm:ss");

    // let user = "";
    // if( e.idUser ){
    //   user = dataUser.name;
    // }
    return {
      title: title,
      tipo: tipo,
      descricao: descricao,
      url: url,
      data: data,
    };
  };

  const defaultMessages = {
    date: "Data",
    time: "Hora",
    event: "Evento",
    allDay: "Dia Todo",
    week: "Semana",
    work_week: "Eventos",
    day: "Dia",
    month: "Mês",
    previous: "Anterior",
    next: "Próximo",
    yesterday: "Ontem",
    tomorrow: "Amanhã",
    today: "Hoje",
    agenda: "Agenda",
    noEventsInRange: "Não há eventos no período.",
    showMore: function showMore(total) {
      return "+" + total + " mais";
    },
  };

  const initialArray = () => {
    if(displayTypes.length === 0){

    
      let selectedItems = [];
      fixedList.map((item) => {
        selectedItems.push(item.description);

        return item;
      });

      return selectedItems;
  
    }
  }


  return (

    <div className="pctCalendar">
        

        <div className={`calendarBase ${props.className}`}>

        <div className={`pctOrganism sectionList ${(props.data.parameters.description)?"pctCalendarInText":''} `}>
          {props.data.parameters.title &&
              <Text component="h2" text={props.data.parameters.title} className='titleSection' />
          }
          {props.data.parameters.description &&
              <Text component="p" text={props.data.parameters.description} className='textSectionDescription' />
          }
        </div>
          
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="calendarEventsModal"
          >
            <Box sx={style}>
              <Text component="h2" text={contentModal.title} />

              <Text component="p" text={`<b>Data: </b> ${contentModal.data}`} />
              <Text component="p" text={`<b>Tipo: </b> ${contentModal.tipo}`} />

              {contentModal.url && (
                <p>
                  <b>Url: </b>
                  <a
                    href={contentModal.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {contentModal.url}
                  </a>
                </p>
              )}

              {contentModal.descricao && (
                <Text
                  component="p"
                  text={`<b>Descrição: </b> ${contentModal.descricao}`}
                />
              )}

              <div className="containerBtnModal">
                <Button onClick={handleClose}> Fechar </Button>
              </div>
            </Box>
          </Modal>

          <div className="sectionList titleAg">
            <AiOutlineCalendar />
            <Text component="h4" text={(props.data.title) ? props.data.title :"Agenda"} className="titleSection" />
          </div>
          {props.data.text &&
              <Text component="p" 
                  text={props.data.text} 
                  className='sectionList textSectionDescription' />
          }

          {/* Filtros */}
          <FormControl
            // style={{
            //   flex: 1,
            //   display: "flex",
            //   flexDirection: "row",
            // }}
            variant="outlined"
            className="sectionList filterBox"
          >
            {/* <InputLabel
              style={{
                backgroundColor: "white",
                top: displayTypes.length ? 0 : -5,
              }}
              id="mutiple-checkbox-label"
            >
              Categorias de eventos
            </InputLabel> */}
            <Select
              labelId="mutiple-checkbox-label"
              id="mutiple-checkbox"
              placeholder="Filtrar por categorias de eventos"
              multiple
              value={displayTypes.length === 0 ? initialArray() : displayTypes}
              onChange={handleTypeFilterChange}
              variant="outlined"
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{ getContentAnchorEl: () => null }}
              className="menuAgenda"
            >
              {fixedList.map((item, index) => (
                <MenuItem key={index} value={item.description} className={ loadingCalendar ? "filterAgendaBlock" : "" }>
                  <Checkbox checked={displayTypes.length > 0 ? displayTypes.indexOf(item.description) > -1 : true } />
                  <ListItemText primary={item.description} />
                </MenuItem>
              ))}
            
            </Select>
            {/* <button
              type="button"
              className="btn btn-secondary"
              style={{ height: 41, marginLeft: 10 }}
              onClick={() => _eventsList(selectedTypeIds)}
            >
              {loadingCalendar ? "Filtrando..." : "Filtrar"}
            </button> */}
            {/* {loadingCalendar && <Label text='Filtrando...'  /> } */}
          </FormControl>

          {/* end filtros */}
          <Calendar
            eventPropGetter={(e) => eventStyleGetter(e)}
            resizable
            messages={defaultMessages}
            localizer={localizer}
            events={state.events}
            showMultiDayTimes={true}
            startAccessor="start"
            endAccessor="end"
            tooltipAccessor={(e) => infoEvent(e)}
            titleAccessor={(e) => infoEvent(e)}
            onSelectEvent={(e) => {
              setContentModal(infoEventModal(e));
              setOpen(true);
            }}
            className="sectionList"
          />
        </div>
    </div>
  );
};

export default Agenda;
