import "./style.scss";

import React from "react";

const FiledSVG = () => {
    const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" width="425.857" height="246.744" viewBox="0 0 425.857 246.744">
    <g id="Grupo_1300" data-name="Grupo 1300" transform="translate(-92 -649)">
      <g id="Caminho_710" data-name="Caminho 710" transform="translate(93 650)" className="svgBg" opacity="0.938">
        <path d="M 421.2530822753906 245.2435607910156 L 2.604342460632324 245.2435607910156 C 1.948500394821167 245.2435607910156 1.014500379562378 244.7928771972656 0.2891319990158081 242.6458740234375 C -0.219710111618042 241.1397094726562 -0.4999732673168182 239.1471405029297 -0.4999732673168182 237.0351409912109 L -0.4999732673168182 7.708449363708496 C -0.4999732673168182 5.596449375152588 -0.219710111618042 3.603870391845703 0.2891319990158081 2.097712516784668 C 0.6895003914833069 0.9126598834991455 1.416184663772583 -0.4999717175960541 2.604342460632324 -0.4999717175960541 L 421.2530822753906 -0.4999717175960541 C 421.908935546875 -0.4999717175960541 422.8429870605469 -0.04928749427199364 423.5683288574219 2.097712516784668 C 424.0771789550781 3.603870391845703 424.3574523925781 5.596449375152588 424.3574523925781 7.708449363708496 L 424.3574523925781 237.0351409912109 C 424.3574523925781 239.1471405029297 424.0771789550781 241.1397094726562 423.5683288574219 242.6458740234375 C 423.16796875 243.8309173583984 422.4412841796875 245.2435607910156 421.2530822753906 245.2435607910156 Z" stroke="none"/>
        <path d="M 2.604339599609375 -3.0517578125e-05 C 1.166015625 -3.0517578125e-05 -3.0517578125e-05 3.451187133789062 -3.0517578125e-05 7.708450317382812 L -3.0517578125e-05 237.0350799560547 C -3.0517578125e-05 241.2923431396484 1.166015625 244.7434997558594 2.604339599609375 244.7434997558594 L 421.2530822753906 244.7434997558594 C 422.6914367675781 244.7434997558594 423.8573913574219 241.2923431396484 423.8573913574219 237.0350799560547 L 423.8573913574219 7.708450317382812 C 423.8573913574219 3.451187133789062 422.6914367675781 -3.0517578125e-05 421.2530822753906 -3.0517578125e-05 L 2.604339599609375 -3.0517578125e-05 M 2.604339599609375 -1.000030517578125 L 421.2530822753906 -1.000030517578125 C 422.0726013183594 -1.000030517578125 423.2217102050781 -0.4903411865234375 424.0420227050781 1.937652587890625 C 424.5678100585938 3.494033813476562 424.8573913574219 5.543441772460938 424.8573913574219 7.708450317382812 L 424.8573913574219 237.0350799560547 C 424.8573913574219 239.2000732421875 424.5678100585938 241.2494964599609 424.0420227050781 242.8058166503906 C 423.2217102050781 245.2338714599609 422.0726013183594 245.7434997558594 421.2530822753906 245.7434997558594 L 2.604339599609375 245.7434997558594 C 1.784820556640625 245.7434997558594 0.635711669921875 245.2338714599609 -0.184600830078125 242.8058776855469 C -0.71044921875 241.2494964599609 -1.000030517578125 239.2000732421875 -1.000030517578125 237.0350799560547 L -1.000030517578125 7.708450317382812 C -1.000030517578125 5.543441772460938 -0.71044921875 3.494033813476562 -0.184600830078125 1.937652587890625 C 0.635711669921875 -0.4903411865234375 1.784820556640625 -1.000030517578125 2.604339599609375 -1.000030517578125 Z" stroke="none" className="svgBg"/>
      </g>
      <g id="Grupo_1299" data-name="Grupo 1299" transform="translate(-1445 92)">
        <text id="Coming_Soon:_Joker" data-name="Coming Soon: Joker" transform="translate(1668 690)" className="svgBorder" font-size="27" font-family="SF Pro Text,SF Pro Display,sans-serif"><tspan x="0" y="0">Arquivado</tspan></text>
        <path id="Caminho_709" data-name="Caminho 709" d="M6.933,30.333a2.115,2.115,0,0,1-1.581-.7,2.249,2.249,0,0,1-.669-1.592V11.883a1.919,1.919,0,0,1-.928-.765,2.245,2.245,0,0,1-.422-1.327V5.583A2.173,2.173,0,0,1,4,4.014a2.142,2.142,0,0,1,1.58-.681h22.5a2.154,2.154,0,0,1,1.569.681,2.154,2.154,0,0,1,.681,1.569V9.791a2.245,2.245,0,0,1-.422,1.327,1.919,1.919,0,0,1-.928.765V28.038a2.232,2.232,0,0,1-.68,1.592,2.126,2.126,0,0,1-1.57.7ZM28.083,9.791V5.583H5.583V9.791ZM13.908,19.015h5.85a1.125,1.125,0,1,0,0-2.249h-5.85a1.125,1.125,0,1,0,0,2.249Z" transform="translate(1802.558 663.177)" className="svgBorder"/>
      </g>
    </g>
  </svg>
  `;

  return svgStr;
}

export default FiledSVG
