import "./style.scss";

import React from "react";

import { Text } from '..'
import { RiMapPin2Fill } from "react-icons/ri";
import { BsChevronDown } from 'react-icons/bs';


type Props = {
  text?:string
  className?: string
}

function PinPct({ 
  text="" , 
  className = "" }: Props) {
  
  return (
      
      <div className={`pctAtom pctPin ${className}`} >
          < RiMapPin2Fill/> 
          {/* <div className="pinInfo">
              <Text component={"small"} text={text} />
              <div className="abaDown">
                < BsChevronDown />
              </div> 
          </div> */}
      </div>
  )
}

export default PinPct
