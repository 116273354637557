import React, { useState, useEffect } from "react";
import useDarkMode from "use-dark-mode";
import hexToRgba from "hex-to-rgba";
import { getColors } from "services/utils";

export const useStyles = (styleData) => {
  const _body = document.documentElement.classList;
  _body.toggle("dark-mode", true);
  const appDetails = JSON.parse(localStorage.getItem("appDetails") as string);
  const menuData = JSON.parse(localStorage.getItem("menu-data") as string);
 
  let stylesObj = {
    colors: {
      'dark': menuData ? menuData.companyDesign.firstColor : appDetails.primaryColor,
      'light': "#FFF",
      'primary': menuData ? menuData.companyDesign.secondColor : appDetails.secondaryColor,
      'secondary': menuData ? menuData.companyDesign.thirdColor : appDetails.thirdColor,
      "tertiary": menuData ? menuData.companyDesign.fourthColor : appDetails.fourthColor,
      "quaternary": "#272353",
      'black': '#333333',
      "suport-success": "#35B352",
      "suport-success-dark": "#31603B",
      "suport-failed": "#C54444",
      "suport-failed-dark": "#9B3838",
      "suport-expired": "#cc5b08"
    },
    fonts: {
      "family": "SF Pro Text",
      "size-small": "12px",
      "size-paragraph": "14px",
      "size-h1": "28px",
      "size-h2": "24px",
      "size-h3": "21px",
      "size-h4": "18px",
      "line-height-small": "15px",
      "line-height-paragraph": "16px",
      "line-height-h1": "34px",
      "line-height-h2": "29px",
      "line-height-h3": "25px",
      "line-height-h4": "21px",
    },
  };


  if (styleData.length > 0) stylesObj = styleData;

  const createStyleColor = (colors: {}) => {
    Object.values(colors).map((value, index) => {
      // console.log(Object.keys(colors)[index] + " " + value);

      let name = Object.keys(colors)[index];
      let colorRgba: string = hexToRgba(value as string, "1");
      let nameRGB: string = colorRgba.replace("rgba(", "").replace(", 1)", "");
      let root = document.documentElement;

      root.style.setProperty(`--${name}`, `${nameRGB}`);

      return value;
    });
  };

  // const createStyleHeader = (header:{}) => {

  // };

  // const createStyleFooter = (footer:{}) => {

  // };

  createStyleColor(stylesObj.colors);
  // createStyleHeader(stylesObj.header)
  // createStyleFooter(stylesObj.footer)

  return stylesObj;
};

export const useDark = () => {
  // const darkMode = useDarkMode(true, {element: document.documentElement});
  const [darkStatus, setStatus] = useState(true);
  const darkToggle = () => {
    // darkMode.toggle()
  };

  // useEffect(() => {
  //   setStatus( ( darkMode?.value ) ? true : false );
  // }, [darkMode] );

  return { darkStatus, darkToggle };
};
