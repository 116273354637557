import "./style.scss";

import React, { useEffect, useState } from "react";
import moment from "moment";

import { Label, Text, DropDown } from "../../atoms";

type Props = {
  data: object;
  _parentData: object;
};

const Meeting = (props) => {
  const { data, _parentData } = props;

  const nodeCode = _parentData.node.nodeCode;

  const [filterStatus, setFilterStatus] = useState("eventPages");
  const [dataMeetings, setDataMeetings] = useState([]);

  useEffect(() => {
    if (filterStatus === "eventPages") {
      const dataMeetingsInit = data.meetings.filter(
        (it) => it.nodeCode === nodeCode
      );
      setDataMeetings(dataMeetingsInit);
    } else {
      setDataMeetings(data.meetings);
    }
  }, [filterStatus]);

  const CardMeeting = ({ item }) => {
    const { meetingType, startDate, finishDate, address, speaker } = item;

    const online = meetingType === "ONLINE";

    const Container = online ? "a" : "div";
    const href = online ? { href: address, target: "_blank" } : {};

    const dateStart = moment(startDate)
      .format("ddd, D MMM • HH:mm ")
      .toLocaleUpperCase();

    const dateFinish = moment(finishDate).format("[as] HH:mm");

    const dataConc = dateStart + dateFinish;

    return (
      <Container
        {...href}
        className={`cardInfoData ${online ? "cardInfoDataOnline" : ""}`}
      >
        <div className="cardInfoTop">
          <div className="dateInfo">
            <Text component={"h4"} text={`${dataConc}`} />
          </div>
          <Label text={meetingType} />
        </div>

        <div className="cardInfoBottom">
          {online ? (
            <Text
              component={"p"}
              text={`<b>Link da sala:</b> <span class='online'>${address}</span>`}
              limit={67}
            />
          ) : (
            <Text
              component={"p"}
              text={`<b>Endereço:</b> <span>${address}</span>`}
            />
          )}
          <Text component={"p"} text={`<b>Nome do condutor</b> ${speaker}`} />
        </div>
      </Container>
    );
  };

  const filterSelect = (e) => {
    setFilterStatus(e.target.value);
  };

  return (
    <div
      className={`pctOrganism pctGrid ${
        data.parameters.description && "pctGridInText"
      } ${data.className}`}
    >
      {data.title && (
        <Text
          component="h2"
          text={data.parameters.title}
          className="titleSection"
        />
      )}

      {data.description && (
        <Text
          component="p"
          text={data.parameters.description}
          className="textSection"
        />
      )}

      <div className="filterBase">
        {/* <label className="select">
          <select
            name="filterEvents"
            id="filterEvents"
            onChange={(e) => {
              filterSelect(e);
            }}
          >
            <option value="eventPages">Eventos da página</option>
            <option value="eventAlls">Todos os eventos</option>
          </select>
        </label> */}

        <DropDown
          placeholder={"Eventos da página"}
          options={[
            { title: "Eventos da página", value: "eventPages" },
            { title: "Todos os eventos", value: "eventAlls" },
          ]}
          onSelected={(item) => {
            setFilterStatus(item["value"]);
          }}
        />
      </div>

      <div className="listMeeting">
        {dataMeetings.map((item, indice) => {
          return <CardMeeting item={item} key={indice} />;
        })}
      </div>
    </div>
  );
};

export default Meeting;
