import React, { useState, useEffect } from "react";

import "./style.scss";

import NiceModal, { useModal, antdModal } from "@ebay/nice-modal-react";

import { Button } from "pctComponents/atoms";

import ModalCompaingsTitle from "./components/ModalCompaingsTitle";
import ModalCompaingsSingle from "./components/ModalCompaingsSingle";
import ModalCompaingsMultiple from "./components/ModalCompaingsMultiple";
import ModalCompaingsList from "./components/ModalCompaingsList";

export enum Model {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
  LIST = "LIST",
}

type Props = {
  model: Model;
  campaign?: any;
  listPoint?: any;
  onclosedEvent?: any;
};

export const ModalCampaign = NiceModal.create(
  ({
    model = Model.SINGLE,
    campaign,
    listPoint,
    onclosedEvent = null,
  }: Props) => {
    const modal = useModal();

    const closed = (e) => {
      if (onclosedEvent) onclosedEvent();
      modal.remove();
    };

    return (
      <div className="modalCampaingn">
        <div className="modal-body-pct">
          <div className="modal-container-campaign">
            <div className="ptcCampaignTitle">
              <ModalCompaingsTitle
                model={model}
                title={`${model === Model.SINGLE ? campaign[0].name : ""}`}
              />
            </div>
            <div className="ptcCampaignDescription">
              {model === Model.SINGLE && (
                <ModalCompaingsSingle campaign={campaign} />
              )}
              {model === Model.MULTIPLE && (
                <ModalCompaingsMultiple campaign={campaign} />
              )}
              {model === Model.LIST && (
                <ModalCompaingsList listPoint={listPoint} />
              )}
            </div>
          </div>

          <div className="bottonBtn">
            <Button
              text="Ok, entendi"
              className="button03"
              callEvent={(e) => closed(e)}
            />
          </div>
        </div>
      </div>
    );
  }
);
