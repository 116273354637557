import './style.scss';

import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProfileInfo } from 'services/utils';
import { useHistory } from 'react-router-dom';
import { updateConfigHeaderFooter } from '../../../actions';
import { Text, Avatar } from '../../atoms';
import {
    MyCertificates,
    MyHistoric,
    ListGamification,
    RequestEnrollmentStatus,
    ExternalCourse,
} from 'pctComponents/organisms';
import { MenuTree } from 'pctComponents/molecules';

const MyJourney = ({ updateConfigHeaderFooter, match, configHasEnrollmentCampaign, configFeatures }) => {
    const userProfile = getProfileInfo();
    const history = useHistory();
    const [currentView, setCurrentView] = useState(match.params.tab ? match.params.tab : 'registered');

    const handleMenuMouseEvent = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const elem = e.target as HTMLInputElement;
        setCurrentView(elem.id);
    };

    const redictToPerfil = () => {
        history.push(`/editPerfil`);
    };

    useEffect(() => {
        updateConfigHeaderFooter({
            prevNavStyle: 'prevShow closeShow',
            backCloseText: 'FECHAR',
        });
    }, [updateConfigHeaderFooter]);

    //Verifica no inicio do header se o status da hasEnrollmentRequests e userIsInCampaign estao ativos
    useEffect(() => {
        if (configHasEnrollmentCampaign && configFeatures) {
            const { hasEnrollmentRequests, userIsInCampaign } = configHasEnrollmentCampaign;
            const { externalcourses } = configFeatures;

            const { tab } = match.params;

            if (tab === 'requestEnrollmentStatus') {
                hasEnrollmentRequests ? setCurrentView('requestEnrollmentStatus') : setCurrentView('registered');
            } else if (tab === 'gamification') {
                userIsInCampaign ? setCurrentView('gamification') : setCurrentView('registered');
            } else if (tab === 'externalCourse') {
                externalcourses ? setCurrentView('externalCourse') : setCurrentView('registered');
            }
        }
    }, [configHasEnrollmentCampaign, configFeatures, match.params]);

    return (
        <div className="pctPage pctPageBlock pctJourney">
            <div className="sectionList">
                <div className="journeyHeader">
                    <div className="journeyPerfil">
                        <div
                            className="avatar"
                            onClick={() => {
                                redictToPerfil();
                            }}
                        >
                            <Avatar />
                            <Text text={userProfile.name} className="nameUser" />
                            <Text text="Editar perfil" className="edit" />
                        </div>
                    </div>
                    <div className="journeyBannerImage"></div>
                </div>

                <div className="journeyBody">
                    <div className="contentsView">
                        <div className="contentsViewMenu">
                            <Text text="Minha Jornada" component={'h3'} className="label" />
                            <div className="journeyNavBar">
                                <ul>
                                    <li
                                        id="registered"
                                        className={currentView === 'registered' ? 'active' : ''}
                                        onClick={(e) => {
                                            handleMenuMouseEvent(e);
                                        }}
                                    >
                                        {' '}
                                        Matriculados{' '}
                                    </li>
                                    {configHasEnrollmentCampaign.hasEnrollmentRequests && (
                                        <li
                                            id="requestEnrollmentStatus"
                                            className={currentView === 'requestEnrollmentStatus' ? 'active' : ''}
                                            onClick={(e) => {
                                                handleMenuMouseEvent(e);
                                            }}
                                        >
                                            Solicitações de matrícula
                                        </li>
                                    )}
                                    <li
                                        id="historic"
                                        className={currentView === 'historic' ? 'active' : ''}
                                        onClick={(e) => {
                                            handleMenuMouseEvent(e);
                                        }}
                                    >
                                        Meu histórico
                                    </li>
                                    <li
                                        id="certificates"
                                        className={currentView === 'certificates' ? 'active' : ''}
                                        onClick={(e) => {
                                            handleMenuMouseEvent(e);
                                        }}
                                    >
                                        Meus certificados
                                    </li>
                                    {configHasEnrollmentCampaign.userIsInCampaign && (
                                        <li
                                            id="gamification"
                                            className={currentView === 'gamification' ? 'active' : ''}
                                            onClick={(e) => {
                                                handleMenuMouseEvent(e);
                                            }}
                                        >
                                            Campanhas e Gamificação
                                        </li>
                                    )}
                                    {configFeatures.externalcourses && (
                                        <li
                                            id="externalCourse"
                                            className={currentView === 'externalCourse' ? 'active' : ''}
                                            onClick={(e) => {
                                                handleMenuMouseEvent(e);
                                            }}
                                        >
                                            Cursos externos
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="contentsShow">
                            {currentView === 'registered' && <MenuTree className="menuTree01" />}

                            {currentView === 'requestEnrollmentStatus' && (
                                <RequestEnrollmentStatus userId={userProfile.idUser} />
                            )}

                            {currentView === 'historic' && <MyHistoric userId={userProfile.idUser} />}

                            {currentView === 'certificates' && <MyCertificates userId={userProfile.idUser} />}

                            {currentView === 'gamification' && <ListGamification userId={userProfile.idUser} />}

                            {currentView === 'externalCourse' && <ExternalCourse userId={userProfile.idUser} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

const mapStateToProps = (store) => ({
    configHasEnrollmentCampaign: store.hasEnrollmentCampaignState.configHasEnrollmentCampaign,
    configFeatures: store.configFeaturesState.configFeatures,
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MyJourney);
