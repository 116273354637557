import React from "react";
 import "./style.scss";
 
 const PctPagination = ({ total, current, onPageChange }) => {
   const pageNumbers: number[] = [];
   for (let i = 1; i <= total; i++) {
     pageNumbers.push(i);
   }
 
   const renderPageNumbers = () => {
    const pageItems: JSX.Element[] = [];
 
     if (current > 1) {
       pageItems.push(
         <li key="prev" className="page-item-prev" onClick={() => onPageChange(current - 1)}>
            &lt; Anterior
         </li>
       );
     }
 
     if (current > 2) {
       pageItems.push(
         <li key="1" className="page-item" onClick={() => onPageChange(1)}>
           1
         </li>
       );
       if (current > 3) {
         pageItems.push(<li key="start-ellipsis" className="page-item ellipsis">...</li>);
       }
     }
 
     for (let i = Math.max(1, current - 1); i <= Math.min(total, current + 1); i++) {
       pageItems.push(
         <li
           key={i}
           className={`page-item ${current === i ? "active" : ""}`}
           onClick={() => onPageChange(i)}
         >
           {i}
         </li>
       );
     }
 
     if (current < total - 1) {
       if (current < total - 2) {
         pageItems.push(<li key="end-ellipsis" className="page-item ellipsis">...</li>);
       }
       pageItems.push(
         <li key={total} className="page-item" onClick={() => onPageChange(total)}>
           {total}
         </li>
       );
     }
 
     if (current < total) {
       pageItems.push(
         <li key="next" className="page-item-next" onClick={() => onPageChange(current + 1)}>
           Próximo &gt;
         </li>
       );
     }
 
     return pageItems;
   };
 
   return (
     <ul className="pctPagination">
       {renderPageNumbers()}
     </ul>
   );
 };
 
 export default PctPagination;