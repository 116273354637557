import "./style.scss";

import React, { useState, useEffect } from "react";
import { BiTime } from "react-icons/bi";
import moment from "moment";
import "moment-duration-format";
import { useTimer } from "react-timer-hook";
import { LuTimer } from "react-icons/lu";

import { Text } from "..";

type Props = {
  expiryTimestamp?: any;
  onExpireTime?: any;
  leftTimeRef?: any;
};

function Timer({ expiryTimestamp, onExpireTime, leftTimeRef }: Props) {
  // console.log(leftTimeRef.current);

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    autoStart: true,
    onExpire: () => {
      if (onExpireTime) {
        leftTimeRef.current = 0;
        onExpireTime();
      }
    },
  });

  useEffect(() => {
    const intervalProgress = setInterval((e) => {
      if (leftTimeRef) {
        leftTimeRef.current -= 1;

        if (leftTimeRef.current == 0) {
          clearInterval(intervalProgress);
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalProgress);
    };
  }, []);

  return (
    <div
      className={`pctTimer ${leftTimeRef.current < 20 ? "lastSeconds" : ""}`}
    >
      <LuTimer />
      <span>
        {days > 0 ? `${days > 9 ? days : "0" + days}d ` : ""}
        {hours > 0 ? `${hours > 9 ? hours : "0" + hours}h ` : ""}
        {minutes > 0 ? `${minutes > 9 ? minutes : "0" + minutes}m ` : ""}
        {seconds > 0 ? `${seconds > 9 ? seconds : "0" + seconds}s ` : ""}{" "}
      </span>
    </div>
  );
}

export default Timer;
