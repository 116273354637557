import React from "react";
import { hasFooter } from "../services/utils";

const JourneyPageRendering = ({ onLoadIframe, page }) => {
  return (
    <iframe
      onLoad={onLoadIframe()}
      className="step-rendered-page" 
      style={{minHeight: hasFooter() ? 'calc(100vh - 206px)' : 'calc(100vh - 126px)'}}
      src={page()} 
      title="Journey page" 
      width="100%" 
      height="100%"
      id="journey-iframe"
    />
  );
};

export default JourneyPageRendering;
