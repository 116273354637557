import "./style.scss"

import React, { Component, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Text } from "../../atoms"
import { CardBlock} from '../../molecules'


function NodeLink(data: any) {
  const dataBlock = data.data;
  const dataParent = data._parentData;

  return (
    
    <div className={`pctOrganism pctNodeLink ${data.className}`}>

        {dataBlock.parameters.title &&
           <Text component="h2" text={dataBlock.parameters.title} className='titleSection' />
        }

        {dataBlock.parameters.description &&
           <Text component="p" text={dataBlock.parameters.description} className='textSection' />
        }

        {
          dataBlock.elements.map((collectionData:any, i:number) => {
            return <CardBlock 
                key={ i }
                id={ collectionData.idBlock }
                collection={ collectionData }
                prerequisite={ true }
                dataParent= { dataParent }
                parameters={dataBlock.parameters}
                
              />
          })
        }

    </div>

  )
}

export default NodeLink
