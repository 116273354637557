import { Label, Preloader, Text } from "pctComponents/atoms";
import { PlaceholderTree } from "pctComponents/placehoader";
import "./style.scss";

import React, { useState, useEffect } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { getProfileInfo } from "services/utils";
import { getMapFromEnrollment } from "services/api";
import CardBlockMap from "../CardBlockMap";

type Props = {
  collection?: Array<object> | any;
  hasTitle?: boolean;
  closeMap?: any;
  mapElements?: any;
};

function TreeMap({ collection, hasTitle=true, closeMap, mapElements }: Props) {
  const [displayChildren, setDisplayChildren] = useState({});
  const [dataMap, setDataMap] = useState({} as any);
  
  

  useEffect(() => {
    let objEnrollment: any = {};
    
    if(!mapElements){
      if (collection.hasOwnProperty("idQuestionnaire")) {
        objEnrollment = {
          idUser: getProfileInfo().idUser,
          idEnrollment: collection.currentItem.idEnrollment,
          nodeCode: collection.currentItem.nodeCode,
          fullMap: collection.isChild == true ? false :  true
        };
      } else if (collection.hasOwnProperty("currentScoObject")) {
        objEnrollment = {
          idUser: getProfileInfo().idUser,
          idEnrollment: collection.rootContent.idEnrollment,
          nodeCode: collection.nodeCode,
          fullMap: collection.isChild == true ? false :  true
        };
      }
      else {
        if(collection.hasOwnProperty("currentItem")){
          //MAPA DE LIÇÕES SCORM
          // if(collection.currentItem.idContentType == 26){
            objEnrollment = {
              idUser: getProfileInfo().idUser,
              idEnrollment: collection.currentItem.idEnrollment,
              nodeCode: collection.currentItem.nodeCode,
              fullMap: collection.isChild == true ? false :  true
            // };
          }

        }else{
          objEnrollment = {
            idUser: getProfileInfo().idUser,
            idEnrollment: collection.contentEnrollment
              ? collection.contentEnrollment.idEnrollment
              : collection.nodeEnrollment.idEnrollment,
            nodeCode: collection.currentItem ? collection.currentItem.nodeCode : collection.nodeCode,
            fullMap: collection.isChild == true ? false :  true
          };
        }
      }

      getMapFromEnrollment(objEnrollment)
        .then((response) => {
          setTimeout(() => {
            setDataMap(response.data);
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }else{
      setDataMap(mapElements);
    }


  }, []);

  let items = [] as Array<object> | any;
  items = dataMap["mapElements"];

  if (!dataMap.hasOwnProperty("mapElements"))
    return <PlaceholderTree number={3} init={hasTitle} />

  return (
    <>
      {hasTitle !== false && (
        <div className="headerMapInfos">
          <Text text={dataMap.title} component={"h3"} />
          <Label text={dataMap.label} />
        </div>
      )}

      <div className="treeComponent">
        {dataMap.hasOwnProperty("mapElements") && (
          <ul>
            {items?.map((item: any, i) => {
              let realTitle = item.title;
              //TODO: SE O PREVITEM FOR IGUAL AO CONTEÚDO ATUAL DA TELA, E A REFERENCIA CONTEÚDO ATUAL ESTIVER COM UM FORCE COMPLETED PRECISAMOS MODIFICAR ESTE ITEM PARA CONCLUÍDO. 
              // CASO O FORCECOMPLETED ESTIVER VERDADEIRO TAMBÉM PRECISAMOS FORÇAR O ITEM PARA COMPLETED 100%
              // O FORCE COMPLETED SÓ PODE SER ENVIADO QUANDO O CONTEÚDO SCORM FOR DE SCO ÚNICO
              let prevItem = i > 0 ? items[i - 1] : null;
              const hasChilds = item.elementType === "NODE";
              const mapElementsToPreExpand = item.hasOwnProperty("mapElements") ? { mapElements: item.mapElements, nodeEnrollment:item.nodeEnrollment } : null;


              // console.log(dataMap, dataMap.nodeEnrollment)
              return (
                <div className="itemRoot" key={i}>
                  <li key={i}>
                    <div className="item">
                      {/* FILHOS QUE NÃO PRECISAM ESTAR EXPANDIDOS AO INICIAR */}
                      {(hasChilds && !mapElementsToPreExpand) && (
                        <>
                          <CardBlockMap
                            collection={item}
                            contentCollection={collection}
                            prevItem={prevItem}
                            nodeEnrollment={dataMap.nodeEnrollment}
                            closeMap={closeMap}
                            forceCurrentComplete = {collection.forceCurrentComplete}
                            onClick={(enrollmentResult) => {
                              
                              setDisplayChildren({
                                ...displayChildren,
                                [realTitle]: !displayChildren[realTitle],
                              });
                            }}
                            
                            expandButton={
                              <button
                                className={
                                  displayChildren[realTitle] ? "" : "openTree"
                                }
                              >
                                {displayChildren[realTitle] ? (
                                  <BsChevronUp />
                                ) : (
                                  <BsChevronDown />
                                )}
                              </button>
                            }
                          />
                        </>
                      )}

                      {/* FILHOS QUE PRECISAM ESTAR EXPANDIDOS AO INICIAR */}
                      {(hasChilds && mapElementsToPreExpand) && (
                        <>
                          <CardBlockMap
                            collection={item}
                            contentCollection={collection}
                            prevItem={prevItem}
                            nodeEnrollment={dataMap.nodeEnrollment}
                            closeMap={closeMap}
                            isCurrentChild={true}
                            forceCurrentComplete = {collection.forceCurrentComplete}
                            onClick={(enrollmentResult) => {
                              
                              setDisplayChildren({
                                ...displayChildren,
                                [realTitle]: !displayChildren[realTitle],
                              });
                            }}
                            expandButton={
                              <button
                                className={
                                  displayChildren[realTitle] ? "" : "openTree"
                                }
                              >
                                {displayChildren[realTitle] ? (
                                  <BsChevronUp />
                                ) : (
                                  <BsChevronDown />
                                )}
                              </button>
                            }
                          />
                        </>
                      )}

                      {/* SÃO CONTEÚDOS */}
                      {!hasChilds && (
                        <CardBlockMap
                          collection={item}
                          contentCollection={collection}
                          nodeEnrollment={dataMap.nodeEnrollment}
                          prevItem={prevItem}
                          closeMap={closeMap}
                          forceCurrentComplete = {collection.forceCurrentComplete}
                        />
                      )}


                    </div>

                    {(displayChildren[realTitle] ) && (
                      // <MenuTree collection={item.data["childNodes"]} />
                      <TreeMap
                        mapElements={mapElementsToPreExpand}
                        closeMap={closeMap}
                        collection={{
                          nodeEnrollment: item.nodeEnrollment,
                          nodeCode: item.nodeCode,
                          // currentContentCollection: collection,
                          currentContentCollection: collection.currentContentCollection ? collection.currentContentCollection : collection,
                          isChild: true,
                        }}
                        hasTitle={false}
                      />
                    )}
                  </li>
                </div>
              );
              
              
              
            })}
          </ul>
        )}
      </div>
    </>
  );
}

export default TreeMap;
