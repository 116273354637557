import { Preloader, Text } from "pctComponents/atoms";
import "./style.scss";

import React, { useState, useEffect, useRef } from "react";
// import { getnode } from "services/api";
import { useFetch } from "pctHooks/useFetch";
// import CardBlockJourney from "../CardBlockJourney";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { getProfileInfo } from "services/utils";
// import { getUserEnrollment, getTree } from "services/api";
import { Link } from "react-router-dom";
import useCards from "../../../pctHooks/useCards";
import CardMenu from "../CardMenu";
import PlaceholderProductsTree from "pctComponents/placehoader/PlaceholderProductsTree";


type Props = {
  nodeCode?: string;
  className?: string;
  collection?: Array<BlockElement> | any;
  levelsQtd?: any;
  inComponent?: any;
  closeRoot?: any;
};

function MenuProducts({
  nodeCode,
  closeRoot,
}: Props) {
  
  const [displayChildren, setDisplayChildren] = useState({});

  let fetchData = {} as LearningNode | any;
  let nodeCodeCurrent = nodeCode ? nodeCode : getProfileInfo().idCompany;

  // const apiUrl = `/learning/nodes/tree?nodeCode=${nodeCodeCurrent}&nodeType=ORGANIZATION&status=PUBLISHED`;
  const apiUrl = `/learning/nodes/side-menu?nodeCode=${nodeCodeCurrent}`;
  const { data, error } = useFetch({url: apiUrl});

  if (error) return <p>Erro de carregamento.</p>;
  // if (!data) return <Preloader className="pctPreloaderLists" />;

  if (!data) return <PlaceholderProductsTree number={3} init={false} />;

  fetchData = data[0];

  return (
    <div className="menuProducts">
      <ul>
        {fetchData.childNodes?.map((item, i) => {
          
          let realTitle = (item.title != null) ? item.title : ("Sem título" as string);
          
          const hasChilds = true;

          return (
            <li key={item.nodeCode}>
              <div
                className="item"
              >
                {hasChilds && (
                  <>

                    <CardMenu 
                        collection={item}
                    />

                    {( item.hasChildNodes === 1 ) && (
                      <button
                        className={displayChildren[item.nodeCode] ? "" : "openTree"}
                        onClick={() => {
                          setDisplayChildren({
                            ...displayChildren,
                            [item.nodeCode]: !displayChildren[item.nodeCode],
                          });
                        }}
                      >
                        {displayChildren[item.nodeCode] ? <BsChevronUp /> : "+"}
                      </button>
                    )}
                  </>
                )}
              </div>

              {displayChildren[item.nodeCode] && (
                <MenuProducts
                  nodeCode={item.nodeCode}
                  closeRoot={closeRoot}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default MenuProducts;
