import React from 'react';
import { Formik, Form } from 'formik';
import NiceModal from '@ebay/nice-modal-react';

import { Preloader } from '../../../../../pctComponents/atoms';
import AlertNotification from '../../components/AlertNotification';
import ButtonsWrapperModal from '../../components/ButtonsWrapperModal';
import FormExternalCourse from '../../components/FormExternalCourse';
import { ExternalCourseType } from '../../ExternalCourse.types';
import { schemaExternalCourse } from '../../ExternalCourse.utils';
import ModalExternalCourseController from './ModalExternalCourse.controller';
import './style.scss';

export interface ModalExternalCourseProps {
    item: ExternalCourseType | null;
    resetItemOffset: () => void | null;
}

const ModalExternalCourse = NiceModal.create(({ item = null, resetItemOffset }: ModalExternalCourseProps) => {
    const {
        externalCourseForm,
        loading,
        errorMessageFile,
        handleFileChange,
        closeModal,
        onSubmitExternalCourse,
        disableForm,
        setDisableForm,
    } = ModalExternalCourseController({
        item,
        resetItemOffset,
    });

    // console.log(externalCourseForm);

    return (
        <div className={`modalExternalCourse`}>
            {loading && <Preloader />}
            {externalCourseForm && (
                <div className={`modal-body-pct`}>
                    <AlertNotification item={externalCourseForm} />

                    <Formik
                        validationSchema={schemaExternalCourse}
                        onSubmit={(values, { setSubmitting, setStatus, setErrors }) => {
                            // console.log(values);
                            onSubmitExternalCourse(values, setSubmitting);
                        }}
                        initialValues={externalCourseForm}
                    >
                        {(props) => (
                            <div className="modalExternalWrapper">
                                <Form noValidate>
                                    <FormExternalCourse
                                        {...props}
                                        errorMessageFile={errorMessageFile}
                                        handleFileChange={(e) => handleFileChange(e, props.setFieldValue)}
                                        closeModal={closeModal}
                                        item={externalCourseForm}
                                        disableForm={disableForm}
                                    />
                                    <ButtonsWrapperModal
                                        item={externalCourseForm}
                                        closeModal={closeModal}
                                        handleSubmit={props.handleSubmit}
                                        disableForm={disableForm}
                                        setDisableForm={setDisableForm}
                                    />
                                </Form>
                            </div>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    );
});

export default ModalExternalCourse;
