import React, { useState, useEffect } from "react";

import { convertToBrDate } from "services/utils";

import { Text } from "pctComponents/atoms";

type Props = {
  campaign: any;
};

const ModalCompaingsSingle = ({ campaign }: Props) => {
  const campaignSingle = campaign[0];
  return (
    <>
      {campaignSingle.description && (
        <Text
          component={"p"}
          text={`${campaignSingle.description}`}
          className={"titleCompaingsSingle"}
        />
      )}
      <div className="dateInfo">
        <Text
          component={"p"}
          text={`Data de Início: ${convertToBrDate(campaignSingle.startDate)}`}
        />
        <Text
          component={"p"}
          text={`Data de Fim: ${convertToBrDate(campaignSingle.endDate)}`}
        />
      </div>
    </>
  );
};

export default ModalCompaingsSingle;
