import './style.scss';

import React from 'react';

const PreTestSVG = () => {
    const svgStr = `
    <svg id="Grupo_5083" data-name="Grupo 5083" xmlns="http://www.w3.org/2000/svg" width="523.742" height="307.932" viewBox="0 0 523.742 307.932">
      <g id="Grupo_1085" data-name="Grupo 1085">
        <rect id="GettyImages-95574693" width="523.742" height="307.932" rx="5" className="svgBg" />
      </g>
      <g id="Grupo_5084" data-name="Grupo 5084" transform="translate(187.348 79.442)">
        <path id="Caminho_3758" data-name="Caminho 3758" d="M0,0H149.048V149.048H0Z" fill="none" stroke="#000" stroke-width="0"/>
        <path id="Caminho_3759" data-name="Caminho 3759" d="M113.786,3H14.421A12.457,12.457,0,0,0,2,15.421v86.944a12.457,12.457,0,0,0,12.421,12.421h99.365a12.457,12.457,0,0,0,12.421-12.421V15.421A12.457,12.457,0,0,0,113.786,3Zm-62.1,86.944H20.631V77.524H51.683Zm0-24.841H20.631V52.683H51.683Zm0-24.841H20.631V27.841H51.683ZM81.616,77.524,64.1,59.887,72.86,51.13l8.757,8.819L101.3,40.262l8.819,8.819Z" transform="translate(10.421 15.631)" className="svgBorder" stroke="#000" stroke-width="0" fill-rule="evenodd"/>
      </g>
    </svg>
    `;

    return svgStr;
};

export default PreTestSVG;
