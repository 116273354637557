import './style.scss';

import React from 'react';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Estilos padrão

import { Portuguese } from 'flatpickr/dist/l10n/pt.js';

import { MdCalendarToday } from 'react-icons/md';

import { formatIsoToDate } from 'services/utils';

function CalendarInput(props) {
    // const isMobile = useIsMobile();

    const disabled = props.disabled;
    const propsRef = { ...props, disabled: false };

    const options = {
        dateFormat: 'd/m/Y',
        locale: Portuguese,
        ...propsRef.options,
        disabled: false,
    };

    return (
        <>
            {disabled ? (
                <div className="placehouderDisabled">{formatIsoToDate(propsRef.value)}</div>
            ) : (
                <div className="calendarInput">
                    <MdCalendarToday className="calendarInputIco" />
                    <Flatpickr {...propsRef} options={options} />
                </div>
            )}
        </>
    );
}

export default CalendarInput;
