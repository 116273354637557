import React, { useState, useEffect, Fragment } from 'react';
import { Drawer, IconButton, Tabs, Tab, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import InteractionContent from './InteractionContent';
import { DrawerContent, DrawerTabs } from './InteractionDrawer.types';
import useMediaQuery from 'pctHooks/useMediaQuery';

type Props = {
    isOpen: boolean;
    handleClose: () => void;
    enabledTabs: boolean;
    tabs: DrawerTabs[];
    contents: DrawerContent[];
};

const InteractionDrawer = ({ handleClose, isOpen = false, enabledTabs = false, tabs = [], contents = [] }: Props) => {
    const isMobile = useMediaQuery('md');
    const [selectedTab, setSelectedTab] = useState(0);

    const toggleSidebar = () => {
        handleClose();
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('bodyOverflowHidden');
        } else {
            document.body.classList.remove('bodyOverflowHidden');
        }

        return () => {
            document.body.classList.remove('bodyOverflowHidden');
        };
    }, [isOpen]);

    return (
        <>
            <Drawer anchor="right" open={isOpen} onClose={toggleSidebar}>
                <div
                    className="interaction-drawer-container"
                    style={{ width: isMobile ? '100vw' : '50vw', minWidth: isMobile ? 'auto' : '800px' }}
                    role="presentation"
                >
                    <div
                        className={`${isMobile ? 'interaction-drawer-header-mobile' : ''} ${
                            enabledTabs ? 'enabled-tabs' : ''
                        }`}
                    >
                        {isMobile && <p>{tabs[selectedTab]?.title}</p>}
                        <IconButton
                            onClick={toggleSidebar}
                            className={`interaction-drawer-close-button right-align${isMobile ? 'mobile' : ''}`}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <>
                        {enabledTabs && (
                            <>
                                <Tabs
                                    value={selectedTab}
                                    onChange={handleTabChange}
                                    aria-label="interaction tabs"
                                    className="interaction-tabs"
                                    textColor="inherit"
                                    classes={{
                                        indicator: 'interaction-tab-indicator',
                                    }}
                                    variant="fullWidth"
                                >
                                    {tabs.map((tab, indice) => (
                                        <Tab
                                            key={indice}
                                            label={!isMobile ? tab.label : tab.icon}
                                            className="interaction-tab"
                                            style={{ flex: 1 }}
                                        />
                                    ))}
                                </Tabs>
                            </>
                        )}

                        {contents.map((content, index) => (
                            <Fragment key={index}>
                                {!isMobile && !enabledTabs && (
                                    <p className="interaction-drawer-header">{content.title}</p>
                                )}
                                <Box
                                    role="tabpanel"
                                    hidden={selectedTab !== index}
                                    className={`tab-content${isMobile ? 'mobile' : ''}`}
                                >
                                    <InteractionContent entityType={content.entityType} idEntity={content.idEntity} />
                                </Box>
                            </Fragment>
                        ))}
                    </>
                </div>
            </Drawer>
        </>
    );
};

export default InteractionDrawer;
