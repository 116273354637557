import './style.scss';

import React from 'react';

type Props = {
    number: number;
    className?: string;
    init?: boolean;
};

function PlaceholderAttachments({ number = 5, className = '', init = false }: Props) {
    let rows: number[] = [];
    let i: number = 0;

    while (++i <= number) {
        rows.push(i);
    }
    const PlaceholderItem = ({ className }) => {
        return (
            <div className={`ph-wrapper ${className}`}>
                <div className="ph-line-base">
                    {/* <div className="ph-text shortLine ph-animated"></div> */}
                    <div className="infoIn">
                        <div className="ph-text shortLabel ph-animated"></div>
                        <div className="ph-base-b">
                            <div className="ph-text short ph-animated"></div>
                        </div>
                    </div>
                    <div className="ph-text shortLine ph-animated"></div>
                </div>
            </div>
        );
    };

    return (
        <div className={`pg-grid-placeholder-tree ${init ? 'ph-wrapperInit' : ''}`}>
            {rows.map(function (i) {
                return <PlaceholderItem key={i} className={className} />;
            })}
        </div>
    );
}

export default PlaceholderAttachments;
