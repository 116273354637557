import React, { Component, useState } from "react";
import "./style.scss";
import { Text } from "../";
import { PiHeartDuotone } from "react-icons/pi";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import useLike from "pctHooks/useLike";

const tooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: "#363636",
  },
  tooltip: {
    backgroundColor: "#363636",
    textAlign: "center",
    maxWidth: 220,
    border: "0.5px solid rgba(238, 238, 238, 0.29)",
  },
}));

type Props = {
  direction?: "" | "invert";
  css?: object;
  className?: string;
  callToLike?: () => void;
  collection: object;
  statusRegistered: string;
  disabled?: boolean;
};

function Like({
  direction = "",
  css = {},
  className = "",
  collection,
  statusRegistered,
  disabled = false
}: Props) {
  const tooltipClasses = tooltipStyles();

  const {
    createLike,
    toLike,
    likeCount,
    inactiveTooltip,
    messageTooltip,
    callHeart,
  } = useLike(collection, statusRegistered);

  return createLike ? (
    <div
      className={`pctAtom pctLike ${toLike} ${direction} ${className}`}
      style={css}
    >
      <Tooltip
        title={messageTooltip}
        aria-label={messageTooltip}
        placement="top"
        classes={tooltipClasses}
        disableHoverListener={inactiveTooltip}
      >
        <button
          className="heartBase"
          disabled={disabled}
          onClick={(e) => {
            callHeart(e);
          }}
        >
          <PiHeartDuotone />
        </button>
      </Tooltip>

      {likeCount > 0 && (
        <Text component="p" text={`${likeCount}`} className="count" />
      )}
    </div>
  ) : null;
}

export default Like;
