import "./style.scss";

import React from "react";

const ExpiredSVG = () => {
    const svgStr = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 1h6v2H9zM12 6c3.87 0 7 3.13 7 7 0 .94-.19 1.83-.52 2.65l1.5 1.5a8.963 8.963 0 00-.95-9.76l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42a8.962 8.962 0 00-9.77-.96l1.5 1.5A7.07 7.07 0 0112 6z"></path><path d="M11 8.17l2 2V8h-2zM2.81 2.81L1.39 4.22l3.4 3.4a8.994 8.994 0 0012.59 12.59l2.4 2.4 1.41-1.41L2.81 2.81zM12 20c-3.87 0-7-3.13-7-7 0-1.47.45-2.83 1.22-3.95l9.73 9.73A6.945 6.945 0 0112 20z"></path></svg>`;

  return svgStr;
}

export default ExpiredSVG
