import "./style.scss";

import React from "react"

import { Text } from "../../atoms"
import { CardListing } from '..'

type Props = {
  data: object
}


const Grid = (props) => {

  const { data, _parentData } = props;

  
  return (
      <div className={`pctOrganism pctGrid ${(data.parameters.text)&&"pctGridInText"} ${data.className}`}>
          {data.parameters.title &&
            <Text component="h2" text={data.parameters.title} className='titleSection' />
          }

          {data.parameters.description &&
            <Text component="p" text={data.parameters.description} className='textSection' />
          }
          <div className="pctCardGrid">
            {CardListing({ data: data, _parentData: _parentData })}
        </div>
      </div>
  )
}


export default Grid
