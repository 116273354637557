import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaRegComment, FaExclamationTriangle } from 'react-icons/fa';
import Divider from '@material-ui/core/Divider';
import InteractionReply from '../InteractionReply';
import InteractionMessageMenu from '../InteractionMessageMenu';
import './style.scss';
import SendMessageBox from 'pctComponents/molecules/SendMessageBox';
import useMediaQuery from 'pctHooks/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';

const InteractionMessage = ({
    handleReplySubmit,
    handleReplyList,
    idComment,
    menuOpitions,
    avatar,
    author,
    time,
    text,
    commentsCount,
    warningsCount,
    displayDivider = false,
    onResize,
    replies,
    removeButton,
    editButton,
    reportButton,
    reportedByMe,
    lastModifiedDate,
    totalReplies,
    handlerMoreAnswers,
    handlerHideAnswers,
    setShowNewMessageBox,
    showNewMessageBox,
    enableMessageBox,
}) => {
    const isMobile = useMediaQuery('md');
    const [init, setInit] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [showReplyBox, setShowReplyBox] = useState(false);
    const [newReply, setNewReply] = useState('');
    const [action, setAction] = useState('');
    const [messageReplies, setMessageReplies] = useState(replies);
    const [replyingToAuthor, setReplyingToAuthor] = useState(''); // New state for storing the author being replied to
    const messageTextRef = useRef<HTMLDivElement>(null);
    const messageRef = useRef<HTMLDivElement>(null);
    const [_reportedByMe, setReportedByMe] = useState(reportedByMe);
    const [currentMessage, setCurrentMessage] = useState(text);

    useEffect(() => {
        setCurrentMessage(text);
    }, [text]);

    useEffect(() => {
        setReportedByMe(reportedByMe);
    }, [reportedByMe]);

    useEffect(() => {
        setAction(menuOpitions.action);
    }, [menuOpitions]);

    const measureHeight = useCallback(() => {
        if (messageTextRef.current) {
            const { clientHeight, scrollHeight } = messageTextRef.current;
            const lineHeight = parseInt(window.getComputedStyle(messageTextRef.current).lineHeight, 10);
            const maxLines = 3;
            const maxHeight = lineHeight * maxLines;

            if (scrollHeight > maxHeight) {
                setShowMoreButton(true);
            } else {
                setShowMoreButton(false);
            }
        }

        if (messageRef.current) {
            const height = messageRef.current.getBoundingClientRect().height;
            onResize(height);
        }
    }, [onResize]);

    useEffect(() => {
        measureHeight();
    }, [text, expanded, measureHeight, replies]);

    useEffect(() => {
        setMessageReplies(replies);
    }, [replies]);

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (isMobile) {
            setShowNewMessageBox(!showReplyBox);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showReplyBox, isMobile]);

    const handleReplyToggle = () => {
        if (!isMobile && !showReplyBox) {
            setShowNewMessageBox(false);
        }

        setShowReplyBox(!showReplyBox);
        setNewReply('');
        setAction('reply');
        menuOpitions.setData({
            ...menuOpitions.data,
            action: 'reply',
        });
        setReplyingToAuthor(author);
    };

    const handleReplyOpen = (message: string) => {
        setShowReplyBox(true);
        setNewReply(message);
        menuOpitions.setData({
            ...menuOpitions.data,
            action: 'reply',
        });
        setReplyingToAuthor(author);
    };

    const handleReplySubmitButton = (newText) => {
        handleReplySubmit(idComment, action, newText);
        setNewReply('');
        setShowReplyBox(false);
    };

    const handleCancelReply = () => {
        setShowReplyBox(false);
    };
    const firstLetter = author ? author.substr(0, 1) : '';
    return (
        <div className="message-wrapper" ref={messageRef}>
            <div className="message">
                <div className="pctAvatar avatarImg avatarHrader">
                    <div className={`avtContainer message-avatar}`}>
                        {avatar ? <img src={avatar} className="avatarImg" alt={author} /> : <div className="avatarTxt">{firstLetter}</div>}
                    </div>
                </div>

                <div className="message-content">
                    <div className="message-author-time">
                        <p className="message-author">{author}</p>
                        <p className="message-time">{`${time} ${Boolean(lastModifiedDate) ? ' - editado' : ''}`}</p>
                        {(removeButton || editButton || Boolean(reportButton)) && (
                            <InteractionMessageMenu
                                removeButton={removeButton}
                                editButton={editButton}
                                idComment={idComment}
                                menuOpitions={menuOpitions}
                                reportButton={reportButton}
                                reportedByMe={_reportedByMe}
                                isReply={false}
                                type={'comment'}
                                message={currentMessage}
                            />
                        )}
                    </div>
                    <div className="message-text-container">
                        <div className={`message-text ${expanded ? 'expanded' : 'collapsed'}`} ref={messageTextRef}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: currentMessage,
                                }}
                            />
                        </div>
                        {showMoreButton && (
                            <button onClick={handleToggleExpand} className="message-toggle">
                                {expanded ? 'Ver menos' : 'Ver mais'}
                            </button>
                        )}
                    </div>
                    <div className="message-icons">
                        {Boolean(commentsCount) && (
                            <span
                                className="icon icon-reply-comments"
                                onClick={() => (Boolean(messageReplies.length) ? handlerHideAnswers(idComment) : handleReplyList(idComment))}
                            >
                                <FaRegComment /> {commentsCount > 0 ? commentsCount : ''}
                            </span>
                        )}
                        {/* {warningsCount > 0 && (
                            <span className="icon">
                                <FaExclamationTriangle style={{ color: 'red' }} /> {warningsCount}
                            </span>
                        )} */}
                        <span className="message-reply" onClick={handleReplyToggle}>
                            Responder
                        </span>
                    </div>
                    {!showNewMessageBox && showReplyBox && (
                        <div className={`reply-box${isMobile ? '-mobile' : ''}`}>
                            <div className={`new-message-box${isMobile ? '-mobile' : ''}`}>
                                {isMobile && (
                                    <div className="message-replying-to-cancel-button-mobile">
                                        <div>
                                            <span className="reply-mention-text">{action === 'edit' ? 'Editando resposta' : 'Respondendo a: '}</span>
                                            {action === 'reply' && <span className="reply-mention">{replyingToAuthor}</span>}
                                        </div>
                                        <button onClick={handleCancelReply} className="cancel-button-mobile">
                                            <CloseIcon />
                                        </button>
                                        <div className="overlay-reply-mention" onClick={handleCancelReply}></div>
                                    </div>
                                )}
                                <SendMessageBox
                                    isReply={true}
                                    placeholder={`Digite sua resposta...`}
                                    isEditing={action === 'edit'}
                                    editContent={action === 'edit' ? menuOpitions.replyEditText : newReply}
                                    handleToggle={() => {
                                        setNewReply('');
                                        setAction('');
                                        handleReplyToggle();
                                    }}
                                    handleSubmit={handleReplySubmitButton}
                                />
                            </div>
                        </div>
                    )}
                    {Boolean(messageReplies.length) && (
                        <div className="replies">
                            {messageReplies?.map((reply) => (
                                <InteractionReply
                                    key={reply.idComment}
                                    handleReplyOpen={handleReplyOpen}
                                    {...reply}
                                    menuOpitions={menuOpitions}
                                    avatar={reply.photo}
                                    author={reply.name}
                                    onResize={() => {}}
                                    handleReply={handleReplyToggle}
                                />
                            ))}
                            {messageReplies.length < totalReplies ? (
                                <div className="verMaisRespostas" onClick={() => handlerMoreAnswers(idComment)}>
                                    <hr /> Ver mais respostas({totalReplies - messageReplies.length})
                                </div>
                            ) : (
                                <div className="ocultarRespostas" onClick={() => handlerHideAnswers(idComment)}>
                                    <hr /> Ocultar respostas
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {displayDivider && <Divider className="message-divider" />}
        </div>
    );
};

export default InteractionMessage;
