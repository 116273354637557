import React, {useEffect} from 'react';
import '../../themes/import.scss';
import {Link} from 'react-router-dom';
import {getSectionById, transformText, getDesign} from '../../services/utils';
import {updateFooterStatus, updateHeaderStatus} from '../../actions';
import {connect} from 'react-redux';
import '../../assets/css/BannerMenu.scss';

const BannerMenu = ({idSection, updateFooterStatus, updateHeaderStatus, footerStatus}) => {
  function _getMenuData(idSection) {
    let data = getSectionById(idSection);
    return data.menuOption;
  }

  function customGetFooterSize() {
    if (footerStatus === 'true') {
      return {height: '100%', marginBottom: '5rem'};
    } else {
      return {};
    }
  }
  function renderImage(item) {
    function validImage(image){
      return (image && image !== 'null')
    }
    return (
      <>
        {item.imageStyle === 'icon' ? (
          <div className="banner-icon">
            {validImage(item.image) && (<img src={item.image} style={{maxHeight: 56, maxWidth: 57, marginBottom: 10, width: 'auto', height: 'auto'}} alt="" />)}
            <div style={getDesign(idSection, 'itemText')}>
              <span>{item.text}</span>
            </div>
            <div style={getDesign(idSection, 'itemDescription')}>
              <span>{ ( item.description && item.description !== 'null') && item.description }</span>
            </div>
          </div>
        ) : item.imageStyle === 'hybrid' ? (
          <div
            className="banner-tile"
            style={{
              background: validImage(item.image) ? `url('${item.image}')` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
            {item.showText === 1 && (
              <div className="banner-tile-text" style={{bottom:'40%'}}>
                { validImage(item.imageSecondary) && (<img src={item.imageSecondary} style={{maxHeight: 56, maxWidth: 57, width: 'auto', height: 'auto'}} alt="" />) }
                <div style={getDesign(idSection, 'itemText')}>
                  <span>{item.text}</span>
                </div>
                <div style={getDesign(idSection, 'itemDescription')}>
                  <span>{( item.description && item.description !== 'null') && item.description}</span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="banner-tile">
            {item.image && (
              <div
                className="banner-tile-image"
                style={{
                  background: validImage(item.image) ? `url('${item.image}')` : '',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                alt=""
              />
            )}
            {item.showText === 1 && (
              <div className="banner-tile-text">
                <div style={getDesign(idSection, 'itemText')}>
                  <span>{item.text}</span>
                </div>
                <div style={getDesign(idSection, 'itemDescription')}>
                  <span>{(item.description && item.description !== 'null') && item.description}</span>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  useEffect(() => {
    updateFooterStatus(getDesign(idSection, 'general')?.showBottomMenu);
    updateHeaderStatus(getDesign(idSection, 'general')?.showTopBar);
  }, []);

  const data = [..._getMenuData(idSection)];

  return (
    <div
      className="mapa-container"
      style={{
        ...getDesign(idSection, 'general'),
        ...customGetFooterSize(),
      }}>
      {data.map((item, index) => {

        const divStyleArray = {
          icon: {
            border: `3px solid ${getDesign(idSection,'general','backgroundColor',true)}`,
            backgroundColor: item.color,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
          hybrid: {
            border: `3px solid ${getDesign(idSection,'general','backgroundColor',true)}`,
            backgroundColor: item.color,
          },
          tile: {
            border: `3px solid ${getDesign(idSection, 'general', 'backgroundColor', true)}`,
          },

        }

        const divStyle = divStyleArray[item.imageStyle] || {};
        return (
          <Link
            to={`/${transformText(item.text)}/${item.sectionTo}?idSection=${item.sectionTo}`}
            className="mapa-item"
            key={index}
            style={{ ...divStyle, minWidth: index === 0 && data.length > 4 ? '66.66%' : ''}}>
            {renderImage(item, index)}
          </Link>
        );
      })}
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateFooterStatus: item => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: item => dispatch(updateHeaderStatus(item)),
});
const mapStateToProps = store => ({
  footerStatus: store.footerState.footerStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerMenu);
