import "./style.scss";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";

import { useFormik } from "formik";
import * as Yup from "yup";

import { getEmail, postEmail } from 'services/api';

import { BsFillExclamationCircleFill } from "react-icons/bs";

import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateConfigHeaderFooter } from "../../../actions";
import { Text } from "../../atoms";
import { PlaceholderContactus } from "../../placehoader";

import imgContactus from "../../../assets/images/contactus.png";

const ContactusSchema = Yup.object().shape({
  // assunto: Yup.string()
  //   .required("O preenchimento do campo é obrigatório."),
  mensagem: Yup.string()
    // .min(2, "No mínimo 2 caractéres")
    .required("O preenchimento do campo é obrigatório."),
});

const ContactUs = (props) => {
  
  const [dataEmail, setDataEmail ] = useState({to:'', description:'',  feedbackMessage:'', title:''});
  const [formComfirm, setFormComfirm ] = useState(false);
  const [preloaderStatus, setPreloader ] = useState(true);
  
  const { updateConfigHeaderFooter } = props;

  const formik = useFormik({
    validationSchema: ContactusSchema,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      mensagem: ""
    },
    onSubmit: (values) => {
     
      const { mensagem} = values;
      
      setPreloader(true);

      const obj = {
        "message": mensagem
      }
      postEmail('CONTACT_US', obj)
        .then((response) => {
          // console.log(response);
          setPreloader(false);
          setFormComfirm(true);
        })
        .catch((error) => {
          console.log(error);
        });

    },
  });

  updateConfigHeaderFooter({  footerStyle: "hide" });


  useEffect(() => {

    if(preloaderStatus){

      getEmail('CONTACT_US')
        .then((response) => {
          
          setDataEmail(response.data);
          setPreloader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    
    
  }, []);

  return (
    <>
        {
          <div className="contactUs-container">
            
              <div className="contactUs-wrapper">

                {( preloaderStatus )
                ? <PlaceholderContactus />
                : <div className="contactUs-form">
                    <Text component="h2" 
                          text={dataEmail.title} />

                    {(!formComfirm) 
                      ?<>
                        <Text component="p" 
                        text={dataEmail.description} /> 

                          <form onSubmit={formik.handleSubmit}>
                            {/* <div className={`inputBase inputBaseAssunto ${formik.errors.assunto ? 'erroBase': ''}`}>
                            <TextField
                              id="assuntoInput"
                              type="text"
                              label="Assunto"
                              name='assunto'
                              onChange={formik.handleChange}
                              value={formik.values.assunto}
                              
                              InputLabelProps={{
                                style: {
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "100%",
                                  color: "#fff",
                                  fontFamily: "'Open Sans', sans-serif",
                                  height: 25,
                                  fontWeight: 18,
                                  top: -7,
                                  left: 10
                                },
                              }}
                              inputProps={{
                                style: {
                                  WebkitBoxShadow: `0 0 0 1000px "transparent" inset`
                                },
                                autoComplete: "off",
                              }}
                            />
                            {formik.errors.assunto && (
                                <div className={'erroAlert'}>
                                  <BsFillExclamationCircleFill />
                                  <Text component="small" className="errorTxt"
                                  text={formik.errors.assunto} />
                                </div>
                              )}
                          </div>  */}

                          <div className={`inputBase inputBaseMensagem ${formik.errors.mensagem ? 'erroBase': ''}`}>
                            <TextField
                              id="mensagemInput"
                              type="text"
                              label="Mensagem"
                              name='mensagem'
                              multiline={true}
                              onChange={formik.handleChange}
                              value={formik.values.mensagem}

                              InputLabelProps={{
                                style: {
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "100%",
                                  color: "#ffff",
                                  fontFamily: "'Open Sans', sans-serif",
                                  height: 25,
                                  fontWeight: 18,
                                  top: 0,
                                  left: 10
                                },
                              }}
                              inputProps={{
                                style: {
                                  WebkitBoxShadow: `0 0 0 1000px "transparent" inset`
                                },
                                autoComplete: "off",
                              }}
                            />
                            {formik.errors.mensagem && (
                              <div className={'erroAlert'}>
                                <BsFillExclamationCircleFill />
                                <Text component="small" className="errorTxt"
                                text={formik.errors.mensagem} />
                              </div>
                            )}
                          </div>
                          <button className="btn" type="submit">
                            Enviar
                          </button>
                        </form>
                        </> 

                      :<div className="mensagemConfirm">
                        <Text component="h4" 
                          text={dataEmail.feedbackMessage} />
                      </div>
                    }
                  </div>}
              </div>
            

            <div className="contactUs-img"
              style={{
                backgroundImage: `url(${imgContactus})`,
              }}
            />
          </div>
        }
      </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(ContactUs);
