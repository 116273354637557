import { CONFIG_HEADER_FOOTER } from "../actions/actionTypes";

const initialState = {
  configHeaderFooter: {
    headerStyle: "",
    prevNavStyle: "",
    logoStyle: "",
    logoUrl: "",
    tituloStyle: "",
    darkStyle: "hide",
    perfilUsuarioStyle: "",
    perfilStyle: "hide",
    menuStyle: "",
    logoutStyle: "",
    titleHeader: "",
    lessonHeader: null,

    footerStyle: "",
    footerLogoUrl: "",
    footerLogoStyle: "",
  },
};

export const configHeaderFooterReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_HEADER_FOOTER:
      return {
        // ...state,
        configHeaderFooter: {
          ...initialState.configHeaderFooter,
          ...action.configHeaderFooter,
        },
      };
    default:
      return state;
  }
};
