import "./style.scss";
import React, { useEffect } from "react";
import NotFoundLogo from '../../../assets/images/not-found.svg';
import { Text } from "../../atoms";
import { TbFaceIdError } from "react-icons/tb";

type Props = {
  error ?: any
};


const ErrorComponent = ({ error }: Props) => {
  return (
    <>
      <div className="pctError">
          <div className="pctErrorContainer"> 
              <TbFaceIdError/>

              { (!error.message && !error.response.data.message) &&
                <Text text={"Um problema inesperado ocorreu."} />
              }

              { (error.message || error.response.data.message) &&
                <Text className="errorMessage" text={ error.response.data.message ? error.response.data.message : error.message} />
              }
          </div>
      </div>
    </>
  );
};


export default ErrorComponent;
