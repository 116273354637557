import "./styles.scss";

import React, { useState } from "react";

import AdminUpgrade from "./components/AdminUpgrade";

import { ContextManagerTrial } from "./context/contextManagerTrial";

import { Profile, ManagerTrialProps } from "./@model/ManagerTrial.model";
import ClientUpgrade from "./components/ClientUpgrade";

const ManagerTrial = (props: ManagerTrialProps) => {
  return (
    <ContextManagerTrial>
      {props.profile &&
        {
          [Profile.admin]: <AdminUpgrade modal={props.modal} />,
           [Profile.client]: (
             <ClientUpgrade
               modal={props.modal}
            />
           ),
        }[props.profile]}
    </ContextManagerTrial>
  );
};

export default ManagerTrial;
