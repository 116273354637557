import React from "react";

import { Label } from "pctComponents/atoms";

function HeaderLesson({ lessonHeader }) {
  return (
    <>
      {lessonHeader && (
        <div className="lessonHeader">
          <Label text={lessonHeader} />
        </div>
      )}
    </>
  );
}

export default HeaderLesson;
