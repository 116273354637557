import React from 'react'
import { Link } from 'react-router-dom'

import { getColors } from '../../services/utils'

export default function HomeMenuRow({ content }) {

  const [ firstItem ] = content

  const getFooterSize = () => (
    getColors('footer').showfooter === 'true' ||
      getColors('footer').showfooter === true
      ? { paddingBottom: '5rem' }
      : {}
  )
  

  const getStylesContainer = () => (
    firstItem.imageStyle === 'tile' ? {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      padding: "5px",
      gap: "5px",
      ...getFooterSize()
    } : {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      ...getFooterSize(),
      border: '3px solid #fff'
    }
  )

  const getStylesItem = () => (
    firstItem.imageStyle === 'tile' ? {
      flex: 1, 
      minWidth: "calc(50% - 5px)", 
      minHeight: "200px"
    } : {
      flex: 1, 
      minWidth: "calc(50% - 5px)", 
      minHeight: "200px",
      border: '3px solid #fff'
    }
  )

  return (
    <div className="home-image-container" style={{ ...getStylesContainer() }}>
      {
        content.map(item => (
          <Link key={item.contentLocation} style={{ ...getStylesItem() }} to={item.contentLocation}>
            {firstItem.imageStyle === 'tile'
              ?
              <div className="menu-item-image" 
                style={{ backgroundImage: `url('${item.image}')`}} 
                alt="img" 
              />
              :
              <div style={{ position: 'relative' }}>
                <img className="menu-item-icon" src={item.image} alt="" />
                <span className="menu-item-text">{item.text}</span>
              </div>
            }
          </Link>
        ))
      }
    </div>
  );
}