import React, { useState, useEffect, useContext } from 'react';

import { IoMdClose } from 'react-icons/io';
import './style.scss';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { getknowledgeEvaluationAnswerSheetQuestion } from 'services/api';

import { EvaluationKnowMultipleChoice } from '../../molecules';
import { Text } from '../../atoms';
import { PlaceholderMultipleChoice } from '../../placehoader';

type Props = {
    obj: any;
};

const ModalEvaluationKnowledgeFeedback = NiceModal.create(({ obj }: Props) => {
    const modal = useModal();
    const [answerSheetQuestion, setAnswerSheetQuestion] = useState(null);

    const {
        item,
        currentQuestion,
        indice,
        itemAll,
        contextCurrentIdUser,
        contextCurrentIdEnrollment,
        contextCurrentIdEvaluation,
        contextCurrentIdQuestionnaire,
    } = obj;

    useEffect(() => {
        getknowledgeEvaluationAnswerSheetQuestion(
            contextCurrentIdUser,
            contextCurrentIdEnrollment,
            contextCurrentIdEvaluation,
            contextCurrentIdQuestionnaire,
            currentQuestion.idAnswer,
        )
            .then((response) => {
                let question = response.data.question;
                if (currentQuestion.question.questionType === 'CORRELATE') {
                    const optionAnswers: {
                        idQuestionOptionAnswer: number, 
                        description: string,
                        idQuestionOptionAnswerCorrect:number,
                        questionOptionAnswerDescriptionCorrect:string,
                        idQuestionOptionChosenCorrect: string
                    }[] = [];
                    question.options.forEach((elem) => {
                        optionAnswers.push({
                            idQuestionOptionAnswer: elem.idQuestionOptionAnswerChosen,
                            description: elem.questionOptionAnswerDescriptionChosen,
                            idQuestionOptionAnswerCorrect: elem.idQuestionOptionAnswerCorrect,
                            questionOptionAnswerDescriptionCorrect: elem.questionOptionAnswerDescriptionCorrect,
                            idQuestionOptionChosenCorrect:  elem.idQuestionOptionChosenCorrect
                        });
                    });
                    question = { ...currentQuestion.question, optionAnswers };
                }
                setAnswerSheetQuestion({ ...currentQuestion, question });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const indiceCurrent: number = indice + 1;
    const iCV = indiceCurrent < 10 ? `0${indiceCurrent}` : indiceCurrent;
    const allV = itemAll < 10 ? `0${itemAll}` : itemAll;
    const porcCalc = Math.round((indiceCurrent / itemAll) * 100);

    const closed = (e) => {
        modal.remove();
    };

    return (
        <div className="modalEvaluationKnowledgeFeedback">
            <div className="modal-body-pct">
                <button className="closeBtn" onClick={(e) => closed(e)}>
                    <IoMdClose />
                </button>

                <div className="modal-container-know">
                    <div className="porcControl">
                        <div className="porcBarra">
                            <div className="porcBarraInt" style={{ width: `${porcCalc}%` }} />
                        </div>
                        <Text component={'p'} text={`<span>${iCV}</span> / ${allV}`} />
                    </div>
                    <div className="ptcEvaluationKnowMultipleChoiceBase">
                        {!answerSheetQuestion ? (
                            <PlaceholderMultipleChoice />
                        ) : (
                            <EvaluationKnowMultipleChoice
                                currentQuestion={answerSheetQuestion}
                                viewType={'modalFeed'}
                                evaluationCurrentIndex={indiceCurrent}
                                selectItemEvent={() => {}}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ModalEvaluationKnowledgeFeedback;
