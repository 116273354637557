import "./style.scss";

import React from "react";


type Props = {
  status?: boolean | string
  css?: object
  className?: string
  percentage: string | number | null | undefined
}

function ProgressBar({ status = '', percentage, css = {}, className = "" }: Props) {

  // console.log(typeof(percentage))

  const porcValue = ( percentage !== null && percentage !== undefined ) ? +percentage : 0;
  const porcString = porcValue.toString() + "%";

  const Style={
    width: porcString,
    ...css
  }

  return (
    ( percentage !== null && percentage !== undefined ) ? 
      (<div className={`pctAtom pctProgressBarContainer ${className} 
          pctProgressModel${status}
          ${( porcValue===100 ) ? 'pctPBComplete' : ''}`}>
        <div className="pctProgressBar" style={Style}></div>
      </div>) : null 
  )
}

export default ProgressBar