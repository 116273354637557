import React, { useState} from 'react';
import { Link }  from 'react-router-dom';
import { getDesignById } from '../../services/utils';
import style from './styles.module.scss';
import {  ReactComponent as ActivatedEye } from '../../assets/images/activatedEye.svg'
import { ReactComponent as DeactivatedEye } from '../../assets/images/deactivatedEye.svg'
import { getDesign } from '../../services/utils'

const HighlightedPostListCard = ({ index, idSection, item }) => {

    function _getDesign(item, only = false, valueOnly = false){
        let design = getDesignById(idSection)
    
        if(!design[item]){return null} // return null if theres no item specified
    
        if(only){ // parameter only means it will only return the property specified
          if(valueOnly){ return design[item][only] } // parameter valueOnly means it will only return the property value if specified
    
          design = { [only]:design[item][only] }
          return design
        }
    
        return design[item]
    }

    const [widthSize, setWidthSize] = useState(window.innerWidth);

    const updateWidthSize = () => {
        setWidthSize(window.innerWidth)
    }

    window.addEventListener('resize', updateWidthSize);

    const viewed = Boolean(item.visits);
    const ViewedComponent = viewed ? ActivatedEye : DeactivatedEye
    const ViewedComponentColor = {fill: _getDesign("itemText","color",true)}
    const textStyleMain = _getDesign("itemText")
    const emphasis = getDesign(idSection, 'menuitem')
    const textColor = (Boolean(emphasis) && Boolean(emphasis.emphasisTextColor)) ? {'color': emphasis.emphasisTextColor} : null

    return(
        <Link key={index} to={`/content/${item.idContentItem}?idSection=${idSection}`} className={style.mainWrapper}>
            <div className={style.mainImgWrapper}>
                <img className={style.mainImg} src={`${item.image}`} alt="" />
                <div className={style.infoWrapper}>
                    <div>
                        <ViewedComponent style={ViewedComponentColor}/>
                    </div>
                    <div style={{ ...textStyleMain, textAlign: 'inherit', fontWeight: 700, ...textColor}}>
                        {item.text}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default HighlightedPostListCard