import "./style.scss";

import React from "react";

const UnAvailable = () => {
    const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="242" height="318" viewBox="0 0 242 318">
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_2035" data-name="Retângulo 2035" width="19.58" height="27.09" className="svgBorder"/>
      </clipPath>
    </defs>
    <g id="Grupo_3384" data-name="Grupo 3384" transform="translate(-316.733 -809)">
      <path id="Caminho_2267" data-name="Caminho 2267" d="M4,0H238a4,4,0,0,1,4,4V314a4,4,0,0,1-4,4H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0Z" transform="translate(316.733 809)" fill="#363636" opacity="0"/>
      <g id="Grupo_3382" data-name="Grupo 3382" transform="translate(-344.068 392.314)">
        <g id="Grupo_3243" data-name="Grupo 3243" transform="translate(223.096 145.29)">
          <g id="Grupo_3383" data-name="Grupo 3383">
            <g id="Grupo_761" data-name="Grupo 761" transform="translate(548.916 397.145)">
              <g id="Grupo_757" data-name="Grupo 757" clip-path="url(#clip-path)">
                <path id="Caminho_451" data-name="Caminho 451" d="M101.742,11.3V10.159h-.028c.011-1.174.067-2.336-.018-3.487A7.318,7.318,0,0,0,96.074.221C95.664.128,95.246.072,94.832,0H93.88c-.215.035-.43.074-.646.105a7.406,7.406,0,0,0-6.278,7.223q0,1.187,0,2.374h0v1.6h3.137v-.847a.424.424,0,0,0,.015-.138c0-.644-.009-1.287,0-1.931a12.787,12.787,0,0,1,.063-1.661,4.242,4.242,0,0,1,8.428.551c.019.961.006,1.923.007,2.884h0V11.3Z" transform="translate(-84.558 0.001)" className="svgBorder"/>
                <path id="Caminho_452" data-name="Caminho 452" d="M17.969,425.988H1.611A1.611,1.611,0,0,1,0,424.377V411.811A1.611,1.611,0,0,1,1.611,410.2H17.969a1.611,1.611,0,0,1,1.611,1.611v12.566a1.611,1.611,0,0,1-1.611,1.611" transform="translate(0 -398.898)" className="svgBorder"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  `;

  return svgStr;
}

export default UnAvailable
